import { colors } from "@fermionprotocol/react-kit";
import styled from "styled-components";

const Span = styled.span`
  font-size: 0.75rem;
  color: ${colors.greyDark};
  &:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

interface Props {
  pageIndex: number;
  pageSize: number;
  allItems: number;
}
export function PaginationPages({ pageIndex, pageSize, allItems }: Props) {
  const start = pageSize * pageIndex - (pageSize - 1);
  const end = pageSize * pageIndex;

  return (
    <Span>
      Showing {start} - {end > allItems ? allItems : end} of {allItems} entries
    </Span>
  );
}
