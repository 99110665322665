import { Grid, Typography } from "@bosonprotocol/react-kit";
import { borders, colors, SectionHeader } from "@fermionprotocol/react-kit";
import FermionTokenCoinImg from "assets/fermion-token-coin.png";
import { ExploreAssetsLink } from "components/homepage/ExploreAssetsLink";
import styled from "styled-components";

interface Props {
  variant?: "horizontal" | "vertical";
}

const Wrapper = styled(Grid)`
  border: 1px solid ${colors.velvetBorder};
  border-radius: ${borders.big}px;
  background-color: ${colors.pinkLightest};
`;

const TypographyWrapper = styled.div`
  flex-grow: 1;
`;

const StyledSectionHeader = styled(SectionHeader)`
  display: block;
`;

export const BuyerHubCard: React.FC<Props> = ({ variant = "vertical" }) => {
  return (
    <Wrapper
      flexDirection={variant === "vertical" ? "column" : "row"}
      gap={`${variant === "vertical" ? "1" : "1.5"}rem`}
      alignItems="center"
      padding={`1.5rem ${variant === "vertical" ? "1" : "1.5"}rem`}
      width="auto"
      marginRight={variant === "vertical" ? "1rem" : undefined}
    >
      <Grid>
        <img
          src={FermionTokenCoinImg}
          style={{ margin: "auto auto" }}
          alt="fermion token coin"
        />
      </Grid>
      <TypographyWrapper>
        <StyledSectionHeader
          tag="h4"
          textAlign="center"
          padding={0}
          fontWeight={400}
          color={colors.velvet}
          margin={"0 auto"}
          fontSize={"1.125rem"}
          width={"100%"}
        >
          Buyer Hub
        </StyledSectionHeader>
        <Typography
          textAlign="center"
          fontSize="0.875rem"
          style={{ color: colors.velvetLight }}
        >
          Buy whole or fractionalized physical RWAs using Fermion.
        </Typography>
      </TypographyWrapper>
      {/* TODO: set valid navigate link */}
      <div>
        <ExploreAssetsLink size="small" style={{ margin: "0 auto" }} />
      </div>
    </Wrapper>
  );
};
