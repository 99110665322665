import { Grid, Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import { WarningCircle } from "phosphor-react";
import { ReactNode, useEffect } from "react";

export function ErrorModal({
  errorMessage,
  detailedErrorMessage,
  children,
}: {
  errorMessage?: string;
  detailedErrorMessage?: string;
  children?: ReactNode;
}) {
  const { updateProps, store } = useModal();
  useEffect(() => {
    updateProps<"ERROR">({
      ...store,
      modalProps: {
        ...store.modalProps,
        errorMessage,
        detailedErrorMessage,
      },
      modalSize: "auto",
      modalMaxWidth: {
        xs: "34.375rem",
      },
      modalMinWidth: {
        xs: "300px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, detailedErrorMessage]);
  return (
    <Grid flexDirection="column" alignItems="center">
      <WarningCircle size={128} color={colors.orange} />

      <Typography fontWeight="600" fontSize="1.5rem" lineHeight="150%">
        {errorMessage || "Error"}
      </Typography>
      <Typography
        fontWeight="400"
        fontSize="1rem"
        lineHeight="150%"
        margin="0.5rem 0 1.5rem 0"
      >
        {detailedErrorMessage || "Something went wrong"}
      </Typography>
      {children}
    </Grid>
  );
}
