import { Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";

import { Container, List, ListItem } from "./styles";

export const SellerRoleComponent = () => {
  return (
    <Container>
      <Typography fontSize="1.125rem" color={colors.velvet}>
        Seller
      </Typography>
      <Typography
        fontSize="0.875rem"
        color={colors.velvetLight}
        marginBottom="1.5rem"
      >
        I am a brand or an authorized reseller, selling my products directly to
        end consumers.
      </Typography>

      <Typography
        fontSize="0.875rem"
        color={colors.velvet}
        marginTop="1.5rem"
        marginBottom="0.4375rem"
        fontWeight={500}
      >
        What you will need to create an account:
      </Typography>
      <List>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Brand information, including the name, description, and category
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Brand assets, a logo and cover picture with a maximum size of 600kb
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Business contact information, such as email, phone number, address,
            and website
          </Typography>
        </ListItem>
      </List>

      <Typography
        fontSize="0.875rem"
        color={colors.velvet}
        marginTop="1.5rem"
        marginBottom="0.4375rem"
        fontWeight={500}
      >
        What comes next?
      </Typography>
      <List>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Create and list a real world asset for sale
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Manage bids and configure your payout settings
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Add and assign team member roles
          </Typography>
        </ListItem>
      </List>
    </Container>
  );
};
