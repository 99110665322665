import { hooks as bosonHooks } from "@bosonprotocol/react-kit";
import { hooks, Profile, Role } from "@fermionprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import React, { useCallback, useEffect, useState } from "react";

import { SelectRoleToCreate } from "../common/select-role/SelectRoleToCreate";
import { ProfileSummary } from "../common/summary-step/ProfileSummary";
import { CreateProfileSteps } from "./const";
import { CreateProfileForm } from "./create-step/CreateProfileForm";
import { InfoCreateAccount } from "./info-create-account/InfoCreateAccount";
import { ProfileSuccess } from "./success-step/ProfileSuccess";

const defaultFirstStep = CreateProfileSteps.INFO_CREATE_ACCOUNT;
interface CreateProfileFlowProps {
  defaultStep?: CreateProfileSteps;
  defaultRole?: Role;
}

export const CreateProfileFlow: React.FC<CreateProfileFlowProps> = ({
  defaultStep,
  defaultRole,
}) => {
  const [showClosedBeta, setShowClosedBeta] = useState<boolean>(true);
  const { showModal } = useModal();
  const [step, setStep] = useState<CreateProfileSteps>(
    defaultStep ? defaultStep : defaultFirstStep,
  );
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [profileData, setProfileData] = useState<Profile | undefined>();
  const { address } = bosonHooks.useAccount();
  const { data: { checkIfSellerIsWhiteListed } = {} } =
    hooks.useSellerCurationList();
  useEffect(() => {
    setShowClosedBeta(!address || !checkIfSellerIsWhiteListed?.(address));
  }, [address, checkIfSellerIsWhiteListed]);

  const onRoleSelected = useCallback((role: Role): void => {
    setSelectedRole(role);
    setStep(CreateProfileSteps.CREATE);
  }, []);

  const onSelectRoleBack = useCallback(() => {
    setStep(defaultFirstStep);
  }, []);

  return (
    <>
      {step === defaultFirstStep ? (
        <InfoCreateAccount
          onCreateAccountClick={() => {
            if (showClosedBeta) {
              showModal({ modalType: "CLOSED_BETA" });
            } else {
              setStep(CreateProfileSteps.SELECT_ROLE);
            }
          }}
        />
      ) : step === CreateProfileSteps.SELECT_ROLE ? (
        <SelectRoleToCreate
          onRoleSelected={onRoleSelected}
          defaultRole={defaultRole}
          goBack={onSelectRoleBack}
        />
      ) : step === CreateProfileSteps.CREATE && selectedRole ? (
        <CreateProfileForm
          role={selectedRole}
          onSubmit={async (profile) => {
            setProfileData(profile);
            setStep(CreateProfileSteps.SUMMARY);
          }}
          goBack={() => {
            setStep(CreateProfileSteps.SELECT_ROLE);
          }}
        />
      ) : step === CreateProfileSteps.SUMMARY && profileData && selectedRole ? (
        <ProfileSummary
          roles={[selectedRole]}
          values={profileData}
          goBack={() => {
            setStep(CreateProfileSteps.CREATE);
          }}
          onSubmit={() => {
            setStep(CreateProfileSteps.SUCCESS);
          }}
          nextText="Confirm and create profile"
        />
      ) : step === CreateProfileSteps.SUCCESS ? (
        <ProfileSuccess
          setupAnotherRole={() => {
            setSelectedRole(undefined);
            setProfileData(undefined);
            setStep(CreateProfileSteps.SELECT_ROLE);
          }}
        />
      ) : (
        <p>There has been an error, please try again...</p>
      )}
    </>
  );
};
