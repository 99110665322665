import * as Yup from "yup";
import { selfVerifierOrThirdPartyOptions, } from "../constants/selfVerifierOrThirtParty";
import { validationMessage } from "./common";
export const getSelfVerifierOrThirdPartySelectValidationSchema = ({ required, }) => {
    if (required) {
        return Yup.object({
            value: Yup.mixed().oneOf(selfVerifierOrThirdPartyOptions.map((option) => option.value)),
            label: Yup.string(),
        })
            .required(validationMessage.required)
            .nullable(true)
            .default(null);
    }
    return Yup.object({
        value: Yup.mixed().oneOf(selfVerifierOrThirdPartyOptions.map((option) => option.value)),
        label: Yup.string(),
    })
        .nullable(true)
        .default(null);
};
