import { Grid, Typography } from "@bosonprotocol/react-kit";
import { Button } from "@fermionprotocol/react-kit";
import fermionSphere from "assets/fermion-sphere.png";
import { CONFIG } from "config";
import { styled } from "styled-components";

const Wrapper = styled(Grid)`
  max-width: 35rem;
`;

export const ClosedBeta = () => {
  return (
    <Wrapper
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="1.5rem"
      padding="1rem"
    >
      <img
        src={fermionSphere}
        style={{ margin: "0 2.5rem 0 2.5rem" }}
        width="328"
        height="342"
      />
      <Grid
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Typography tag="h2" margin={0}>
          Closed Beta
        </Typography>
        <Typography
          tag="p"
          textAlign="center"
          margin={0}
          style={{ maxWidth: "31.25rem" }}
        >
          Our product is currently in closed beta. If you'd like to stay updated
          on our progress and be among the first to know when we launch
          publicly, please sign up below. We are excited to share our journey
          with you!
        </Typography>
      </Grid>
      <a href={CONFIG.signUpLink} rel="noopener noreferrer" target="_blank">
        <Button variant="pink" style={{ width: "11.6875rem" }}>
          Sign Up
        </Button>
      </a>
    </Wrapper>
  );
};
