import { jsx as _jsx } from "react/jsx-runtime";
import { eCheckoutRequestStatus, eFNFTStatus } from "@fermionprotocol/common";
import { CircleWavyWarning, Package, ShieldCheck, ShieldCheckered, } from "phosphor-react";
import { colors } from "../../styles/colors";
export const getStatusToData = ({ status, checkoutRequestStatus, }) => {
    const data = {
        backgroundColor: colors.blueLight,
        color: colors.blue,
        icon: (props) => _jsx(ShieldCheck, { color: colors.blue, ...props }),
        label: "",
    };
    switch (status) {
        case eFNFTStatus.Inexistent: // TODO: not in figma
            data.label = "INEXISTENT";
            break;
        case eFNFTStatus.Wrapped: // TODO: not in figma
            data.label = "WRAPPED";
            break;
        case eFNFTStatus.Unverified:
            data.label = "IN VERIF.";
            data.backgroundColor = colors.purpleLight;
            data.color = colors.purple;
            data.icon = (props) => (_jsx(ShieldCheck, { color: colors.purple, ...props }));
            break;
        case eFNFTStatus.Verified:
            data.label = "VERIFIED";
            data.backgroundColor = colors.greenLight;
            data.color = colors.green;
            data.icon = (props) => (_jsx(ShieldCheck, { color: colors.green, ...props }));
            break;
        case eFNFTStatus.CheckedIn:
            data.label = "IN CUSTODY";
            data.backgroundColor = colors.greenLight;
            data.color = colors.green;
            data.icon = (props) => (_jsx(ShieldCheckered, { color: colors.green, ...props }));
            break;
        case eFNFTStatus.CheckedOut:
            data.label = "CHECKED OUT";
            data.backgroundColor = colors.greenLight;
            data.color = colors.green;
            data.icon = (props) => (_jsx(Package, { color: colors.green, ...props }));
            break;
        case eFNFTStatus.Burned: // this is the same as eVerificationStatus.Rejected according to the core-components
            data.label = "VERIF. DENIED";
            data.backgroundColor = colors.redLight;
            data.color = colors.red;
            data.icon = (props) => (_jsx(ShieldCheckered, { color: colors.red, ...props }));
            break;
        default:
            break;
    }
    switch (checkoutRequestStatus) {
        case eCheckoutRequestStatus.CheckOutRequestCleared:
        case eCheckoutRequestStatus.CheckOutRequested:
            data.label = "CHECKOUT REQ.";
            data.backgroundColor = colors.blueLight;
            data.color = colors.blue;
            data.icon = (props) => (_jsx(CircleWavyWarning, { color: colors.blue, ...props }));
            break;
        case eCheckoutRequestStatus.None:
        case eCheckoutRequestStatus.CheckedIn: // already handled in eFNFTStatus.CheckedIn
        case eCheckoutRequestStatus.CheckedOut: // already handled in eFNFTStatus.CheckedOut
        case undefined:
            break;
    }
    return data;
};
