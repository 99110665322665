import { Grid, Typography } from "@bosonprotocol/react-kit";
import {
  bridgingRoleOptions,
  Button,
  colors,
  Profile,
  profileValidationSchema,
  Role,
  SimpleError,
} from "@fermionprotocol/react-kit";
import * as Sentry from "@sentry/browser";
import { useModal } from "components/modal/useModal";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";

import { ProfileFormFields } from "../../common/ProfileFormFields";
import { roleToIcon } from "../../common/select-role/const";

interface Props {
  initial: Profile;
  onSubmit: (createValues: Profile) => Promise<void>;
  goBack: () => void;
  role: Role;
}

export function EditProfileRoleForm({
  onSubmit,
  goBack,
  role,
  initial,
}: Props) {
  const [error, setError] = useState<Error | null>(null);
  const { updateProps, store } = useModal();
  const roleBeingAdded = roleToIcon.find(
    (roleItem) => role === roleItem.role,
  )?.roleDisplay;
  useEffect(() => {
    updateProps<"ADD_ROLE_TO_PROFILE">({
      ...store,
      modalProps: {
        ...store.modalProps,
        contentStyle: undefined,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              tag="h3"
              fontWeight={500}
              marginBottom="0.5rem"
              marginTop={0}
            >
              Add info
            </Typography>
            {roleBeingAdded && (
              <Typography fontWeight={400} color={colors.greyDark}>
                Add specific {roleBeingAdded.toLowerCase()} info to your profile
              </Typography>
            )}
          </Grid>
        ),
        footerComponent: undefined,
      },
      modalMaxWidth: {
        s: "550px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);
  return (
    <Formik<Profile>
      validationSchema={profileValidationSchema}
      initialValues={{
        ...initial,
        roles: [role, ...initial.roles],
      }}
      onSubmit={async (values) => {
        try {
          setError(null);
          await onSubmit(values);
        } catch (err) {
          console.error(err);
          Sentry.captureException(error);
          setError(err as Error);
        }
      }}
    >
      {(formValues) => {
        console.log(formValues);
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Form>
            <ProfileFormFields
              overrideBridgingRoleOptions={bridgingRoleOptions.filter(
                (option) => !initial.roles.includes(option.value), // if you are a custodian, you wont be able to select bridger-custodian (and same for verifier)
              )}
            >
              {error ? <SimpleError /> : <></>}
              <Grid
                margin="2rem 0 0 0"
                justifyContent="space-between"
                gap="2rem"
              >
                <Button variant="white" type="button" onClick={() => goBack()}>
                  Back
                </Button>
                <Button variant="pink" type="submit">
                  Next
                </Button>
              </Grid>
            </ProfileFormFields>
          </Form>
        );
      }}
    </Formik>
  );
}
