import { useContext } from "react";

import { CoreSDKContext } from "../../components/core-sdk/CoreSDKContext";

export function useCoreSDKContext() {
  const context = useContext(CoreSDKContext);
  if (!context) {
    throw new Error(
      `useCoreSDKContext can only be used under 'CoreSDKContext'`,
    );
  }
  return context;
}
