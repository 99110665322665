import { Error, Grid, Typography } from "@bosonprotocol/react-kit";
import { useField, useFormikContext } from "formik";
import styled, { css } from "styled-components";

import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "name"> {
  childLeft?: React.ReactNode;
  childRight?: React.ReactNode;
  name: string;
  hideError?: boolean;
}

const IconWrapper = styled(Typography)<{
  $iconPosition?: "left" | "right";
}>`
  display: inline-flex;
  background: ${colors.whiteOff};
  border: 1px solid transparent;
  align-items: center;
  cursor: default;
  padding: 0 0.75rem;
  ${(props) =>
    props.$iconPosition &&
    css`
    border-${props.$iconPosition === "left" ? "right" : "left"}: none;
    border-top-${props.$iconPosition}-radius: ${borders.big}px;
    border-bottom-${props.$iconPosition}-radius: ${borders.big}px;
    `}
`;

const Input = styled.input<{ $iconPosition?: ("left" | "right")[] }>`
  width: 100%;
  height: 100%;
  background: ${colors.whiteOff};
  border: 1px solid transparent;
  border-radius: ${borders.big}px;
  flex-grow: 1;
  padding: 1rem;
  height: 100%;
  ${(props) =>
    props.$iconPosition?.length === 1 &&
    css`
    border-${props.$iconPosition}: none;
    border-top-${props.$iconPosition}-radius: 0;
    border-bottom-${props.$iconPosition}-radius: 0;
    `}
  ${(props) =>
    props.$iconPosition?.length === 2 &&
    css`
      border-inline: none;
      border-radius: 0;
    `}
`;

const Wrapper = styled(Grid)`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${colors.border};
  outline: none;
  border-radius: ${borders.big}px;
  transition: all 150ms ease-in-out;
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    border: 1px solid ${colors.pink};
    caret-color: ${colors.pink};
  }
`;

export const InputWithIcon: React.FC<Props> = ({
  childLeft,
  childRight,
  name,
  hideError,
  ...props
}) => {
  const { status } = useFormikContext();
  const [field, meta] = useField(name);
  const errorText = meta.error || status?.[name];
  const errorMessage = errorText && meta.touched ? errorText : "";
  const displayError = typeof errorMessage === "string" && errorMessage !== "";

  const iconPositions: ("left" | "right")[] = [];
  childLeft && iconPositions.push("left");
  childRight && iconPositions.push("right");

  return (
    <div style={{ width: "100%" }}>
      <Wrapper alignItems="none">
        {childLeft && (
          <IconWrapper $iconPosition="left" tag="span">
            {childLeft}
          </IconWrapper>
        )}
        <Input $iconPosition={iconPositions} {...field} {...props} />
        {childRight && (
          <IconWrapper $iconPosition="right" tag="span">
            {childRight}
          </IconWrapper>
        )}
      </Wrapper>
      <Error display={!hideError && displayError} message={errorMessage} />
    </div>
  );
};
