import { Grid, Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";
import { WarningCircle } from "phosphor-react";
import { useEffect } from "react";

import { useModal } from "../../../useModal";

export function TransactionFailedModal({
  errorMessage,
  detailedErrorMessage,
}: {
  errorMessage: string;
  detailedErrorMessage?: string;
}) {
  const { updateProps, store } = useModal();
  useEffect(() => {
    updateProps<"TRANSACTION_FAILED">({
      ...store,
      modalProps: {
        ...store.modalProps,
        errorMessage,
        detailedErrorMessage,
      },
      modalSize: "auto",
      modalMaxWidth: {
        xs: "34.375rem",
      },
      modalMinWidth: {
        xs: "300px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, detailedErrorMessage]);
  return (
    <Grid flexDirection="column" alignItems="center">
      <WarningCircle size={128} color={colors.orange} />

      <Typography fontWeight="600" fontSize="1.5rem" lineHeight="150%">
        {errorMessage || "Confirmation Failed"}
      </Typography>
      <Typography
        fontWeight="400"
        fontSize="1rem"
        lineHeight="150%"
        margin="0.5rem 0 1.5rem 0"
      >
        {detailedErrorMessage || "Please retry this action"}
      </Typography>
    </Grid>
  );
}
