import { Grid, Loading, Typography } from "@bosonprotocol/react-kit";
import {
  borders,
  breakpoint,
  Button,
  colors,
  formatCurrency,
  getMutationKey,
  hooks,
  resolveUrlFromIPFS,
} from "@fermionprotocol/react-kit";
import { FnftData } from "components/buyer/components/BuyerTableRenderContent";
import { ExploreAssetsLink } from "components/homepage/ExploreAssetsLink";
import { GridRow } from "components/modal/components/shared/GridRow";
import { GridTwoColumns } from "components/modal/components/shared/GridTwoColumns";
import { CONFIG } from "config";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import { generateListingPath } from "lib/utils/generateListingPath";
import { fermionRoutes } from "router/routeNames";
import styled from "styled-components";

type Props =
  | {
      type: "checked-out";
      fnft: FnftData;
      hideModal: () => void;
    }
  | { type: "product-created"; offerUUId: string; isFinished: boolean };

const Wrapper = styled.div`
  display: grid;
  gap: 2.5rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  ${breakpoint.s} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const FnftDetails = styled(Grid)`
  border-radius: ${borders.big}px;
  border: 1px solid ${colors.velvetBorder};
`;

const FnftHeader = styled(Grid)`
  padding-bottom: 2rem;
  border-bottom: 1px solid ${colors.velvetBorder};
`;

const Img = styled.img`
  margin: auto;
  display: block;
  max-height: 31.25rem;
  min-width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  aspect-ratio: 1/1;
  border-radius: ${borders.big}px;
`;

export const CongratulationSection: React.FC<Props> = (props) => {
  const navigate = useCustomNavigate();

  const { isSuccess: isTransactionLoaded } = hooks.useWaitForTransaction({
    options: {
      retry: 5,
      retryDelay: 2000,
      enabled: props.type === "product-created" && props.isFinished,
    },
    getMutationKeyFnc: () =>
      getMutationKey.createOffer(
        props.type === "product-created" ? props.offerUUId : "",
      ),
  });

  const {
    data: offers = [],
    isLoading,
    isFetched,
  } = hooks.useGetOffers({
    ...(props.type === "product-created" && {
      queryVars: { offersFilter: { metadata_: { uuid: props.offerUUId } } },
    }),
    options: {
      select: (data) =>
        data.map((offer) => ({
          brandName: offer.metadata?.product?.brand.name ?? "",
          thumbnail: resolveUrlFromIPFS(
            offer.metadata?.product?.visuals_images[0]?.url || "",
            CONFIG.ipfsGateway,
          ),
          custodian: offer.custodian?.metadata?.name,
          verifier: offer.verifier.metadata?.name,
          condition: offer.metadata?.condition,
          productName: offer.metadata?.name ?? "",
          offer: {
            sellerDeposit: offer.sellerDeposit,
            verifierFee: offer.verifierFee,
            exchangeToken: offer.exchangeToken,
            custodianFee: offer.custodianFee,
            sellerId: offer.sellerId,
            metadata: offer.metadata,
            bosonOfferId: offer.bosonOfferId,
          },
          price: undefined,
        })),
      enabled: props.type === "product-created" && isTransactionLoaded,
    },
  });

  const fnft = props.type === "product-created" ? offers?.[0] : props.fnft;

  if (
    props.type === "product-created" &&
    (isLoading || !isFetched || !props.isFinished)
  ) {
    return (
      <Grid margin="6rem 0">
        <Loading style={{ margin: "0 auto" }} />
      </Grid>
    );
  }

  if (!fnft) {
    <Wrapper>
      {/* TODO: */}
      NOT FOUND
    </Wrapper>;
  }

  return (
    <Wrapper>
      <ImageWrapper>
        <Img src={fnft?.thumbnail} alt="Thumbnail" />
      </ImageWrapper>
      <Grid flexDirection="column">
        <FnftDetails
          marginBottom="2.5rem"
          padding="2.5rem"
          flexDirection="column"
          gap="1rem"
        >
          <FnftHeader gap="0.25rem" flexDirection="column">
            <Typography
              tag="span"
              style={{ textTransform: "uppercase" }}
              fontSize="0.875rem"
              color={colors.velvetLight}
            >
              {fnft?.brandName}
            </Typography>
            <Typography
              tag="h3"
              margin="0"
              fontSize="1.5rem"
              style={{ textTransform: "capitalize" }}
            >
              {fnft?.productName}
            </Typography>
          </FnftHeader>
          <GridTwoColumns>
            <GridRow label="Asset condition" text={fnft?.condition} />
            {props.type === "checked-out" ? (
              <GridRow
                label="Price"
                text={
                  fnft?.price
                    ? `${formatCurrency(fnft.price.amount)} ${fnft.price.currency}`
                    : "-"
                }
              />
            ) : (
              <GridRow
                label="Seller deposit"
                text={fnft?.offer.sellerDeposit}
              />
            )}
          </GridTwoColumns>
          <GridTwoColumns>
            <GridRow label="Verifier" text={fnft?.verifier} />
            <GridRow label="Custodian" text={fnft?.custodian} />
          </GridTwoColumns>
          {props.type === "product-created" && (
            <GridTwoColumns>
              <GridRow
                label="Verification fee"
                text={`${fnft?.offer?.verifierFee} ${fnft?.offer?.exchangeToken.symbol}`}
              />
              <GridRow
                label="Custodian fee"
                text={`${fnft?.offer?.custodianFee.amount} ${fnft?.offer?.exchangeToken.symbol}`}
              />
            </GridTwoColumns>
          )}
        </FnftDetails>
        <Grid flexDirection="column" gap="0.5rem">
          {props.type === "product-created" ? (
            <>
              <Button
                variant="pink"
                style={{ width: "100%" }}
                onClick={() => {
                  navigate({
                    pathname: generateListingPath({
                      sellerId: `${fnft?.offer?.sellerId}`,
                      uuid: `${fnft?.offer?.metadata?.uuid}`,
                      bosonOfferId: `${fnft?.offer?.bosonOfferId}`,
                    }),
                  });
                }}
              >
                <Typography tag="span">List on OpenSea</Typography>
              </Button>
              <Button
                variant="transparent"
                style={{ width: "100%" }}
                onClick={() => {
                  navigate({
                    pathname: fermionRoutes.hub,
                  });
                }}
              >
                <Typography tag="span">Back to Agent Hub</Typography>
              </Button>
            </>
          ) : (
            <>
              <ExploreAssetsLink
                hideIcon
                style={{ width: "100%", marginBottom: "0.5rem" }}
              />
              <Button
                variant="transparent"
                style={{ width: "100%" }}
                onClick={() => {
                  props.hideModal();
                  navigate({
                    pathname: fermionRoutes.buyer,
                  });
                }}
              >
                <Typography>Back to Buyer Hub</Typography>
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
};
