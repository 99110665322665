import { colors } from "@fermionprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import React from "react";
import styled from "styled-components";

interface NavItem {
  label: string;
  route: string;
  external?: boolean;
}

interface MobileMenuProps {
  navItems: NavItem[];
}

const HamburgerContainer = styled.div`
  /* Left px's for proper scaling */
  width: 14px;
  height: 10px;
  position: relative;
  cursor: pointer;
`;

const HamburgerLine = styled.span`
  display: block;
  /* Left px's for proper scaling */
  width: 14px;
  height: 1px;
  background-color: ${colors.velvetLight};
  position: absolute;
  right: 0;

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  &:nth-child(3) {
    bottom: 0;
  }
`;

const HamburgerButton = styled.button`
  /* Left px's for proper scaling */
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 3rem;
  padding: 0;
  background: none;
  border: none;
`;

export const MobileMenu: React.FC<MobileMenuProps> = ({ navItems }) => {
  const { showModal } = useModal();

  return (
    <HamburgerButton
      onClick={() => {
        showModal({
          modalType: "BUYER_HUB_MOBILE_MENU_MODAL",
          isMobileSlidingModal: true,
          modalProps: {
            navItems: navItems,
          },
        });
      }}
    >
      <HamburgerContainer>
        <HamburgerLine />
        <HamburgerLine />
        <HamburgerLine />
      </HamburgerContainer>
    </HamburgerButton>
  );
};
