/* eslint-disable @typescript-eslint/no-explicit-any */
// source: https://wagmi.sh/react/ethers-adapters

import { handleUseQueryError } from "@fermionprotocol/react-kit";
import { useWeb3React } from "@web3-react/core";
import { providers } from "ethers";
import { BrowserProvider } from "ethers-v6";
import { useQuery } from "react-query";

export function useProvider() {
  const { provider } = useWeb3React();
  return provider;
}

export function useSignerV6() {
  const providerV5 = useProvider();
  const url = providerV5?.connection?.url;
  return handleUseQueryError(
    useQuery(["signer", url], async () => {
      if (!providerV5) {
        return;
      }
      const providerV6 = providerV5toV6(providerV5);
      return await providerV6?.getSigner();
    }),
  );
}

function providerV5toV6(
  providerV5: providers.Web3Provider,
): BrowserProvider | undefined {
  return providerV5
    ? new BrowserProvider({
        request: async (request: {
          method: string;
          params?: Array<any> | Record<string, any>;
        }) => {
          const params = request.params
            ? Array.isArray(request.params)
              ? (request.params as Array<any>)
              : Array.from(request.params.values())
            : [];
          return providerV5.send(request.method, params);
        },
      })
    : undefined;
}

export type SignerV6 = Awaited<
  ReturnType<
    Exclude<Awaited<ReturnType<typeof providerV5toV6>>, undefined>["getSigner"]
  >
>;
