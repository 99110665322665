import { atom } from "jotai";
import _ from "lodash";
const conversionAtom = atom({});
export const readWriteConversionAtom = atom((get) => get(conversionAtom), (get, set, newValue) => {
    // no ref atom
    const currentAtom = { ...get(conversionAtom) };
    const incKeys = Object.keys(newValue);
    for (const incKey of incKeys) {
        const currency = currentAtom[incKey];
        if (!currency) {
            _.set(currentAtom, incKey, newValue[incKey]);
            continue;
        }
        const incCurrency = newValue[incKey];
        if (!incCurrency)
            throw new Error("Currency not found");
        for (const currencyKey in incCurrency) {
            if (!(currencyKey in currency))
                continue;
            _.set(currentAtom, `${incKey}.${currencyKey}`, incCurrency[currencyKey]);
        }
    }
    set(conversionAtom, currentAtom);
});
