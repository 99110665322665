import { Typography } from "@bosonprotocol/react-kit";
import type { Dayjs } from "dayjs";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useCallback } from "react";

import { Button } from "../../buttons/Button";
import { Selector } from "./DatePicker.style";
import { changeMonth } from "./utils";

interface Props {
  month: Dayjs;
  setMonth: React.Dispatch<React.SetStateAction<Dayjs>>;
}

export function SelectMonth({ month, setMonth }: Props) {
  const handleMonthChange = useCallback(
    (isNext: boolean) => setMonth(changeMonth(month, isNext)),
    [setMonth, month],
  );

  return (
    <Selector>
      <Button variant={"pinkLight"} onClick={() => handleMonthChange(false)}>
        <CaretLeft size={18} />
      </Button>
      <Typography tag="p">{month.format("MMMM YYYY")}</Typography>
      <Button variant={"pinkLight"} onClick={() => handleMonthChange(true)}>
        <CaretRight size={18} />
      </Button>
    </Selector>
  );
}
