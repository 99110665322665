export const FONT_CONSTANTS = {
    PPFragment: "PPFragment",
    PPFragmentGlareBlack: "PPFragmentGlareBlack",
    PPFragmentGlareBlackItalic: "PPFragmentGlareBlackItalic",
    PPFragmentGlareBold: "PPFragmentGlareBold",
    PPFragmentGlareBoldItalic: "PPFragmentGlareBoldItalic",
    PPFragmentGlareExtraBold: "PPFragmentGlareExtraBold",
    PPFragmentGlareExtraBoldItalic: "PPFragmentGlareExtraBoldItalic",
    PPFragmentGlareExtraLight: "PPFragmentGlareExtraLight",
    PPFragmentGlareExtraLightItalic: "PPFragmentGlareExtraLightItalic",
    PPFragmentGlareLight: "PPFragmentGlareLight",
    PPFragmentGlareLightItalic: "PPFragmentGlareLightItalic",
    PPFragmentGlareMedium: "PPFragmentGlareMedium",
    PPFragmentGlareMediumItalic: "PPFragmentGlareMediumItalic",
    PPFragmentGlareRegular: "PPFragmentGlareRegular",
    PPFragmentGlareRegularItalic: "PPFragmentGlareRegularItalic",
    PPFragmentGlareSemiBold: "PPFragmentGlareSemiBold",
    PPFragmentGlareSemiBoldItalic: "PPFragmentGlareSemiBoldItalic",
    PPFragmentGlareThin: "PPFragmentGlareThin",
    PPFragmentGlareThinItalic: "PPFragmentGlareThinItalic",
};
