import * as Yup from "yup";

import {
  productCategoriesOptions,
  ProductCategoryValues,
} from "../constants/productCategories";
import { validationMessage } from "./common";

export const getCategorySelectValidationSchema = ({
  required,
}: {
  required: boolean;
}) => {
  if (required) {
    return Yup.object({
      value: Yup.mixed<keyof typeof ProductCategoryValues>().oneOf(
        productCategoriesOptions.map((category) => category.value),
      ),
      label: Yup.string(),
    })
      .required(validationMessage.required)
      .nullable(true)
      .default(null);
  }
  return Yup.object({
    value: Yup.mixed<keyof typeof ProductCategoryValues>().oneOf(
      productCategoriesOptions.map((category) => category.value),
    ),
    label: Yup.string(),
  })
    .nullable(true)
    .default(null);
};
