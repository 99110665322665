import * as Yup from "yup";
import { storageFeesPeriodOptions } from "../constants/storageFeesPeriod";
import { validationMessage } from "./common";
import { getNumberValidation } from "./number";
import { getSelectOptionValidation } from "./selectOption";
const numberValidationProperties = {
    min: 0,
    optional: true,
};
const tokenValidation = getNumberValidation({
    ...numberValidationProperties,
    required: false,
});
const tokenValidationRequired = getNumberValidation({
    ...numberValidationProperties,
    required: true,
});
export const getStorageFeesValidation = ({ required, }) => {
    const storageFeesValidation = Yup.array(Yup.object({
        usdc: tokenValidation.test({
            name: "usdcRequired",
            message: validationMessage.required,
            test: function (value) {
                console.log("usdc validation", value);
                return testIfRequiredToken.call(this, value, "usdc");
            },
        }),
        weth: tokenValidation.test({
            name: "wethRequired",
            message: validationMessage.required,
            test: function (value) {
                console.log("weth validation", value);
                return testIfRequiredToken.call(this, value, "weth");
            },
        }),
    }));
    if (!required) {
        return storageFeesValidation;
    }
    return storageFeesValidation
        .required(validationMessage.required)
        .min(1, validationMessage.length(1));
    function testIfRequiredToken(value, tokenValue) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const storageFeesTokens = this.from?.[1]?.value?.storageFeesTokens;
        const isValid = storageFeesTokens?.some(({ value }) => value === tokenValue)
            ? tokenValidationRequired.isValidSync(value, { abortEarly: true })
            : true;
        console.log("testIfRequiredToken", {
            that: this,
            value,
            tokenValue,
            storageFeesTokens,
            isValid,
        });
        return isValid;
    }
};
export const getStorageFeesTokensValidation = ({ required, }) => {
    const storageFeesTokensValidation = Yup.array(getSelectOptionValidation({
        required: false,
    }));
    if (!required) {
        return storageFeesTokensValidation;
    }
    return storageFeesTokensValidation
        .required(validationMessage.required)
        .min(1, validationMessage.length(1));
};
export const getStorageFeesPeriodValidation = ({ required, }) => {
    const storageFeesPeriodValidation = getSelectOptionValidation({
        required,
        oneOfValues: storageFeesPeriodOptions.map((option) => option.value),
        default: null,
        nullable: true,
    });
    return storageFeesPeriodValidation;
};
