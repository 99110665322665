import { lazy } from "react";

import { fermionRoutes } from "./routeNames";
import { IRoute } from "./types";
const CreateProductPage = lazy(
  () => import("../pages/hub/create-product/CreateProductPage"),
);
const HomePage = lazy(() => import("../pages/home/HomePage"));
const BuyerHubPage = lazy(() => import("../pages/buyer/BuyerHubPage"));
const AgentHubPage = lazy(() => import("../pages/hub/AgentHubPage"));
const ListingPage = lazy(() => import("../pages/listing/ListingPage"));
const AboutPage = lazy(() => import("../pages/about/AboutPage"));
const TermsAndConditionsPage = lazy(
  () => import("../pages/terms-and-conditions/TermsAndConditionsPage"),
);
const PrivacyPolicyPage = lazy(
  () => import("../pages/privacy-policy/PrivacyPolicyPage"),
);
// TODO: to be commented out after incen. testnet launch
// const MoonPayPage = lazy(() => import("../pages/moonpay/MoonPayPage"));
const NotFoundPage = lazy(() => import("../pages/not-found/NotFound"));

const base = {
  component: null,
  index: false,
  auth: false,
} as const;

export const allRoutes = [
  {
    ...base,
    index: true,
    exact: true,
    path: fermionRoutes.home,
    component: HomePage,
  },
  {
    ...base,
    index: true,
    exact: true,
    auth: true,
    path: fermionRoutes.buyer + "/*",
    component: BuyerHubPage,
  },
  {
    ...base,
    exact: false,
    auth: true,
    path: fermionRoutes.hub + "/*",
    component: AgentHubPage,
  },
  {
    ...base,
    index: true,
    exact: true,
    auth: true,
    path: fermionRoutes.createProduct,
    component: CreateProductPage,
  },
  {
    ...base,
    index: true,
    exact: true,
    auth: true,
    path: fermionRoutes.listing,
    component: ListingPage,
  },
  // TODO: to be commented out after incen. testnet launch
  // {
  //   ...base,
  //   index: true,
  //   exact: true,
  //   path: fermionRoutes.moonpay,
  //   component: MoonPayPage,
  // },
  {
    ...base,
    index: true,
    exact: true,
    path: fermionRoutes.termsAndConditions,
    component: TermsAndConditionsPage,
  },
  {
    ...base,
    index: true,
    exact: true,
    path: fermionRoutes.privacyPolicy,
    component: PrivacyPolicyPage,
  },
  {
    ...base,
    index: true,
    exact: true,
    path: fermionRoutes.about,
    component: AboutPage,
  },
  {
    ...base,
    exact: false,
    path: "*",
    component: NotFoundPage,
  },
] as const satisfies IRoute[];
