import {
  Currencies,
  CurrencyDisplay,
  Grid,
  hooks as bosonHooks,
  isTruthy,
  Typography,
} from "@bosonprotocol/react-kit";
import {
  borders,
  boxShadows,
  Button,
  colors,
  hooks,
  InfoBox,
  InfoBoxProps,
  Profile,
  resolveUrlFromIPFS,
  Role,
  roleToEntityRole,
  SimpleError,
  tokensValues,
} from "@fermionprotocol/react-kit";
import React, { useCallback } from "react";
import { styled } from "styled-components";

import { ProfileFormFieldsTemplate } from "../ProfileFormFieldsTemplate";

const infoBoxTheme = {
  wrapperProps: {
    $boxShadow: boxShadows.infoBoxDefault,
  },
} satisfies InfoBoxProps["customTheme"];

const Img = styled.img`
  border-radius: ${borders.defaultBorderRadiusPx}px;
`;
const Text = styled(Grid).attrs({ color: colors.greyDark })``;
const USDC = (
  <CurrencyDisplay
    currency={Currencies.USDC}
    style={{ height: "auto", width: "auto" }}
  />
);
const WETH = (
  <CurrencyDisplay
    currency={Currencies.WETH}
    style={{ height: "auto", width: "auto" }}
  />
);
const labelToCurrencyComponent = {
  USDC: USDC,
  WETH: WETH,
} as const;
type ProfileSummaryProps = {
  onSubmit: () => void;
  goBack: () => void;
  values: Profile;
  roles: Role[] | null | undefined;
  nextText: string;
};
export const ProfileSummary: React.FC<ProfileSummaryProps> = ({
  values,
  goBack,
  onSubmit,
  roles,
  nextText,
}) => {
  const {
    hook: { mutateAsync: createOrUpdateEntity, isLoading, error },
  } = hooks.useCreateOrUpdateEntity();

  const FeePriceBands = useCallback(
    ({
      feePriceBands,
      feePriceBandsTokens,
    }: {
      feePriceBands: Profile["verifier"]["feePriceBands"];
      feePriceBandsTokens: Profile["verifier"]["feePriceBandsTokens"];
    }) => {
      return (
        <Text flexDirection="column" alignItems="flex-start">
          <Typography fontSize="0.7rem" fontWeight={600} padding="0.4rem 0">
            Accepted tokens
          </Typography>
          <Grid marginBottom="2rem">
            {feePriceBandsTokens?.filter(isTruthy).map(({ label }) => {
              return (
                <Grid key={label} justifyContent="flex-start" gap="0.5rem">
                  {
                    labelToCurrencyComponent[
                      label as keyof typeof labelToCurrencyComponent
                    ]
                  }
                  {label}
                </Grid>
              );
            })}
          </Grid>
          <Typography fontSize="0.7rem" fontWeight={600} padding="0.4rem 0">
            Price bands
          </Typography>
          {feePriceBands?.map((feePriceBand) => {
            const { min, max, usdc, weth } = feePriceBand;
            return (
              <Grid
                key={JSON.stringify(feePriceBand)}
                gap="1rem"
                justifyContent="space-between"
                flex={0}
              >
                <Grid width="fit-content">
                  ${min}-{max}
                </Grid>{" "}
                {![undefined, null, ""].includes(usdc as null) && (
                  <Grid>
                    <div>{usdc}</div>
                    {USDC}
                  </Grid>
                )}{" "}
                {![undefined, null, ""].includes(weth as null) && (
                  <Grid>
                    <div>{weth}</div>
                    {WETH}
                  </Grid>
                )}
              </Grid>
            );
          })}
        </Text>
      );
    },
    [],
  );
  const LocationAddress = useCallback(
    ({ location }: { location: Profile["custodian"]["location"] }) => {
      return (
        <Grid flexDirection="column" alignItems="flex-start">
          <Text>{location.streetName}</Text>
          <Text>{location.number}</Text>
          <Text>{location.city}</Text>
          <Text>{location.state}</Text>
          <Text>{location.zip}</Text>
          <Text>{location.country}</Text>
        </Grid>
      );
    },
    [],
  );
  const StorageFees = useCallback(
    ({
      storageFeesTokens,
      storageFees,
    }: {
      storageFeesTokens: Profile["custodian"]["storageFeesTokens"];
      storageFees: Profile["custodian"]["storageFees"];
    }) => {
      const [onlyStorageFee] = storageFees || [];
      return (
        <Grid flexDirection="column" alignItems="flex-start">
          <Text>
            {storageFeesTokens?.filter(isTruthy).map(({ value, label }) => {
              return (
                <Grid key={value} justifyContent="flex-start" gap="1rem">
                  {value === tokensValues.usdc
                    ? onlyStorageFee?.usdc
                    : onlyStorageFee?.weth}{" "}
                  {label
                    ? labelToCurrencyComponent[
                        label as keyof typeof labelToCurrencyComponent
                      ]
                    : ""}
                </Grid>
              );
            })}
          </Text>
        </Grid>
      );
    },
    [],
  );
  const InsuredValue = useCallback(
    ({
      insuredValue,
    }: {
      insuredValue: Profile["custodian"]["insuredValue"];
    }) => {
      return (
        <Text justifyContent="flex-start" alignItems="center">
          {insuredValue} USD
        </Text>
      );
    },
    [],
  );
  const { ipfsImageGateway } = bosonHooks.useIpfsContext();
  return (
    <Grid flexDirection="column" alignItems="flex-start">
      <ProfileFormFieldsTemplate
        roles={roles}
        withSubtitles={false}
        withRequired={false}
        Logo={
          <Img
            src={resolveUrlFromIPFS(
              values.logo?.[0]?.src || "",
              ipfsImageGateway,
            )}
            width={128}
            height={128}
          />
        }
        CoverPicture={
          <Img
            src={resolveUrlFromIPFS(
              values.coverPicture?.[0]?.src || "",
              ipfsImageGateway,
            )}
            width={227}
          />
        }
        bridgingRoles={values.bridger.bridgingRoles}
        BridgerWhichRoleAreYouBridging={undefined}
        BridgerVerifierName={<Text>{values.bridger.verifier.name}</Text>}
        BridgerVerifierCategory={
          <Text>{values.bridger.verifier.category?.label}</Text>
        }
        BridgerVerifierAuthenticationReport={
          <Text>{values.bridger.verifier.authenticationReport}</Text>
        }
        BridgerVerifierValuationCertificate={
          <Text>{values.bridger.verifier.valuationCertificate}</Text>
        }
        BridgerVerifierFeePriceBands={
          <FeePriceBands
            feePriceBands={values.bridger.verifier.feePriceBands}
            feePriceBandsTokens={values.bridger.verifier.feePriceBandsTokens}
          />
        }
        BrigerVerifierSelfOrThirdParty={
          <Text>{values.bridger.verifier.selfOrThirdParty?.label}</Text>
        }
        BridgerCustodianName={<Text>{values.bridger.custodian.name}</Text>}
        BridgerCustodianCategory={
          <Text>{values.bridger.custodian.category?.label}</Text>
        }
        BridgerCustodianLocationName={
          <Text>{values.bridger.custodian.locationName}</Text>
        }
        BridgerCustodianLocationAddress={
          <LocationAddress location={values.bridger.custodian.location} />
        }
        BridgerCustodianInsuredValue={
          <InsuredValue insuredValue={values.bridger.custodian.insuredValue} />
        }
        BridgerCustodianStorageFee={
          <StorageFees
            storageFeesTokens={values.bridger.custodian.storageFeesTokens}
            storageFees={values.bridger.custodian.storageFees}
          />
        }
        BridgerCustodianShippingMethod={
          <Text>{values.bridger.custodian.shippingMethod?.label}</Text>
        }
        CustodianCategory={<Text>{values.custodian.category?.label}</Text>}
        CustodianLocationAddress={
          <LocationAddress location={values.custodian.location} />
        }
        CustodianLocationName={<Text>{values.custodian.locationName}</Text>}
        CustodianInsuredValue={
          <InsuredValue insuredValue={values.custodian.insuredValue} />
        }
        CustodianShippingMethod={
          <Text>{values.custodian.shippingMethod?.label}</Text>
        }
        CustodianStorageFee={
          <StorageFees
            storageFeesTokens={values.custodian.storageFeesTokens}
            storageFees={values.custodian.storageFees}
          />
        }
        VerifierAuthenticationReport={
          <Text>{values.verifier.authenticationReport}</Text>
        }
        VerifierCategory={<Text>{values.verifier.category?.label}</Text>}
        VerifierValuationCertificate={
          <Text>{values.verifier.valuationCertificate}</Text>
        }
        VerifierFeePriceBands={
          <FeePriceBands
            feePriceBands={values.verifier.feePriceBands}
            feePriceBandsTokens={values.verifier.feePriceBandsTokens}
          />
        }
        VerifierSelfOrThirdParty={
          <Text>{values.verifier.selfOrThirdParty?.label}</Text>
        }
        Name={<Text>{values.name}</Text>}
        Description={<Text>{values.description}</Text>}
        Email={<Text>{values.email}</Text>}
        BusinessPhoneNumber={<Text>{values.businessPhoneNumber}</Text>}
        Website={<Text>{values.website}</Text>}
        LegalTradingName={<Text>{values.legalTradingName}</Text>}
        BusinessRegistrationAddress={
          <LocationAddress location={values.businessLocation} />
        }
        WebsiteForFermion={<Text>{values.websiteForFermionIdentity}</Text>}
      />
      <InfoBox
        message="Profile information can be edited or changed at anytime."
        customTheme={infoBoxTheme}
      />
      {error && <SimpleError>{error.message}</SimpleError>}
      <Grid margin="2rem 0 0 0" justifyContent="space-between" gap="2rem">
        <Button variant="white" type="button" onClick={() => goBack()}>
          Back
        </Button>
        <Button
          variant="pink"
          type="button"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={async () => {
            await createOrUpdateEntity({
              // if you create a role, then first one, when updating, we dont care
              role: roles?.[0] ? roleToEntityRole[roles[0]] : undefined,
              values,
            });
            onSubmit();
          }}
        >
          {nextText}
        </Button>
      </Grid>
    </Grid>
  );
};
