import { Grid, Typography } from "@bosonprotocol/react-kit";
import { Button, colors } from "@fermionprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import { saveItemInStorage } from "hooks/storage/useLocalStorage";
import { fermionRoutes } from "router/routeNames";
import styled from "styled-components";

import { COOKIE_CONSTANTS } from "./CookieConstants";

const StyledLinks = styled.a`
  color: ${colors.pink};
  text-decoration: none;
  cursor: pointer;
`;

export function CookieModal() {
  const { hideModal } = useModal();

  const navigate = useCustomNavigate();

  const handleAccept = () => {
    saveItemInStorage("cookies-accepted", true);
    hideModal();
  };

  const navigateToPrivacyPolicy = () => {
    navigate({
      pathname: fermionRoutes.privacyPolicy,
      search: `?${COOKIE_CONSTANTS.URL_PARAM_SOURCE}=${COOKIE_CONSTANTS.URL_PARAM_VALUE_COOKIE_MODAL}`,
    });
    hideModal();
  };

  const navigateToTermsAndConditions = () => {
    navigate({
      pathname: fermionRoutes.termsAndConditions,
      search: `?${COOKIE_CONSTANTS.URL_PARAM_SOURCE}=${COOKIE_CONSTANTS.URL_PARAM_VALUE_COOKIE_MODAL}`,
    });
    hideModal();
  };

  return (
    <Grid gap="2rem">
      <Typography fontWeight="400" fontSize="1rem" lineHeight="1.5rem">
        <span>
          This website uses cookies to improve user experience. By using our
          website you consent to all cookies in accordance with our&nbsp;
          <StyledLinks onClick={navigateToPrivacyPolicy}>
            Privacy&nbsp;Policy
          </StyledLinks>
          &nbsp;and agree to be bound by our&nbsp;
          <StyledLinks onClick={navigateToTermsAndConditions}>
            Terms&nbsp;and&nbsp;Conditions
          </StyledLinks>
          .
        </span>
      </Typography>
      <Button variant="pinkLightest" onClick={handleAccept}>
        Okay
      </Button>
    </Grid>
  );
}
