import { Currencies, Grid, Typography } from "@bosonprotocol/react-kit";
import {
  colors,
  formatExpirationMessage,
  formatUSD,
  getShortenEthAddress,
  hooks,
  Subgraph,
} from "@fermionprotocol/react-kit";
import { SymbolToCurrency } from "components/finances/FinancesTable";
import { useModal } from "components/modal/useModal";
import { TableButtonStyled, TableStyled } from "components/table/TableStyles";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

interface BidSubTableProps {
  subRows: {
    address: string;
    price: string;
    tokenId: string | null;
    closingDate: string;
  }[];
  setAcceptedBid: Dispatch<SetStateAction<number | undefined>>;
  acceptedBid: undefined | number;
  offer: Subgraph.OfferFieldsFragment;
}

const BidSubTableAcceptButton = styled(TableButtonStyled)`
  min-height: 2.125rem;
  width: 4.5625rem;
`;

export const BidSubTable = ({
  subRows,
  acceptedBid,
  offer,
}: BidSubTableProps) => {
  const { showModal } = useModal();
  const { data: conversionRate } = hooks.useConversionPrice({
    fromTokens: ["ETH"],
    toToken: "USD",
  });
  return (
    <TableStyled className="subrows-table">
      <div className="thead">
        <div className="tr">
          <div className="th">
            <Typography
              marginLeft={"1rem"}
              fontSize={"0.75rem"}
              fontWeight={500}
              color={colors.velvetLight}
            >
              From
            </Typography>
          </div>
          <div className="th">
            <Typography
              fontSize={"0.75rem"}
              fontWeight={500}
              color={colors.velvetLight}
            >
              Expiration
            </Typography>
          </div>
          <div className="th">
            <Typography
              fontSize={"0.75rem"}
              fontWeight={500}
              color={colors.velvetLight}
            >
              Bid price
            </Typography>
          </div>
          <div className="th">
            <Typography
              fontSize={"0.75rem"}
              fontWeight={500}
              color={colors.velvetLight}
            >
              Actions
            </Typography>
          </div>
        </div>
      </div>
      <div className="tbody">
        {subRows.map((subRow, subIndex) => (
          <div
            className="tr row"
            key={`subRow-${subIndex}`}
            style={{
              padding: "0.5rem 0",
            }}
          >
            <div className="td">
              <Typography
                fontSize={"0.875rem"}
                fontWeight={"500"}
                color={colors.velvet}
                marginLeft={"1.5rem"}
              >
                {getShortenEthAddress(subRow.address)}
              </Typography>
            </div>
            <div className="td">
              <Typography
                fontSize={"0.875rem"}
                fontWeight={"500"}
                color={colors.velvetLight}
              >
                {formatExpirationMessage(subRow.closingDate)}
              </Typography>
            </div>
            <div className="td">
              <Grid
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Grid
                  alignItems="center"
                  justifyContent="flex-start"
                  width={"min-content"}
                >
                  <SymbolToCurrency
                    currency={Currencies.ETH}
                    hideLabel
                    width={18}
                    height={18}
                  />
                  <Typography
                    fontSize={"0.875rem"}
                    fontWeight={"400"}
                    color={colors.velvet}
                    marginLeft={"0.3125rem"}
                  >
                    {subRow.price}
                  </Typography>
                </Grid>
                <Typography
                  color={colors.velvetLight}
                  fontSize={"0.75rem"}
                  fontWeight={400}
                >
                  {conversionRate
                    ? formatUSD(
                        Number(subRow.price) * conversionRate.ETH.USD,
                        "USD",
                        true,
                      )
                    : "-"}
                </Typography>
              </Grid>
            </div>
            <div className="td">
              <BidSubTableAcceptButton
                variant={"green"}
                onClick={() => {
                  if (!subRow.tokenId) {
                    return;
                  }
                  showModal({
                    modalType: "ACCEPT_BID",
                    modalProps: {
                      sellerId: offer.sellerId,
                      exchangeTokenAddress: offer.exchangeToken.address,
                      exchangeTokenDecimals: offer.exchangeToken.decimals,
                      offerSellerDeposit: offer.sellerDeposit,
                      offerId: offer.id,
                      tokenId: subRow.tokenId,
                    },
                  });
                }}
              >
                <Typography fontSize={"0.75rem"}>
                  {acceptedBid !== undefined ? "Accepted" : "Accept"}
                </Typography>
              </BidSubTableAcceptButton>
            </div>
          </div>
        ))}
      </div>
    </TableStyled>
  );
};
