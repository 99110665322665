import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BaseButton as Button, Grid, } from "@bosonprotocol/react-kit";
import { styled } from "styled-components";
import { borders } from "../../styles/borders";
import { colors, defaultColors } from "../../styles/colors";
const Wrapper = styled(Grid) `
  border-radius: ${borders.big}px;
  background-color: ${defaultColors.inputBackgroundColor};
  padding: 0.5rem;
`;
const activeTheme = {
    background: colors.white,
    color: colors.purpleDeep,
    borderRadius: borders.medium,
    borderWidth: 1,
    borderColor: colors.shade,
    boxShadow: `0 0.5625rem 2.5rem 0 ${colors.blackLight}`,
    hover: {
        background: colors.white,
        color: colors.purpleDeep,
    },
};
const inactiveTheme = {
    background: "transparent",
    color: colors.purpleDeep,
    borderRadius: borders.medium,
    borderWidth: 1,
    hover: {
        ...activeTheme,
    },
};
export const ToggleButton = ({ active, leftChild, rightChild, onLeftClick, onRightClick, ...rest }) => {
    return (_jsxs(Wrapper, { gap: "0.25rem", flex: 1, ...rest, children: [_jsx(Button, { theme: active === "left" ? activeTheme : inactiveTheme, onClick: onLeftClick, children: leftChild }), _jsx(Button, { theme: active === "right" ? activeTheme : inactiveTheme, onClick: onRightClick, children: rightChild })] }));
};
