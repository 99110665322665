import { SelectProps } from "@bosonprotocol/react-kit";
import { stringConstants } from "@fermionprotocol/common";

export const ProductCategoryValues = stringConstants.ProductCategory;

export enum ProductCategoryLabels {
  Wine = "Wine",
  Watches = "Watches",
  Spirits = "Spirits",
  Art = "Art",
  Automobiles = "Automobiles",
  Commodities = "Commodities",
  RealEstates = "Real Estates",
  Other = "Other",
}

const productCategoryLabels = Object.values(ProductCategoryLabels);
export const productCategoriesOptions = Object.values(
  ProductCategoryValues,
).map((category, index) => {
  const label = productCategoryLabels[index];
  if (!label) {
    throw new Error(`no product label for category ${category}`);
  }
  return {
    label,
    value: category,
  };
}) satisfies SelectProps["options"];

export const categoryToAttributes = {
  wine: [
    "Wine typology",
    "Age/year",
    "ABV",
    "Size",
    "Country",
    "Region",
    "Varietal/Blend (Grape)",
  ],
  watches: [
    "Year",
    "Make",
    "Model",
    "Ref. number",
    "Case size",
    "Case material",
    "Strap",
    "Movement",
  ],
} as const satisfies Partial<
  Record<keyof typeof ProductCategoryValues, string[]>
>;
