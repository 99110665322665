export const SelfVerifierOrThirdPartyValues = {
    true: "true", // is self verifier
    false: "false", // third party verifier
};
export const SelfVerifierOrThirdPartyLabels = {
    "I am a self-verifier": "I am a self-verifier",
    "I am a third party verifier": "I am a third party verifier",
};
export const selfVerifierOrThirdPartyOptions = [
    {
        value: SelfVerifierOrThirdPartyValues.true,
        label: SelfVerifierOrThirdPartyLabels["I am a self-verifier"],
    },
    {
        value: SelfVerifierOrThirdPartyValues.false,
        label: SelfVerifierOrThirdPartyLabels["I am a third party verifier"],
    },
];
