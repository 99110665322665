import { MetaTxConfig } from "@bosonprotocol/common";
import { ProtocolConfig } from "@fermionprotocol/common";
import { createContext } from "react";

export type ConfigContextProps = {
  config: ProtocolConfig & { metaTx?: Partial<MetaTxConfig> };
};

export const FermionConfigContext = createContext<
  | (ConfigContextProps & {
      setEnvConfig: React.Dispatch<React.SetStateAction<ProtocolConfig>>;
    })
  | null
>(null);
