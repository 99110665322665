import { ChainId } from "@bosonprotocol/common";
import { MarketplaceType } from "@fermionprotocol/core-sdk";
import dayjs from "dayjs";
import { Signer as SignerV6 } from "ethers-v6";
import { Chain as OSChain, OpenSeaSDK } from "opensea-js";
import { API_BASE_MAINNET, API_BASE_TESTNET } from "opensea-js/lib/constants";
import { useMutation } from "react-query";

import { invariant } from "../../../lib/utils/invariant";
import { formatDecimalsToIntValue } from "../../../utils/number/formatDecimalsToIntValue";
import { useCoreSDKContext } from "../useCoreSDKContext";

export function getOpenSeaChain(chainId: ChainId): OSChain {
  switch (chainId) {
    case 1:
      return OSChain.Mainnet;
    case 137:
      return OSChain.Polygon;
    case 80002:
      return OSChain.Amoy;
    case 11155111:
      return OSChain.Sepolia;
    case 31337:
      return "hardhat" as OSChain;
    default:
      throw new Error(`Chain ${chainId} not supported`);
  }
}

export function createOpenSeaSDK<Signer extends SignerV6 | undefined>(
  signerV6: Signer,
  chainId: ChainId,
  openSeaApiKey: string,
): OpenSeaSDK {
  if (!signerV6) {
    throw new Error("Signer v6 not found");
  }
  let openseaUrl;
  switch (chainId) {
    case 1:
    case 137:
      openseaUrl = API_BASE_MAINNET;
      break;
    default:
      openseaUrl = API_BASE_TESTNET;
  }
  const openseaSdk = new OpenSeaSDK(
    signerV6,
    {
      chain: getOpenSeaChain(chainId),
      apiKey: openSeaApiKey,
      apiBaseUrl: openseaUrl,
    },
    (line) => console.info(`SEPOLIA OS: ${line}`),
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (openseaSdk.api as any).apiBaseUrl = openseaUrl; // << force the API URL
  return openseaSdk;
}

interface OpenSeaListingOptions<T extends SignerV6 | undefined> {
  signerV6: T;
  tokenId: string;
  price: number;
  duration: number;
  openSeaApiKey: string;
  openseaFeeRecipient: string;
  nftContract: string | null;
  decimals: number;
  exchangeTokenAddress: string;
}

export const useOpenSeaListing = <T extends SignerV6 | undefined>({
  signerV6,
  tokenId,
  price,
  duration,
  openSeaApiKey,
  openseaFeeRecipient,
  nftContract,
  decimals,
  exchangeTokenAddress,
}: OpenSeaListingOptions<T>) => {
  const coreSDK = useCoreSDKContext();

  const createListing = async (onSuccess: () => void) => {
    try {
      invariant(signerV6, "Signer not found");
      const openseaSdk = coreSDK.marketplace(
        MarketplaceType.OPENSEA,
        createOpenSeaSDK(
          signerV6,
          (await coreSDK.web3Lib.getChainId()) as ChainId,
          openSeaApiKey,
        ),
        openseaFeeRecipient,
      );

      if (nftContract && tokenId) {
        let futureDate = dayjs().add(duration, "days");
        const currentDate = dayjs();

        const differenceInMs = futureDate.diff(currentDate);

        // Check if the difference is less than or equal to 10 minutes (10 * 60 * 1000 ms)
        if (differenceInMs <= 10 * 60 * 1000) {
          futureDate = futureDate.add(10, "minutes"); // should be greater than now + 10 mins
        }

        const expirationTimeS = Math.floor(futureDate.valueOf() / 1000);
        const listing = {
          asset: {
            contract: nftContract,
            tokenId: tokenId.toString(),
          },
          offerer: (await signerV6?.getAddress()) ?? "",
          price: formatDecimalsToIntValue(price, decimals).toString(),
          expirationTime: expirationTimeS,
          exchangeToken: {
            address: exchangeTokenAddress, // can't be anything else than WETH on testnet
            decimals: decimals,
          },
          auction: true,
        };
        await openseaSdk.createListing(listing);
        onSuccess();
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return useMutation({
    mutationFn: createListing,
    mutationKey: ["createListing"],
  });
};
