import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loading, Typography } from "@bosonprotocol/react-kit";
import { useCoreSDKContext, useCtaClickHandler } from "../../../../hooks";
import { Button } from "../../Button";
import { ButtonTextWrapper, ExtraInfo, LoadingWrapper } from "../common/styles";
export function CtaButton({ disabled = false, showLoading = false, extraInfo, onSuccess, onError, onPendingSignature, onPendingTransaction, actions, defaultLabel, successPayload, waitBlocks = 1, children, size = "regular", variant = "pink", buttonRef, ...rest }) {
    const coreSdk = useCoreSDKContext();
    const { clickHandler, isLoading } = useCtaClickHandler({
        waitBlocks,
        coreSdk,
        actions,
        onSuccess,
        successPayload,
        onError,
        onPendingSignature,
        onPendingTransaction,
    });
    return (_jsx(Button, { variant: variant, size: size, disabled: disabled, ref: buttonRef, onClick: (e) => {
            if (!isLoading) {
                clickHandler(e);
            }
        }, ...rest, children: _jsxs(ButtonTextWrapper, { children: [_jsx(Typography, { fontSize: "0.75rem", children: children || defaultLabel }), extraInfo && ((!isLoading && showLoading) || !showLoading) ? (_jsx(ExtraInfo, { children: extraInfo })) : (_jsx(_Fragment, { children: isLoading && showLoading && (_jsx(LoadingWrapper, { children: _jsx(Loading, {}) })) }))] }) }));
}
