import { borders, Button, colors } from "@fermionprotocol/react-kit";
export {
  CategoryWrapper,
  TableContainer,
  TableStyled,
  TableThumbnailStyled,
} from "@fermionprotocol/react-kit";
import styled from "styled-components";

export const TableButtonStyled = styled(Button)`
  padding-left: 16px;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  margin-right: 24px;
`;

export const TableCategoryWrapperStyled = styled.div`
  padding: 0.25rem 1rem 0.25rem 1rem;
  border: 1px solid ${colors.hoveredShade};
  border-radius: ${borders.small}px;
  width: fit-content;
`;

export const ExpandButton = styled.button`
  width: 3rem;
  height: 2rem;
  background-color: ${colors.whiteOff};
  border: 1px solid ${colors.whiteOff};
  border-radius: ${borders.big}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverflowContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

export const TransparentButton = styled.button`
  background: none;
  border: none;
`;

export const Pagination = styled.div`
  width: 100%;
  padding-top: 1rem;
  select {
    padding: 0.5rem;
    border: 1px solid ${colors.border};
    background: ${colors.white};
    margin: 0 1rem;
  }
`;
export const Span = styled.span`
  font-size: 0.75rem;
  color: ${colors.greyDark};
  &:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

export const HeaderSorter = styled.span`
  margin-left: 0.5rem;
`;
