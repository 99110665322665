import { initSentry } from "@fermionprotocol/vite/dist/setupSentry";
import ReactDOM from "react-dom/client";
import { AppRouter } from "router/AppRouter";

import { StrictMode } from "./app/StrictMode";

initSentry({ app: "interface" });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode enable>
    <AppRouter />
  </StrictMode>,
);
