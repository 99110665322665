import { colors } from "@fermionprotocol/react-kit";
import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  background-color: ${colors.white};
  border-radius: 8px;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 12px;
  position: relative;
  margin-left: 18px;
  &:before {
    content: "·";
    font-size: 1.875rem;
    vertical-align: middle;
    line-height: 1.25rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -0.8125rem;
    margin-top: 1px;
  }
`;
