import { Grid, Typography } from "@bosonprotocol/react-kit";
import { Info } from "phosphor-react";
import { css, CSSProperties, styled } from "styled-components";

import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";

const Wrapper = styled(Grid)<InfoBoxTheme["wrapperProps"]>`
  border-radius: ${borders.small}px;
  border: 1px solid ${colors.border};
  ${({ $boxShadow }) =>
    $boxShadow &&
    css`
      box-shadow: ${$boxShadow};
    `};
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};
`;

type InfoBoxTheme = {
  iconProps: Parameters<typeof Info>[0];
  wrapperProps: Partial<{
    $boxShadow: CSSProperties["boxShadow"];
    $backgroundColor: CSSProperties["backgroundColor"];
  }>;
};

export type InfoBoxProps = {
  message: string;
  customTheme?: Partial<InfoBoxTheme>;
  className?: string;
};

export const InfoBox = ({
  message,
  customTheme: { iconProps, wrapperProps } = {},
  className,
}: InfoBoxProps) => {
  return (
    <Wrapper
      gap="0.5rem"
      padding="1rem"
      justifyContent="flex-start"
      {...wrapperProps}
      className={className}
    >
      <Info color={colors.blue} weight="bold" {...iconProps} />
      <Typography fontWeight={500} fontSize="0.875rem">
        {message}
      </Typography>
    </Wrapper>
  );
};
