import { useQuery } from "react-query";
import { handleUseQueryError } from "../../../utils/errors/handleUseQueryError";
import { useCoreSDKContext } from "../useCoreSDKContext";
export const useGetWallet = ({ enabled, walletAddress, queryVars, }) => {
    const coreSDK = useCoreSDKContext();
    const filters = [walletAddress, queryVars];
    return handleUseQueryError(useQuery(["get-wallet", coreSDK.uuid, ...filters], () => {
        return coreSDK.getAccount(...filters);
    }, {
        enabled: enabled,
    }));
};
