import { Action, Loading, Typography } from "@bosonprotocol/react-kit";
import { providers } from "ethers";
import React from "react";

import { useCoreSDKContext, useCtaClickHandler } from "../../../../hooks";
import { Button } from "../../Button";
import { ButtonTextWrapper, ExtraInfo, LoadingWrapper } from "../common/styles";
import { CtaButtonProps } from "../common/types";

type Props<T> = CtaButtonProps<T> & {
  defaultLabel?: string;
  actions: Action[];
  successPayload: T | ((receipt: providers.TransactionReceipt) => T);
};

export function CtaButton<T>({
  disabled = false,
  showLoading = false,
  extraInfo,
  onSuccess,
  onError,
  onPendingSignature,
  onPendingTransaction,
  actions,
  defaultLabel,
  successPayload,
  waitBlocks = 1,
  children,
  size = "regular",
  variant = "pink",
  buttonRef,
  ...rest
}: Props<T>) {
  const coreSdk = useCoreSDKContext();
  const { clickHandler, isLoading } = useCtaClickHandler<T>({
    waitBlocks,
    coreSdk,
    actions,
    onSuccess,
    successPayload,
    onError,
    onPendingSignature,
    onPendingTransaction,
  });
  return (
    <Button
      variant={variant}
      size={size}
      disabled={disabled}
      ref={buttonRef}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!isLoading) {
          clickHandler(e);
        }
      }}
      {...rest}
    >
      <ButtonTextWrapper>
        <Typography fontSize={"0.75rem"}>{children || defaultLabel}</Typography>
        {extraInfo && ((!isLoading && showLoading) || !showLoading) ? (
          <ExtraInfo>{extraInfo}</ExtraInfo>
        ) : (
          <>
            {isLoading && showLoading && (
              <LoadingWrapper>
                <Loading />
              </LoadingWrapper>
            )}
          </>
        )}
      </ButtonTextWrapper>
    </Button>
  );
}
