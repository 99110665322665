import { SelectProps } from "@bosonprotocol/react-kit";

export enum ShippingMethodsValues {
  delivery = "delivery",
  pickup = "pickup",
  delivery_and_pickup = "delivery_and_pickup",
}

export enum ShippingMethodsLabels {
  delivery = "Delivery",
  pickup = "Pickup",
  delivery_and_pickup = "Delivery and pickup",
}

export const shippingMethodsOptions = [
  {
    value: ShippingMethodsValues.delivery,
    label: ShippingMethodsLabels.delivery,
  },
  {
    value: ShippingMethodsValues.pickup,
    label: ShippingMethodsLabels.pickup,
  },
  {
    value: ShippingMethodsValues.delivery_and_pickup,
    label: ShippingMethodsLabels.delivery_and_pickup,
  },
] as const satisfies SelectProps["options"];
