/* eslint-disable @typescript-eslint/ban-ts-comment */
import set from "lodash/set";
import * as Yup from "yup";

export function throwNestedValidationError(
  validateSyncSchemaWithValue: () => unknown,
) {
  try {
    validateSyncSchemaWithValue();
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      const errors = {};
      err.inner.forEach((innerError) => {
        set(errors, innerError.path || "", innerError.message);
      });
      // @ts-ignore
      throw this.createError({
        // @ts-ignore
        path: this.path,
        message: errors,
      });
    }
    throw err;
  }
}
