import { BaseButtonProps, Typography } from "@bosonprotocol/react-kit";
import { borders, Button, buttonThemes } from "@fermionprotocol/react-kit";
import { ArrowSquareOut } from "phosphor-react";
import React from "react";
import { fermionProtocolRoutes } from "router/routeNames";
import styled from "styled-components";

import { CALL_TO_ACTION_BREAKPOINT } from "./CallToActionCard";

export interface ExploreAssetsLinkProps {
  hideIcon?: boolean;
  fontSize?: string;
  variant?: keyof typeof buttonThemes;
  style?: React.CSSProperties;
  size?: BaseButtonProps["size"];
  className?: string;
}

export const ExploreAssetsLinkCTAStyled = styled(
  ExploreAssetsLink,
)<ExploreAssetsLinkProps>`
  border-radius: ${borders.large}px;
  margin-left: auto;
  white-space: nowrap;
  @media (max-width: ${CALL_TO_ACTION_BREAKPOINT}px) {
    margin: 0 auto;
    margin-top: 0.625rem;
  }
  padding: 0;
  button {
    padding: 0.875rem 2rem;
  }
`;

const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;

  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
`;
export const exploreAssetsLabel = "Explore Assets";

export function ExploreAssetsLink({
  hideIcon,
  fontSize = "1rem",
  variant = "pink",
  size = "regular",
  style,
  className = "",
}: ExploreAssetsLinkProps) {
  return (
    <StyledLink
      href={fermionProtocolRoutes.exploreAssets}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      <Button size={size} variant={variant} style={style}>
        <StyledTypography fontSize={fontSize}>
          {exploreAssetsLabel}
        </StyledTypography>
        {!hideIcon && <ArrowSquareOut size={24} />}
      </Button>
    </StyledLink>
  );
}
