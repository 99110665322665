export const MODAL_TYPES = {
  CREATE_PROFILE: "CREATE_PROFILE",
  ADD_ROLE_TO_PROFILE: "ADD_ROLE_TO_PROFILE",
  EDIT_PROFILE: "EDIT_PROFILE",
  CREATE_PRODUCT_DRAFT: "CREATE_PRODUCT_DRAFT",
  INVALID_ROLE: "INVALID_ROLE",
  ERROR: "ERROR",
  EDIT_ROLE: "EDIT_ROLE",
  LISTED_OPENSEA: "LISTED_OPENSEA",
  WITHDRAW_FUNDS: "WITHDRAW_FUNDS",
  OFFRAMP_FUNDS: "OFFRAMP_FUNDS",
  DEPOSIT_FUNDS: "DEPOSIT_FUNDS",
  SEND_TO_VERIFIER: "SEND_TO_VERIFIER",
  ACCEPT_BID: "ACCEPT_BID",
  BIDS_FOR_PRODUCT: "BIDS_FOR_PRODUCT",
  PRODUCT_DETAILS: "PRODUCT_DETAILS",
  WAITING_FOR_CONFIRMATION: "WAITING_FOR_CONFIRMATION",
  TRANSACTION_SUBMITTED: "TRANSACTION_SUBMITTED",
  TRANSACTION_FAILED: "TRANSACTION_FAILED",
  BUYER_HUB_MOBILE_SELECT_MODAL: "BUYER_HUB_MOBILE_SELECT_MODAL",
  BUYER_HUB_MOBILE_HELP_MODAL: "BUYER_HUB_MOBILE_HELP_MODAL",
  BUYER_HUB_MOBILE_MENU_MODAL: "BUYER_HUB_MOBILE_MENU_MODAL",
  CLOSED_BETA: "CLOSED_BETA",
  CHECKOUT_SUCCESS: "CHECKOUT_SUCCESS",
  COOKIE_MODAL: "COOKIE_MODAL",
} as const;
