import { TransactionReceipt } from "@bosonprotocol/common";
import {
  Action,
  hooks as bosonHooks,
  withQueryClientProvider,
} from "@bosonprotocol/react-kit";
import { BigNumber, BigNumberish, constants } from "ethers";

import { useCoreSDKContext, useFermionConfigContext } from "../../../../hooks";
import { CtaButton } from "../common/CtaButton";
import { CtaButtonProps } from "../common/types";

type AdditionalProps = {
  exchangeToken: string;
  accountId: string;
  amountToDeposit: BigNumberish;
};

type SuccessPayload = TransactionReceipt;

export type IDepositFundsButton = AdditionalProps &
  CtaButtonProps<SuccessPayload>;

export const DepositFundsButton = withQueryClientProvider(
  ({
    exchangeToken,
    accountId,
    variant = "pinkLight",
    amountToDeposit,
    ...restProps
  }: IDepositFundsButton) => {
    const { address: signerAddress = "" } = bosonHooks.useAccount();
    const { config } = useFermionConfigContext();
    const coreSdk = useCoreSDKContext();
    const isMetaTx = coreSdk.isMetaTxConfigSet;
    const spender = config.contracts.protocolDiamond;
    const actions = [
      // Approve exchange token
      {
        name: "approveExchangeToken" as const,
        writeContractFn: () =>
          coreSdk.erc20Approve({
            contractAddress: exchangeToken,
            spender,
            value: constants.MaxInt256,
            useMetaTx: isMetaTx,
          }),
        shouldActionRun: async () => {
          const isNativeCoin = constants.AddressZero === exchangeToken;
          if (isNativeCoin) {
            return false;
          }
          const allowance = await coreSdk.erc20GetAllowance({
            contractAddress: exchangeToken,
            spender,
            owner: signerAddress,
          });

          return BigNumber.from(allowance).lt(amountToDeposit);
        },
      },
      // Deposit funds
      {
        name: "depositFunds" as const,
        writeContractFn: () =>
          coreSdk.depositFunds(accountId, exchangeToken, amountToDeposit, {
            useMetaTx: isMetaTx,
          }),
      },
    ] satisfies Action[];

    return (
      <CtaButton
        variant={variant}
        defaultLabel="Deposit"
        successPayload={(receipt) => receipt}
        actions={actions}
        {...restProps}
      />
    );
  },
);
