import { hooks } from "@bosonprotocol/react-kit";
import * as yup from "yup";

import { storageKeys } from "./const";

const {
  getItemFromStorage: baseGetItemFromStorage,
  saveItemInStorage: baseSaveItemInStorage,
  removeItemInStorage: baseRemoveItemInStorage,
  clearLocalStorage: baseClearLocalStorage,
  useLocalStorage: useBaseLocalStorage,
} = hooks;

export type Keys = keyof typeof storageKeys;

export function getItemFromStorage<T, Schema extends yup.AnySchema>(
  key: Keys,
  initialValue: T,
  schema: Schema,
): T | yup.InferType<Schema> {
  const item: unknown = baseGetItemFromStorage(key, initialValue);
  if (!schema) return item;
  try {
    return schema.validateSync(item, {
      strict: true,
      abortEarly: false,
    });
  } catch (error) {
    console.error(error);
    return initialValue;
  }
}
export function saveItemInStorage<T>(key: Keys, value: T) {
  return baseSaveItemInStorage(key, value);
}

export function removeItemInStorage(key: Keys) {
  return baseRemoveItemInStorage(key);
}

export const clearLocalStorage = baseClearLocalStorage;

export function useLocalStorage<T>(key: Keys, initialValue: T) {
  return useBaseLocalStorage(key, initialValue);
}
