import { GlobalStyle } from "@fermionprotocol/react-kit";
import { AppProvider } from "AppProvider";
import { CustomErrorBoundary } from "components/error/CustomErrorBoundary";
import { Footer } from "components/footer/Footer";
import { Header } from "components/header/Header";
import { COOKIE_CONSTANTS } from "components/modal/components/cookie-modal/CookieConstants";
import { useModal } from "components/modal/useModal";
import { getItemFromStorage } from "hooks/storage/useLocalStorage";
import { ReactNode, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { fermionRoutes } from "router/routeNames";
import { css, styled } from "styled-components";
import { maxWidthStyles } from "styles/layout";
import * as yup from "yup";

const Content = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

type ComponentWrapperProps = {
  $isHomepage: boolean;
};

const ComponentWrapper = styled.div<ComponentWrapperProps>`
  display: flex;
  justify-content: center;
  padding: 0;
  ${({ $isHomepage }) =>
    $isHomepage &&
    css`
      padding-top: 0;
      padding-bottom: 1rem;
    `}
  > * {
    ${({ $isHomepage }) =>
      !$isHomepage &&
      css`
        ${maxWidthStyles};
      `}
  }
`;

type AppProps = {
  children: ReactNode;
};

export function App({ children }: AppProps) {
  const location = useLocation();
  const { showModal } = useModal();
  const isHomepage = location.pathname === fermionRoutes.home;
  const buyWithFermionSectionRef = useRef<HTMLDivElement>(null);
  const sellWithFermionSectionRef = useRef<HTMLDivElement>(null);
  const advancedRolesSectionRef = useRef<HTMLDivElement>(null);
  const isCookiesAccepted = getItemFromStorage(
    "cookies-accepted",
    false,
    yup.boolean().required(),
  );
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get(COOKIE_CONSTANTS.URL_PARAM_SOURCE);

    if (
      !isCookiesAccepted &&
      source !== COOKIE_CONSTANTS.URL_PARAM_VALUE_COOKIE_MODAL
    ) {
      showModal({
        modalType: "COOKIE_MODAL",
        isMobileSlidingModal: true,
        hideHeader: true,
        modalProps: {
          closable: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalStyle />
      <Content>
        <Header />

        <AppProvider
          buyWithFermionSectionRef={buyWithFermionSectionRef}
          sellWithFermionSectionRef={sellWithFermionSectionRef}
          advancedRolesSectionRef={advancedRolesSectionRef}
        >
          <ComponentWrapper $isHomepage={isHomepage}>
            <CustomErrorBoundary>{children}</CustomErrorBoundary>
          </ComponentWrapper>
          <Footer />
        </AppProvider>
      </Content>
    </>
  );
}
