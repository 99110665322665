import { hooks as bosonHooks } from "@bosonprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import { useCheckIfSupportedChain } from "hooks/connection/useCheckIfSupportedChain";
import { useEffect } from "react";

export const CheckIfSupportedChain = () => {
  const { showModal, store, hideModal } = useModal();
  const isSupportedChain = useCheckIfSupportedChain();
  const { address } = bosonHooks.useAccount();
  useEffect(() => {
    if (!address) {
      return;
    }
    if (isSupportedChain) {
      if (store.modalType === "ERROR") {
        hideModal();
      }
    } else {
      showModal({
        modalType: "ERROR",
        modalProps: {
          closable: false,
          headerComponent: "Wrong chain",
          detailedErrorMessage: "Connect to Polygon Amoy to use the dApp",
        },
        modalMaxWidth: {
          m: "31.25rem",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSupportedChain, address]);
  return null;
};
