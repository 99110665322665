import { jsx as _jsx } from "react/jsx-runtime";
import { withQueryClientProvider } from "@bosonprotocol/react-kit";
import { useCoreSDKContext, useOpenSeaListing } from "../../../../hooks";
import { CtaButton } from "../common/CtaButton";
export const CreateListingButton = withQueryClientProvider(({ tokenId, nftContract, offerId, signerV6, price, duration, openSeaApiKey, openseaFeeRecipient, openseaConduit, decimals, exchangeTokenAddress, variant = "pinkLight", ...restProps }) => {
    const coreSdk = useCoreSDKContext();
    const isMetaTx = coreSdk.isMetaTxConfigSet;
    const { mutateAsync: createListing } = useOpenSeaListing({
        signerV6,
        tokenId,
        price,
        duration,
        openSeaApiKey,
        openseaFeeRecipient,
        nftContract,
        decimals,
        exchangeTokenAddress,
    });
    const actions = [
        {
            name: "approveExchangeToken", // name is not matching, but needs changes in Boson CC
            writeContractFn: () => coreSdk.erc721SetApprovalForAll({
                contractAddress: nftContract,
                operator: openseaConduit,
                approved: true,
                useMetaTx: isMetaTx,
                offerId,
            }),
            shouldActionRun: async () => {
                const isApprovedForAll = await coreSdk.erc721IsApprovedForAll({
                    contractAddress: nftContract,
                    operator: openseaConduit,
                });
                return !isApprovedForAll;
            },
        },
        {
            name: "depositFunds", // name is not matching, but needs changes in Boson CC
            writeContractFn: async () => {
                await createListing(() => { });
                return {
                    hash: "dummy",
                    wait: async () => {
                        return {}; // Dummy receipt
                    },
                };
            },
        },
    ];
    return (_jsx(CtaButton, { variant: variant, defaultLabel: "Create Listing", successPayload: (receipt) => receipt, actions: actions, ...restProps }));
});
