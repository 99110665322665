import { RoleId } from "./roles";
export const BridgingRoleValues = {
    verifier: RoleId.VERIFIER,
    custodian: RoleId.CUSTODIAN,
};
export var BridgingRoleLabels;
(function (BridgingRoleLabels) {
    BridgingRoleLabels["Verifier"] = "Verifier";
    BridgingRoleLabels["Custodian"] = "Custodian";
})(BridgingRoleLabels || (BridgingRoleLabels = {}));
export const bridgingRoleOptions = [
    {
        value: BridgingRoleValues.custodian,
        label: BridgingRoleLabels.Custodian,
    },
    {
        value: BridgingRoleValues.verifier,
        label: BridgingRoleLabels.Verifier,
    },
];
