import * as Yup from "yup";
import { validationMessage } from "./common";
import { getStringValidation } from "./string";
const tokenValidation = Yup.number()
    .nullable()
    .min(0, validationMessage.min(0))
    .default(null);
export const getFeePriceBandsValidation = ({ required, }) => {
    const feePriceBandsValidation = Yup.array(Yup.object({
        min: Yup.number()
            .min(0, validationMessage.min(0))
            .nullable()
            .default(null)
            .required(validationMessage.required),
        max: Yup.number()
            .nullable()
            .default(null)
            .required(validationMessage.required)
            .test({
            name: "feePriceBandMax",
            message: "It has to be greater than or equal to the minimum value",
            test: function (maxValue) {
                if (maxValue === null) {
                    return false;
                }
                const minValue = this.parent.min;
                const isValid = maxValue >= minValue;
                return isValid;
            },
        })
            .test({
            name: "feePriceBandMaxRepeated",
            message: "Max value has already been set",
            test: function () {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const currentArrayItem = this.from?.[0]?.value;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const feePriceBands = this.from?.[1]?.value
                    ?.feePriceBands;
                if (!currentArrayItem || !feePriceBands) {
                    return true;
                }
                const currentIndex = feePriceBands.findIndex((feePriceBand) => feePriceBand === currentArrayItem);
                const isNotValid = currentIndex !== 0 &&
                    feePriceBands.some((feePriceBand) => {
                        return (feePriceBand !== currentArrayItem &&
                            feePriceBand.max === currentArrayItem.max &&
                            feePriceBand.min === currentArrayItem.min);
                    });
                return !isNotValid;
            },
        }),
        usdc: tokenValidation.test({
            name: "usdcRequired",
            message: validationMessage.required,
            test: function (value) {
                return testIfRequiredToken.call(this, value, "usdc");
            },
        }),
        weth: tokenValidation.test({
            name: "wethRequired",
            message: validationMessage.required,
            test: function (value) {
                return testIfRequiredToken.call(this, value, "weth");
            },
        }),
    }));
    if (!required) {
        return feePriceBandsValidation;
    }
    return feePriceBandsValidation
        .required(validationMessage.required)
        .min(1, validationMessage.length(1));
    function testIfRequiredToken(value, tokenValue) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const feePriceBandsTokens = this.from?.[1]?.value?.feePriceBandsTokens;
        const isValid = feePriceBandsTokens?.find(({ value }) => value === tokenValue)
            ? value !== null
            : true;
        return isValid;
    }
};
export const getFeePriceBandsTokensValidation = ({ required, }) => {
    const feePriceBandsTokensValidation = Yup.array(Yup.object({
        value: getStringValidation({ required }),
        label: getStringValidation({ required }),
    }));
    if (!required) {
        return feePriceBandsTokensValidation;
    }
    return feePriceBandsTokensValidation
        .required(validationMessage.required)
        .min(1, validationMessage.length(1));
};
