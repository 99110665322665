import { ScrollToTop } from "@bosonprotocol/react-kit";
import { Providers } from "app/Providers";
import { CustomErrorBoundary } from "components/error/CustomErrorBoundary";
import { InvalidateQueries } from "components/query-client/InvalidateQueries";
import { HashRouter, Route, Routes } from "react-router-dom";

import { CheckIfSupportedChain } from "../app/CheckIfSupportedChain";
import { allRoutes } from "./routes";
import { SuspenseRoute } from "./SuspenseRoute";

export function AppRouter() {
  return (
    <CustomErrorBoundary>
      <HashRouter>
        <ScrollToTop />
        <Providers>
          <CheckIfSupportedChain />
          <InvalidateQueries />
          <Routes>
            {allRoutes.map((route) => (
              <Route
                key={`route_${route.path}`}
                {...route}
                element={<SuspenseRoute {...route} />}
              />
            ))}
          </Routes>
        </Providers>
      </HashRouter>
    </CustomErrorBoundary>
  );
}
