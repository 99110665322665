import { jsx as _jsx } from "react/jsx-runtime";
import { hooks as bosonHooks } from "@bosonprotocol/react-kit";
import { envConfigs, getEnvConfigById, } from "@fermionprotocol/common";
import { useMemo, useState } from "react";
import { FermionConfigContext } from "./FermionConfigContext";
export const FermionConfigProvider = ({ configProps, children, }) => {
    const { config } = bosonHooks.useConfigContext();
    const { configId, envName } = config;
    const defaultEnvConfig = useMemo(() => {
        try {
            const envConfig = getEnvConfigById(envName, configId);
            return envConfig;
        }
        catch (error) {
            return envConfigs[envName][0];
        }
    }, [envName, configId]);
    const [envConfig, setEnvConfig] = useState(defaultEnvConfig);
    return (_jsx(FermionConfigContext.Provider, { value: {
            config: { ...envConfig, ...configProps },
            setEnvConfig,
        }, children: children }));
};
