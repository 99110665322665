import { useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";
import { handleUseQueryError, hooks } from "../..";
const transactionResponseSchema = Yup.object({
    hash: Yup.string().required(),
    // We are not parsing data here so promise MUST be unknown, it should be validated on execute time
    wait: Yup.mixed()
        .test("is-function", "wait must be a function", (value) => typeof value === "function")
        .required(),
});
export const useWaitForTransaction = ({ getMutationKeyFnc, options = {}, }) => {
    const coreSDK = hooks.useCoreSDKContext();
    const queryClient = useQueryClient();
    const mutationKey = getMutationKeyFnc();
    return handleUseQueryError(useQuery({
        ...(options && { ...options }),
        queryKey: [...mutationKey, "get-cache"],
        queryFn: async () => {
            const response = queryClient.getMutationCache().find({
                mutationKey,
            });
            const data = response?.state.data;
            if (!data)
                return true;
            const processedData = await transactionResponseSchema.validate(response?.state.data);
            await coreSDK.waitForGraphNodeIndexing(processedData);
            return true;
        },
    }));
};
