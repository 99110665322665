import { Loading } from "@bosonprotocol/react-kit";
import { hooks, Profile, Role } from "@fermionprotocol/react-kit";
import React, { useCallback, useEffect, useState } from "react";

import { SelectRoleToCreate } from "../common/select-role/SelectRoleToCreate";
import { ProfileSummary } from "../common/summary-step/ProfileSummary";
import { AddRoleToProfileSteps } from "./const";
import { EditProfileRoleForm } from "./edit-step/EditProfileRoleForm";
import { ProfileSuccess } from "./success-step/ProfileSuccess";

const defaultFirstStep = AddRoleToProfileSteps.SELECT_ROLE;
export const AddRoleToProfileFlow: React.FC = () => {
  const [step, setStep] = useState<AddRoleToProfileSteps>(defaultFirstStep);
  const {
    data: { profile },
    isLoading,
    isSuccess,
  } = hooks.useGetProfile();
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [profileData, setProfileData] = useState<Profile | undefined>(profile);
  useEffect(() => {
    if (isSuccess) {
      setProfileData(profile);
    }
  }, [isSuccess, profile]);
  const onRoleSelected = useCallback((role: Role): void => {
    setSelectedRole(role);
    setStep(AddRoleToProfileSteps.EDIT_FORM);
  }, []);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : step === AddRoleToProfileSteps.SELECT_ROLE ? (
        <SelectRoleToCreate
          alreadyOwnedRoles={profileData?.roles}
          onRoleSelected={onRoleSelected}
        />
      ) : step === AddRoleToProfileSteps.EDIT_FORM &&
        selectedRole &&
        profileData ? (
        <EditProfileRoleForm
          role={selectedRole}
          initial={profileData}
          onSubmit={async (profile) => {
            setProfileData(profile);
            setStep(AddRoleToProfileSteps.SUMMARY);
          }}
          goBack={() => {
            setStep(AddRoleToProfileSteps.SELECT_ROLE);
          }}
        />
      ) : step === AddRoleToProfileSteps.SUMMARY && profileData ? (
        <ProfileSummary
          roles={profileData.roles}
          values={profileData}
          goBack={() => {
            setStep(AddRoleToProfileSteps.EDIT_FORM);
          }}
          onSubmit={() => {
            setStep(AddRoleToProfileSteps.SUCCESS);
          }}
          nextText="Confirm and edit profile"
        />
      ) : step === AddRoleToProfileSteps.SUCCESS ? (
        <ProfileSuccess
          setupAnotherRole={() => {
            setSelectedRole(undefined);
            setProfileData(undefined);
            setStep(AddRoleToProfileSteps.SELECT_ROLE);
          }}
        />
      ) : (
        <p>There has been an error, please try again...</p>
      )}
    </>
  );
};
