import * as Sentry from "@sentry/browser";
export function handleUseQueryError(result, handleError = (error) => {
    console.error(error);
    Sentry.captureException(error);
}) {
    if (result.isError) {
        handleError(result.error);
    }
    return result;
}
