import {
  BaseButton as Button,
  BaseButtonTheme,
  Grid,
} from "@bosonprotocol/react-kit";
import { ReactNode } from "react";
import { styled } from "styled-components";

import { borders } from "../../styles/borders";
import { colors, defaultColors } from "../../styles/colors";
import { ButtonProps } from "./Button";
const Wrapper = styled(Grid)`
  border-radius: ${borders.big}px;
  background-color: ${defaultColors.inputBackgroundColor};
  padding: 0.5rem;
`;
const activeTheme = {
  background: colors.white,
  color: colors.purpleDeep,
  borderRadius: borders.medium,
  borderWidth: 1,
  borderColor: colors.shade,
  boxShadow: `0 0.5625rem 2.5rem 0 ${colors.blackLight}`,
  hover: {
    background: colors.white,
    color: colors.purpleDeep,
  },
} satisfies BaseButtonTheme;
const inactiveTheme = {
  background: "transparent",
  color: colors.purpleDeep,
  borderRadius: borders.medium,
  borderWidth: 1,
  hover: {
    ...activeTheme,
  },
} satisfies BaseButtonTheme;

export type ToggleButtonProps = {
  active?: "left" | "right";
  leftChild: ReactNode;
  rightChild: ReactNode;
  className?: string;
  onLeftClick: ButtonProps["onClick"];
  onRightClick: ButtonProps["onClick"];
};
export const ToggleButton = ({
  active,
  leftChild,
  rightChild,
  onLeftClick,
  onRightClick,
  ...rest
}: ToggleButtonProps) => {
  return (
    <Wrapper gap="0.25rem" flex={1} {...rest}>
      <Button
        theme={active === "left" ? activeTheme : inactiveTheme}
        onClick={onLeftClick}
      >
        {leftChild}
      </Button>
      <Button
        theme={active === "right" ? activeTheme : inactiveTheme}
        onClick={onRightClick}
      >
        {rightChild}
      </Button>
    </Wrapper>
  );
};
