import { Grid, Typography } from "@bosonprotocol/react-kit";
import { SidebarLinkTile } from "components/hub/sidebar/HubSidebar";
import { useModal } from "components/modal/useModal";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { fermionRoutes } from "router/routeNames";
import styled from "styled-components";

import { BuyerHubCard } from "./BuyerHubCard";

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;
export function BuyerHubMobileSelectModal() {
  const { updateProps, store, hideModal } = useModal();
  const location = useLocation();
  const showCard = location.pathname === fermionRoutes.buyerAssets;

  useEffect(() => {
    updateProps<"BUYER_HUB_MOBILE_SELECT_MODAL">({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              fontSize={"1.5rem"}
              fontWeight={500}
              tag="h3"
              margin={0}
            >
              Buyer Hub
            </Typography>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid
      flexDirection="column"
      alignItems="center"
      width="calc(100vw - 3.925rem)"
    >
      <StyledList>
        <SidebarLinkTile hubType={"buyer"} onNavigate={hideModal} />
        {showCard && <BuyerHubCard />}
      </StyledList>
    </Grid>
  );
}
