import {
  Grid,
  hooks as bosonHooks,
  Typography,
} from "@bosonprotocol/react-kit";
import { FermionConnectWallet } from "components/connection/FermionConnectWallet";
import { useModal } from "components/modal/useModal";
import { useEffect } from "react";

import { EditProfileFlow } from "./EditProfileFlow";

export type EditProfileModalProps = {
  textBeforeEditProfile?: string;
  onClose: () => void;
};

export const EditProfileModal = ({
  textBeforeEditProfile,
}: EditProfileModalProps) => {
  const { address } = bosonHooks.useAccount();
  const { updateProps, store } = useModal();
  useEffect(() => {
    if (!address) {
      updateProps<"CREATE_PROFILE">({
        ...store,
        modalProps: {
          ...store.modalProps,
          headerComponent: (
            <Grid flexDirection="column" alignItems="flex-start">
              <Typography tag="h3" fontWeight={500} marginBottom="0.5rem">
                Connect your wallet
              </Typography>
            </Grid>
          ),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  if (!address) {
    return (
      <Grid gap="1rem" flexDirection="column">
        <Typography>
          To create a profile you must first connect your wallet
        </Typography>
        <FermionConnectWallet />
      </Grid>
    );
  }
  if (textBeforeEditProfile) {
    return (
      <Grid gap="1rem" flexDirection="column">
        <Typography>{textBeforeEditProfile}</Typography>
      </Grid>
    );
  }
  return <EditProfileFlow />;
};
