import { Loading } from "@bosonprotocol/react-kit";
import { hooks, Profile } from "@fermionprotocol/react-kit";
import React, { useEffect, useState } from "react";

import { ProfileSummary } from "../common/summary-step/ProfileSummary";
import { EditProfileForm } from "./edit-step/EditProfileForm";
import { ProfileSuccess } from "./success-step/ProfileSuccess";

enum EditProfileSteps {
  EDIT = "EDIT",
  SUMMARY = "SUMMARY",
  SUCCESS = "SUCCESS",
}

export const EditProfileFlow: React.FC = () => {
  const [step, setStep] = useState<EditProfileSteps>(EditProfileSteps.EDIT);
  const {
    data: { profile },
    isLoading,
    isSuccess,
  } = hooks.useGetProfile();
  const [profileData, setProfileData] = useState<Profile | undefined>(profile);
  console.log("profileData", profileData);
  useEffect(() => {
    if (isSuccess) {
      setProfileData(profile);
    }
  }, [isSuccess, profile]);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : step === EditProfileSteps.EDIT && profileData ? (
        <EditProfileForm
          initial={profileData}
          onSubmit={async (profile) => {
            setProfileData(profile);
            setStep(EditProfileSteps.SUMMARY);
          }}
        />
      ) : step === EditProfileSteps.SUMMARY && profileData ? (
        <ProfileSummary
          roles={profile?.roles}
          values={profileData}
          goBack={() => {
            setStep(EditProfileSteps.EDIT);
          }}
          onSubmit={async () => {
            setStep(EditProfileSteps.SUCCESS);
          }}
          nextText="Confirm and edit profile"
        />
      ) : step === EditProfileSteps.SUCCESS ? (
        <ProfileSuccess />
      ) : (
        <p>There has been an error, please try again...</p>
      )}
    </>
  );
};
