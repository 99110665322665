import { AddDollarPrefixToKeys } from "@fermionprotocol/react-kit";
import { forwardRef } from "react";
import styled, { CSSProperties } from "styled-components";

interface PageContainerProps {
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  backgroundColor?: string;
  maxWidth: CSSProperties["maxWidth"];
  children?: React.ReactNode;
  ref?: React.ForwardedRef<HTMLDivElement>;
}
type StyledPageContainerProps = AddDollarPrefixToKeys<PageContainerProps>;

const StyledPageContainer = styled.div<StyledPageContainerProps>`
  position: relative;
  padding-top: ${(props) => props.$paddingTop || "0"};
  padding-bottom: ${(props) => props.$paddingBottom || "0"};
  padding-left: ${(props) => props.$paddingLeft || "7rem"};
  padding-right: ${(props) => props.$paddingRight || "7rem"};
  width: 100vw;
  margin: 0 auto;
  max-width: ${(props) => props.$maxWidth};
  background: ${(props) => props.$backgroundColor || "transparent"};
  display: block;
`;

export const PageContainer = forwardRef<HTMLDivElement, PageContainerProps>(
  (
    {
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      backgroundColor,
      maxWidth,
      ...props
    },
    ref,
  ) => {
    return (
      <StyledPageContainer
        $paddingTop={paddingTop}
        $paddingBottom={paddingBottom}
        $paddingLeft={paddingLeft}
        $paddingRight={paddingRight}
        $backgroundColor={backgroundColor}
        $maxWidth={maxWidth}
        ref={ref}
        {...props}
      />
    );
  },
);
