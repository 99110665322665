import { jsx as _jsx } from "react/jsx-runtime";
import { SimpleError as BaseSimpleError, } from "@bosonprotocol/react-kit";
import styled from "styled-components";
import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";
const StyledBaseSimpleError = styled(BaseSimpleError) `
  border-radius: ${borders.small}px;
`;
export const SimpleError = ({ ...rest }) => {
    return _jsx(StyledBaseSimpleError, { ...rest, backgroundColor: colors.whiteOff });
};
