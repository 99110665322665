import { jsx as _jsx } from "react/jsx-runtime";
import Select from "react-select";
import { colors } from "../../styles/colors";
const customStyles = () => ({
    control: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        padding: "0.4rem 0.25rem",
        boxShadow: "none",
        ":hover": {
            borderColor: colors.grey,
            borderWidth: "1px",
        },
        background: colors.greyLight,
        border: state.isFocused `1px solid ${colors.grey}`,
    }),
    container: (provided) => ({
        ...provided,
        zIndex: 1,
        position: "relative",
        width: "100%",
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: state.isDisabled ? "not-allowed" : "pointer",
        opacity: state.isDisabled ? "0.5" : "1",
        background: state.isOptionSelected || state.isSelected || state.isFocused
            ? colors.greyLight
            : colors.white,
        color: state.isOptionSelected || state.isSelected ? colors.grey : colors.black,
    }),
    indicatorsContainer: () => ({
        display: "none",
    }),
});
export function BaseSelect({ options, placeholder = "Choose...", onChange, ...props }) {
    const handleChange = (option) => {
        onChange?.(option);
    };
    return (_jsx(Select, { styles: customStyles(), ...props, placeholder: placeholder, options: options, onChange: handleChange }));
}
