export var ShippingMethodsValues;
(function (ShippingMethodsValues) {
    ShippingMethodsValues["delivery"] = "delivery";
    ShippingMethodsValues["pickup"] = "pickup";
    ShippingMethodsValues["delivery_and_pickup"] = "delivery_and_pickup";
})(ShippingMethodsValues || (ShippingMethodsValues = {}));
export var ShippingMethodsLabels;
(function (ShippingMethodsLabels) {
    ShippingMethodsLabels["delivery"] = "Delivery";
    ShippingMethodsLabels["pickup"] = "Pickup";
    ShippingMethodsLabels["delivery_and_pickup"] = "Delivery and pickup";
})(ShippingMethodsLabels || (ShippingMethodsLabels = {}));
export const shippingMethodsOptions = [
    {
        value: ShippingMethodsValues.delivery,
        label: ShippingMethodsLabels.delivery,
    },
    {
        value: ShippingMethodsValues.pickup,
        label: ShippingMethodsLabels.pickup,
    },
    {
        value: ShippingMethodsValues.delivery_and_pickup,
        label: ShippingMethodsLabels.delivery_and_pickup,
    },
];
