import { Grid, Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";
import { myAssetsBuyerHubHelp } from "components/create-product/constants/help";
import { useModal } from "components/modal/useModal";
import { useEffect } from "react";

export function BuyerHubMobileHelpModal() {
  const { updateProps, store } = useModal();

  useEffect(() => {
    updateProps<"BUYER_HUB_MOBILE_HELP_MODAL">({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              fontSize={"1.5rem"}
              fontWeight={500}
              tag="h3"
              margin={0}
            >
              Help
            </Typography>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid
      flexDirection="column"
      alignItems="center"
      width="calc(100vw - 3.925rem)"
    >
      {myAssetsBuyerHubHelp.map((item, index) => (
        <Grid
          key={index}
          marginBottom={"2rem"}
          flexDirection="column"
          alignItems="flex-start"
        >
          <Typography
            fontSize="1rem"
            fontWeight={500}
            color={colors.velvet}
            marginBottom={"0.5rem"}
          >
            {item.title}
          </Typography>
          <Typography
            fontSize="1rem"
            fontWeight={400}
            color={colors.velvetLight}
          >
            {item.description}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}
