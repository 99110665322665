import * as Yup from "yup";
import { AnyObject } from "yup/lib/types";

import { emptyInputNumber } from "../constants/validation";
import { validationMessage } from "./common";

export const getNumberValidation = ({
  required,
  min,
  max,
  optional,
  default: defaultValue,
}: {
  required: boolean;
  min?: number;
  max?: number;
  optional?: boolean;
  default?: number | null;
}) => {
  let numberValidation: Yup.NumberSchema<
    number | undefined | null,
    AnyObject,
    number | undefined | null
  > = Yup.number();
  if (min !== undefined) {
    numberValidation = numberValidation.min(min, validationMessage.min(min));
  }
  if (max !== undefined) {
    numberValidation = numberValidation.max(max, validationMessage.max(max));
  }
  if (defaultValue !== undefined) {
    numberValidation = numberValidation.default(defaultValue);
  } else {
    numberValidation = numberValidation.default(emptyInputNumber);
  }
  if (optional !== undefined) {
    numberValidation = numberValidation.optional();
  }
  numberValidation = numberValidation.typeError(
    validationMessage.invalidType("number"),
  );
  if (!required) {
    return numberValidation.nullable(true);
  }
  return numberValidation.required(validationMessage.required);
};
