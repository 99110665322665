import { jsx as _jsx } from "react/jsx-runtime";
import { hooks as bosonHooks, withQueryClientProvider, } from "@bosonprotocol/react-kit";
import { BigNumber, constants } from "ethers";
import { useCoreSDKContext, useFermionConfigContext } from "../../../../hooks";
import { CtaButton } from "../common/CtaButton";
export const DepositFundsButton = withQueryClientProvider(({ exchangeToken, accountId, variant = "pinkLight", amountToDeposit, ...restProps }) => {
    const { address: signerAddress = "" } = bosonHooks.useAccount();
    const { config } = useFermionConfigContext();
    const coreSdk = useCoreSDKContext();
    const isMetaTx = coreSdk.isMetaTxConfigSet;
    const spender = config.contracts.protocolDiamond;
    const actions = [
        // Approve exchange token
        {
            name: "approveExchangeToken",
            writeContractFn: () => coreSdk.erc20Approve({
                contractAddress: exchangeToken,
                spender,
                value: constants.MaxInt256,
                useMetaTx: isMetaTx,
            }),
            shouldActionRun: async () => {
                const isNativeCoin = constants.AddressZero === exchangeToken;
                if (isNativeCoin) {
                    return false;
                }
                const allowance = await coreSdk.erc20GetAllowance({
                    contractAddress: exchangeToken,
                    spender,
                    owner: signerAddress,
                });
                return BigNumber.from(allowance).lt(amountToDeposit);
            },
        },
        // Deposit funds
        {
            name: "depositFunds",
            writeContractFn: () => coreSdk.depositFunds(accountId, exchangeToken, amountToDeposit, {
                useMetaTx: isMetaTx,
            }),
        },
    ];
    return (_jsx(CtaButton, { variant: variant, defaultLabel: "Deposit", successPayload: (receipt) => receipt, actions: actions, ...restProps }));
});
