import { MetadataType } from "@fermionprotocol/core-sdk";
import { ProfileContactLinkTags, ProfileImageTags, ProfileLocationTags, ProfileSocialLinkTags, } from "../../constants/entityMetadataTags";
import { RoleId } from "../../constants/roles";
import { ShippingMethodsValues } from "../../constants/shippingMethods";
import { isTruthy } from "../../types/helpers";
export function buildMetadataFromProfile(profile) {
    const values = profile;
    const logo = values.logo?.[0];
    const coverPicture = values.coverPicture?.[0];
    const roleIds = getRoleIds(profile);
    return {
        externalUrl: "", // TODO: what should this be?
        externalLink: "", // TODO: what should this be?
        licenseUrl: "", // TODO: what should this be?
        type: MetadataType.ENTITY,
        roleIds: roleIds,
        name: values.name || "",
        description: values.description || "",
        businessPhoneNumber: values.businessPhoneNumber,
        contactLinks: [
            { url: values.email || "", tag: ProfileContactLinkTags.EMAIL },
        ],
        legalTradingName: values.legalTradingName ?? "",
        socialLinks: [
            {
                url: values.website || "",
                tag: ProfileSocialLinkTags.WEBSITE,
            },
        ],
        fermionIdentityWebsite: values.websiteForFermionIdentity || "",
        images: [
            {
                url: logo?.src || "",
                tag: ProfileImageTags.PROFILE,
                height: logo?.height ?? undefined,
                type: logo?.type,
                width: logo?.width ?? undefined,
                fit: logo?.fit ?? undefined,
                position: logo?.position ?? undefined,
            },
            {
                url: coverPicture?.src || "",
                tag: ProfileImageTags.COVER,
                height: coverPicture?.height ?? undefined,
                type: coverPicture?.type,
                width: coverPicture?.width ?? undefined,
                fit: coverPicture?.fit ?? undefined,
                position: coverPicture?.position ?? undefined,
            },
        ],
        businessLocation: {
            tag: ProfileLocationTags.BUSINESS,
            streetName: values.businessLocation.streetName,
            number: values.businessLocation.number,
            city: values.businessLocation.city || "",
            state: values.businessLocation.state,
            zip: values.businessLocation.zip || "",
            country: values.businessLocation.country || "",
        },
        roles: getRoles(profile),
    };
}
function getRoleIds(profile) {
    return profile.roles;
}
function getRoles(profile) {
    const roleIds = getRoleIds(profile);
    return [
        ...(roleIds?.includes(RoleId.FACILITATOR)
            ? [{ id: RoleId.FACILITATOR }]
            : []),
        ...(roleIds?.includes(RoleId.SELLER) ? [{ id: RoleId.SELLER }] : []),
        ...(roleIds?.includes(RoleId.VERIFIER)
            ? [getVerifierFromProfile(profile)]
            : []),
        ...(roleIds?.includes(RoleId.CUSTODIAN)
            ? [getCustodianFromProfile(profile)]
            : []),
        // ...(roleIds?.includes(RoleId.BRIDGER) ? [{ id: RoleId.BRIDGER }] : []),
    ];
}
function getVerifierFromProfile(profile) {
    return {
        id: RoleId.VERIFIER,
        name: profile.name,
        categories: [profile.verifier.category?.value || "other"],
        authenticationReport: profile.verifier.authenticationReport || "",
        valuationCertificate: profile.verifier.valuationCertificate || "",
        feePriceBands: profile.verifier.feePriceBands?.map((band) => ({
            min: { value: band.min.toString(), currency: "USD" },
            max: { value: band.max.toString(), currency: "USD" },
            tokens: [
                band.usdc
                    ? {
                        value: band.usdc.toString(),
                        chainId: "80002",
                        address: "0x41e94eb019c0762f9bfcf9fb1e58725bfb0e7582",
                    } // TODO: Real chainId / Address to get from config
                    : undefined,
                band.weth
                    ? {
                        value: band.weth.toString(),
                        chainId: "80002",
                        address: "0x52eF3d68BaB452a294342DC3e5f464d7f610f72E",
                    } // TODO: Real chainId / Address to get from config
                    : undefined,
            ].filter(isTruthy),
        })),
        isSelfVerifier: profile.verifier.selfOrThirdParty?.value === "true",
    };
}
function getCustodianFromProfile(profile) {
    const storageFee = profile?.custodian?.storageFees?.[0];
    const storageFees = [
        storageFee?.usdc
            ? {
                value: storageFee.usdc.toString(),
                chainId: "80002",
                address: "0x41e94eb019c0762f9bfcf9fb1e58725bfb0e7582",
                // TODO: Real chainId / Address to get from config
            }
            : undefined,
        storageFee?.weth
            ? {
                value: storageFee.weth.toString(),
                chainId: "80002",
                address: "0x52eF3d68BaB452a294342DC3e5f464d7f610f72E",
                // TODO: Real chainId / Address to get from config
            }
            : undefined,
    ].filter(isTruthy);
    return {
        id: RoleId.CUSTODIAN,
        name: profile.name,
        locations: [
            {
                tag: ProfileLocationTags.CUSTODY,
                name: profile.custodian.locationName || "",
                number: profile.custodian.location.number,
                streetName: profile.custodian.location.streetName,
                city: profile.custodian.location.city || "",
                zip: profile.custodian.location.zip || "",
                state: profile.custodian.location.state,
                country: profile.custodian.location.country || "",
            },
        ],
        insuredValue: {
            value: profile.custodian.insuredValue?.toString() || "",
            currency: "USD",
        },
        storageFees,
        storageFeesPeriod: profile.custodian.storageFeesPeriod?.value,
        shippingMethods: profile.custodian.shippingMethod?.value === ShippingMethodsValues.delivery
            ? ["delivery"]
            : ShippingMethodsValues.pickup
                ? ["pickup"]
                : ShippingMethodsValues.delivery_and_pickup
                    ? ["delivery", "pickup"]
                    : [],
        categories: [profile.custodian.category?.value || "other"],
    };
}
