import { Grid, Loading, Prettify, Typography } from "@bosonprotocol/react-kit";
import { eFNFTStatus } from "@fermionprotocol/common";
import {
  borders,
  Button,
  colors,
  formatCurrency,
  hooks,
  resolveUrlFromIPFS,
  Subgraph,
} from "@fermionprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import { generateListingPath } from "lib/utils/generateListingPath";
import { Trash } from "phosphor-react";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { CONFIG } from "../../../../config";
import { GridRow } from "../shared/GridRow";
import { GridThreeColumns } from "../shared/GridThreeColumns";
import { GridTwoColumns } from "../shared/GridTwoColumns";
import { Span } from "../shared/Span";
import { Timeline } from "./Timeline";

interface ProductInformationBuyerModalProps {
  type: "buyer";
  price: { amount: string; currency: string } | undefined;
  fNFTId: string;
  unverifiedDate?: string | null;
  checkOutRequestedDate?: string | null;
  verifiedDate?: string | null;
  checkedInDate?: string | null;
  checkedOutDate?: string | null;
  assessmentStatus: eFNFTStatus;
}

type ProductDetailsModalProps = {
  productId: string;
} & ({ type: "agent"; fNFTId?: never } | ProductInformationBuyerModalProps);

type EmailSpanProps = {
  entity?: {
    metadata?: {
      contactLinks?: Prettify<Subgraph.EntityContactLink>[] | null | undefined;
    } | null;
  };
};

interface LocationRowProps {
  label: string;
  businessLocation?: Subgraph.PostalAddress;
}

interface ReadMoreSpanProps {
  text?: string;
}

const ProductDetailsModalContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`;

const ProductInformationColumn = styled(Grid)`
  position: relative;
  padding: 1rem;
  &:before {
    position: absolute;
    content: "";
    top: -2rem;
    left: -1rem;
    width: 0.0625rem;
    height: calc(100% + 4rem);
    background-color: ${colors.greyLight};
  }
`;

const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-top: 0.5rem;
`;
const ThumbnailContainer = styled.div`
  border: 2px solid ${colors.greyLight};
  border-radius: ${borders.small}px;
`;
const Thumbnail = styled.img`
  width: 9.375rem;
  height: 9.375rem;
  display: block;
`;

const SectionsWrapper = styled(Grid)`
  flex-direction: column;
  gap: 2.5rem;
  min-width: 40.625rem;
`;

const currenciesSymbols: Record<string, string> = {
  USD: "$",
} as const;

const Section: React.FC<{ header: string; children: React.ReactNode }> = ({
  children,
  header,
}) => {
  return (
    <Grid flexDirection="column" gap="1rem" alignItems="start">
      <Typography
        tag="h4"
        margin={0}
        fontWeight={400}
        style={{ textTransform: "capitalize" }}
      >
        {header}
      </Typography>
      {children}
    </Grid>
  );
};

const EmailSpan: React.FC<EmailSpanProps> = ({ entity }) => {
  const email = entity?.metadata?.contactLinks?.find(
    (link) => link.tag === "email",
  )?.url;

  return <Span>{email ?? "-"}</Span>;
};

const LocationRow: React.FC<LocationRowProps> = ({
  businessLocation,
  label,
}) => {
  return (
    <GridRow label={label}>
      <Span>
        {businessLocation?.streetName}, {businessLocation?.number}
      </Span>
      <Span>
        {businessLocation?.zip}, {businessLocation?.city}
      </Span>
      <Span>{businessLocation?.state}</Span>
      <Span>{businessLocation?.country}</Span>
    </GridRow>
  );
};

const MAX_CHARS = 381;
const ReadMoreSpan: React.FC<ReadMoreSpanProps> = ({ text }) => {
  const [showMore, setShowMore] = useState(false);
  if (!text) return "-";
  const textToShow = showMore ? text : text.slice(0, MAX_CHARS);
  // TODO: on figma looks like array of paragraphs
  return (
    <Typography fontSize="0.875rem" tag="p" margin={0} display="inline-block">
      {textToShow}
      {showMore ? "" : "..."}
      {text.length > MAX_CHARS && (
        <Button
          style={{
            display: "flex",
            minHeight: "auto",
            padding: 0,
            fontWeight: 500,
            textDecorationLine: "underline",
            fontSize: "0.875rem",
            color: colors.velvet,
            margin: 0,
          }}
          variant="transparent"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Less" : "More"}
        </Button>
      )}
    </Typography>
  );
};

export function ProductDetailsModal(props: ProductDetailsModalProps) {
  const { data, isLoading } = hooks.useGetOffers({
    // Buyer page does not use this query, so query just for one product
    ...(props.type === "buyer" && {
      queryVars: { offersFilter: { bosonOfferId: props.productId } },
    }),
    options: {
      // If type is agent it means we have cached data, so we don't need to refetch
      enabled: props.type === "buyer",
      select: (offers) => {
        const product = offers.find((offer) => offer.id === props.productId);
        return product ? [product] : [];
      },
    },
  });

  const [offer] = data ?? [];
  const { updateProps, store, hideModal } = useModal();
  const navigate = useCustomNavigate();
  useEffect(() => {
    updateProps<"PRODUCT_DETAILS">({
      ...store,
      modalProps: {
        ...store.modalProps,
        productId: props.productId,
        ...(props.type === "buyer"
          ? {
              type: "buyer",
              assessmentStatus: props.assessmentStatus,
              fNFTId: props.fNFTId,
              price: props.price,
            }
          : { type: "agent" }),
        headerComponent: (
          <Typography
            fontSize={"1.5rem"}
            tag="h3"
            color={colors.velvet}
            fontWeight={400}
            margin={0}
            padding={0}
          >
            Asset details
          </Typography>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const custodian = offer?.custodian.metadata;
  const productMeta = offer?.metadata?.product;
  const seller = offer?.seller?.metadata;
  const verifier = offer?.verifier.metadata;

  if (isLoading) {
    return (
      <Grid alignItems="center" justifyContent="center">
        <Loading />
      </Grid>
    );
  }

  return (
    <ProductDetailsModalContainer>
      <SectionsWrapper>
        <Section header="Asset information">
          {props.type === "buyer" && (
            <Typography tag="span" fontSize="0.75rem" color={colors.velvetHalf}>
              fNFT ID: {props.fNFTId}
            </Typography>
          )}
          <GridThreeColumns>
            <GridRow
              label="Asset type"
              capitalizeText
              text={productMeta?.offerCategory.toLowerCase()}
            />
            {/* TODO: remove magic text*/}
            <GridRow label="Asset variants" text="One item type" />
            {/* TODO: remove magic text */}
            <GridRow label="Token gating" text="Ungated" />
          </GridThreeColumns>
          <GridTwoColumns>
            <GridRow label="Asset title" text={productMeta?.title} />
            <GridRow label="Brand name" text={productMeta?.brand.name} />
          </GridTwoColumns>
          <GridTwoColumns>
            <GridRow
              label="Asset category"
              text={productMeta?.category?.value}
            />
            <GridRow label="Category attributes">
              {(productMeta?.categoryAttributes?.length ?? 0) > 0
                ? productMeta?.categoryAttributes?.map((attr) => (
                    <Span key={attr.name}>
                      {attr.name}: {attr.value}
                    </Span>
                  ))
                : "-"}
            </GridRow>
          </GridTwoColumns>
          <GridTwoColumns>
            <GridRow
              label="Asset condition"
              text={productMeta?.condition?.value}
            />
            <GridRow
              label="Asset condition description"
              text={productMeta?.conditionDescription ?? undefined}
            />
          </GridTwoColumns>
        </Section>
        <Section header="Photo / video">
          <Grid flexDirection="column" alignItems="flex-start">
            {productMeta?.visuals_images.map((image) => (
              <ThumbnailContainer key={image.id}>
                <Thumbnail
                  src={resolveUrlFromIPFS(image.url || "", CONFIG.ipfsGateway)}
                />
              </ThumbnailContainer>
            ))}
          </Grid>
        </Section>
        <Section header="Seller Information">
          <GridRow label="Business or company name" text={seller?.name} />
          <GridRow label="Contact email">
            <EmailSpan entity={offer?.seller} />
          </GridRow>
          <LocationRow
            label="Business registration address"
            businessLocation={seller?.businessLocation}
          />
        </Section>
        <Section header="Verifier & Custodian Information">
          <GridTwoColumns>
            <Grid
              flexDirection="column"
              alignItems="flex-start"
              gap="1.5rem"
              justifyContent="start"
            >
              <Typography
                tag="h6"
                margin={0}
                fontSize="0.875rem"
                fontWeight={500}
              >
                Verifier profile info
              </Typography>
              <GridRow
                label="Business, brand, or company name"
                text={verifier?.name}
              />
              <GridRow label="Contact email">
                <EmailSpan entity={offer?.verifier} />
              </GridRow>
              <GridRow
                label="Business phone number"
                text={verifier?.businessPhoneNumber}
              />
              <GridRow label="Authentication services">
                <ReadMoreSpan
                  text={verifier?.bridger?.verifier?.authenticationReport}
                />
              </GridRow>
            </Grid>
            <Grid
              flexDirection="column"
              alignItems="flex-start"
              gap="1.5rem"
              justifyContent="start"
            >
              <Typography
                margin={0}
                tag="h6"
                fontSize="0.875rem"
                fontWeight={500}
              >
                Custodian profile info
              </Typography>
              <GridRow
                label="Business or company name"
                text={custodian?.name}
              />
              <GridRow label="Contact email">
                <EmailSpan entity={offer?.custodian} />
              </GridRow>
              <GridRow
                label="Business phone number"
                text={custodian?.businessPhoneNumber}
              />
              <GridRow
                label="Location name"
                text={custodian?.businessLocation.name}
              />
              <LocationRow
                label="Location address"
                businessLocation={custodian?.businessLocation}
              />
              <GridRow
                label="Insured value"
                text={`${currenciesSymbols?.[custodian?.custodian?.insuredValue.currency ?? ""] ?? ""} ${formatCurrency(custodian?.custodian?.insuredValue.value)}`}
              />
            </Grid>
          </GridTwoColumns>
          <GridRow
            label="Valuation certificate"
            text={verifier?.verifier?.valuationCertificate}
          />
        </Section>
        <Section header="Terms of Exchange">
          <GridTwoColumns>
            <GridRow
              label="Asset price"
              text={
                props.type === "buyer"
                  ? `${formatCurrency(props.price?.amount)} ${props.price?.currency}`
                  : ""
              }
            />
            <GridRow
              label="Seller deposit"
              text={`${offer?.sellerDeposit} ${offer?.exchangeToken.symbol}`}
            />
          </GridTwoColumns>
          <GridTwoColumns>
            <GridRow
              label="Custodian fee"
              text={`${offer?.custodianFee.amount} ${offer?.exchangeToken.symbol}, ${offer?.custodianFee.period}`}
            />
            <GridRow
              label="Verification fee"
              text={`${offer?.verifierFee} ${offer?.exchangeToken.symbol}`}
            />
          </GridTwoColumns>
          <GridRow
            label="Facilitator commission"
            text={`${offer?.facilitatorFeePercent}%`}
          />
        </Section>
        <Section header="Delivery & Returns">
          <GridTwoColumns>
            {/* TODO: how to take it? */}
            <GridRow label="Delivery options">
              {(offer?.metadata?.shipping?.supportedJurisdictions?.length ??
                0) > 0
                ? offer?.metadata?.shipping?.supportedJurisdictions?.map(
                    ({ deliveryTime, label }) => (
                      <div key={`${label}-${deliveryTime}`}>
                        {label}, {deliveryTime}
                      </div>
                    ),
                  )
                : "-"}
            </GridRow>
            <GridRow label="Return policy">
              {offer?.metadata?.shipping?.returnPolicy?.name && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={offer.metadata.shipping.returnPolicy.url}
                >
                  {offer.metadata.shipping.returnPolicy.name}
                </a>
              )}
            </GridRow>
          </GridTwoColumns>
        </Section>
        {/* TODO: extend */}
        {props.type === "buyer" && (
          <Section header="Verification Assessment">
            <GridRow
              label="Assessment"
              text={eFNFTStatus[props.assessmentStatus]}
            />
          </Section>
        )}
      </SectionsWrapper>
      <ProductInformationColumn
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="start"
      >
        {props.type === "buyer" ? (
          <>
            <Typography tag="h3" fontSize="1.25rem" margin={0}>
              Asset History
            </Typography>
            <Timeline
              timesteps={[
                { text: "Bought", date: props.unverifiedDate ?? undefined },
                {
                  text: "Sent to verification",
                  date: props.verifiedDate ?? undefined, // TODO: change, it should come from actionLogs in profile
                },
                { text: "Verified", date: props.verifiedDate ?? undefined },
                {
                  text: "Sent to custodian",
                  date: props.checkedInDate ?? undefined, // TODO: change, it should come from actionLogs in profile
                },
                { text: "Checked in", date: props.checkedInDate ?? undefined },
                {
                  text: "Requested checkout",
                  date: props.checkOutRequestedDate ?? undefined,
                },
                {
                  text: "Checked out",
                  date: props.checkedOutDate ?? undefined,
                },
              ]}
            />
          </>
        ) : (
          <Grid
            padding="0.3125rem 1.5rem 1.5rem 1.5rem"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography
              fontSize={"1.125rem"}
              color={colors.velvet}
              marginBottom={"1rem"}
            >
              Actions
            </Typography>
            <StyledButton
              variant="pinkLight"
              onClick={() => {
                navigate({
                  pathname: generateListingPath({
                    sellerId: `${offer?.sellerId}`,
                    uuid: `${offer?.metadata?.uuid}`,
                    bosonOfferId: `${offer?.bosonOfferId}`,
                  }),
                });
                hideModal();
              }}
            >
              List on OpenSea
            </StyledButton>
            <StyledButton variant="grey">Duplicate</StyledButton>
            <StyledButton variant="transparent">Unlist</StyledButton>
            <StyledButton
              variant="transparent"
              style={{
                color: colors.red,
              }}
            >
              Void
              <Trash size={20} />
            </StyledButton>
          </Grid>
        )}
      </ProductInformationColumn>
    </ProductDetailsModalContainer>
  );
}
