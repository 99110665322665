import {
  SimpleError as BaseSimpleError,
  SimpleErrorProps as BaseSimpleErrorProps,
} from "@bosonprotocol/react-kit";
import styled from "styled-components";

import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";

const StyledBaseSimpleError = styled(BaseSimpleError)`
  border-radius: ${borders.small}px;
`;

export type SimpleErrorProps = BaseSimpleErrorProps;
export const SimpleError = ({ ...rest }: SimpleErrorProps) => {
  return <StyledBaseSimpleError {...rest} backgroundColor={colors.whiteOff} />;
};
