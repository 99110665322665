import { Grid, Typography } from "@bosonprotocol/react-kit";
import {
  borders,
  Button,
  colors,
  ColumnHeaderContainer,
  getNumberWithDecimals,
  hooks,
  Table,
} from "@fermionprotocol/react-kit";
import ETHImg from "assets/currencies/ETH.png";
import MaticImg from "assets/currencies/MATIC.png";
import USDCImg from "assets/currencies/USDC.png";
import { FilterButtons } from "components/hub/shared/FilterButtons";
import { useModal } from "components/modal/useModal";
import { EmptyTable } from "components/table/EmptyTable";
import { TablePagination } from "components/table/TablePagination";
import {
  CategoryWrapper,
  TableThumbnailStyled,
} from "components/table/TableStyles";
import { BigNumber } from "ethers";
import { Clock, Package, ShieldCheck, ShieldCheckered } from "phosphor-react";
import React, { useMemo, useState } from "react";
import {
  useExpanded,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { styled } from "styled-components";

const ActionsContainer = styled.div`
  margin-right: 1.5rem;
`;
interface StyledStateLabelProps {
  backgroundColor?: string;
}

const StyledStateLabel = styled.div<StyledStateLabelProps>`
  display: flex;
  background-color: ${(props) => props.backgroundColor || "transparent"};
  padding: 0.25rem 0.375rem 0.25rem 0.375rem;
  width: fit-content;
  justify-content: center;
  align-items: center;
  border-radius: ${borders.small}px;
  svg {
    margin-right: 0.625rem;
  }
`;

const StyledFinancesButton = styled(Button)`
  min-width: 100px;
`;

const accessors = {
  token: "token",
  allFunds: "allFunds",
  lockedFunds: "lockedFunds",
  withdrawable: "withdrawable",
  actions: "actions",
} as const;

const parseStateLabel = (state: string) => {
  if (state === "ACCEPTED") {
    return (
      <StyledStateLabel backgroundColor={colors.blueLight}>
        <Clock size={20} color={colors.blue} />
        <Typography fontSize={"0.75rem"} fontWeight={"600"} color={colors.blue}>
          ACCEPTED BID
        </Typography>
      </StyledStateLabel>
    );
  }
  if (state === "VERIFIED") {
    return (
      <StyledStateLabel backgroundColor={colors.greenLight}>
        <ShieldCheck size={20} color={colors.green} />
        <Typography
          fontSize={"0.75rem"}
          fontWeight={"600"}
          color={colors.green}
        >
          VERIFIED
        </Typography>
      </StyledStateLabel>
    );
  }
  if (state === "TO_CUSTODIAN") {
    return (
      <StyledStateLabel backgroundColor={colors.purpleLight}>
        <ShieldCheckered size={20} color={colors.purple} />
        <Typography
          fontSize={"0.75rem"}
          fontWeight={"600"}
          color={colors.purple}
        >
          TO CUSTODIAN
        </Typography>
      </StyledStateLabel>
    );
  }
  if (state === "IN_CUSTODY") {
    return (
      <StyledStateLabel backgroundColor={colors.greenLight}>
        <ShieldCheckered size={20} color={colors.green} />
        <Typography
          fontSize={"0.75rem"}
          fontWeight={"600"}
          color={colors.green}
        >
          IN CUSTODY
        </Typography>
      </StyledStateLabel>
    );
  }
  if (state === "REDEEMED") {
    return (
      <StyledStateLabel backgroundColor={colors.greenLight}>
        <Package size={20} color={colors.green} />
        <Typography
          fontSize={"0.75rem"}
          fontWeight={"600"}
          color={colors.green}
        >
          REDEEMED
        </Typography>
      </StyledStateLabel>
    );
  }
};

const currencies = {
  ETH: ETHImg,
  WETH: ETHImg,
  MATIC: MaticImg,
  USDC: USDCImg,
} as const;

interface SymbolToCurrencyProps {
  currency: string;
  hideLabel?: boolean;
  width?: number | string;
  height?: number | string;
}

export const SymbolToCurrency: React.FC<SymbolToCurrencyProps> = ({
  currency,
  hideLabel,
  width,
  height,
}) => {
  const src = currencies[currency as keyof typeof currencies];

  if (!src) return currency;

  return (
    <Grid gap="0.5rem" justifyContent="start" alignItems="center">
      <img src={src} alt={currency} width={width} height={height} />
      {!hideLabel && <Typography tag="span">{currency}</Typography>}
    </Grid>
  );
};

type FilterButton = {
  label: string;
  value: string;
};

const filterButtons: FilterButton[] = [
  { label: "All", value: "all" },
  { label: "Seller income", value: "sellerIncome" },
  { label: "Verification fees", value: "verificationFees" },
  { label: "Custodian fees", value: "custodianFees" },
  { label: "Facilitator commissions", value: "facilitatorCommissions" },
] as const;

const availableMethods = ["withdraw", "offramp", "deposit"] as const;

interface FinancesTableProps {
  financesData: hooks.ExtendedFundsEntityFieldsFragment[];
  hiddenColumns?: (keyof typeof accessors)[];
  hiddenMethods?: (typeof availableMethods)[keyof typeof availableMethods][];
  disableFilters?: boolean;
  refetch: () => void;
}

export function FinancesTable({
  financesData,
  hiddenColumns = [],
  hiddenMethods = [],
  disableFilters,
  refetch,
}: FinancesTableProps) {
  const [activeFilterButton, setActiveFilterButton] = useState<
    string | undefined
  >(filterButtons?.[0]?.value);
  const { showModal } = useModal();

  const columns = useMemo(
    () => [
      {
        Header: (
          <ColumnHeaderContainer marginLeft="0.75rem">
            <Typography fontSize="0.75rem">Token</Typography>
          </ColumnHeaderContainer>
        ),

        accessor: accessors.token,
        minWidth: 55,
      } as const,
      {
        Header: (
          <ColumnHeaderContainer marginLeft="-0.5rem">
            <Typography fontSize="0.75rem">All funds</Typography>
          </ColumnHeaderContainer>
        ),
        accessor: accessors.allFunds,
        minWidth: 250,
      } as const,
      {
        Header: (
          <ColumnHeaderContainer marginLeft="-0.5rem">
            <Typography fontSize="0.75rem">Locked funds</Typography>
          </ColumnHeaderContainer>
        ),
        accessor: accessors.lockedFunds,
        minWidth: 250,
      } as const,
      {
        Header: (
          <ColumnHeaderContainer>
            <Typography fontSize="0.75rem">Withdrawable</Typography>
          </ColumnHeaderContainer>
        ),
        accessor: accessors.withdrawable,
        minWidth: 250,
      } as const,
      {
        Header: "",
        accessor: accessors.actions,
        minWidth: 300,
      } as const,
    ],
    [],
  );

  const data = useMemo(() => {
    return financesData.map((e) => ({
      token: <SymbolToCurrency currency={e.token.symbol} />,
      tokenSymbol: e.token.symbol,
      tokenDecimals: e.token.decimals,
      allFunds: BigNumber.from(e.availableAmount).add(e.lockedFunds).toString(),
      lockedFunds: e.lockedFunds,
      withdrawable: e.availableAmount,
      tokenAddress: e.tokenAddress,
      actions: null,
    }));
  }, [financesData]);

  const parseColumnType = (
    columnId: string,
    columnText: string,
    rowOriginal: (typeof data)[number],
  ) => {
    if (columnId === "token") {
      return <Typography marginLeft={"1.5rem"}>{columnText}</Typography>;
    }
    if (columnId === "state") {
      return parseStateLabel(columnText);
    }
    if (columnId === "category") {
      return (
        <CategoryWrapper>
          <Typography
            fontSize={"0.75rem"}
            fontWeight={500}
            color={colors.greyDark}
          >
            {columnText}
          </Typography>
        </CategoryWrapper>
      );
    }
    if (columnId === "thumbnail") {
      return <TableThumbnailStyled src={columnText} alt="Thumbnail" />;
    }
    if (columnId === "id") {
      return (
        <Typography
          fontSize={"0.75rem"}
          fontWeight={400}
          color={colors.purpleDeep}
        >
          {columnText}
        </Typography>
      );
    }
    if (columnId === "productName") {
      return (
        <Typography
          fontSize={"0.875rem"}
          fontWeight={500}
          color={colors.purpleDeep}
        >
          {columnText}
        </Typography>
      );
    }

    if (
      columnId === "actions" &&
      new Set(hiddenMethods).size !== availableMethods.length
    ) {
      return (
        <ActionsContainer>
          <Grid justifyContent="center" alignItems="center" gap={"0.5rem"}>
            {!hiddenMethods.includes("withdraw") && (
              <StyledFinancesButton
                variant={"grey"}
                onClick={() =>
                  showModal({
                    modalType: "WITHDRAW_FUNDS",
                    modalProps: {
                      tokenSymbol: rowOriginal.tokenSymbol,
                      maxWithdrawableAmount: rowOriginal.withdrawable,
                      tokenOrNativeAddress: rowOriginal.tokenAddress,
                      tokenDecimals: rowOriginal.tokenDecimals,
                      reload: refetch,
                    },
                    modalMinWidth: {
                      xs: "37.5rem",
                    },
                  })
                }
              >
                <Typography fontSize={"0.75rem"} fontWeight={600}>
                  Withdraw
                </Typography>
              </StyledFinancesButton>
            )}
            {!hiddenMethods.includes("offramp") && (
              <StyledFinancesButton
                variant={"grey"}
                onClick={() =>
                  showModal({
                    modalType: "OFFRAMP_FUNDS",
                    modalProps: {
                      tokenOrNativeSymbol: "eth",
                    },
                  })
                }
              >
                <Typography fontSize={"0.75rem"} fontWeight={600}>
                  Offramp
                </Typography>
              </StyledFinancesButton>
            )}
            {!hiddenMethods.includes("deposit") && (
              <StyledFinancesButton
                variant={"grey"}
                onClick={() =>
                  showModal({
                    modalType: "DEPOSIT_FUNDS",
                    modalProps: {
                      tokenSymbol: rowOriginal.token,
                      token: rowOriginal.tokenAddress,
                      tokenDecimals: rowOriginal.tokenDecimals,
                      protocolBalance: rowOriginal.allFunds,
                      reload: refetch,
                    },
                    modalMinWidth: {
                      xs: "37.5rem",
                    },
                  })
                }
              >
                <Typography fontSize={"0.75rem"} fontWeight={600}>
                  Deposit
                </Typography>
              </StyledFinancesButton>
            )}
          </Grid>
        </ActionsContainer>
      );
    }
    if (
      columnId === "allFunds" ||
      columnId === "lockedFunds" ||
      columnId === "withdrawable"
    ) {
      return (
        <>{getNumberWithDecimals(columnText, rowOriginal.tokenDecimals)}</>
      );
    }
    return columnText;
  };

  const tableProps = useTable(
    {
      columns,
      data,
      autoResetExpanded: false,
      initialState: {
        pageIndex: 0,
        hiddenColumns,
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
  );

  const {
    rows,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
    state: { pageIndex, pageSize },
  } = tableProps;

  const paginate = useMemo(() => {
    return Array.from(Array(pageCount).keys()).slice(
      pageIndex < 1 ? 0 : pageIndex - 1,
      pageIndex < 1 ? 3 : pageIndex + 2,
    );
  }, [pageCount, pageIndex]);

  if (tableProps.page.length === 0) {
    return <EmptyTable emptyText="There is no tokens to show" />;
  }

  return (
    <>
      {!disableFilters && (
        <Grid alignItems="center" marginBottom="1.0625rem">
          <FilterButtons
            buttons={filterButtons}
            setActiveFilterButton={setActiveFilterButton}
            activeFilterButton={activeFilterButton}
          />
        </Grid>
      )}
      <Table tableProps={tableProps} parseColumnType={parseColumnType} />
      <TablePagination
        pageSize={pageSize}
        pageIndex={pageIndex}
        pageCount={pageCount}
        rowsLength={rows.length}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        paginate={paginate}
        setPageSize={setPageSize}
        previousPage={previousPage}
        nextPage={nextPage}
        gotoPage={gotoPage}
      />
    </>
  );
}
