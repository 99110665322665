export const formatUSD = (value, currency = "USD", symbolAtEnd = false) => {
    const formatted = value.toLocaleString("en-US", {
        style: "currency",
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    if (symbolAtEnd) {
        const symbolMatch = formatted.match(/^(\D+)/);
        const symbol = symbolMatch ? symbolMatch[0].trim() : "";
        return formatted.replace(/^(\D+)/, "") + symbol;
    }
    return formatted;
};
export const formatCurrency = (value) => Number(value ?? 0).toLocaleString("en-US", {
    minimumFractionDigits: 4,
});
