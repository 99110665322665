const hubRoute = "/hub";

const hubRoutes = {
  createProduct: "/create-product",
  productsOwn: "/products/own",
  exchanges: "/exchanges",
};

export const UrlParameters = {
  uuid: "uuid",
  sellerId: "id",
  bosonOfferId: "bosonOfferId",
} as const;

export const fermionRoutes = {
  home: "/",
  moonpay: "/moonpay",
  buyer: "/buyer",
  buyerAssets: "/buyer/assets",
  hub: hubRoute,
  about: "/about",
  createProduct: `${hubRoute}${hubRoutes.createProduct}`,
  exchanges: `${hubRoute}${hubRoutes.exchanges}`,
  productsOwn: `${hubRoute}${hubRoutes.productsOwn}`,
  listing: `/listing/:${UrlParameters.sellerId}/:${UrlParameters.uuid}/:${UrlParameters.bosonOfferId}`,
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
} as const;

export const fermionProtocolRoutes = {
  aboutFermion: "https://www.fermionprotocol.io/",
  community: "https://www.fermionprotocol.io/community/",
  build: "https://www.fermionprotocol.io/build/",
  missionAndVision: "https://www.fermionprotocol.io/mission-vision/",
  // TODO: change the URL here after postnet launch
  exploreAssets:
    "https://testnets.opensea.io/0xC58163fC8b820c735EA5f8f782AAFf74E71b5156",
} as const;

export const AgentHubSubRoutes = {
  Dashboard: "dashboard",
  Products: "products",
  ProductsOwn: "products/own",
  ProductsFacilitated: "products/facilitated",
  Bids: "bids",
  Exchanges: "exchanges",
  Verifications: "verifications",
  Custody: "custody",
  Finances: "finances",
  Profile: "profile",
} as const;

export const BuyerHubSubRoutes = {
  Assets: "assets",
  Finances: "finances",
} as const;
