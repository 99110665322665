import type {
  BaseButtonTheme,
  BaseUploadProps,
} from "@bosonprotocol/react-kit";

import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";

export const buttonThemes = {
  pink: {
    color: colors.white,
    background: colors.pink,
    borderColor: colors.pink,
    borderWidth: borders.defaultBorderWidthPx,
    borderRadius: borders.large,
    hover: {
      background: colors.black,
      color: colors.white,
      borderColor: colors.black,
    },
  },
  pinkLight: {
    color: colors.pink,
    background: colors.pinkLight,
    borderColor: colors.borderPinkLight,
    borderWidth: borders.defaultBorderWidthPx,
    borderRadius: borders.large,
    hover: {
      background: colors.pink,
      color: colors.white,
      borderColor: colors.pink,
    },
  },
  pinkLightest: {
    color: colors.pink,
    background: colors.pinkLightest,
    borderColor: colors.borderPinkLight,
    borderWidth: borders.defaultBorderWidthPx,
    borderRadius: borders.large,
    hover: {
      background: colors.pink,
      color: colors.white,
      borderColor: colors.pink,
    },
  },
  blue: {
    color: colors.white,
    background: colors.blue,
    borderColor: colors.blue,
    borderWidth: borders.defaultBorderWidthPx,
    borderRadius: borders.large,
    hover: {
      background: colors.black,
      color: colors.white,
      borderColor: colors.black,
    },
  },
  pinkTransparent: {
    color: colors.velvetLight,
    background: colors.white,
    borderColor: colors.white,
    borderWidth: borders.defaultBorderWidthPx,
    borderRadius: borders.defaultBorderRadiusPx,
    hover: {
      background: colors.pinkLightest,
      color: colors.pinkAccent,
      borderColor: colors.pinkLightest,
    },
  },
  pinkMain: {
    color: colors.pink,
    background: colors.pinkLight,
    borderColor: colors.pinkMain,
    borderWidth: borders.defaultBorderWidthPx,
    borderRadius: borders.defaultBorderRadiusPx,
    hover: {
      background: colors.pinkMain,
      color: colors.white,
      borderColor: colors.pinkMain,
    },
  },
  grey: {
    color: colors.purpleDeep,
    background: colors.shade,
    borderColor: colors.borderShade,
    borderWidth: borders.defaultBorderWidthPx,
    borderRadius: borders.large,
    hover: {
      background: colors.hoveredShade,
      color: colors.purpleDeep,
      borderColor: colors.borderHoveredShade,
    },
  },
  white: {
    color: colors.greyDark,
    background: colors.white,
    borderColor: colors.white,
    borderWidth: borders.defaultBorderWidthPx,
    borderRadius: borders.large,
    hover: {
      background: colors.shade,
      color: colors.black,
      borderColor: colors.borderShade,
    },
  },
  green: {
    color: colors.green,
    background: colors.greenLight,
    borderColor: colors.greenLight,
    borderWidth: borders.defaultBorderWidthPx,
    borderRadius: borders.large,
    hover: {
      background: colors.shade,
      color: colors.black,
      borderColor: colors.borderShade,
    },
  },
  transparent: {
    color: colors.greyDark,
    background: "transparent",
    borderColor: "transparent",
    borderWidth: borders.defaultBorderWidthPx,
    borderRadius: borders.large,
    hover: {
      background: "transparent",
      color: colors.black,
      borderColor: "transparent",
    },
  },
} satisfies Record<string, BaseButtonTheme>;

export const buttonThemeKeys = Object.keys(buttonThemes);

export const uploadTheme = {
  triggerTheme: {
    background: colors.whiteOff,
    borderColor: colors.border,
    borderRadius: `${borders.small}px`,
    error: {
      borderColor: colors.orange,
    },
    focus: {
      borderColor: colors.border,
    },
  },
} satisfies BaseUploadProps["theme"];
