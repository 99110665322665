import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint @typescript-eslint/no-explicit-any: "off" */
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
import { FieldInput } from "@bosonprotocol/react-kit";
import { memo, useCallback, useEffect, useRef, useState, } from "react";
import { useDidMountEffect } from "../../../hooks/useDidMountEffect";
import { getTimeZoneWithGMT } from "../../../utils/dates/time";
import { Calendar } from "./Calendar";
import { ClearButton, DatePickerWrapper, Picker, PickerGrid, } from "./DatePicker.style";
import { SelectMonth } from "./SelectMonth";
import { SelectTime } from "./SelectTime";
const handleInitialDates = (initialValue) => {
    let startDate = null;
    let endDate = null;
    let chosenTime = null;
    if (initialValue) {
        if (Array.isArray(initialValue)) {
            if (initialValue.length) {
                startDate = dayjs(initialValue[0]);
                endDate = dayjs(initialValue[1]);
                chosenTime = {
                    hour: [
                        startDate.toDate().getHours().toString(),
                        endDate.toDate().getHours().toString(),
                    ],
                    minute: [
                        startDate.toDate().getMinutes().toString(),
                        endDate.toDate().getMinutes().toString(),
                    ],
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    timezone: startDate.$x.$timezone ?? endDate.$x.$timezone,
                };
            }
        }
        else {
            startDate = dayjs(initialValue);
            chosenTime = {
                hour: startDate.toDate().getHours().toString(),
                minute: startDate.toDate().getMinutes().toString(),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                timezone: startDate.$x.$timezone,
            };
        }
    }
    return {
        startDate,
        endDate,
        chosenTime,
    };
};
const dateTimeFormat = "MMMM D, YYYY HH:mm";
const DatePicker = memo(function DatePicker({ initialValue, onChange, onBlur, onClick, onShow, period, selectTime, maxDate, minDate = dayjs(), isClearable, placeholder = "Choose dates...", ...props }) {
    const ref = useRef(null);
    const [month, setMonth] = useState(dayjs());
    const [time, setTime] = useState(null);
    const [date, setDate] = useState(null);
    const [secondDate, setSecondDate] = useState(null);
    const [shownDate, setShownDate] = useState();
    const [show, setShow] = useState(false);
    const [showTime, setShowTime] = useState(false);
    useEffect(() => {
        const { startDate, endDate, chosenTime } = handleInitialDates(initialValue);
        if (date === null)
            setDate(startDate);
        if (secondDate === null)
            setSecondDate(endDate);
        if (chosenTime === null)
            setTime(chosenTime);
    }, [initialValue]); // eslint-disable-line
    const setShowHandler = (value) => {
        setShow(value);
        onShow?.(value);
    };
    const handleShow = () => {
        setShowHandler(!show);
        onClick?.();
    };
    const reset = useCallback(() => {
        setDate(null);
        setSecondDate(null);
        setShowTime(false);
        setShownDate("");
    }, []);
    const handleDateChange = (inputDate) => {
        if (inputDate === null) {
            return reset();
        }
        if (period) {
            if (date === null || (date !== null && secondDate !== null)) {
                setDate(inputDate);
                setSecondDate(null);
            }
            else if (date !== null &&
                secondDate === null &&
                inputDate?.isBefore(date, "day")) {
                setDate(inputDate);
                setSecondDate(date);
            }
            else if (secondDate === null) {
                setSecondDate(inputDate);
            }
        }
        else {
            setDate(inputDate);
        }
    };
    useDidMountEffect(() => {
        if ((!period && date !== null) || (period && date !== null)) {
            setShowTime(true);
        }
        if ((!period && date === null) ||
            (period && date === null && secondDate === null)) {
            setShownDate("");
            if (period) {
                onChange?.([]);
            }
            else {
                onChange?.(null);
            }
        }
    }, [date, secondDate, period]);
    useEffect(() => {
        if (period) {
            if (date instanceof dayjs && secondDate instanceof dayjs) {
                let newDate = date;
                let newSecondDate = secondDate;
                if (time !== null) {
                    const prevDay = date.get("day");
                    newDate = date
                        .tz(time.timezone)
                        .set("day", prevDay)
                        .set("hour", Number(time.hour[0]))
                        .set("minute", Number(time.minute[0]));
                    const prevSecondDay = secondDate.get("day");
                    newSecondDate = secondDate
                        .tz(time.timezone)
                        .set("day", prevSecondDay)
                        .set("hour", Number(time.hour[1]))
                        .set("minute", Number(time.minute[1]));
                }
                setShownDate(`${newDate?.format(dateTimeFormat)} - ${newSecondDate?.format(dateTimeFormat)} ${time && newDate.isValid()
                    ? `(${getTimeZoneWithGMT(time.timezone)})`
                    : ""}`);
                onChange?.([newDate, newSecondDate]);
            }
        }
        else {
            if (date instanceof dayjs) {
                let newDate = date;
                if (time !== null) {
                    const prevDay = date.get("day");
                    newDate = date
                        .tz(time.timezone)
                        .set("day", prevDay)
                        .set("hour", Number(time.hour))
                        .set("minute", Number(time.minute));
                }
                setShownDate(`${newDate?.format(dateTimeFormat)} ${time && newDate.isValid()
                    ? `(${getTimeZoneWithGMT(time.timezone)})`
                    : ""}`);
                onChange?.(newDate);
            }
        }
    }, [date, secondDate, time]); // eslint-disable-line
    useEffect(() => {
        if (!period) {
            setSecondDate(null);
        }
    }, [period]);
    useEffect(() => {
        function handleClickOutside(event) {
            const clicksOn = ref.current && ref?.current.contains(event.target);
            if (!clicksOn && show) {
                setShowHandler(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]); // eslint-disable-line
    return (_jsxs(Picker, { children: [_jsx(FieldInput, { value: shownDate, onClick: handleShow, onBlur: onBlur, ...props, readOnly: true, placeholder: placeholder }), _jsx(DatePickerWrapper, { show: show, selectTime: selectTime && showTime, ref: (r) => {
                    ref.current = r;
                }, children: _jsxs(PickerGrid, { selectTime: selectTime && showTime, children: [_jsxs("div", { children: [_jsx(SelectMonth, { month: month, setMonth: setMonth }), _jsx(Calendar, { date: date, secondDate: secondDate, month: month, period: period, onChange: handleDateChange, minDate: minDate, maxDate: maxDate })] }), selectTime && showTime && (_jsx(SelectTime, { date: date, secondDate: secondDate, setTime: setTime, period: period }))] }) }), isClearable && _jsx(ClearButton, { onClick: reset })] }));
});
export { DatePicker };
