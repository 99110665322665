import { METADATA_LENGTH_LIMIT } from "@bosonprotocol/react-kit";
import * as Yup from "yup";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject } from "yup/lib/types";

import { validationMessage } from "./common";

export const getStringValidation = <T extends boolean>({
  required,
}: {
  required: T;
}): T extends true
  ? RequiredStringSchema<string | undefined, AnyObject>
  : ReturnType<(typeof Yup)["string"]> => {
  const stringValidation = Yup.string()
    .trim()
    .max(METADATA_LENGTH_LIMIT, validationMessage.maxLength);
  if (!required) {
    // @ts-expect-error there is something wrong with Yup types here
    return stringValidation;
  }
  return stringValidation.required(validationMessage.required);
};
