import { BuyerHubMobileHelpModal } from "components/buyer/components/BuyerHubMobileHelpModal";
import { BuyerHubMobileMenuModal } from "components/buyer/components/BuyerHubMobileMenuModal";
import { BuyerHubMobileSelectModal } from "components/buyer/components/BuyerHubMobileSelectModal";

import { AcceptBidModal } from "./components/accept-bid/AcceptBidModal";
import { BidsForProductModal } from "./components/bids-for-product/BidsForProductModal";
import { CheckoutSuccessModal } from "./components/checkout-success/CheckoutSuccessModal";
import { ClosedBetaModal } from "./components/closed-beta/ClosedBetaModal";
import { CookieModal } from "./components/cookie-modal/CookieModal";
import { CreateProductDraftModal } from "./components/create-product/CreateProductDraftModal";
import { DepositFundsModal } from "./components/deposit-funds/DepositFundsModal";
import { EditRoleModal } from "./components/edit-role/EditRoleModal";
import { ErrorModal } from "./components/error/ErrorModal";
import { InvalidRoleModal } from "./components/invalid-role/InvalidRoleModal";
import { SuccessListedOpenSeaModal } from "./components/listed-opensea/SuccessListedOpenSeaModal";
import { OfframpFundsModal } from "./components/offramp-funds/OfframpFundsModal";
import { ProductDetailsModal } from "./components/product-details/ProductDetailsModal";
import { AddRoleToProfileModal } from "./components/profile/add-role-to-profile/AddRoleToProfileModal";
import { CreateProfileModal } from "./components/profile/create-profile/CreateProfileModal";
import { EditProfileModal } from "./components/profile/edit-profile/EditProfileModal";
import { SendToVerifierModal } from "./components/send-to-verifier/SendToVerifierModal";
import { TransactionFailedModal } from "./components/transactions/transaction-failed/TransactionFailedModal";
import { TransactionSubmittedModal } from "./components/transactions/transaction-submitted/TransactionSubmittedModal";
import { WaitingForConfirmationModal } from "./components/transactions/wait-for-confirmation/WaitingForConfirmationModal";
import { WithdrawFundsModal } from "./components/withdraw-funds/WithdrawFundsModal";
import { MODAL_TYPES } from "./ModalTypes";

export const MODAL_COMPONENTS = {
  [MODAL_TYPES.CREATE_PROFILE]: CreateProfileModal,
  [MODAL_TYPES.ADD_ROLE_TO_PROFILE]: AddRoleToProfileModal,
  [MODAL_TYPES.EDIT_PROFILE]: EditProfileModal,
  [MODAL_TYPES.CREATE_PRODUCT_DRAFT]: CreateProductDraftModal,
  [MODAL_TYPES.INVALID_ROLE]: InvalidRoleModal,
  [MODAL_TYPES.ERROR]: ErrorModal,
  [MODAL_TYPES.EDIT_ROLE]: EditRoleModal,
  [MODAL_TYPES.WITHDRAW_FUNDS]: WithdrawFundsModal,
  [MODAL_TYPES.OFFRAMP_FUNDS]: OfframpFundsModal,
  [MODAL_TYPES.DEPOSIT_FUNDS]: DepositFundsModal,
  [MODAL_TYPES.LISTED_OPENSEA]: SuccessListedOpenSeaModal,
  [MODAL_TYPES.SEND_TO_VERIFIER]: SendToVerifierModal,
  [MODAL_TYPES.ACCEPT_BID]: AcceptBidModal,
  [MODAL_TYPES.BIDS_FOR_PRODUCT]: BidsForProductModal,
  [MODAL_TYPES.PRODUCT_DETAILS]: ProductDetailsModal,
  [MODAL_TYPES.WAITING_FOR_CONFIRMATION]: WaitingForConfirmationModal,
  [MODAL_TYPES.TRANSACTION_SUBMITTED]: TransactionSubmittedModal,
  [MODAL_TYPES.TRANSACTION_FAILED]: TransactionFailedModal,
  [MODAL_TYPES.BUYER_HUB_MOBILE_SELECT_MODAL]: BuyerHubMobileSelectModal,
  [MODAL_TYPES.BUYER_HUB_MOBILE_HELP_MODAL]: BuyerHubMobileHelpModal,
  [MODAL_TYPES.BUYER_HUB_MOBILE_MENU_MODAL]: BuyerHubMobileMenuModal,
  [MODAL_TYPES.CLOSED_BETA]: ClosedBetaModal,
  [MODAL_TYPES.CHECKOUT_SUCCESS]: CheckoutSuccessModal,
  [MODAL_TYPES.COOKIE_MODAL]: CookieModal,
} as const;
