import { EthersAdapter, IpfsMetadataStorage, } from "@bosonprotocol/react-kit";
import { getEnvConfigById, } from "@fermionprotocol/common";
import { CoreSDK, validateMetadata } from "@fermionprotocol/core-sdk";
import { providers } from "ethers";
import { useEffect, useState } from "react";
/**
 * Hook that initializes an instance of `CoreSDK` from the `@bosonprotocol/core-sdk`
 * package. The instance will be reinitialized when the passed in `web3Provider`
 * changes.
 * @param config - Configuration arguments.
 * @returns Instance of `CoreSDK`.
 */
export function useCoreSdk(config) {
    const [coreSdk, setCoreSdk] = useState(initCoreSdk(config));
    useEffect(() => {
        const newCoreSdk = initCoreSdk(config);
        setCoreSdk(newCoreSdk);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config.web3Provider, config.configId]);
    return coreSdk;
}
function initCoreSdk(config) {
    const defaultConfig = getEnvConfigById(config.envName, config.configId);
    const getConnectedProvider = () => config.web3Provider ||
        createDefaultProvider(config.jsonRpcUrl || defaultConfig.jsonRpcUrl);
    const metadataStorageUrl = config.ipfsMetadataStorageUrl || defaultConfig.ipfsMetadataUrl;
    const theGraphStorageUrl = config.theGraphIpfsUrl ||
        defaultConfig.theGraphIpfsUrl ||
        metadataStorageUrl;
    const instance = CoreSDK.fromDefaultConfig({
        ...defaultConfig,
        theGraphStorage: new IpfsMetadataStorage(validateMetadata, {
            url: theGraphStorageUrl,
            headers: config.theGraphIpfsStorageHeaders ||
                theGraphStorageUrl === metadataStorageUrl
                ? config.ipfsMetadataStorageHeaders
                : undefined,
        }),
        metadataStorage: new IpfsMetadataStorage(validateMetadata, {
            url: metadataStorageUrl,
            headers: config.ipfsMetadataStorageHeaders,
        }),
        web3Lib: new EthersAdapter(getConnectedProvider()),
        metaTx: {
            ...defaultConfig.metaTx,
            ...config.metaTx,
        },
    });
    Object.setPrototypeOf(instance, CoreSDK.prototype);
    return instance;
}
function createDefaultProvider(jsonRpcUrl) {
    const key = jsonRpcUrl.toLowerCase();
    if (!providersCache.has(key)) {
        providersCache.set(key, new providers.StaticJsonRpcProvider(jsonRpcUrl));
    }
    return providersCache.get(key);
}
const providersCache = new Map();
