import { ThemedButton, Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";
import { X } from "phosphor-react";
import React, { ReactNode } from "react";
import styled from "styled-components";

const Header = styled(Typography)`
  position: relative;

  text-align: left;
  padding: 1rem 2rem;
  display: flex;
  border-bottom: 2px solid ${colors.border};
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  > * {
    margin: 0;
  }
`;

const CloseButton = styled(ThemedButton)`
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  align-self: flex-start;
  max-height: 100%;
`;

const Close = styled(X)`
  && {
    stroke: initial;
  }
  line {
    stroke: ${colors.greyDark};
  }
`;

const HeaderWrapper = styled(Header)`
  min-height: 4.25rem;
`;

interface ModalHeaderProps {
  headerComponent: ReactNode;
  handleOnClose: () => void;
  closable?: boolean;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  headerComponent = <div />,
  handleOnClose,
  closable,
}) => {
  return (
    <HeaderWrapper>
      {headerComponent}
      {closable && (
        <CloseButton data-close themeVal="blank" onClick={handleOnClose}>
          <Close size={32} />
        </CloseButton>
      )}
    </HeaderWrapper>
  );
};
