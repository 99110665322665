import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "@bosonprotocol/react-kit";
import { Check, DotsThree } from "phosphor-react";
import { useCallback } from "react";
import { css, styled } from "styled-components";
import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";
const ProgressText = styled.div `
  min-height: 3.5rem;
  font-size: 0.875rem;
  ${({ $status }) => $status === "current" &&
    css `
      color: ${colors.velvet};
      font-weight: 500;
    `}

  ${({ $status, $forceHover }) => ($status !== "uncompleted" || $forceHover) &&
    css `
      &:hover {
        cursor: pointer;
        color: ${colors.velvetLight};
      }
    `}
`;
const DotWrapper = styled.div `
  min-height: 3.5rem;
  ${({ $status }) => $status === "current" &&
    css `
      color: ${colors.black};
      font-weight: 500;
    `}
`;
const ProgressDot = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  ${({ $status }) => $status === "current"
    ? css `
          background-color: ${colors.pinkLight};
          border: 1px solid ${colors.pink};
        `
    : $status === "completed"
        ? css `
            background-color: ${colors.pinkLight};
          `
        : css `
            background-color: ${colors.shade};
          `}
`;
const Wrapper = styled.div `
  display: flex;
  gap: 0.8125rem;
  color: ${colors.greyDark};
  > * {
    padding: 0.1875rem;
    :last-of-type {
      min-height: initial;
    }
  }
`;
const Progress = styled.div `
  border: 1px solid ${colors.shade};
  border-top-left-radius: ${borders.big}px;
  border-top-right-radius: ${borders.big}px;
  border-bottom-left-radius: ${borders.big}px;
  border-bottom-right-radius: ${borders.big}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const statusToIcon = {
    completed: _jsx(Check, { color: colors.pink }),
    current: _jsx(DotsThree, { color: colors.pink }),
    uncompleted: _jsx(_Fragment, {}),
};
const getStatus = ({ index, active, lastCompleted, }) => {
    const status = index === active
        ? "current"
        : index <= lastCompleted
            ? "completed"
            : "uncompleted";
    return status;
};
export const MultiSteps = ({ disableInactiveSteps, active, lastCompleted, forceHoverOneStepAfterCompleted, data, callback, className, }) => {
    const actualLastCompleted = lastCompleted === undefined ? active : lastCompleted;
    const handleClickStep = useCallback(({ index, status }) => {
        if (status === "uncompleted" && disableInactiveSteps) {
            return;
        }
        callback?.(index);
    }, [disableInactiveSteps, callback]);
    return (_jsxs(Wrapper, { className: className, children: [_jsx(Progress, { children: data.map(({ text }, index) => {
                    const status = getStatus({
                        index,
                        active,
                        lastCompleted: actualLastCompleted,
                    });
                    return (_jsx(DotWrapper, { onClick: () => handleClickStep({ index, status }), "$status": status, children: _jsx(ProgressDot, { "$status": status, children: statusToIcon[status] }) }, text));
                }) }), _jsx(Grid, { flexDirection: "column", alignItems: "flex-start", children: data.map(({ text }, index) => {
                    const status = getStatus({
                        index,
                        active,
                        lastCompleted: actualLastCompleted,
                    });
                    return (_jsx(ProgressText, { "$status": status, "$forceHover": !!forceHoverOneStepAfterCompleted &&
                            actualLastCompleted + 1 === index, onClick: () => handleClickStep({ index, status }), children: text }, text));
                }) })] }));
};
