import { Typography } from "@bosonprotocol/react-kit";
import { borders, colors, hooks } from "@fermionprotocol/react-kit";
import CheckmarkFull from "assets/icons/dashboard/sidebar/circleFull.svg?react";
import CheckmarkOpen from "assets/icons/dashboard/sidebar/circleOpen.svg?react";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import { useLocation } from "react-router-dom";
import { AgentHubSubRoutes, fermionRoutes } from "router/routeNames";
import styled, { css } from "styled-components";

import { BuyerHubCard } from "../../buyer/components/BuyerHubCard";
import { buyerHubPageTypes } from "../../buyer/const";
import { agentHubPageTypes } from "../const";
import { FlexContainer } from "./FlexContainer";

interface Props {
  hubType: "agent" | "buyer";
  showCard?: boolean;
}

interface SidebarLinkTileProps {
  hubType: Props["hubType"];
  onNavigate?: () => void;
}

const Aside = styled.aside`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background: ${colors.white};
  position: relative;
  min-width: 15.625rem;
  padding: 1.5rem 2rem;
  &:after {
    position: absolute;
    content: "";
    height: calc(100% + 2.9375rem);
    width: 0.0625rem;
    background-color: ${colors.shade};
    right: 0;
    /* TODO: negative margin */
    margin-top: -1rem;
  }
  .sidebar-flex-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  .label {
    transition: all 200ms;
    width: 7.5em;
  }
  .iconGrid {
    justify-content: flex-start;
  }

  .sidebar-list {
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
    li {
      width: 100%;
      min-height: 4.5rem;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
  }
`;

const AsideLink = styled.li<{ $active?: boolean; $isProfilePage?: boolean }>`
  ${({ $isProfilePage }) =>
    $isProfilePage &&
    css`
      margin-top: 10rem;
    `}
  div {
    color: ${colors.black};
  }
  .icon {
    svg {
      fill: ${(props) => (props.$active ? colors.pink : colors.greyDark)};
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const StyledLink = styled.a<{ $active?: boolean }>`
  display: flex;
  height: 100%;
  font-size: 1rem;
  border-radius: ${borders.big}px;
  padding: 1.5625rem;
  text-decoration: none;
  color: inherit;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 0.9375rem;
      display: block;
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      background: ${colors.whiteOff};

      .label {
        color: ${colors.pink};
      }
    `}
`;

const NavCardWrapper = styled.div<{ $hasSublinks: boolean }>`
  ${(props) =>
    props.$hasSublinks
      ? css`
          display: flex;
          flex-direction: column;
          div {
            margin-bottom: 0.9375rem;
          }
        `
      : ""}
`;

export const SidebarLinkTile: React.FC<SidebarLinkTileProps> = ({
  hubType,
  onNavigate,
}) => {
  const {
    data: { metadata },
  } = hooks.useGetProfile();
  const location = useLocation();
  const navigate = useCustomNavigate();

  const displayVerifications =
    !!metadata && (!!metadata.verifier || !!metadata.bridger?.verifier);

  return Object.entries(
    hubType === "agent" ? agentHubPageTypes : buyerHubPageTypes,
  ).map(([key, { url, label, icon, urlPrefix, subLinks }]) => {
    if (!displayVerifications && key === AgentHubSubRoutes.Verifications) {
      return null;
    }

    const isActive = location.pathname.includes(urlPrefix ?? url);

    const hubPrefix =
      hubType === "agent" ? fermionRoutes.hub : fermionRoutes.buyer;

    const handleClick = (e: React.MouseEvent) => {
      e.preventDefault();
      navigate({ pathname: hubPrefix + "/" + url });
      if (onNavigate) {
        onNavigate();
      }
    };

    return (
      <AsideLink
        key={`${hubType}_aside_route_${label}`}
        $active={isActive}
        $isProfilePage={key === AgentHubSubRoutes.Profile}
      >
        <StyledLink
          href={urlPrefix + "/" + url}
          onClick={handleClick}
          $active={isActive}
        >
          <NavCardWrapper $hasSublinks={(subLinks?.length ?? 0) > 0}>
            <div>
              <span className="icon">{icon}</span>
              <span className="label">{label}</span>
            </div>
            {subLinks && isActive && (
              <FlexContainer>
                {subLinks.map((item) => {
                  const itemUrl = hubPrefix + "/" + item.url;
                  const isSubLinkActive = location.pathname.includes(itemUrl);

                  const handleSubLinkClick = (e: React.MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate({ pathname: itemUrl });
                  };

                  return (
                    <StyledLink
                      key={itemUrl}
                      href={itemUrl}
                      onClick={handleSubLinkClick}
                      $active={isSubLinkActive}
                    >
                      <span>
                        {isSubLinkActive ? (
                          <CheckmarkFull />
                        ) : (
                          <CheckmarkOpen />
                        )}
                      </span>
                      {item.label}
                    </StyledLink>
                  );
                })}
              </FlexContainer>
            )}
          </NavCardWrapper>
        </StyledLink>
      </AsideLink>
    );
  });
};

export const HubSidebar: React.FC<Props> = ({ hubType, showCard }) => {
  return (
    <Aside>
      <Typography
        tag="h3"
        fontWeight={500}
        marginBottom="1.5rem"
        marginLeft={"1.5625rem"}
        marginTop={"1.5rem"}
        textAlign="center"
      >
        {hubType === "agent" ? "Agent" : "Buyer"} Hub
      </Typography>
      <div className="sidebar-flex-wrapper">
        <ul className="sidebar-list">
          <SidebarLinkTile hubType={hubType} />
        </ul>
        {showCard && <BuyerHubCard />}
      </div>
    </Aside>
  );
};
