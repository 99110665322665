import { Grid, Typography } from "@bosonprotocol/react-kit";
import { CongratulationSection } from "components/agent-buyer-common/CongratulationSection";
import { FnftData } from "components/buyer/components/BuyerTableRenderContent";
import { useModal } from "components/modal/useModal";
import { useEffect } from "react";

interface Props {
  fnft: FnftData;
}

export function CheckoutSuccessModal(props: Props) {
  const { updateProps, store, hideModal } = useModal();
  useEffect(() => {
    updateProps<"CHECKOUT_SUCCESS">({
      ...store,
      modalProps: {
        ...store.modalProps,
        ...props,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              tag="h3"
              fontSize={"1.5rem"}
              fontWeight={500}
              marginBottom="0.5rem"
              marginTop="0.25rem"
            >
              Congratulations, your asset has been successfully checked out!
            </Typography>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CongratulationSection
      type="checked-out"
      fnft={props.fnft}
      hideModal={hideModal}
    />
  );
}
