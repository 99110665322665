import { Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";

import { Container, List, ListItem } from "./styles";

export const VerifierRoleComponent = () => {
  return (
    <Container>
      <Typography fontSize="1.125rem" color={colors.velvet}>
        Verifier
      </Typography>
      <Typography
        fontSize="0.875rem"
        color={colors.velvetLight}
        marginBottom="1.5rem"
      >
        I am a trusted party providing authenticity and condition reports for
        specific asset classes.
      </Typography>

      <Typography
        fontSize="0.875rem"
        color={colors.velvet}
        marginTop="1.5rem"
        marginBottom="0.4375rem"
        fontWeight={500}
      >
        What you will need to create an account:
      </Typography>
      <List>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Business and contact information, including the name, description,
            email, phone number, address, and website
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Brand assets, a logo and cover picture with a maximum size of 600kb
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Details of authentication and/or valuation certificates you provide,
            along with your service fees
          </Typography>
        </ListItem>
      </List>

      <Typography
        fontSize="0.875rem"
        color={colors.velvet}
        marginTop="1.5rem"
        marginBottom="0.4375rem"
        fontWeight={500}
      >
        What comes next?
      </Typography>
      <List>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Submit and set verification of an asset
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Manage and configure your payout settings
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Add and assign team member roles
          </Typography>
        </ListItem>
      </List>
    </Container>
  );
};
