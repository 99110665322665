import { hooks as bosonHooks } from "@bosonprotocol/react-kit";
import { validateMetadata, } from "@fermionprotocol/core-sdk";
import { useMutation } from "react-query";
import { buildMetadataFromProfile } from "../../utils/profile/buildMetadataFromProfile";
import { useCreateEntity } from "../core-sdk/entities/useCreateEntity";
import { useGetBaseEntities } from "../core-sdk/entities/useGetBaseEntities";
import { useUpdateEntity } from "../core-sdk/entities/useUpdateEntity";
import { useCoreSDKContext } from "../core-sdk/useCoreSDKContext";
export const useCreateOrUpdateEntity = () => {
    const coreSDK = useCoreSDKContext();
    const { address } = bosonHooks.useAccount();
    const { data: baseEntities } = useGetBaseEntities({
        enabled: !!address,
        filter: {
            baseEntitiesFilter: {
                adminAccount: address?.toLowerCase(),
            },
        },
    });
    const entity = baseEntities?.[0];
    const existingRoles = entity?.roles;
    const hasEntityAlready = !!existingRoles;
    const { mutateAsync: createEntity } = useCreateEntity();
    const { mutateAsync: updateEntity } = useUpdateEntity();
    return {
        entity: entity,
        hook: useMutation(async ({ role, values }) => {
            const metadata = buildMetadataFromProfile(values);
            if (hasEntityAlready) {
                validateMetadata(metadata);
                const ipfsHash = await coreSDK.storeMetadata(metadata);
                const tx = await updateEntity({
                    entityId: entity.id,
                    metadataURI: `ipfs://${ipfsHash}`,
                    roles: [...existingRoles, ...(role ? [role] : [])],
                });
                await tx.wait();
            }
            else {
                if (role === null || role === undefined) {
                    throw new Error("when you create an entity the role has to be defined");
                }
                validateMetadata(metadata);
                const ipfsHash = await coreSDK.storeMetadata(metadata);
                const tx = await createEntity({
                    metadataURI: `ipfs://${ipfsHash}`,
                    roles: [role],
                });
                await tx.wait();
            }
        }),
    };
};
