import { RoleId } from "@fermionprotocol/react-kit";
import {
  Atom,
  GitBranch,
  LinkSimpleHorizontal,
  ShieldCheck,
  ShieldCheckered,
} from "phosphor-react";

import { BridgerRoleComponent } from "../role-components/BridgerRoleComponent";
import { CustodianRoleComponent } from "../role-components/CustodianRoleComponent";
import { FacilitatorRoleComponent } from "../role-components/FacilitatorRoleComponent";
import { SellerRoleComponent } from "../role-components/SellerRoleComponent";
import { VerifierRoleComponent } from "../role-components/VerifierRoleComponent";

export const roleToIcon = [
  {
    role: RoleId.SELLER,
    icon: Atom,
    roleDisplay: "Seller",
    isAdvanced: false,
  },
  {
    role: RoleId.VERIFIER,
    icon: ShieldCheck,
    roleDisplay: "Verifier",
    isAdvanced: false,
  },
  {
    role: RoleId.CUSTODIAN,
    icon: ShieldCheckered,
    roleDisplay: "Custodian",
    isAdvanced: false,
  },
  {
    role: RoleId.FACILITATOR,
    icon: GitBranch,
    roleDisplay: "Facilitator",
    isAdvanced: true,
  },
  {
    role: RoleId.BRIDGER,
    icon: LinkSimpleHorizontal,
    roleDisplay: "Bridger",
    isAdvanced: true,
  },
] as const;

export const roleToComponent = {
  [RoleId.SELLER]: <SellerRoleComponent />,
  [RoleId.FACILITATOR]: <FacilitatorRoleComponent />,
  [RoleId.BRIDGER]: <BridgerRoleComponent />,
  [RoleId.VERIFIER]: <VerifierRoleComponent />,
  [RoleId.CUSTODIAN]: <CustodianRoleComponent />,
} as const;
