import { Grid, Typography } from "@bosonprotocol/react-kit";
import { Button, colors, Input } from "@fermionprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { Plus } from "phosphor-react";
import { useEffect } from "react";
import styled from "styled-components";
import * as Yup from "yup";

const EditRoleModalContainer = styled.div`
  padding: 20px;
`;

interface FormValues {
  seller: string;
  assistants: string[];
  treasury: string;
}

const validationSchema = Yup.object().shape({
  seller: Yup.string().required("Required"),
  assistants: Yup.array().of(Yup.string().required("Required")),
  treasury: Yup.string().required("Required"),
});

export function EditRoleModal() {
  const initialValues: FormValues = {
    seller: "",
    assistants: [""],
    treasury: "",
  };
  const { updateProps, store, hideModal } = useModal();
  useEffect(() => {
    updateProps<"EDIT_ROLE">({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              fontSize={"1.5rem"}
              fontWeight={500}
              marginBottom="0.5rem"
              marginTop="0.25rem"
            >
              Your Role: Role
            </Typography>
          </Grid>
        ),
        footerComponent: (
          <Grid
            width={"100%"}
            justifyContent="space-between"
            alignItems="center"
            padding={"1.5rem 2rem"}
          >
            <Button
              variant="transparent"
              type="button"
              onClick={() => {
                hideModal();
              }}
            >
              Close
            </Button>
            <Button variant="pink" type="submit">
              Save
            </Button>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          // TODO: submit to endpoint when available
          setSubmitting(false);
        }}
      >
        {() => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Form>
            <EditRoleModalContainer>
              <Field name="seller">
                {({ field, meta }: FieldProps<string>) => (
                  <div>
                    <Typography fontSize={"0.875rem"} color={colors.purpleDeep}>
                      Seller
                    </Typography>
                    <Typography fontSize={"0.75rem"} color={colors.greyDark}>
                      Input within what timeframe customers can return the
                      product.
                    </Typography>
                    <Input
                      {...field}
                      type="text"
                      style={{
                        display: "block",
                        marginTop: "0.75rem",
                        marginBottom: "0.75rem",
                      }}
                    />
                    {meta.touched && meta.error ? (
                      <div>{meta.error}</div>
                    ) : null}
                  </div>
                )}
              </Field>
              <FieldArray name="assistants">
                {({ push, form }) => (
                  <>
                    <Typography fontSize={"0.875rem"} color={colors.purpleDeep}>
                      Assistant/s
                    </Typography>
                    <Typography fontSize={"0.75rem"} color={colors.greyDark}>
                      Input within what timeframe customers can return the
                      product.
                    </Typography>
                    {form.values.assistants.map((index: number) => (
                      <Field name={`assistants.${index}`} key={index}>
                        {({ field, meta }: FieldProps<string>) => (
                          <>
                            <Input
                              {...field}
                              type="text"
                              style={{
                                display: "block",
                                marginTop: "0.75rem",
                                marginBottom: "0.75rem",
                              }}
                            />
                            {meta.touched && meta.error ? (
                              <>{meta.error}</>
                            ) : null}
                          </>
                        )}
                      </Field>
                    ))}
                    <Button
                      variant="transparent"
                      type="button"
                      onClick={() => push("")}
                    >
                      <Typography color={colors.greyDark} fontSize={"0.875rem"}>
                        Add new
                        <Plus
                          size={16}
                          color={colors.purpleDeep}
                          style={{
                            stroke: "transparent",
                            marginTop: "0.125rem",
                            marginLeft: "0.3125rem",
                          }}
                        />
                      </Typography>
                    </Button>
                  </>
                )}
              </FieldArray>
              <Field name="treasury">
                {({ field, meta }: FieldProps<string>) => (
                  <div>
                    <Typography fontSize={"0.875rem"} color={colors.purpleDeep}>
                      Treasury
                    </Typography>
                    <Typography fontSize={"0.75rem"} color={colors.greyDark}>
                      Input within what timeframe customers can return the
                      product.
                    </Typography>
                    <Input
                      {...field}
                      type="text"
                      style={{
                        display: "block",
                        marginTop: "0.75rem",
                        marginBottom: "0.75rem",
                      }}
                    />
                    {meta.touched && meta.error ? (
                      <div>{meta.error}</div>
                    ) : null}
                  </div>
                )}
              </Field>
            </EditRoleModalContainer>
          </Form>
        )}
      </Formik>
    </>
  );
}
