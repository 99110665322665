import { Typography } from "@bosonprotocol/react-kit";
import { IconProps } from "phosphor-react";
import styled from "styled-components";

import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";
import { AddDollarPrefixToKeys } from "../../types/styled-components";

const Label = styled.div<
  AddDollarPrefixToKeys<{
    backgroundColor: (typeof colors)[keyof typeof colors];
    color: (typeof colors)[keyof typeof colors];
  }>
>`
  display: flex;
  background-color: ${(props) => props.$backgroundColor || "transparent"};
  color: ${(props) => props.$color || "black"};
  padding: 0.25rem 0.475rem 0.25rem 0.375rem;
  width: max-content;
  justify-content: center;
  align-items: center;
  border-radius: ${borders.small}px;
  svg {
    margin-right: 0.25rem;
  }
`;

type BaseLabelProps = {
  label: string;
  className?: string;
  backgroundColor: (typeof colors)[keyof typeof colors];
  color: (typeof colors)[keyof typeof colors];
  icon: (props: IconProps) => JSX.Element;
  fontSize?: string;
};

export const BaseLabel = ({
  backgroundColor,
  color,
  icon: Icon,
  label,
  className,
  fontSize,
}: BaseLabelProps) => {
  return (
    <Label
      $backgroundColor={backgroundColor}
      $color={color}
      className={className}
    >
      <Icon size={16} weight="bold" />
      <Typography tag="span" fontSize={fontSize} fontWeight={500}>
        {label}
      </Typography>
    </Label>
  );
};
