import { hooks as bosonHooks } from "@bosonprotocol/react-kit";
import { providers } from "ethers";
import { useFermionConfigContext } from "../config/useFermionConfigContext";
import { useCoreSdk } from "./useCoreSDK";
export function useProviderCoreSDK() {
    const { ipfsMetadataStorageHeaders, ipfsMetadataStorageUrl } = bosonHooks.useIpfsContext();
    const provider = bosonHooks.useProvider();
    const { config } = useFermionConfigContext();
    const providerToUse = provider instanceof providers.JsonRpcProvider
        ? provider
        : provider.providerConfigs[0]?.provider &&
            provider.providerConfigs[0]?.provider instanceof
                providers.JsonRpcProvider
            ? provider.providerConfigs[0]?.provider
            : undefined;
    return useCoreSdk({
        configId: config.configId,
        envName: config.envName,
        jsonRpcUrl: config.jsonRpcUrl,
        web3Provider: providerToUse,
        theGraphIpfsStorageHeaders: undefined,
        theGraphIpfsUrl: config.theGraphIpfsUrl,
        ipfsMetadataStorageUrl: ipfsMetadataStorageUrl,
        ipfsMetadataStorageHeaders: ipfsMetadataStorageHeaders,
        metaTx: config.metaTx,
    });
}
