import { TransactionReceipt } from "@bosonprotocol/common";
import { withQueryClientProvider } from "@bosonprotocol/react-kit";

import { useCoreSDKContext } from "../../../../hooks";
import { CtaButton } from "../common/CtaButton";
import { CtaButtonProps } from "../common/types";

type AdditionalProps = {
  tokenId: string;
  nftContract: string;
  offerId: string;
};

type SuccessPayload = TransactionReceipt;

export type IRequestCheckoutButton = AdditionalProps &
  CtaButtonProps<SuccessPayload>;

export const RequestCheckoutButton = withQueryClientProvider(
  ({
    tokenId,
    nftContract,
    offerId,
    variant = "pinkLight",
    ...restProps
  }: IRequestCheckoutButton) => {
    const coreSdk = useCoreSDKContext();
    const isMetaTx = coreSdk.isMetaTxConfigSet;
    const actions = [
      {
        name: "approveExchangeToken" as const, // name is not matching, but needs changes in Boson CC
        writeContractFn: () =>
          coreSdk.erc721SetApprovalForAll({
            contractAddress: nftContract,
            approved: true,
            useMetaTx: isMetaTx,
            offerId,
          }),
        shouldActionRun: async () => {
          const isApprovedForAll = await coreSdk.erc721IsApprovedForAll({
            contractAddress: nftContract,
          });
          return !isApprovedForAll;
        },
      },
      {
        name: "depositFunds" as const, // name is not matching, but needs changes in Boson CC
        writeContractFn: () =>
          coreSdk.requestCheckOut(tokenId, { useMetaTx: isMetaTx }),
      },
    ];

    return (
      <CtaButton
        variant={variant}
        defaultLabel="Request checkout"
        successPayload={(receipt) => receipt}
        actions={actions}
        {...restProps}
      />
    );
  },
);
