import { useQuery } from "react-query";
import { handleUseQueryError } from "../../../utils/errors/handleUseQueryError";
import { useFermionConfigContext } from "../../config/useFermionConfigContext";
export const useSellerCurationList = () => {
    const { config } = useFermionConfigContext();
    return handleUseQueryError(useQuery(["sellerCurationList", config.configId, config.sellersWhiteList], async () => {
        const response = await fetch(config.sellersWhiteList);
        if (!response.ok) {
            throw new Error(`Error fetching seller list: ${response.statusText}`);
        }
        let data = await response.json();
        data =
            data && Array.isArray(data) ? data.map((s) => s.toLowerCase()) : data;
        return {
            checkIfSellerIsWhiteListed: (walletAddress) => data && Array.isArray(data)
                ? !data.length || data.includes(walletAddress.toLowerCase())
                : false,
        };
    }, { enabled: true }));
};
