import { Grid, Typography } from "@bosonprotocol/react-kit";
import { colors, hooks } from "@fermionprotocol/react-kit";
import { ModalTitleHeader } from "components/modal/header/ModalTitleHeader";
import { useModal } from "components/modal/useModal";
import { CheckCircle } from "phosphor-react";
import React, { useEffect } from "react";

export const ProfileSuccess: React.FC = () => {
  const { updateProps, store } = useModal();
  const { refetch } = hooks.useGetProfile();
  useEffect(() => {
    updateProps({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: <ModalTitleHeader>Congratulations!</ModalTitleHeader>,
      },
      modalMaxWidth: {
        m: "1100px",
      },
      modalMinWidth: {
        m: "900px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid flexDirection="column" alignItems="center" justifyContent="center">
      <CheckCircle color={colors.green} size="6.5625rem" />
      <Typography tag="h2" marginTop="0.75rem">
        Your profile is successfully edited!
      </Typography>
      <Typography fontSize="1rem">You may close this</Typography>
    </Grid>
  );
};
