/* eslint @typescript-eslint/no-explicit-any: "off" */
import { BaseSelectProps, SelectDataProps } from "@bosonprotocol/react-kit";
import Select, { SingleValue } from "react-select";

import { colors } from "../../styles/colors";

const customStyles = () => ({
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 0,
    padding: "0.4rem 0.25rem",
    boxShadow: "none",
    ":hover": {
      borderColor: colors.grey,
      borderWidth: "1px",
    },
    background: colors.greyLight,
    border: state.isFocused`1px solid ${colors.grey}`,
  }),
  container: (provided: any) => ({
    ...provided,
    zIndex: 1,
    position: "relative",
    width: "100%",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    opacity: state.isDisabled ? "0.5" : "1",
    background:
      state.isOptionSelected || state.isSelected || state.isFocused
        ? colors.greyLight
        : colors.white,
    color:
      state.isOptionSelected || state.isSelected ? colors.grey : colors.black,
  }),
  indicatorsContainer: () => ({
    display: "none",
  }),
});

export function BaseSelect({
  options,
  placeholder = "Choose...",
  onChange,
  ...props
}: BaseSelectProps) {
  const handleChange = (option: SingleValue<SelectDataProps>) => {
    onChange?.(option);
  };

  return (
    <Select
      styles={customStyles()}
      {...props}
      placeholder={placeholder}
      options={options}
      onChange={handleChange}
    />
  );
}
