import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Typography } from "@bosonprotocol/react-kit";
import { Info } from "phosphor-react";
import { css, styled } from "styled-components";
import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";
const Wrapper = styled(Grid) `
  border-radius: ${borders.small}px;
  border: 1px solid ${colors.border};
  ${({ $boxShadow }) => $boxShadow &&
    css `
      box-shadow: ${$boxShadow};
    `};
  ${({ $backgroundColor }) => $backgroundColor &&
    css `
      background-color: ${$backgroundColor};
    `};
`;
export const InfoBox = ({ message, customTheme: { iconProps, wrapperProps } = {}, className, }) => {
    return (_jsxs(Wrapper, { gap: "0.5rem", padding: "1rem", justifyContent: "flex-start", ...wrapperProps, className: className, children: [_jsx(Info, { color: colors.blue, weight: "bold", ...iconProps }), _jsx(Typography, { fontWeight: 500, fontSize: "0.875rem", children: message })] }));
};
