import { Grid, Loading, Typography } from "@bosonprotocol/react-kit";
import {
  borders,
  Button,
  colors,
  DatePickerForm,
  FormField,
  getNumberWithDecimals,
  hooks,
  SimpleError,
} from "@fermionprotocol/react-kit";
import * as Sentry from "@sentry/browser";
import { useSignerV6 } from "components/listing/hooks/connection";
import { useModal } from "components/modal/useModal";
import { CONFIG } from "config";
import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { Form, Formik } from "formik";
import { Warning } from "phosphor-react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";

const validationSchema = Yup.object({
  dateSelect: Yup.mixed<dayjs.Dayjs>()
    .nullable()
    .default(null)
    .required("Date is required"),
});

const ApproveTransferContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  background-color: ${colors.purpleDeep};
  display: flex;
  border-radius: ${borders.defaultBorderRadiusPx}px;
  justify-content: space-between;
  align-items: center;
  > div {
    max-width: 18.1875rem;
    margin-left: 1rem;
  }
  button {
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin-left: 1rem;
  }
`;

type AcceptBidModalProps = {
  sellerId: string;
  exchangeTokenAddress: string;
  exchangeTokenDecimals: string;
  offerSellerDeposit: string;
  offerId: string;
  tokenId: string;
};
export function AcceptBidModal({
  sellerId,
  exchangeTokenAddress,
  exchangeTokenDecimals,
  offerSellerDeposit,
  offerId,
  tokenId,
}: AcceptBidModalProps) {
  const [error, setError] = useState<Error | null>(null);
  const [showApproveTransfer, setShowApproveTransfer] = useState<boolean>(true);
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const { updateProps, store, hideModal } = useModal();

  const { data: availableFunds } = hooks.useAvailableFunds({
    filter: {
      exchangeTokenAddress,
      sellerId,
    },
    options: {
      enabled: true,
    },
  });
  useEffect(() => {
    updateProps<"ACCEPT_BID">({
      ...store,
      modalProps: {
        ...store.modalProps,
        exchangeTokenAddress,
        exchangeTokenDecimals,
        sellerId,
        offerSellerDeposit,
        offerId,
        tokenId,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              fontSize={"1.5rem"}
              fontWeight={500}
              marginBottom="0.5rem"
              marginTop="0.25rem"
            >
              Accept Bid
            </Typography>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    exchangeTokenAddress,
    sellerId,
    offerSellerDeposit,
    offerId,
    tokenId,
    exchangeTokenDecimals,
  ]);
  const depositBN = BigNumber.from(offerSellerDeposit || "0");
  const availableFundsBN = BigNumber.from(availableFunds || "0");
  const pendingFundsToDeposit = depositBN.sub(availableFundsBN);
  const formatPendingFundsToDeposit = (value: BigNumber) => {
    return getNumberWithDecimals(value.toString(), exchangeTokenDecimals);
  };
  const { data: signerV6 } = useSignerV6();
  const { mutateAsync: unwrapNFT } = hooks.useUnwrapNFT({ signerV6 });
  const [withMarginBottom, setWithMarginBottom] = useState<boolean>(false);
  const { config } = hooks.useFermionConfigContext();
  const { mutateAsync: erc20EnsureAllowanceAsync } =
    hooks.useErc20EnsureAllowance(
      {
        contractAddress: exchangeTokenAddress,
        spender: config.contracts.protocolDiamond,
        value: offerSellerDeposit,
      },
      {
        coreSDK: hooks.useCoreSDKContext(),
      },
    );
  const { data: allowance, refetch: refetchAllowance } =
    hooks.useErc20GetAllowance({
      args: {
        contractAddress: exchangeTokenAddress,
        spender: config.contracts.protocolDiamond,
      },
      options: {
        enabled: true,
      },
    });
  useEffect(() => {
    setShowApproveTransfer(
      pendingFundsToDeposit.gt(BigNumber.from("0")) &&
        depositBN.gt(allowance || "0"),
    );
  }, [pendingFundsToDeposit, allowance, depositBN]);
  return (
    <Formik<Yup.InferType<typeof validationSchema>>
      initialValues={
        // @ts-expect-error not sure why TS complains here
        { dateSelect: null }
      }
      validationSchema={validationSchema}
      onSubmit={async ({ dateSelect }) => {
        try {
          setError(null);
          await unwrapNFT({
            offerId,
            openSeaApiKey: CONFIG.openSeaApiKey,
            openseaFeeRecipient: CONFIG.openSeaFeeRecipient,
            tokenId,
            verificationTimeout: dateSelect.valueOf() / 1000, // in ns
          });
          hideModal();
        } catch (err) {
          console.error(err);
          Sentry.captureException(error);
          setError(err as Error);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          // TODO: Fix formik bug
          // @ts-expect-error Formik bug
          <Form style={withMarginBottom ? { marginBottom: "15rem" } : {}}>
            <FormField
              title="Set a verification completion date"
              subTitle="Specify within what timeframe the verifier must submit the
              verification of the product."
            >
              <DatePickerForm
                maxDate={dayjs().add(30, "days")}
                period={false}
                selectTime={false}
                name="dateSelect"
                placeholder="Select Date"
                onShow={(shown) => {
                  setWithMarginBottom(shown);
                }}
              />
            </FormField>
            {showApproveTransfer && (
              <ApproveTransferContainer>
                <Warning size={20} color={colors.pink} />
                <div>
                  <Typography
                    fontSize={"0.875rem"}
                    fontWeight={500}
                    color={colors.white}
                    marginBottom={"0.3125rem"}
                  >
                    Approve transfer of seller deposit
                  </Typography>
                  <Typography
                    fontSize={"0.75rem"}
                    fontWeight={400}
                    color={colors.white}
                  >
                    You do not have enough seller deposit to accept this bid.
                    Approve the Fermion Protocol contract to transfer the seller
                    deposit of at least{" "}
                    {formatPendingFundsToDeposit(pendingFundsToDeposit)} ETH to
                    complete this sale.
                  </Typography>
                </div>
                <Button
                  variant="pinkMain"
                  disabled={isApproving}
                  onClick={async () => {
                    try {
                      setError(null);
                      setIsApproving(true);
                      await erc20EnsureAllowanceAsync();
                      refetchAllowance();
                    } catch (err) {
                      console.error(err);
                      Sentry.captureException(error);
                      setError(err as Error);
                    } finally {
                      setShowApproveTransfer(false);
                      setIsApproving(false);
                    }
                  }}
                >
                  <Typography fontSize={"0.75rem"}>Approve transfer</Typography>
                </Button>
              </ApproveTransferContainer>
            )}
            <Grid
              width={"100%"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="transparent"
                type="button"
                onClick={() => {
                  hideModal();
                }}
              >
                Close
              </Button>
              <Button
                variant="green"
                type="submit"
                disabled={showApproveTransfer || isSubmitting}
              >
                Accept and confirm date {isSubmitting && <Loading size={1} />}
              </Button>
            </Grid>
            {error ? <SimpleError /> : <></>}
          </Form>
        );
      }}
    </Formik>
  );
}
