export var ProfileImageTags;
(function (ProfileImageTags) {
    ProfileImageTags["COVER"] = "cover";
    ProfileImageTags["PROFILE"] = "profile";
})(ProfileImageTags || (ProfileImageTags = {}));
export var ProfileContactLinkTags;
(function (ProfileContactLinkTags) {
    ProfileContactLinkTags["EMAIL"] = "email";
})(ProfileContactLinkTags || (ProfileContactLinkTags = {}));
export var ProfileSocialLinkTags;
(function (ProfileSocialLinkTags) {
    ProfileSocialLinkTags["WEBSITE"] = "website";
})(ProfileSocialLinkTags || (ProfileSocialLinkTags = {}));
export var ProfileLocationTags;
(function (ProfileLocationTags) {
    ProfileLocationTags["BUSINESS"] = "business";
    ProfileLocationTags["CUSTODY"] = "custody";
})(ProfileLocationTags || (ProfileLocationTags = {}));
