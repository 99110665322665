import { Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";

import { Container, List, ListItem } from "./styles";

export const FacilitatorRoleComponent = () => {
  return (
    <Container>
      <Typography fontSize="1.125rem" color={colors.velvet}>
        Facilitator
      </Typography>
      <Typography
        fontSize="0.875rem"
        color={colors.velvetLight}
        marginBottom="1.5rem"
      >
        I am a party authorized to list assets and manage the operations of a
        seller's account.
      </Typography>

      <Typography
        fontSize="0.875rem"
        color={colors.velvet}
        marginTop="1.5rem"
        marginBottom="0.4375rem"
        fontWeight={500}
      >
        What you will need to create an account:
      </Typography>
      <List>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Brand information, including the name, description, and category
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Brand assets, a logo and cover picture with a maximum size of 600kb
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Business contact information, such as email, phone number, address,
            and website
          </Typography>
        </ListItem>
      </List>

      <Typography
        fontSize="0.875rem"
        color={colors.velvet}
        marginTop="1.5rem"
        marginBottom="0.4375rem"
        fontWeight={500}
      >
        What comes next?
      </Typography>
      <List>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            The seller will add you as an authorized facilitator to their
            account
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Create and list assets on behalf of the seller
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Add and assign team member roles
          </Typography>
        </ListItem>
      </List>
    </Container>
  );
};
