import { Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";

import { Container, List, ListItem } from "./styles";

export const BridgerRoleComponent = () => {
  return (
    <Container>
      <Typography fontSize="1.125rem" color={colors.velvet}>
        Bridger
      </Typography>
      <Typography
        fontSize="0.875rem"
        color={colors.velvetLight}
        marginBottom="1.5rem"
      >
        I am an authorized agent acting on behalf of a verifier or custodian on
        Fermion.
      </Typography>

      <Typography
        fontSize="0.875rem"
        color={colors.velvet}
        marginTop="1.5rem"
        marginBottom="0.4375rem"
        fontWeight={500}
      >
        What you will need to create an account:
      </Typography>
      <List>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Business and contact information, including the name, description,
            email, phone number, address, and website
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Brand assets, a logo and cover picture with a maximum size of 600kb
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            For verifiers, details of the authentication and/or valuation
            certificates you provide, and your service fees
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            For custodians, physical facility location details, insured value,
            and storage fee
          </Typography>
        </ListItem>
      </List>

      <Typography
        fontSize="0.875rem"
        color={colors.velvet}
        marginTop="1.5rem"
        marginBottom="0.4375rem"
        fontWeight={500}
      >
        What comes next?
      </Typography>
      <List>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            For verifiers, submit and set verification of an asset
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            For custodian, check-in assets as they are received and check-out
            assets upon redemption
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Manage and configure your payout settings
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Add and assign team member roles
          </Typography>
        </ListItem>
      </List>
    </Container>
  );
};
