import {
  bosonButtonThemes,
  ConnectWalletProps,
} from "@bosonprotocol/react-kit";
import { buttonThemes } from "@fermionprotocol/react-kit";

export const successButtonTheme: ConnectWalletProps["connectWalletButtonTheme"] =
  {
    ...buttonThemes["pinkLight"],
    gap: "2px",
  };

export const errorButtonTheme = {
  ...bosonButtonThemes({ withBosonStyle: false })["orangeInverse"],
  gap: "2px",
};
