import { METADATA_LENGTH_LIMIT } from "@bosonprotocol/react-kit";
import * as Yup from "yup";
import { validationMessage } from "./common";
export const getStringValidation = ({ required, }) => {
    const stringValidation = Yup.string()
        .trim()
        .max(METADATA_LENGTH_LIMIT, validationMessage.maxLength);
    if (!required) {
        // @ts-expect-error there is something wrong with Yup types here
        return stringValidation;
    }
    return stringValidation.required(validationMessage.required);
};
