import { Grid } from "@bosonprotocol/react-kit";
import {
  hooks,
  Input,
  openSeaSupportedCurrencies,
  Profile,
  Select,
  setInputNumberValueFieldFactory,
  storageFeesPeriodOptions,
  tokensValues,
} from "@fermionprotocol/react-kit";
import { getCurrencyFlagsFromOptions } from "@fermionprotocol/react-kit/src";
type CustodianStorageFeeProps = {
  prefix: string;
  isBridger: boolean;
};
const emptyInputNumber = null;
export const CustodianStorageFee = ({
  prefix: custodianPrefix,
  isBridger,
}: CustodianStorageFeeProps) => {
  const { setFieldValue, values } = hooks.useForm<Profile>();
  const { storageFees, storageFeesTokens } = isBridger
    ? values.bridger.custodian
    : values.custodian;
  const { isUsdc, isWeth } = getCurrencyFlagsFromOptions(
    storageFeesTokens as Parameters<typeof getCurrencyFlagsFromOptions>[0],
  );
  const custodianStorageFeeField = `${custodianPrefix}custodian.storageFees`;
  const { setInputNumberValueField } = setInputNumberValueFieldFactory({
    setFieldValue,
  });
  return (
    <Grid
      flexDirection="column"
      alignItems="flex-start"
      gap="1rem"
      justifyContent="flex-start"
    >
      <Grid
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Select
          name={`${custodianStorageFeeField}Period`}
          options={storageFeesPeriodOptions}
        />
      </Grid>
      <Grid
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Select
          name={`${custodianStorageFeeField}Tokens`}
          options={openSeaSupportedCurrencies}
          isMulti
          onChange={(selectedOptions) => {
            const { isWeth, isUsdc } =
              getCurrencyFlagsFromOptions(selectedOptions);
            const filteredStorageFees = storageFees?.filter(
              ({ weth, usdc }) =>
                (isWeth && weth !== 0) || (isUsdc && usdc !== 0),
            );
            if (filteredStorageFees?.length && (isUsdc || isWeth)) {
              setFieldValue(
                custodianStorageFeeField,
                filteredStorageFees.map(({ usdc, weth }) => {
                  return {
                    usdc: isUsdc ? usdc : emptyInputNumber,
                    weth: isWeth ? weth : emptyInputNumber,
                  };
                }),
              );
            } else if (isUsdc || isWeth) {
              if (isUsdc) {
                setFieldValue(custodianStorageFeeField, [
                  {
                    usdc: emptyInputNumber,
                    weth: 0,
                  },
                ] satisfies [
                  Pick<NonNullable<typeof storageFees>[0], "usdc" | "weth">,
                ]);
              } else if (isWeth) {
                setFieldValue(custodianStorageFeeField, [
                  {
                    weth: emptyInputNumber,
                    usdc: 0,
                  },
                ] satisfies [
                  Pick<NonNullable<typeof storageFees>[0], "weth" | "usdc">,
                ]);
              }
            } else {
              setFieldValue(
                custodianStorageFeeField,
                [] satisfies typeof storageFees,
              );
            }
          }}
        />
      </Grid>
      {storageFees?.map((_, index) => {
        const getStorageFeePrefix = (i: number) =>
          `${custodianStorageFeeField}[${i}]`;
        const prefix = getStorageFeePrefix(index);
        const UsdcComponent = (
          <>
            {isUsdc && (
              <Grid flexDirection="column" alignItems="flex-start">
                <Input
                  name={`${prefix}.usdc`}
                  placeholder="USDC"
                  type="number"
                  onChange={(e) => {
                    setInputNumberValueField(`${prefix}.usdc`, e.target.value);
                  }}
                />
              </Grid>
            )}
          </>
        );
        const WethComponent = (
          <>
            {isWeth && (
              <Grid flexDirection="column" alignItems="flex-start">
                <Input
                  name={`${prefix}.weth`}
                  placeholder="WETH"
                  type="number"
                  onChange={(e) => {
                    setInputNumberValueField(`${prefix}.weth`, e.target.value);
                  }}
                />
              </Grid>
            )}
          </>
        );
        return (
          <Grid key={`${index}`} gap="1rem" alignItems="flex-start">
            {storageFeesTokens?.[0]?.value === tokensValues.usdc ? (
              <>
                {UsdcComponent}
                {WethComponent}
              </>
            ) : (
              <>
                {WethComponent}
                {UsdcComponent}
              </>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};
