import { createContext, ReactNode, RefObject } from "react";

type AppContextType = {
  buyWithFermionSectionRef: RefObject<HTMLDivElement>;
  sellWithFermionSectionRef: RefObject<HTMLDivElement>;
  advancedRolesSectionRef: RefObject<HTMLDivElement>;
};

export const AppContext = createContext<AppContextType | null>(null);

type AppProviderProps = AppContextType & {
  children: ReactNode;
};

export const AppProvider = ({ children, ...refs }: AppProviderProps) => {
  return <AppContext.Provider value={refs}>{children}</AppContext.Provider>;
};
