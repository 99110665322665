import { Grid } from "@bosonprotocol/react-kit";
import { borders, Button, colors } from "@fermionprotocol/react-kit";
import tokenImage from "assets/token.png";
import { ReactElement, ReactNode } from "react";
import { useMediaQuery } from "react-responsive";
import styled, { css } from "styled-components";

export interface CallToActionCardProps {
  backgroundColor?: string;
  borderColor?: string;
  headerText: ReactElement;
  paragraphText: ReactElement;
  isAbsoluteCentered?: boolean;
  padding?: string;
  margin?: string;
  rightChild?: ReactNode;
}

export const CALL_TO_ACTION_BREAKPOINT = 1340;

const CallToActionCardContainer = styled.div<{
  $backgroundColor: string;
  $borderColor: string;
  $isAbsoluteCentered?: boolean;
  $padding?: string;
  $margin?: string;
}>`
  background-color: ${(props) =>
    props.$backgroundColor || colors.velvetBackground};
  border: 1px solid ${(props) => props.$borderColor || colors.velvetBorderDark};
  padding: ${(props) => props.$padding || "2.5rem 3.5rem"};
  margin: ${(props) => props.$margin || "0"};
  border-radius: ${borders.big}px;
  position: relative;
  width: 100%;
  ${(props) =>
    props.$isAbsoluteCentered &&
    css`
      width: calc(100% - 14rem);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    `}
`;

const ParagraphTextWrapper = styled.div`
  max-width: 28.5625rem;
  @media (max-width: ${CALL_TO_ACTION_BREAKPOINT}px) {
    max-width: unset;
  }
`;

export const CallToActionCardButton = styled(Button)`
  padding: 0.875rem 2rem;
  border-radius: ${borders.large}px;
  margin-left: auto;
  white-space: nowrap;
  @media (max-width: ${CALL_TO_ACTION_BREAKPOINT}px) {
    margin: 0 auto;
    margin-top: 0.625rem;
  }
`;

export const CallToActionCard: React.FC<CallToActionCardProps> = ({
  backgroundColor = colors.velvetBackground,
  borderColor = colors.velvetBorderDark,
  headerText,
  paragraphText,
  isAbsoluteCentered = false,
  padding,
  margin,
  rightChild,
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${CALL_TO_ACTION_BREAKPOINT}px)`,
  });

  return (
    <CallToActionCardContainer
      $backgroundColor={backgroundColor}
      $borderColor={borderColor}
      $isAbsoluteCentered={isAbsoluteCentered}
      $padding={padding}
      $margin={margin}
    >
      <Grid>
        <Grid
          justifyContent={isMobile ? "center" : "flex-start"}
          flexDirection={isMobile ? "column" : "row"}
        >
          <img src={tokenImage} alt="Token" />
          <Grid
            flexDirection="column"
            marginLeft={isMobile ? 0 : "1.5rem"}
            alignItems={isMobile ? "center" : "flex-start"}
            marginTop={isMobile ? "0.625rem" : 0}
            width={isMobile ? "100%" : "60%"}
          >
            {headerText}
            <ParagraphTextWrapper>{paragraphText}</ParagraphTextWrapper>
          </Grid>
          {rightChild}
        </Grid>
      </Grid>
    </CallToActionCardContainer>
  );
};
