import { generatePath } from "react-router-dom";
import { fermionRoutes, UrlParameters } from "router/routeNames";

interface ListingParams {
  sellerId: string | number;
  uuid?: string;
  bosonOfferId: string | number;
}

export function generateListingPath(params: ListingParams): string {
  return generatePath(fermionRoutes.listing, {
    [UrlParameters.sellerId]: `${params.sellerId}`,
    [UrlParameters.uuid]: params.uuid || "",
    [UrlParameters.bosonOfferId]: `${params.bosonOfferId}`,
  });
}
