import * as Yup from "yup";

import { productConditionsOptions } from "../constants/productConditions";
import { validationMessage } from "./common";

export const conditionSelectValidationSchema = Yup.object({
  value: Yup.string().oneOf(
    productConditionsOptions.map((condition) => condition.value),
  ),
  label: Yup.string(),
})
  .default(null)
  .required(validationMessage.required);
