import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useField } from "formik";
import { useForm } from "../../../hooks";
import { DatePicker } from "./DatePicker";
import { Error } from "./Error";
export function DatePickerForm({ name, period = false, selectTime = false, onShow, ...rest }) {
    const { handleBlur } = useForm();
    const [field, meta, helpers] = useField(name);
    const errorMessage = meta.error && meta.touched ? meta.error : "";
    const displayError = typeof errorMessage === "string" && errorMessage !== "";
    const handleChange = (date) => {
        helpers.setValue(date);
    };
    return (_jsxs(_Fragment, { children: [_jsx(DatePicker, { onChange: handleChange, error: errorMessage, period: period, selectTime: selectTime, initialValue: field.value, onBlur: handleBlur, onClick: () => {
                    if (!meta.touched) {
                        helpers.setTouched(true);
                    }
                }, ...rest, onShow: onShow, name: name }), _jsx(Error, { display: displayError, message: errorMessage })] }));
}
