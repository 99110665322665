// https://www.fatf-gafi.org/en/countries.html
export const fatfMemberCountries = [
    "AR",
    "AU",
    "AT",
    "BE",
    "BR",
    "CA",
    "CN",
    "DK",
    "FI",
    "FR",
    "DE",
    "GR",
    "HK",
    "IS",
    "IN",
    "IE",
    "IL",
    "IT",
    "JP",
    "KR",
    "LU",
    "MY",
    "MX",
    "NE",
    "NZ",
    "PT",
    "NO",
    "SA",
    "SG",
    "ZA",
    "ES",
    "SE",
    "CH",
    "TR",
    "GB",
    "US",
];
