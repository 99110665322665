import { WithNullableOptionalProperties } from "@fermionprotocol/common";
import * as Yup from "yup";

export const SUPPORTED_FILE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/webp",
] as const;
export type FileProps = WithNullableOptionalProperties<{
  src: string;
  name?: string; // for example: "redeemeum.png"
  size?: number;
  type?: string; // for example: "image/png"
  width?: number | null;
  height?: number | null;
  fit?: string | null;
  position?: string | null;
}>;
export const validationOfRequiredIpfsImage = <T extends FileProps>() =>
  Yup.mixed<T[]>()
    .test("fileUploaded", "You need to upload an image", function (value) {
      return !!(value && value?.[0]?.src);
    })
    .test("FILE_FORMAT", "Image has unsupported format!", (value) => {
      return (
        !(value && value.length > 0) ||
        (value &&
          value.length !== 0 &&
          (value?.[0]?.src.startsWith("ipfs://") ||
            SUPPORTED_FILE_FORMATS.includes(
              (value?.[0]?.type ||
                "") as (typeof SUPPORTED_FILE_FORMATS)[number],
            )))
      );
    });

export const validationOfIpfsImage = () => Yup.mixed<FileProps[]>();
