import { errors } from "ethers";
import { useState } from "react";
export function useCtaClickHandler({ waitBlocks, coreSdk, actions, onPendingSignature, onPendingTransaction, onCancelledTransaction, onRepricedTransaction, onError, onSuccess, successPayload, }) {
    const useMetaTx = coreSdk.isMetaTxConfigSet;
    const [isLoading, setIsLoading] = useState(false);
    const clickHandler = async (e) => {
        e.stopPropagation();
        let txResponse = undefined;
        let receipt = undefined;
        try {
            setIsLoading(true);
            for (const action of actions) {
                const { name, writeContractFn, shouldActionRun = () => Promise.resolve(true), } = action;
                if (!(await shouldActionRun())) {
                    continue;
                }
                onPendingSignature?.(name);
                txResponse = await writeContractFn();
                if (txResponse) {
                    try {
                        onPendingTransaction?.(txResponse.hash, useMetaTx, name);
                        receipt = await txResponse.wait(waitBlocks);
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    }
                    catch (error) {
                        // Handle transaction that was replaced, canceled or repriced.
                        // See https://docs.ethers.io/v5/api/utils/logger/#errors--transaction-replaced
                        // for details.
                        if (error.code === errors.TRANSACTION_REPLACED) {
                            // Transaction was replaced or cancelled
                            if (error.cancelled) {
                                if (onCancelledTransaction) {
                                    onCancelledTransaction(txResponse.hash, error.replacement, useMetaTx);
                                }
                                else {
                                    throw error;
                                }
                            }
                            else {
                                // Transaction was repriced, i.e. speed up
                                onRepricedTransaction?.(txResponse.hash, error.replacement, error.receipt, useMetaTx, name);
                                receipt = error.receipt;
                            }
                        }
                        else {
                            throw error;
                        }
                    }
                }
            }
            if (receipt) {
                const payload = successPayload instanceof Function
                    ? successPayload(receipt)
                    : successPayload;
                onSuccess?.(receipt, payload);
            }
        }
        catch (error) {
            onError?.(typeof error === "object"
                ? (await coreSdk.parseError(error))
                : error, {
                txResponse: txResponse,
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    return { clickHandler, isLoading };
}
