import {
  stringConstants,
  type stringConstants as typeStringConstants,
} from "@fermionprotocol/common";
import { eEntityRole } from "@fermionprotocol/core-sdk";

export const RoleId = stringConstants.RoleId;
type RoleIdKey = typeStringConstants.RoleIdKey;
export type Role = RoleIdKey;
export const allRoles = Object.values(RoleId);

export const roleToEntityRole = {
  [RoleId.SELLER]: eEntityRole.Seller,
  [RoleId.FACILITATOR]: eEntityRole.Seller,
  [RoleId.BRIDGER]: eEntityRole.Seller,
  [RoleId.VERIFIER]: eEntityRole.Verifier,
  [RoleId.CUSTODIAN]: eEntityRole.Custodian,
} as const;

export const entityRoleToRoles = {
  [eEntityRole.Seller]: [RoleId.SELLER, RoleId.FACILITATOR, RoleId.BRIDGER],
  [eEntityRole.Verifier]: RoleId.VERIFIER,
  [eEntityRole.Custodian]: RoleId.CUSTODIAN,
} as const;
