import { useEffect, useRef } from "react";
// to have a 'useEffect' that only changes when dependencies change (instead of that + initial render)
export const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current)
            func();
        else
            didMount.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
