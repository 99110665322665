import { Provider } from "@bosonprotocol/ethers-sdk";
import {
  extractUserFriendlyError,
  getHasUserRejectedTx,
  Grid,
  hooks as bosonHooks,
  Typography,
} from "@bosonprotocol/react-kit";
import { eAccountRole } from "@fermionprotocol/core-sdk";
import {
  borders,
  Button,
  colors,
  getNumberWithDecimals,
  getNumberWithoutDecimals,
  hooks,
  Input,
  WithdrawFundsButton,
} from "@fermionprotocol/react-kit";
import * as Sentry from "@sentry/browser";
import { useSignerV6 } from "components/listing/hooks/connection";
import { useModal } from "components/modal/useModal";
import { BigNumber } from "ethers";
import { Form, Formik } from "formik";
import { Spinner } from "phosphor-react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";

const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

const MaxButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0.125rem;
`;

export type CurrencyOption = {
  label: string;
  value: string;
  icon: JSX.Element;
};

const FormContainer = styled.div`
  .withdrawForm {
    display: flex;
    position: relative;
    flex-direction: column;
    .currencyField {
      max-width: 7.5rem;
      flex: 0 0 7.5rem;
      z-index: 99999;

      div {
        border-radius: ${borders.big}px;
        background: ${colors.champagne};
        color: ${colors.velvetLight};
      }
    }

    .amountField {
      flex-grow: 1;
      margin-right: 0.5rem;
    }
  }
`;

const withdrawFundsSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Amount is required")
    .positive("Amount must be positive")
    .typeError("Amount must be a number"),
});

interface WithdrawFundsValues {
  amount: string;
}

type WithdrawFundsModalProps = {
  tokenSymbol: string | React.ReactNode;
  maxWithdrawableAmount: string;
  tokenOrNativeAddress: string;
  reload: () => void;
  tokenDecimals: string;
};
export function WithdrawFundsModal({
  tokenSymbol,
  maxWithdrawableAmount,
  tokenOrNativeAddress,
  reload,
  tokenDecimals,
}: WithdrawFundsModalProps) {
  const tokenStep = 10 ** -Number(tokenDecimals);
  const formattedMaxWithdrawableAmount = getNumberWithDecimals(
    maxWithdrawableAmount,
    tokenDecimals,
  );
  const { address } = bosonHooks.useAccount();
  const { data: signerV6 } = useSignerV6();
  const { data: entity } = hooks.useGetWallet({
    enabled: !!address,
    walletAddress: address ?? "",
  });
  const entityThatCanWithdraw = entity?.accountRoles?.find(
    (role) =>
      role.accountRole === eAccountRole.Treasury ||
      role.accountRole === eAccountRole.Assistant ||
      role.accountRole === eAccountRole.Manager, // TODO: remove, right now the subgraph doesnt return that Im also a treasury and assistant so I have to do this
  );
  const treasuryAddress = address ?? "";
  const accountId = entityThatCanWithdraw?.entityRole.id ?? ""; // TODO: check if this is correct

  const { updateProps, store, hideModal, showModal } = useModal();

  const [isBeingWithdrawn, setIsBeingWithdrawn] = useState<boolean>(false);
  const [isWithdrawInvalid, setIsWithdrawInvalid] = useState<boolean>(true);
  useEffect(() => {
    updateProps<"EDIT_ROLE">({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              fontSize={"1.5rem"}
              fontWeight={500}
              tag="span"
              marginBottom="0.5rem"
              marginTop="0.25rem"
            >
              Withdraw {tokenSymbol}
            </Typography>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenSymbol]);

  return (
    <FormContainer>
      <Formik<WithdrawFundsValues>
        initialValues={{
          amount: "",
        }}
        validationSchema={withdrawFundsSchema}
        onSubmit={() => {
          // do nothing on purpose, it's handled by the button directly
        }}
      >
        {({
          setFieldValue,
          handleChange,
          values: { amount: amountToWithdraw },
        }) => {
          const setAmountToWithdraw = (value: string) => {
            setFieldValue("amount", value);
          };
          const handleChangeWithdrawAmount = (
            e: React.ChangeEvent<HTMLInputElement>,
          ) => {
            const valueStr = e.target.value;
            const value = Number(valueStr) || 0;
            setIsWithdrawInvalid(false);

            try {
              const valueWithNoDecimals = getNumberWithoutDecimals(
                valueStr,
                tokenDecimals,
              );

              if (
                value < tokenStep ||
                BigNumber.from(maxWithdrawableAmount).lt(valueWithNoDecimals) ||
                !value
              ) {
                setIsWithdrawInvalid(true);
              }
            } catch (e) {
              setIsWithdrawInvalid(true);
            }

            setAmountToWithdraw(valueStr);
          };
          return (
            // TODO: Fix formik bug
            // @ts-expect-error Formik bug
            <Form className="withdrawForm" id="withdrawForm">
              <Typography
                fontSize={"0.875rem"}
                fontWeight="500"
                color={colors.velvetLight}
              >
                Enter amount to withdraw
              </Typography>
              <Grid alignItems="flex-start">
                <InputWrapper className="amountField">
                  <Input
                    name="amount"
                    type="text"
                    step={tokenStep}
                    min={0}
                    onChange={(e) => {
                      handleChangeWithdrawAmount(e);
                      handleChange(e);
                    }}
                  />
                  <MaxButtonWrapper>
                    <Button
                      variant="transparent"
                      type="button"
                      onClick={() => {
                        setIsWithdrawInvalid(false);
                        setAmountToWithdraw(
                          getNumberWithDecimals(
                            maxWithdrawableAmount,
                            tokenDecimals,
                          ).toString(),
                        );
                      }}
                    >
                      <Typography
                        fontSize={"0.75rem"}
                        fontWeight={500}
                        color={colors.pink}
                      >
                        MAX
                      </Typography>
                    </Button>
                  </MaxButtonWrapper>
                </InputWrapper>
              </Grid>
              <Typography
                color={colors.velvetLight}
                fontWeight={400}
                fontSize={"0.75rem"}
              >
                Withdrawable: {formattedMaxWithdrawableAmount} {tokenSymbol}
              </Typography>

              <Grid
                width={"100%"}
                justifyContent="space-between"
                alignItems="center"
                padding="1.5rem 0 0 0"
              >
                <Button
                  variant="transparent"
                  type="button"
                  onClick={() => {
                    hideModal();
                  }}
                >
                  <Typography fontSize={"0.875rem"} fontWeight={500}>
                    Close
                  </Typography>
                </Button>
                <WithdrawFundsButton
                  variant="pinkLight"
                  accountId={accountId}
                  treasuryAddress={treasuryAddress}
                  tokensToWithdraw={[
                    {
                      tokenAddress: tokenOrNativeAddress,
                      amount:
                        isWithdrawInvalid || !Number(amountToWithdraw)
                          ? BigNumber.from("0")
                          : BigNumber.from(
                              getNumberWithoutDecimals(
                                amountToWithdraw,
                                tokenDecimals,
                              ),
                            ),
                    },
                  ]}
                  disabled={isBeingWithdrawn || isWithdrawInvalid}
                  onPendingSignature={() => {
                    setIsBeingWithdrawn(true);
                    showModal({
                      modalType: "WAITING_FOR_CONFIRMATION",
                      modalMaxWidth: {
                        xs: "400px",
                      },
                    });
                  }}
                  onPendingTransaction={(hash) => {
                    showModal({
                      modalType: "TRANSACTION_SUBMITTED",
                      modalProps: {
                        action: "Finance withdraw",
                        txHash: hash,
                      },
                    });
                  }}
                  onSuccess={async () => {
                    setAmountToWithdraw("0");
                    setIsWithdrawInvalid(true);
                    hideModal();
                    reload();
                    setIsBeingWithdrawn(false);
                  }}
                  onError={async (error, { txResponse }) => {
                    console.error("onError", error);
                    const hasUserRejectedTx = getHasUserRejectedTx(error);
                    if (hasUserRejectedTx) {
                      showModal({
                        modalType: "TRANSACTION_FAILED",
                      });
                    } else {
                      Sentry.captureException(error);
                      showModal({
                        modalType: "TRANSACTION_FAILED",
                        modalProps: {
                          errorMessage: "Something went wrong",
                          detailedErrorMessage: await extractUserFriendlyError(
                            error,
                            {
                              txResponse,
                              // TODO: is this cast okay?
                              provider:
                                signerV6?.provider as unknown as Provider,
                            },
                          ),
                        },
                      });
                    }
                    reload();
                    setIsBeingWithdrawn(false);
                  }}
                >
                  {isBeingWithdrawn ? (
                    <Spinner size={20} />
                  ) : (
                    <Typography
                      tag="p"
                      margin="0"
                      fontSize={"0.875rem"}
                      fontWeight={500}
                    >
                      Withdraw
                    </Typography>
                  )}
                </WithdrawFundsButton>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
}
