import { isTruthy } from "@bosonprotocol/react-kit";
import {
  borders,
  bridgingRoleOptions,
  hooks,
  Input,
  productCategoriesOptions,
  Profile,
  Select,
  selfVerifierOrThirdPartyOptions,
  SetInputNumberFieldFactoryReturn,
  setInputNumberValueFieldFactory,
  shippingMethodsOptions,
  TextArea,
  Upload,
} from "@fermionprotocol/react-kit";
import { LocationAddress } from "components/location/LocationAddress";
import { FormikTouched } from "formik";
import { ReactNode } from "react";

import { CustodianStorageFee } from "./CustodianStorageFee";
import { ProfileFormFieldsTemplate } from "./ProfileFormFieldsTemplate";
import { VerifierFeePriceBands } from "./VerifierFeePriceBands";

type BridgerProps = {
  isBridger: boolean;
};

const bridgerPrefix = "bridger.";
const getVerifierCategory = ({ isBridger }: BridgerProps) => {
  return (
    <Select
      name={`${isBridger ? bridgerPrefix : ""}verifier.category`}
      options={productCategoriesOptions}
    />
  );
};
const getVerifierAuthenticationReport = ({ isBridger }: BridgerProps) => {
  return (
    <Input
      name={`${isBridger ? bridgerPrefix : ""}verifier.authenticationReport`}
      placeholder="Describe your authentication report"
    />
  );
};
const getVerifierValuationCertificate = ({ isBridger }: BridgerProps) => {
  return (
    <Input
      name={`${isBridger ? bridgerPrefix : ""}verifier.valuationCertificate`}
      placeholder="Describe your valuation certificate"
    />
  );
};
const getVerifierFeePriceBands = ({ isBridger }: BridgerProps) => {
  return (
    <VerifierFeePriceBands
      prefix={isBridger ? bridgerPrefix : ""}
      isBridger={isBridger}
    />
  );
};
const getVerifierSelfOrThirdParty = ({ isBridger }: BridgerProps) => {
  return (
    <Select
      name={`${isBridger ? bridgerPrefix : ""}verifier.selfOrThirdParty`}
      options={selfVerifierOrThirdPartyOptions}
    />
  );
};
const getCustodianCategory = ({ isBridger }: BridgerProps) => {
  return (
    <Select
      name={`${isBridger ? bridgerPrefix : ""}custodian.category`}
      options={productCategoriesOptions}
    />
  );
};
const getCustodianLocationName = ({ isBridger }: BridgerProps) => {
  return (
    <Input
      name={`${isBridger ? bridgerPrefix : ""}custodian.locationName`}
      placeholder=""
    />
  );
};
const getCustodianLocationAddress = ({
  isBridger,
  touchedBusinessLocation,
}: BridgerProps & {
  touchedBusinessLocation:
    | FormikTouched<Profile["businessLocation"]>
    | undefined;
}) => {
  return (
    <LocationAddress
      prefix={`${isBridger ? bridgerPrefix : ""}custodian.location`}
      anyTouched={
        touchedBusinessLocation
          ? Object.values(touchedBusinessLocation).some(isTruthy)
          : false
      }
    />
  );
};
const getCustodianInsuredValue = ({
  isBridger,
  setInputNumberValueField,
}: BridgerProps & SetInputNumberFieldFactoryReturn) => {
  const name = `${isBridger ? bridgerPrefix : ""}custodian.insuredValue`;
  return (
    <Input
      name={name}
      placeholder="Enter value in USD"
      type="number"
      onChange={(e) => {
        setInputNumberValueField(name, e.target.value);
      }}
    />
  );
};
const getCustodianShippingMethod = ({ isBridger }: BridgerProps) => {
  return (
    <Select
      name={`${isBridger ? bridgerPrefix : ""}custodian.shippingMethod`}
      options={shippingMethodsOptions}
    />
  );
};
const getCustodianStorageFee = ({ isBridger }: BridgerProps) => {
  return (
    <CustodianStorageFee
      prefix={isBridger ? bridgerPrefix : ""}
      isBridger={isBridger}
    />
  );
};
type ProfileFormFieldsrops = {
  children: ReactNode;
  overrideBridgingRoleOptions?: (typeof bridgingRoleOptions)[number][];
};
export function ProfileFormFields({
  children,
  overrideBridgingRoleOptions,
}: ProfileFormFieldsrops) {
  const { values, setFieldValue, touched } = hooks.useForm<Profile>();
  const {
    roles,
    bridger: { bridgingRoles },
  } = values;
  const { setInputNumberValueField } = setInputNumberValueFieldFactory({
    setFieldValue,
  });
  return (
    <>
      <ProfileFormFieldsTemplate
        withSubtitles
        withRequired
        roles={roles}
        Logo={
          <Upload
            name="logo"
            multiple={false}
            withUpload
            withEditor
            borderRadius={borders.small}
            borderRadiusUnit="px"
            width={200}
            height={200}
          />
        }
        CoverPicture={
          <Upload
            name="coverPicture"
            multiple={false}
            borderRadius={borders.small}
            borderRadiusUnit="px"
            width={400}
            height={200}
            withUpload
            withEditor
            imgPreviewStyle={{ objectFit: "contain" }}
            wrapperProps={{ style: { width: "100%" } }}
          />
        }
        bridgingRoles={bridgingRoles}
        BridgerWhichRoleAreYouBridging={
          <Select
            name={`${bridgerPrefix}bridgingRoles`}
            options={overrideBridgingRoleOptions || bridgingRoleOptions}
            isMulti
          />
        }
        BridgerVerifierName={
          <Input name={`${bridgerPrefix}verifier.name`} placeholder="Name" />
        }
        BridgerVerifierCategory={getVerifierCategory({ isBridger: true })}
        BridgerVerifierAuthenticationReport={getVerifierAuthenticationReport({
          isBridger: true,
        })}
        BridgerVerifierValuationCertificate={getVerifierValuationCertificate({
          isBridger: true,
        })}
        BridgerVerifierFeePriceBands={getVerifierFeePriceBands({
          isBridger: true,
        })}
        BrigerVerifierSelfOrThirdParty={getVerifierSelfOrThirdParty({
          isBridger: true,
        })}
        BridgerCustodianName={
          <Input name={`${bridgerPrefix}custodian.name`} placeholder="Name" />
        }
        BridgerCustodianCategory={getCustodianCategory({ isBridger: true })}
        BridgerCustodianLocationName={getCustodianLocationName({
          isBridger: true,
        })}
        BridgerCustodianLocationAddress={getCustodianLocationAddress({
          isBridger: true,
          touchedBusinessLocation: touched.bridger?.custodian?.location,
        })}
        BridgerCustodianInsuredValue={getCustodianInsuredValue({
          isBridger: true,
          setInputNumberValueField,
        })}
        BridgerCustodianStorageFee={getCustodianStorageFee({ isBridger: true })}
        BridgerCustodianShippingMethod={getCustodianShippingMethod({
          isBridger: true,
        })}
        Name={<Input name="name" placeholder="Name" />}
        Description={
          <TextArea
            name="description"
            placeholder="Tell people more about your brand"
            style={{ resize: "vertical" }}
          />
        }
        Email={
          <Input
            name="email"
            placeholder="email"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          />
        }
        Website={
          <Input
            name="website"
            placeholder="www.example.com OR www.instagram.com/example"
          />
        }
        BusinessPhoneNumber={
          <Input name="businessPhoneNumber" placeholder="Input phone number" />
        }
        LegalTradingName={
          <Input name="legalTradingName" placeholder="Polly Seller UK ltd." />
        }
        BusinessRegistrationAddress={
          <LocationAddress
            prefix="businessLocation"
            anyTouched={
              touched.businessLocation
                ? Object.values(touched.businessLocation).some(isTruthy)
                : false
            }
          />
        }
        VerifierCategory={getVerifierCategory({ isBridger: false })}
        VerifierAuthenticationReport={getVerifierAuthenticationReport({
          isBridger: false,
        })}
        VerifierValuationCertificate={getVerifierValuationCertificate({
          isBridger: false,
        })}
        VerifierFeePriceBands={getVerifierFeePriceBands({ isBridger: false })}
        VerifierSelfOrThirdParty={getVerifierSelfOrThirdParty({
          isBridger: false,
        })}
        CustodianCategory={getCustodianCategory({ isBridger: false })}
        CustodianLocationName={getCustodianLocationName({ isBridger: false })}
        CustodianLocationAddress={getCustodianLocationAddress({
          isBridger: false,
          touchedBusinessLocation: touched.custodian?.location,
        })}
        CustodianInsuredValue={getCustodianInsuredValue({
          isBridger: false,
          setInputNumberValueField,
        })}
        CustodianStorageFee={getCustodianStorageFee({ isBridger: false })}
        CustodianShippingMethod={getCustodianShippingMethod({
          isBridger: false,
        })}
        WebsiteForFermion={
          <Input
            name="websiteForFermionIdentity"
            placeholder="www.example.com"
          />
        }
      />
      {children}
    </>
  );
}
