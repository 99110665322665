import { Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";

import { Container, List, ListItem } from "./styles";

export const CustodianRoleComponent = () => {
  return (
    <Container>
      <Typography fontSize="1.125rem" color={colors.velvet}>
        Custodian
      </Typography>
      <Typography
        fontSize="0.875rem"
        color={colors.velvetLight}
        marginBottom="1.5rem"
      >
        I am a trusted party providing physical facilities for the custody and
        storage of assets.
      </Typography>

      <Typography
        fontSize="0.875rem"
        color={colors.velvet}
        marginTop="1.5rem"
        marginBottom="0.4375rem"
        fontWeight={500}
      >
        What you will need to create an account:
      </Typography>
      <List>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Business and contact information, including the name, description,
            email, phone number, address, and website
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Brand assets, a logo and cover picture with a maximum size of 600kb
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Physical facility location details, insured value, and storage fee
          </Typography>
        </ListItem>
      </List>

      <Typography
        fontSize="0.875rem"
        color={colors.velvet}
        marginTop="1.5rem"
        marginBottom="0.4375rem"
        fontWeight={500}
      >
        What comes next?
      </Typography>
      <List>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Check-in assets as they are received and check-out assets upon
            redemption
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Manage and configure your payout settings
          </Typography>
        </ListItem>
        <ListItem>
          <Typography fontSize="0.875rem" color={colors.velvetLight}>
            Add and assign team member roles
          </Typography>
        </ListItem>
      </List>
    </Container>
  );
};
