import {
  Grid,
  GridContainer,
  hooks as bosonHooks,
  Typography,
} from "@bosonprotocol/react-kit";
import {
  Button,
  CardCTA,
  colors,
  getOpenSeaUrl,
} from "@fermionprotocol/react-kit";
import openSeaLogo from "assets/openseaLogo.png";
import { ModalTitleHeader } from "components/modal/header/ModalTitleHeader";
import { useModal } from "components/modal/useModal";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import { ArrowsLeftRight, CheckCircle, Storefront } from "phosphor-react";
import { useEffect } from "react";
import { fermionRoutes } from "router/routeNames";
import { styled } from "styled-components";
const Wrapper = styled.div``;

export const SuccessListedOpenSeaModal = ({
  tokenId,
  wrapperAddress,
}: {
  tokenId?: string;
  wrapperAddress?: string;
}) => {
  const { store, updateProps, hideModal } = useModal();
  const navigate = useCustomNavigate();
  const { config } = bosonHooks.useConfigContext();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updateProps({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: <ModalTitleHeader>Congratulations!</ModalTitleHeader>,
      },
      modalMaxWidth: {
        l: "68.75rem",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Wrapper>
      <Grid flexDirection="column" margin="2.5rem 0">
        <CheckCircle size={105} weight="fill" color={colors.green} />
        <Typography tag="h3">Your product is now on OpenSea!</Typography>
      </Grid>
      <GridContainer
        itemsPerRow={{
          xs: 1,
          s: 2,
          m: 2,
          l: 3,
          xl: 3,
        }}
      >
        {wrapperAddress && tokenId && (
          <CardCTA
            icon={
              <img
                src={openSeaLogo}
                width={32}
                height={32}
                style={{ objectFit: "cover" }}
              />
            }
            title="Customise OpenSea page"
            text=""
            cta={
              <Button
                variant="grey"
                onClick={() => {
                  const link = getOpenSeaUrl({
                    configId: config.configId,
                    envName: config.envName,
                    tokenId: tokenId,
                    contractAddress: wrapperAddress,
                  });
                  window.open(link, "_blank");
                }}
              >
                Customise OpenSea page
              </Button>
            }
          />
        )}
        <CardCTA
          icon={<Storefront size={32} color={colors.pink} />}
          title="Create new products"
          text="Create new products and benefits to grow revenues and engage new audiences."
          cta={
            <Button
              variant="grey"
              onClick={() => {
                hideModal();
                navigate({
                  pathname: fermionRoutes.createProduct,
                });
              }}
            >
              Create new products
            </Button>
          }
        />
        <CardCTA
          icon={<ArrowsLeftRight size={32} color={colors.pink} />}
          title="View & Manage Bids"
          text=""
          cta={
            <Button
              variant="grey"
              onClick={() => {
                hideModal();
                navigate({
                  pathname: fermionRoutes.hub, // TODO: change to bids subpage specifically
                });
              }}
            >
              Go to Seller Hub
            </Button>
          }
        />
      </GridContainer>
    </Wrapper>
  );
};
