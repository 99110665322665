import { Grid } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";
import { PaginationPages } from "components/hub/shared/PaginationsPages";
import {
  Pagination,
  Span,
  TransparentButton,
} from "components/table/TableStyles";
import { CaretLeft, CaretRight } from "phosphor-react";

const numPageOptions = 5;

interface TablePaginationProps {
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  rowsLength: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  paginate: number[];
  setPageSize: (size: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  gotoPage: (page: number) => void;
}

export function TablePagination({
  pageSize,
  pageIndex,
  pageCount,
  rowsLength,
  canPreviousPage,
  canNextPage,
  paginate,
  setPageSize,
  previousPage,
  nextPage,
  gotoPage,
}: TablePaginationProps) {
  const itemsPerPage = new Array(numPageOptions)
    .fill(0)
    .map((_, index) => (index + 1) * pageSize);
  return (
    <Pagination>
      <Grid>
        <Grid justifyContent="flex-start" gap="1rem">
          <Span>
            Show
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {itemsPerPage.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            per page
          </Span>
          <PaginationPages
            pageIndex={pageIndex + 1}
            pageSize={pageSize}
            allItems={rowsLength}
          />
        </Grid>
        {pageCount > 1 && (
          <Grid justifyContent="flex-end" gap="1rem">
            <TransparentButton
              onClick={previousPage}
              disabled={!canPreviousPage}
              style={{
                marginTop: "0.3125rem",
              }}
            >
              <CaretLeft size={16} color={colors.greyDark} />
            </TransparentButton>
            {paginate.map((pageNumber: number) => (
              <TransparentButton
                key={`page_btn_${pageNumber}`}
                style={{
                  color:
                    pageNumber === pageIndex ? colors.purpleDeep : colors.grey,
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
                onClick={() => gotoPage(pageNumber)}
              >
                {pageNumber + 1}
              </TransparentButton>
            ))}
            <TransparentButton
              onClick={nextPage}
              disabled={!canNextPage}
              style={{
                marginTop: "0.375rem",
              }}
            >
              <CaretRight size={16} color={colors.greyDark} />
            </TransparentButton>
          </Grid>
        )}
      </Grid>
    </Pagination>
  );
}
