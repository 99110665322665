import { hooks as bosonHooks } from "@bosonprotocol/react-kit";
import { useMemo } from "react";
import { entityRoleToRoles } from "../../../constants/roles";
import { buildProfileFromMetadata } from "../../../utils/profile/buildProfileFromMetadata";
import { useGetBaseEntities } from "../entities/useGetBaseEntities";
export const useGetProfile = () => {
    const { address } = bosonHooks.useAccount();
    const { ipfsGateway } = bosonHooks.useIpfsContext();
    const { data: baseEntities, isLoading, isSuccess, refetch, isFetching, } = useGetBaseEntities({
        enabled: !!address,
        filter: {
            baseEntitiesFilter: {
                adminAccount: address?.toLowerCase(),
            },
        },
    });
    const entity = baseEntities?.[0];
    const metadata = entity?.metadata;
    const roles = useMemo(() => entity?.roles?.map((role) => {
        let entityRole = entityRoleToRoles[role];
        if (Array.isArray(entityRole)) {
            entityRole = entityRole[0];
        }
        return entityRole;
    }), [entity?.roles]);
    const profile = useMemo(() => {
        return ipfsGateway && metadata && roles
            ? buildProfileFromMetadata(metadata, roles, {
                ipfsGateway,
            })
            : undefined;
    }, [ipfsGateway, metadata, roles]);
    return {
        data: { profile, entity, metadata: entity?.metadata },
        isLoading,
        isSuccess,
        refetch,
        isFetching,
    };
};
