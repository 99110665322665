import { borders, breakpoint, colors } from "@fermionprotocol/react-kit";
import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled, { createGlobalStyle, css, keyframes } from "styled-components";
import { zIndex } from "styles/zIndex";

import { modelContentStyle } from "./const";
import { ModalHeader } from "./header/ModalHeader";
import { ModalType, Store } from "./types";

const slideUp = keyframes`
  from {
    bottom: -100vh;
  }
  to {
    bottom: 0;
  }
`;

const slideDown = keyframes`
  from {
    bottom: 0;
  }
  to {
    bottom: -100vh;
  }
`;

const ModalGlobalStyle = createGlobalStyle<{ $isMobileSlidingModal?: boolean }>`
  [data-testid="modal"] > *:first-child {
    border-radius: ${borders.defaultBorderRadiusPx}px;
    ${({ $isMobileSlidingModal }) =>
      $isMobileSlidingModal &&
      css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `}
  }
`;

const Root = styled.div<{
  $isMobileSlidingModal: Props["isMobileSlidingModal"];
  $isClosing: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.Modal};
  max-height: inherit;
  display: flex;
  justify-content: center;

  ${({ $isMobileSlidingModal, $isClosing }) =>
    $isMobileSlidingModal &&
    css`
      top: unset;
      width: 100%;
      bottom: ${$isClosing ? "0" : "-100vh"};
      animation: ${$isClosing ? slideDown : slideUp} 0.3s ease-out forwards;
    `}
`;

const RootBG = styled.div<{ $isClosing: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000080;
  z-index: ${zIndex.Modal - 1};
  opacity: ${({ $isClosing }) => ($isClosing ? 0 : 1)};
  transition: opacity 0.3s ease-out;
`;

const sizeToMargin = {
  xs: {
    xs: "0",
    s: "4rem 12rem",
    m: "4rem 20rem",
    l: "4rem 24rem",
    xl: "4rem 30rem",
  },
  s: {
    xs: "0",
    s: "4rem 10rem",
    m: "4rem 19rem",
    l: "4rem 22rem",
    xl: "4rem 30rem",
  },
  m: {
    xs: "0",
    s: "4rem 6rem",
    m: "4rem 12rem",
    l: "4rem 16rem",
    xl: "4rem 25.75rem",
  },
  l: {
    xs: "0",
    s: "4rem",
    m: "4rem 8rem",
    l: "4rem 10rem",
    xl: "4rem 14rem",
  },
  auto: {
    xs: "4rem auto",
    s: "4rem auto",
    m: "4rem auto",
    l: "4rem auto",
    xl: "4rem auto",
  },
  fullscreen: {
    xs: "0 auto",
    s: "0 auto",
    m: "0 auto",
    l: "0 auto",
    xl: "0 auto",
  },
} as const;

const Wrapper = styled.div<{
  $modalType: ModalType | string;
  $size: Props["size"];
  $maxWidths: Props["maxWidths"];
  $minWidths: Props["minWidths"];
  $isMobileSlidingModal: Props["isMobileSlidingModal"];
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${zIndex.Modal};
  color: ${colors.black};
  background-color: ${colors.white};
  border: ${colors.border};

  ${({ $maxWidths, $minWidths }) => css`
    ${breakpoint.xxs} {
      max-width: ${$maxWidths?.["xxs"]};
      min-width: ${$minWidths?.["xxs"]};
    }
    ${breakpoint.xs} {
      max-width: ${$maxWidths?.["xs"]};
      min-width: ${$minWidths?.["xs"]};
    }
    ${breakpoint.s} {
      max-width: ${$maxWidths?.["s"]};
      min-width: ${$minWidths?.["s"]};
    }
    ${breakpoint.m} {
      max-width: ${$maxWidths?.["m"]};
      min-width: ${$minWidths?.["m"]};
    }
    ${breakpoint.l} {
      max-width: ${$maxWidths?.["l"]};
      min-width: ${$minWidths?.["l"]};
    }
    ${breakpoint.xl} {
      max-width: ${$maxWidths?.["xl"]};
      min-width: ${$minWidths?.["xl"]};
    }
  `}

  ${({ $size, $isMobileSlidingModal }) => {
    if ($isMobileSlidingModal) {
      return css`
        margin: 0;
      `;
    }

    return css`
      margin: ${sizeToMargin[$size as keyof typeof sizeToMargin]["xs"] || 0};

      ${breakpoint.s} {
        max-height: calc(100% - 4rem);
        margin: ${sizeToMargin[$size as keyof typeof sizeToMargin]["s"] ||
        "4rem"};
      }
      ${breakpoint.m} {
        margin: ${sizeToMargin[$size as keyof typeof sizeToMargin]["m"] ||
        "4rem 8rem"};
      }
      ${breakpoint.l} {
        margin: ${sizeToMargin[$size as keyof typeof sizeToMargin]["l"] ||
        "4rem 10rem"};
      }
      ${breakpoint.xl} {
        margin: ${sizeToMargin[$size as keyof typeof sizeToMargin]["xl"] ||
        "4rem 14rem"};
      }
    `;
  }}

  ${({ $size }) =>
    $size === "fullscreen" &&
    css`
      min-height: 100vh;
    `};
`;

const FooterWrapper = styled.div`
  border-top: 2px solid ${colors.border};
`;

const Content = styled.div`
  padding: ${modelContentStyle.padding};
  flex: 1;
  overflow-y: auto;
`;

interface Props {
  children: React.ReactNode;
  hideModal: (data?: unknown | undefined | null) => void;
  headerComponent?: ReactNode;
  footerComponent?: ReactNode;
  contentStyle?: CSSProperties;
  size: NonNullable<Store["modalSize"]>;
  maxWidths: Store["modalMaxWidth"];
  minWidths: Store["modalMinWidth"];
  closable?: boolean;
  modalType: ModalType;
  isMobileSlidingModal?: boolean;
  hideHeader?: boolean;
}

export function Modal({
  children,
  hideModal,
  headerComponent: HeaderComponent,
  footerComponent: FooterComponent,
  size,
  maxWidths,
  minWidths,
  contentStyle,
  closable = true,
  modalType,
  isMobileSlidingModal = false,
  hideHeader = false,
}: Props) {
  const [isClosing, setIsClosing] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        hideModal();
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isClosing, hideModal]);

  const handleOnClose = () => {
    if (closable) {
      setIsClosing(true);
    }
  };

  if (!isVisible) {
    return null;
  }

  return createPortal(
    <>
      <ModalGlobalStyle $isMobileSlidingModal={isMobileSlidingModal} />
      <Root
        data-testid="modal"
        $isMobileSlidingModal={isMobileSlidingModal}
        $isClosing={isClosing}
      >
        <Wrapper
          $size={size}
          $modalType={modalType}
          $maxWidths={maxWidths}
          $minWidths={minWidths}
          $isMobileSlidingModal={isMobileSlidingModal}
        >
          {!hideHeader && (
            <ModalHeader
              headerComponent={HeaderComponent}
              handleOnClose={handleOnClose}
              closable={closable}
            />
          )}
          <Content style={contentStyle}>{children}</Content>
          {FooterComponent && <FooterWrapper>{FooterComponent}</FooterWrapper>}
        </Wrapper>
        <RootBG onClick={handleOnClose} $isClosing={isClosing} />
      </Root>
    </>,
    document.body,
  );
}
