import * as Yup from "yup";
import { productCategoriesOptions, } from "../constants/productCategories";
import { validationMessage } from "./common";
export const getCategorySelectValidationSchema = ({ required, }) => {
    if (required) {
        return Yup.object({
            value: Yup.mixed().oneOf(productCategoriesOptions.map((category) => category.value)),
            label: Yup.string(),
        })
            .required(validationMessage.required)
            .nullable(true)
            .default(null);
    }
    return Yup.object({
        value: Yup.mixed().oneOf(productCategoriesOptions.map((category) => category.value)),
        label: Yup.string(),
    })
        .nullable(true)
        .default(null);
};
