import { Grid } from "@bosonprotocol/react-kit";
import { borders, colors, SectionHeader } from "@fermionprotocol/react-kit";
import { SquaresFour } from "phosphor-react";
import styled from "styled-components";

interface Props {
  emptyText: string;
}

const BoxContainer = styled(Grid)`
  border: 1px solid ${colors.velvetBorder};
  border-radius: ${borders.big}px;
  min-width: 100%;
  padding: 2.5rem;
`;

const IconWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const EmptyTable: React.FC<Props> = ({ emptyText }) => {
  return (
    <BoxContainer
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      marginBottom={"2rem"}
      padding="2.5rem"
    >
      <IconWrapper>
        <SquaresFour size={32} color={colors.pink} weight="thin" />
      </IconWrapper>
      <SectionHeader fontSize={"1.125rem"} color={colors.velvet}>
        {emptyText}
      </SectionHeader>
    </BoxContainer>
  );
};
