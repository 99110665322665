import {
  AccountDrawer,
  ChainSelector,
  Grid,
  hooks as bosonHooks,
  Portal,
  Typography,
} from "@bosonprotocol/react-kit";
import { getEnvConfigs } from "@fermionprotocol/core-sdk";
import { borders, colors, hooks } from "@fermionprotocol/react-kit";
import { FermionConnectWallet } from "components/connection/FermionConnectWallet";
import { exploreAssetsLabel } from "components/homepage/ExploreAssetsLink";
import { CustomLink } from "components/navigation/CustomLink";
import { TransparentButton } from "components/table/TableStyles";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { fermionProtocolRoutes, fermionRoutes } from "router/routeNames";
import styled from "styled-components";
import { maxWidthStyles } from "styles/layout";
import { errorButtonTheme, successButtonTheme } from "styles/themes";
import { zIndex } from "styles/zIndex";

import fermionLogo from "../../assets/fermionlogoblack.svg";
import { MobileMenu } from "./MobileMenu";

const buyCryptoTheme = {
  ...successButtonTheme,
  gap: "0.25rem",
};

const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  background-color: ${colors.white};
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid ${colors.border};
  z-index: ${zIndex.Header};
  display: flex;
  justify-content: center;
  > * {
    ${maxWidthStyles};
  }
`;

const navItems = [
  { label: "Agent Hub", route: fermionRoutes.hub },
  {
    label: exploreAssetsLabel,
    route: fermionProtocolRoutes.exploreAssets,
    external: true,
  },
  { label: "Buyer Hub", route: fermionRoutes.buyer },
];

export const Header: React.FC = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { config } = hooks.useFermionConfigContext();
  const [clickedOnWalletOption, setClickedOnWalletOption] =
    useState<boolean>(false);
  const navigate = useCustomNavigate();
  const windowBreakpoints = bosonHooks.useBreakpoints();
  const isMobile = windowBreakpoints.isLteM;

  const handleNavigation = (item: { route: string; external?: boolean }) => {
    if (item.external) {
      window.open(item.route, "_blank", "noopener,noreferrer");
    } else {
      navigate({ pathname: item.route });
    }
  };

  return (
    <>
      <div
        style={{
          height: headerRef.current
            ? window.getComputedStyle(headerRef.current).height
            : "5.625rem",
        }}
      />
      <StyledHeader ref={headerRef}>
        <Grid justifyContent="space-between">
          <Grid marginLeft={isMobile ? "1.9375rem" : 0}>
            <CustomLink to={fermionRoutes.home}>
              <img src={fermionLogo} className="logo" alt="Fermion logo" />
            </CustomLink>
          </Grid>
          {isMobile ? (
            <>
              <Grid justifyContent="flex-end" marginRight={"2.1875rem"}>
                <ChainSelector
                  leftAlign={true}
                  backgroundColor={colors.pink}
                  config={config}
                  getEnvConfigsFilteredByEnv={getEnvConfigs}
                />
                <FermionConnectWallet
                  clickedOnWalletOption={clickedOnWalletOption}
                />
              </Grid>
              <MobileMenu navItems={navItems} />
            </>
          ) : (
            <>
              <Grid justifyContent="center" gap="1rem">
                {navItems.map((item) => (
                  <TransparentButton
                    key={item.label}
                    onClick={() => handleNavigation(item)}
                  >
                    <Typography
                      fontSize={"0.875rem"}
                      fontWeight={500}
                      color={colors.velvetLight}
                    >
                      {item.label}
                    </Typography>
                  </TransparentButton>
                ))}
              </Grid>
              <Grid justifyContent="flex-end">
                <ChainSelector
                  leftAlign={true}
                  backgroundColor={colors.pink}
                  config={config}
                  getEnvConfigsFilteredByEnv={getEnvConfigs}
                />
                <FermionConnectWallet
                  clickedOnWalletOption={clickedOnWalletOption}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Portal>
          <AccountDrawer
            defaultTokens={config.defaultTokens}
            backgroundColor={colors.white}
            buyCryptoTheme={buyCryptoTheme}
            disconnectBorderRadius={`${borders.defaultBorderRadiusPx}px`}
            disconnectBackgroundColor={colors.pink}
            disconnectColor={colors.white}
            onUserDisconnect={() => {
              setClickedOnWalletOption(false);
            }}
            walletModalProps={{
              connectionErrorProps: {
                tryAgainTheme: errorButtonTheme,
                backToWalletSelectionTheme: errorButtonTheme,
              },
              optionProps: {
                borderRadius: `1.25rem`,
                iconBorderRadius: `${borders.defaultBorderRadiusPx}px`,
                backgroundColor: colors.purple,
                color: colors.white,
                hoverFocusBackgroundColor: colors.black,
                hoverColor: colors.white,
                onOptionClick: () => {
                  setClickedOnWalletOption(true);
                },
              },
              withMagicLogin: false,
              PrivacyPolicy: () => (
                <Typography fontSize="0.75rem" display="block">
                  By connecting a wallet, you agree to Fermion App's{" "}
                  <Link
                    to={fermionRoutes.termsAndConditions}
                    style={{ fontSize: "inherit" }}
                  >
                    Terms & Conditions
                  </Link>{" "}
                  and consent to its{" "}
                  <Link
                    to={fermionRoutes.privacyPolicy}
                    style={{ fontSize: "inherit" }}
                  >
                    Privacy Policy
                  </Link>
                  . (Last Updated 3 October 2024)
                </Typography>
              ),
            }}
          />
        </Portal>
      </StyledHeader>
    </>
  );
};
