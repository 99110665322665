import {
  Grid,
  hooks as bosonHooks,
  Typography,
} from "@bosonprotocol/react-kit";
import { FermionConnectWallet } from "components/connection/FermionConnectWallet";
import { useModal } from "components/modal/useModal";
import { useEffect } from "react";

import { AddRoleToProfileFlow } from "./AddRoleToProfileFlow";

export const AddRoleToProfileModal = () => {
  const { address } = bosonHooks.useAccount();
  const { updateProps, store } = useModal();
  useEffect(() => {
    if (!address) {
      updateProps<"ADD_ROLE_TO_PROFILE">({
        ...store,
        modalProps: {
          ...store.modalProps,
          headerComponent: (
            <Grid flexDirection="column" alignItems="flex-start">
              <Typography tag="h3" fontWeight={500} marginBottom="0.5rem">
                Connect your wallet
              </Typography>
            </Grid>
          ),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  if (!address) {
    return (
      <Grid gap="1rem" flexDirection="column">
        <Typography>
          Add a role to your profile you must first connect your wallet
        </Typography>
        <FermionConnectWallet />
      </Grid>
    );
  }
  return <AddRoleToProfileFlow />;
};
