import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const StyledDiv = styled.div `
  margin-left: ${({ $marginLeft }) => $marginLeft || "0"};
  margin-right: ${({ $marginRight }) => $marginRight || "0"};
  padding-left: ${({ $paddingLeft }) => $paddingLeft || "0"};
  padding-right: ${({ $paddingRight }) => $paddingRight || "0"};
  max-width: ${({ $maxWidth }) => $maxWidth || "none"};
  text-align: ${({ $textAlign }) => $textAlign || "left"};
  display: inline-block;
  width: ${({ $width }) => $width || "100%"};
  div {
    display: inline-block;
  }
`;
export const ColumnHeaderContainer = ({ marginLeft, marginRight, paddingLeft, paddingRight, maxWidth, textAlign, width, children, }) => {
    return (_jsx(StyledDiv, { "$marginLeft": marginLeft, "$marginRight": marginRight, "$paddingLeft": paddingLeft, "$paddingRight": paddingRight, "$maxWidth": maxWidth, "$textAlign": textAlign, "$width": width, children: children }));
};
