import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridFour, ListBullets } from "phosphor-react";
import { styled } from "styled-components";
import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";
const Button = styled.button `
  line-height: 0;
  padding: 0.25rem 0.375rem;
  background-color: ${({ $isSelected }) => $isSelected ? colors.white : "transparent"};
  cursor: pointer;
  border-radius: ${borders.small}px;
  border: ${({ $isSelected }) => $isSelected ? `1px solid ${colors.velvetBorder}` : "1px solid transparent"};
`;
const Wrapper = styled.div `
  width: fit-content;
  display: flex;
  gap: 0.25rem;
  padding: 0.38rem;
  background-color: ${colors.champagne};
  border: 1px solid ${colors.velvetBorder};
  border-radius: ${borders.medium}px;
`;
export const TableViewSelector = ({ isGridSelected, onGridSelected, }) => {
    return (_jsxs(Wrapper, { children: [_jsx(Button, { "$isSelected": isGridSelected, onClick: () => onGridSelected(true), children: _jsx(GridFour, { size: 16 }) }), _jsx(Button, { "$isSelected": !isGridSelected, onClick: () => onGridSelected(false), children: _jsx(ListBullets, { size: 16 }) })] }));
};
