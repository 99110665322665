import * as Yup from "yup";
export const SUPPORTED_FILE_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/webp",
];
export const validationOfRequiredIpfsImage = () => Yup.mixed()
    .test("fileUploaded", "You need to upload an image", function (value) {
    return !!(value && value?.[0]?.src);
})
    .test("FILE_FORMAT", "Image has unsupported format!", (value) => {
    return (!(value && value.length > 0) ||
        (value &&
            value.length !== 0 &&
            (value?.[0]?.src.startsWith("ipfs://") ||
                SUPPORTED_FILE_FORMATS.includes((value?.[0]?.type ||
                    "")))));
});
export const validationOfIpfsImage = () => Yup.mixed();
