import { useContext } from "react";

import { FermionConfigContext } from "../../components/config/FermionConfigContext";

export const useFermionConfigContext = () => {
  const contextValue = useContext(FermionConfigContext);
  if (!contextValue) {
    throw new Error(
      "You need to use FermionConfigProvider before using useFermionConfigContext",
    );
  }
  return contextValue;
};
