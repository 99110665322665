import { Loading } from "@bosonprotocol/react-kit";
import { Suspense } from "react";

import { App } from "../App";
import { IRoute } from "./types";

export type SuspenseRouteProps = IRoute;
export function SuspenseRoute({ component: Component }: SuspenseRouteProps) {
  return (
    <Suspense
      fallback={
        <App>
          <Loading />
        </App>
      }
    >
      <App>
        <Component />
      </App>
    </Suspense>
  );
}
