import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "@bosonprotocol/react-kit";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { CaretDown } from "phosphor-react";
import { createGlobalStyle } from "styled-components";
import { colors } from "../../../styles/colors";
import { Button } from "../../buttons/Button";
const GlobalStyle = createGlobalStyle `
.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 7.5rem;
  background-color: ${colors.white};
  border: 1px solid ${colors.border};
  border-radius: 0.375rem;
  padding: 0.3125rem;
  box-shadow: 0px 0.625rem 2.375rem -0.625rem rgba(22, 23, 24, 0.35), 0px 0.625rem 1.25rem -0.9375rem rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  font-size: 0.8125rem;
  line-height: 1;
  color: ${colors.black};
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  height: 1.5625rem;
  position: relative;
  padding-right: 1.5625rem;
  user-select: none;
  outline: none;
}
.DropdownMenuSubTrigger[data-state='open'] {
  background-color: ${colors.blue};
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  color: ${colors.greyLight};
  pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  background-color: ${colors.greyLight};
  color: ${colors.greyDark};
}

.DropdownMenuLabel {
  padding-left: 1.5625rem;
  font-size: 0.75rem;
  line-height: 1.5625rem;
  color: ${colors.black};
}

.DropdownMenuSeparator {
  height: 1px;
  margin: 0.3125rem;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 1.5625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: ${colors.white};
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 2.1875rem;
  width: 2.1875rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet11);
  background-color: ${colors.white};
  box-shadow: 0 0.125rem 0.625rem ${colors.black};
}
.IconButton:hover {
}
.IconButton:focus {
  box-shadow: 0 0 0 0.125rem black;
}

.RightSlot {
  margin-left: auto;
  padding-left: 1.25rem;
}
[data-highlighted] > .RightSlot {
  color: ${colors.white};
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(0.125rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-0.125rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-0.125rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(0.125rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
  `;
export const Actions = ({ label, items }) => {
    return (_jsxs("div", { children: [_jsx(GlobalStyle, {}), _jsxs(DropdownMenu.Root, { children: [_jsx(DropdownMenu.Trigger, { asChild: false, children: _jsx(Button, { variant: "transparent", size: "small", children: _jsxs(Grid, { gap: "0.5rem", children: [label, " ", _jsx(CaretDown, { size: 16, color: colors.velvet })] }) }) }), _jsx(DropdownMenu.Portal, { children: _jsx(DropdownMenu.Content, { className: "DropdownMenuContent", sideOffset: 5, children: items.map((item) => {
                                return (_jsx(DropdownMenu.Item, { className: "DropdownMenuItem", onClick: (e) => {
                                        e.stopPropagation();
                                    }, children: item.content }, item.key));
                            }) }) })] })] }));
};
