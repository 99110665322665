import { Grid } from "@bosonprotocol/react-kit";
import {
  Button,
  Profile,
  profileValidationSchema,
  SimpleError,
} from "@fermionprotocol/react-kit";
import * as Sentry from "@sentry/browser";
import { ModalTitleHeader } from "components/modal/header/ModalTitleHeader";
import { useModal } from "components/modal/useModal";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";

import { ProfileFormFields } from "../../common/ProfileFormFields";

interface Props {
  initial: Profile;
  onSubmit: (createValues: Profile) => Promise<void>;
}

export function EditProfileForm({ initial, onSubmit }: Props) {
  const [error, setError] = useState<Error | null>(null);
  const { updateProps, store } = useModal();
  useEffect(() => {
    updateProps({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: <ModalTitleHeader>Edit your profile</ModalTitleHeader>,
      },
      modalMaxWidth: {
        s: "550px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Formik<Profile>
      validationSchema={profileValidationSchema}
      initialValues={initial}
      onSubmit={async (values) => {
        try {
          setError(null);
          await onSubmit(values);
        } catch (err) {
          console.error(err);
          Sentry.captureException(error);
          setError(err as Error);
        }
      }}
    >
      {() => {
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Form>
            <ProfileFormFields>
              {error ? <SimpleError /> : <></>}
              <Grid margin="2rem 0 0 0" justifyContent="flex-end" gap="2rem">
                <Button variant="pink" type="submit">
                  Next
                </Button>
              </Grid>
            </ProfileFormFields>
          </Form>
        );
      }}
    </Formik>
  );
}
