import { hooks } from "@fermionprotocol/react-kit";
import { queryClient } from "app/QueryClient";
import { useEffect } from "react";

export function InvalidateQueries(): null {
  const { config } = hooks.useFermionConfigContext();
  useEffect(() => {
    queryClient.invalidateQueries();
  }, [config.configId]);
  return null;
}
