import * as Yup from "yup";

import { validationMessage } from "./common";
import { getStringValidation } from "./string";

export const getSelectOptionValidation = ({
  required,
  default: defaultValue = undefined,
  nullable,
  oneOfValues,
}: {
  required: boolean;
  nullable?: boolean;
  oneOfValues?: string[];
  default?: null | { value: string; label: string };
}) => {
  let selectOptionValidation = Yup.object({
    value: oneOfValues
      ? getStringValidation({ required }).oneOf(oneOfValues)
      : getStringValidation({ required }),
    label: getStringValidation({ required }),
  }).default(defaultValue);

  if (required) {
    selectOptionValidation = selectOptionValidation.required(
      validationMessage.required,
    );
  }
  if (nullable) {
    selectOptionValidation = selectOptionValidation.nullable(nullable);
  }

  return selectOptionValidation;
};
