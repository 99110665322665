import { colors } from "@fermionprotocol/react-kit";
import styled from "styled-components";

export const FlexContainer = styled.span`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  svg {
    margin-left: 0.9375rem;
  }
  span {
    display: flex;
    align-items: center;
  }
  a {
    width: 100%;
    height: 2.3125rem;
    display: flex;
    align-items: center;
    padding-left: 0.625rem;
    /* TODO: negative margin */
    margin-left: -1.25rem;
    span {
      margin-left: -0.625rem;
      margin-right: 0.3125rem;
    }
  }
  a.active {
    background: ${colors.pinkLightest};
  }
`;
