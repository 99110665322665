import { websitePattern } from "@bosonprotocol/react-kit";
import * as Yup from "yup";
import { bridgingRoleOptions } from "../constants/bridgingRoles";
import { allRoles, RoleId } from "../constants/roles";
import { shippingMethodsOptions } from "../constants/shippingMethods";
import { isTruthy } from "../types/helpers";
import { getCategorySelectValidationSchema } from "./category";
import { validationMessage } from "./common";
import { getFeePriceBandsTokensValidation, getFeePriceBandsValidation, } from "./feePriceBands";
import { throwNestedValidationError } from "./helpers/validationError";
import { getLocationSchema } from "./location";
import { validationOfRequiredIpfsImage } from "./media";
import { getNumberValidation } from "./number";
import { getSelectOptionValidation } from "./selectOption";
import { getSelfVerifierOrThirdPartySelectValidationSchema } from "./selfVerifierOrThirdParty";
import { getStorageFeesPeriodValidation, getStorageFeesTokensValidation, getStorageFeesValidation, } from "./storageFees";
import { getStringValidation } from "./string";
const testIfRole = function ({ that, role, bridgerRole, validateValueFn, }) {
    const roles = getRoleFromOptions(that.options);
    const shouldCheckBecauseHasBridgerRole = role === RoleId["BRIDGER"]
        ? bridgerRole
            ? getBridgingRolesFromOptions(that.options).some((selectedBridgingRoles) => selectedBridgingRoles.value === bridgerRole)
            : true
        : true;
    const isValid = roles.includes(role) && shouldCheckBecauseHasBridgerRole
        ? validateValueFn()
        : true;
    console.log({
        path: that.path,
        that,
        role,
        roles,
        bridgerRole,
        bridgingRoles: getBridgingRolesFromOptions(that.options),
        isValid,
    });
    return isValid;
};
const getRoleFromOptions = (options) => {
    return options?.from?.at(-1)?.value?.roles;
};
const getBridgingRolesFromOptions = (options) => {
    return options?.from?.at(-1)?.value?.bridger
        ?.bridgingRoles;
};
const getVerifierValidation = ({ isBridger }) => {
    const validationRole = RoleId.VERIFIER;
    const roleToCheck = isBridger ? RoleId.BRIDGER : validationRole;
    const bridgerRole = isBridger ? validationRole : null;
    return Yup.object({
        category: getCategorySelectValidationSchema({ required: false }).test({
            name: "verifierCategory",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getCategorySelectValidationSchema({
                        required: true,
                    }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
        authenticationReport: getStringValidation({ required: false }).test({
            name: "verifierAuthenticationReport",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getStringValidation({ required: true }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
        valuationCertificate: getStringValidation({ required: false }).test({
            name: "verifierValuationCertificate",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getStringValidation({ required: true }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
        feePriceBandsTokens: getFeePriceBandsTokensValidation({
            required: false,
        }).test({
            name: "feePriceBandsTokens",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getFeePriceBandsTokensValidation({ required: true }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
        feePriceBands: getFeePriceBandsValidation({ required: false }).test({
            name: "feePriceBands",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getFeePriceBandsValidation({ required: true }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
        selfOrThirdParty: getSelfVerifierOrThirdPartySelectValidationSchema({
            required: false,
        }).test({
            name: "selfOrThirdParty",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getSelfVerifierOrThirdPartySelectValidationSchema({
                        required: true,
                    }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
    });
};
const getCustodianValidation = ({ isBridger }) => {
    const validationRole = RoleId.CUSTODIAN;
    const roleToCheck = isBridger ? RoleId.BRIDGER : validationRole;
    const bridgerRole = isBridger ? validationRole : null;
    return Yup.object({
        category: getCategorySelectValidationSchema({ required: false }).test({
            name: "custodianCategory",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getCategorySelectValidationSchema({
                        required: true,
                    }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
        locationName: getStringValidation({ required: false }).test({
            name: "locationName",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => !!value,
                });
            },
        }),
        location: getLocationSchema({ required: false }).test({
            name: "custodianLocation",
            message: validationMessage.required,
            test: function (value, context) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => {
                        throwNestedValidationError.call(this, () => getLocationSchema({ required: true }).validateSync(value, {
                            abortEarly: false,
                            context,
                        }));
                        return !!value;
                    },
                });
            },
        }),
        insuredValue: getNumberValidation({
            required: false,
            min: 0.01,
        }).test({
            name: "insuredValue",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getNumberValidation({
                        required: true,
                        min: 0.01,
                    }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
        storageFeesTokens: getStorageFeesTokensValidation({
            required: false,
        }).test({
            name: "storageFeesTokens",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getStorageFeesTokensValidation({ required: true }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
        storageFeesPeriod: getStorageFeesPeriodValidation({ required: false }).test({
            name: "storageFeesPeriod",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getStorageFeesPeriodValidation({ required: true }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
        storageFees: getStorageFeesValidation({ required: false }).test({
            name: "storageFees",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getStorageFeesValidation({ required: true }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
        shippingMethod: getSelectOptionValidation({
            required: false,
            default: null,
            nullable: true,
        }).test({
            name: "shippingMethod",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: roleToCheck,
                    bridgerRole,
                    validateValueFn: () => getSelectOptionValidation({
                        required: true,
                        nullable: true,
                        default: null,
                        oneOfValues: shippingMethodsOptions.map((shippingMethodOption) => shippingMethodOption.value),
                    }).isValidSync(value, {
                        abortEarly: true,
                    }),
                });
            },
        }),
    });
};
export const profileValidationSchema = Yup.object({
    roles: Yup.array(Yup.mixed()
        .oneOf(allRoles)
        .required(validationMessage.required))
        .min(1, validationMessage.min(1))
        .required(validationMessage.required),
    logo: validationOfRequiredIpfsImage(),
    coverPicture: validationOfRequiredIpfsImage(),
    name: getStringValidation({ required: true }),
    description: getStringValidation({ required: true }),
    email: getStringValidation({ required: true }).email("Must be an email"),
    website: getStringValidation({ required: true }).matches(new RegExp(websitePattern), validationMessage.notUrl),
    legalTradingName: getStringValidation({ required: false }),
    verifier: getVerifierValidation({ isBridger: false }),
    custodian: getCustodianValidation({ isBridger: false }),
    bridger: Yup.object({
        bridgingRoles: Yup.array(Yup.mixed()
            .nullable(true)
            .test({
            name: "bridgingRoles",
            message: validationMessage.required,
            test: function (selectedBridgingRole) {
                return testIfRole({
                    that: this,
                    role: RoleId.BRIDGER,
                    bridgerRole: null,
                    validateValueFn: () => !!selectedBridgingRole &&
                        bridgingRoleOptions.some((option) => option.value === selectedBridgingRole.value),
                });
            },
        })).test({
            name: "bridgingRoles",
            message: validationMessage.required,
            test: function (value) {
                return testIfRole({
                    that: this,
                    role: RoleId.BRIDGER,
                    bridgerRole: null,
                    validateValueFn: () => !!value?.filter(isTruthy).length,
                });
            },
        }),
    }).concat(Yup.object({
        verifier: Yup.object({
            name: getStringValidation({
                required: false,
            }).test({
                name: "verifierName",
                message: validationMessage.required,
                test: function (value) {
                    return testIfRole({
                        that: this,
                        role: RoleId.BRIDGER,
                        bridgerRole: RoleId.VERIFIER,
                        validateValueFn: () => getStringValidation({
                            required: true,
                        }).isValidSync(value, {
                            abortEarly: true,
                        }),
                    });
                },
            }),
        }).concat(getVerifierValidation({ isBridger: true })),
        custodian: Yup.object({
            name: getStringValidation({
                required: false,
            }).test({
                name: "custodianName",
                message: validationMessage.required,
                test: function (value) {
                    return testIfRole({
                        that: this,
                        role: RoleId.BRIDGER,
                        bridgerRole: RoleId.CUSTODIAN,
                        validateValueFn: () => getStringValidation({
                            required: true,
                        }).isValidSync(value, {
                            abortEarly: true,
                        }),
                    });
                },
            }),
        }).concat(getCustodianValidation({ isBridger: true })),
    })),
    businessLocation: getLocationSchema({ required: true }), // TODO: should NOT be required?
    websiteForFermionIdentity: getStringValidation({ required: true }),
    businessPhoneNumber: getStringValidation({ required: true }),
});
