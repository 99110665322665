import { TransactionReceipt, TransactionResponse } from "@bosonprotocol/common";
import { Action, CtaButtonProps } from "@bosonprotocol/react-kit";
import { CoreSDK } from "@fermionprotocol/core-sdk";
import { errors, providers } from "ethers";
import { useState } from "react";

export function useCtaClickHandler<T>({
  waitBlocks,
  coreSdk,
  actions,
  onPendingSignature,
  onPendingTransaction,
  onCancelledTransaction,
  onRepricedTransaction,
  onError,
  onSuccess,
  successPayload,
}: {
  waitBlocks: number;
  coreSdk: CoreSDK;
  actions: Action[];
  successPayload: T | ((receipt: providers.TransactionReceipt) => T);
} & Pick<
  CtaButtonProps<T>,
  | "onPendingSignature"
  | "onCancelledTransaction"
  | "onRepricedTransaction"
  | "onPendingTransaction"
  | "onError"
  | "onSuccess"
>) {
  const useMetaTx = coreSdk.isMetaTxConfigSet;
  const [isLoading, setIsLoading] = useState(false);

  const clickHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    let txResponse: TransactionResponse | undefined = undefined;
    let receipt: TransactionReceipt | undefined = undefined;

    try {
      setIsLoading(true);

      for (const action of actions) {
        const {
          name,
          writeContractFn,
          shouldActionRun = () => Promise.resolve(true),
        } = action;

        if (!(await shouldActionRun())) {
          continue;
        }

        onPendingSignature?.(name);

        txResponse = await writeContractFn();

        if (txResponse) {
          try {
            onPendingTransaction?.(txResponse.hash, useMetaTx, name);
            receipt = await txResponse.wait(waitBlocks);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // Handle transaction that was replaced, canceled or repriced.
            // See https://docs.ethers.io/v5/api/utils/logger/#errors--transaction-replaced
            // for details.
            if (error.code === errors.TRANSACTION_REPLACED) {
              // Transaction was replaced or cancelled
              if (error.cancelled) {
                if (onCancelledTransaction) {
                  onCancelledTransaction(
                    txResponse.hash,
                    error.replacement,
                    useMetaTx,
                  );
                } else {
                  throw error;
                }
              } else {
                // Transaction was repriced, i.e. speed up
                onRepricedTransaction?.(
                  txResponse.hash,
                  error.replacement,
                  error.receipt,
                  useMetaTx,
                  name,
                );
                receipt = error.receipt;
              }
            } else {
              throw error;
            }
          }
        }
      }

      if (receipt) {
        const payload =
          successPayload instanceof Function
            ? successPayload(receipt as providers.TransactionReceipt)
            : successPayload;
        onSuccess?.(receipt as providers.TransactionReceipt, payload);
      }
    } catch (error) {
      onError?.(
        typeof error === "object"
          ? ((await coreSdk.parseError(error as object)) as Error)
          : (error as Error),
        {
          txResponse: txResponse as providers.TransactionResponse,
        },
      );
    } finally {
      setIsLoading(false);
    }
  };

  return { clickHandler, isLoading };
}
