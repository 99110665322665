import { EvaluationMethod, TokenType } from "@bosonprotocol/common";
import { BuyerTransferInfo } from "@bosonprotocol/react-kit";
const yesOrNoOptions = [
    {
        value: "false",
        label: "No",
    },
    {
        value: "true",
        label: "Yes",
    },
];
export const OPTIONS_TOKEN_GATED = [...yesOrNoOptions];
export var TokenTypes;
(function (TokenTypes) {
    TokenTypes["erc20"] = "erc20";
    TokenTypes["erc721"] = "erc721";
    TokenTypes["erc1155"] = "erc1155";
})(TokenTypes || (TokenTypes = {}));
export const TokenTypeEnumToString = {
    [TokenType.FungibleToken]: TokenTypes.erc20,
    [TokenType.NonFungibleToken]: TokenTypes.erc721,
    [TokenType.MultiToken]: TokenTypes.erc1155,
};
export const TOKEN_TYPES = [
    {
        value: TokenTypes.erc20,
        label: "ERC20",
    },
    {
        value: TokenTypes.erc721,
        label: "ERC721",
    },
    {
        value: TokenTypes.erc1155,
        label: "ERC1155",
    },
];
export const NFT_TOKEN_TYPES = [
    {
        value: TokenTypes.erc721,
        label: "ERC721",
    },
    {
        value: TokenTypes.erc1155,
        label: "ERC1155",
    },
];
export const BUYER_TRANSFER_INFO_OPTIONS = [
    {
        value: BuyerTransferInfo.email,
        label: "E-mail",
    },
    {
        value: BuyerTransferInfo.walletAddress,
        label: "Wallet address",
    },
];
export const TOKEN_CRITERIA = [
    {
        value: "minbalance",
        label: "Collection balance",
        method: EvaluationMethod.Threshold,
    },
    {
        value: "tokenid",
        label: "Specific token",
        method: EvaluationMethod.TokenRange,
    },
];
// TODO: export const OPTIONS_COUNTRIES = countries;
export const OPTIONS_LENGTH = [
    {
        value: "cm",
        label: "Centimetres",
    },
    {
        value: "m",
        label: "Metres",
    },
    {
        value: "in",
        label: "Inches",
    },
    {
        value: "ft",
        label: "Feet",
    },
];
export const OPTIONS_WEIGHT = [
    {
        value: "g",
        label: "Gram",
    },
    {
        value: "kg",
        label: "Kilogram",
    },
];
export var ProductTypeTypeValues;
(function (ProductTypeTypeValues) {
    ProductTypeTypeValues["physical"] = "physical";
    ProductTypeTypeValues["phygital"] = "phygital";
})(ProductTypeTypeValues || (ProductTypeTypeValues = {}));
export var ProductTypeVariantsValues;
(function (ProductTypeVariantsValues) {
    ProductTypeVariantsValues["oneItemType"] = "oneItemType";
    ProductTypeVariantsValues["differentVariants"] = "differentVariants";
})(ProductTypeVariantsValues || (ProductTypeVariantsValues = {}));
export var TypeKeys;
(function (TypeKeys) {
    TypeKeys["Size"] = "Size";
    TypeKeys["Color"] = "Color";
})(TypeKeys || (TypeKeys = {}));
export var ImageSpecificOrAll;
(function (ImageSpecificOrAll) {
    ImageSpecificOrAll["all"] = "all";
    ImageSpecificOrAll["specific"] = "specific";
})(ImageSpecificOrAll || (ImageSpecificOrAll = {}));
export const IMAGE_SPECIFIC_OR_ALL_OPTIONS = [
    {
        value: ImageSpecificOrAll.all,
        label: "All",
    },
    {
        value: ImageSpecificOrAll.specific,
        label: "Specific",
    },
];
export const ProductMetadataAttributeKeys = {
    "Token Type": "Token Type",
    "Redeemable At": "Redeemable At",
    "Redeemable Until": "Redeemable Until",
    "Redeemable After X Days": "Redeemable After X Days",
    Seller: "Seller",
    [TypeKeys.Size]: TypeKeys.Size,
    [TypeKeys.Color]: TypeKeys.Color,
};
