import { Grid, Typography } from "@bosonprotocol/react-kit";
import {
  Button,
  colors,
  Profile,
  profileValidationSchema,
  Role,
  selfVerifierOrThirdPartyOptions,
  SimpleError,
} from "@fermionprotocol/react-kit";
import { capitalizeFirstLetter } from "@fermionprotocol/react-kit/src";
import * as Sentry from "@sentry/browser";
import { useModal } from "components/modal/useModal";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";

import { ProfileFormFields } from "../../common/ProfileFormFields";

interface Props {
  onSubmit: (createValues: Profile) => Promise<void>;
  goBack: () => void;
  role: Role;
}

export function CreateProfileForm({ onSubmit, goBack, role }: Props) {
  const [error, setError] = useState<Error | null>(null);
  const { updateProps, store } = useModal();
  useEffect(() => {
    updateProps<"CREATE_PROFILE">({
      ...store,
      modalProps: {
        ...store.modalProps,
        contentStyle: undefined,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              tag="h3"
              fontWeight={500}
              marginBottom="0.5rem"
              marginTop={0}
            >
              Create your {capitalizeFirstLetter(role)} profile
            </Typography>
            <Grid
              flexDirection="column"
              alignItems="flex-start"
              flexGrow="1"
              flexShrink="1"
              flexBasis="0"
            >
              <Typography fontWeight={400} color={colors.greyDark}>
                To begin using Fermion, create a profile via the steps below.
                The information you provide here is public and accessible on
                IPFS. Please provide business information only.Do not include
                personal information.
              </Typography>
              <Typography fontWeight={400} color={colors.greyDark}>
                Do not include personal information.
              </Typography>
            </Grid>
          </Grid>
        ),
        footerComponent: undefined,
      },
      modalMaxWidth: {
        s: "550px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const verifierInitialValues = {
    category: null,
    authenticationReport: "",
    valuationCertificate: "",
    feePriceBands: [],
    feePriceBandsTokens: [],
    selfOrThirdParty: selfVerifierOrThirdPartyOptions[0],
  } satisfies Profile["verifier"];
  const custodianInitialValues = {
    category: null,
    locationName: "",
    location: {
      streetName: "",
      number: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    insuredValue: 0.01, // TODO: change so it's not visible by default? (right now it complains as this is validated at the beginning too so it has to pass validation)
    storageFees: [],
    storageFeesTokens: [],
    storageFeesPeriod: null as unknown as undefined, // null should be valid because it's nullable and default(null)
    shippingMethod: null as unknown as undefined, // null should be valid because it's nullable and default(null)
  } satisfies Profile["custodian"];
  return (
    <Formik<Profile>
      validationSchema={profileValidationSchema}
      initialValues={{
        roles: [role],
        description: "",
        email: "",
        logo: [],
        coverPicture: [],
        name: "",
        website: "",
        businessPhoneNumber: "",
        legalTradingName: "",
        verifier: { ...verifierInitialValues },
        custodian: { ...custodianInitialValues },
        bridger: {
          bridgingRoles: [],
          verifier: {
            name: "",
            ...verifierInitialValues,
          },
          custodian: {
            name: "",
            ...custodianInitialValues,
          },
        },
        businessLocation: {
          streetName: "",
          number: "",
          city: "",
          state: "",
          zip: "",
          country: "",
        },
        websiteForFermionIdentity: "",
      }}
      onSubmit={async (values) => {
        try {
          setError(null);
          await onSubmit(values);
        } catch (err) {
          console.error(err);
          Sentry.captureException(error);
          setError(err as Error);
        }
      }}
    >
      {() => {
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Form>
            <ProfileFormFields>
              {error ? <SimpleError /> : <></>}
              <Grid
                margin="2rem 0 0 0"
                justifyContent="space-between"
                gap="2rem"
              >
                <Button variant="white" type="button" onClick={() => goBack()}>
                  Back
                </Button>
                <Button variant="pink" type="submit">
                  Next
                </Button>
              </Grid>
            </ProfileFormFields>
          </Form>
        );
      }}
    </Formik>
  );
}
