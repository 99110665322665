import { eCheckoutRequestStatus, eFNFTStatus } from "@fermionprotocol/common";
import {
  CircleWavyWarning,
  IconProps,
  Package,
  ShieldCheck,
  ShieldCheckered,
} from "phosphor-react";

import { colors } from "../../styles/colors";

interface StatusData {
  backgroundColor: (typeof colors)[keyof typeof colors];
  color: (typeof colors)[keyof typeof colors];
  icon: (props: IconProps) => JSX.Element;
  label: string;
}

export const getStatusToData = ({
  status,
  checkoutRequestStatus,
}: {
  status: eFNFTStatus;
  checkoutRequestStatus?: eCheckoutRequestStatus;
}): StatusData => {
  const data: StatusData = {
    backgroundColor: colors.blueLight,
    color: colors.blue,
    icon: (props: IconProps) => <ShieldCheck color={colors.blue} {...props} />,
    label: "",
  };

  switch (status) {
    case eFNFTStatus.Inexistent: // TODO: not in figma
      data.label = "INEXISTENT";
      break;
    case eFNFTStatus.Wrapped: // TODO: not in figma
      data.label = "WRAPPED";
      break;
    case eFNFTStatus.Unverified:
      data.label = "IN VERIF.";
      data.backgroundColor = colors.purpleLight;
      data.color = colors.purple;
      data.icon = (props: IconProps) => (
        <ShieldCheck color={colors.purple} {...props} />
      );
      break;
    case eFNFTStatus.Verified:
      data.label = "VERIFIED";
      data.backgroundColor = colors.greenLight;
      data.color = colors.green;
      data.icon = (props: IconProps) => (
        <ShieldCheck color={colors.green} {...props} />
      );
      break;
    case eFNFTStatus.CheckedIn:
      data.label = "IN CUSTODY";
      data.backgroundColor = colors.greenLight;
      data.color = colors.green;
      data.icon = (props: IconProps) => (
        <ShieldCheckered color={colors.green} {...props} />
      );
      break;
    case eFNFTStatus.CheckedOut:
      data.label = "CHECKED OUT";
      data.backgroundColor = colors.greenLight;
      data.color = colors.green;
      data.icon = (props: IconProps) => (
        <Package color={colors.green} {...props} />
      );
      break;
    case eFNFTStatus.Burned: // this is the same as eVerificationStatus.Rejected according to the core-components
      data.label = "VERIF. DENIED";
      data.backgroundColor = colors.redLight;
      data.color = colors.red;
      data.icon = (props: IconProps) => (
        <ShieldCheckered color={colors.red} {...props} />
      );
      break;
    default:
      break;
  }

  switch (checkoutRequestStatus) {
    case eCheckoutRequestStatus.CheckOutRequestCleared:
    case eCheckoutRequestStatus.CheckOutRequested:
      data.label = "CHECKOUT REQ.";
      data.backgroundColor = colors.blueLight;
      data.color = colors.blue;
      data.icon = (props: IconProps) => (
        <CircleWavyWarning color={colors.blue} {...props} />
      );
      break;
    case eCheckoutRequestStatus.None:
    case eCheckoutRequestStatus.CheckedIn: // already handled in eFNFTStatus.CheckedIn
    case eCheckoutRequestStatus.CheckedOut: // already handled in eFNFTStatus.CheckedOut
    case undefined:
      break;
  }

  return data;
};
