import DashboardIcon from "../../assets/icons/dashboard/sidebar/dashboard.svg?react";
import DollarIcon from "../../assets/icons/dashboard/sidebar/dollar.svg?react";
import { HubType } from "../hub/types";

const buyerHubPageLabels = {
  myAssets: "My Assets",
  finances: "Finances",
} as const;

export const buyerHubPageTypes: Record<
  keyof typeof buyerHubPageLabels,
  HubType
> = {
  myAssets: {
    icon: <DashboardIcon />,
    label: buyerHubPageLabels.myAssets,
    url: "assets",
  },
  finances: {
    icon: <DollarIcon />,
    label: buyerHubPageLabels.finances,
    url: "finances",
  },
} as const;
