import { MarketplaceType } from "@fermionprotocol/core-sdk";
import { useMutation } from "react-query";
import { invariant } from "../../../lib/utils/invariant";
import { createOpenSeaSDK } from "../listing/useOpenSeaListing";
import { useCoreSDKContext } from "../useCoreSDKContext";
export const useUnwrapNFT = ({ signerV6 }) => {
    const coreSDK = useCoreSDKContext();
    const unwrapNFT = async ({ offerId, tokenId, verificationTimeout, openSeaApiKey, openseaFeeRecipient, }) => {
        invariant(signerV6, "SignerV6 is required");
        const openseaSdk = coreSDK.marketplace(MarketplaceType.OPENSEA, createOpenSeaSDK(signerV6, (await coreSDK.web3Lib.getChainId()), openSeaApiKey), openseaFeeRecipient);
        // It also handle empty string
        invariant(!!tokenId, "TokenId is required to unwrap NFT");
        const fNftRange = await coreSDK.getFNFTRangesByOffers([offerId]);
        const buyerOrder = await openseaSdk.buildAdvancedOrder({
            contract: fNftRange?.[0]?.wrapperAddress,
            tokenId,
            withWrapper: true,
        });
        const data = await coreSDK.unwrapNFT(tokenId, buyerOrder, {
            useMetaTx: coreSDK.isMetaTxConfigSet,
            verificationTimeout,
        });
        return data;
    };
    return useMutation({ mutationFn: unwrapNFT, mutationKey: ["unwrapNFT"] });
};
