import styled from "styled-components";
import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";
export const TableThumbnailStyled = styled.img `
  width: 2.5rem;
  height: 2.5rem;
  border-radius: ${borders.small}px;
  margin-left: 1.5rem;
`;
export const CategoryWrapper = styled.div `
  padding: 0.25rem 1rem 0.25rem 1rem;
  border: 1px solid ${colors.hoveredShade};
  border-radius: ${borders.small}px;
  width: fit-content;
`;
