import { SelectProps } from "@bosonprotocol/react-kit";

import { RoleId } from "./roles";

export const BridgingRoleValues = {
  verifier: RoleId.VERIFIER,
  custodian: RoleId.CUSTODIAN,
} as const;

export enum BridgingRoleLabels {
  Verifier = "Verifier",
  Custodian = "Custodian",
}

export const bridgingRoleOptions = [
  {
    value: BridgingRoleValues.custodian,
    label: BridgingRoleLabels.Custodian,
  },
  {
    value: BridgingRoleValues.verifier,
    label: BridgingRoleLabels.Verifier,
  },
] as const satisfies SelectProps["options"];
