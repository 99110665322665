import { jsx as _jsx } from "react/jsx-runtime";
import { withQueryClientProvider } from "@bosonprotocol/react-kit";
import { useCoreSDKContext } from "../../../../hooks";
import { CtaButton } from "../common/CtaButton";
export const WithdrawFundsButton = withQueryClientProvider(({ accountId, variant = "pinkLight", tokensToWithdraw, treasuryAddress, ...restProps }) => {
    const coreSdk = useCoreSDKContext();
    const isMetaTx = coreSdk.isMetaTxConfigSet;
    const actions = [
        // Withdraw funds
        {
            writeContractFn: () => {
                return coreSdk.withdrawFunds(accountId, treasuryAddress, tokensToWithdraw, { useMetaTx: isMetaTx });
            },
        },
    ];
    return (_jsx(CtaButton, { variant: variant, defaultLabel: "Withdraw", successPayload: (receipt) => receipt, actions: actions, ...restProps }));
});
