import { Grid } from "@bosonprotocol/react-kit";
import {
  colors,
  CountrySelect,
  fatfMemberCountries,
  InfoBox,
  Input,
} from "@fermionprotocol/react-kit";
import { ReactNode } from "react";
import styled from "styled-components";

const gapIntraFields = "1rem";

const TwoFieldsDiv = styled.div`
  container: twofields / inline-size;
  width: 100%;

  > * {
    display: flex;
    flex-direction: column;
    gap: ${gapIntraFields};
    width: 100%;
    @container (width >= 450px) {
      flex-direction: row;

      :nth-of-type(1) {
        width: 100%;
      }
      :nth-of-type(2) {
        max-width: 7rem;
      }
    }
  }
`;

const TwoFieldsContainer = ({ children }: { children: ReactNode }) => (
  <TwoFieldsDiv>
    <div>{children}</div>
  </TwoFieldsDiv>
);

export type LocationAddressProps = {
  prefix: string;
  anyTouched: boolean;
};
export const LocationAddress = ({
  prefix,
  anyTouched,
}: LocationAddressProps) => {
  return (
    <Grid flexDirection="column" alignItems="flex-start" gap="1rem">
      <TwoFieldsContainer>
        <Grid
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Input name={`${prefix}.streetName`} placeholder="Street name" />
        </Grid>
        <Grid
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Input name={`${prefix}.number`} placeholder="Number" />
        </Grid>
      </TwoFieldsContainer>
      <Grid
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Input name={`${prefix}.city`} placeholder="City" />
      </Grid>
      <TwoFieldsContainer>
        <Grid
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Input name={`${prefix}.state`} placeholder="State" />
        </Grid>
        <Grid
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Input name={`${prefix}.zip`} placeholder="Zip" />
        </Grid>
      </TwoFieldsContainer>
      <Grid
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <CountrySelect
          name={`${prefix}.country`}
          placeholder="Country"
          countries={fatfMemberCountries}
        />
      </Grid>
      {anyTouched && (
        <InfoBox
          message="Your address will be changed for all roles in your account."
          customTheme={{
            iconProps: { color: colors.pink },
          }}
        />
      )}
    </Grid>
  );
};
