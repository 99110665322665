import { hooks as bosonHooks } from "@bosonprotocol/react-kit";
import { isTruthy } from "@fermionprotocol/react-kit";
import { useCallback, useEffect } from "react";
import { NavigateOptions, Path, useNavigate } from "react-router-dom";
import { allRoutes } from "router/routes";

import { usePendingNavigation } from "./usePendingNavigation";

export type ReturnUseCustomNavigate = (
  to: Omit<Partial<Path>, "pathname"> & {
    pathname: string;
  },
  options?: NavigateOptions,
) => void;
export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const { address } = bosonHooks.useAccount();
  const [, openAccountDrawer] = bosonHooks.useOpenAccountDrawer();
  const prevAddress = bosonHooks.usePrevious(address);
  const { pendingNavigation, setPendingNavigation } = usePendingNavigation();
  useEffect(() => {
    if (address && prevAddress !== address && pendingNavigation) {
      navigate(...pendingNavigation);
      setPendingNavigation(null);
    }
  }, [address, prevAddress, pendingNavigation, navigate, setPendingNavigation]);
  return useCallback(
    (...props) => {
      const isAuthenticated = !!address;
      const [to] = props;

      const matchingRoute = allRoutes.find((route) =>
        route.path.length === 1
          ? to.pathname === route.path
          : matchPath(route.path, to.pathname),
      );
      const requiresAuth = !!matchingRoute?.auth;
      if (requiresAuth && !isAuthenticated) {
        openAccountDrawer();
        setPendingNavigation(props);
        return;
      }
      return navigate(...props);
    },
    [address, navigate, openAccountDrawer, setPendingNavigation],
  ) as ReturnUseCustomNavigate;
};

function matchPath(pathPattern: string, url: string) {
  try {
    // Convert the path pattern into a regular expression
    const pathToRegex = pathPattern
      .replace(/:\w+/g, "([^/]+)") // Convert path parameters into regex match groups
      .replace(/\//g, "\\/"); // Escape slashes

    const regex = new RegExp(`^${pathToRegex}$`);
    const match = url.match(regex);

    if (!match) {
      return null;
    }

    // Extract param names from the pattern
    const paramNames = [...pathPattern.matchAll(/:(\w+)/g)]
      .map((match) => match[1])
      .filter(isTruthy);

    // Create an object with param names as keys and matched values as values
    const params = paramNames.reduce((acc, paramName, index) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[paramName] = match[index + 1];
      return acc;
    }, {});

    return params;
  } catch (err) {
    console.error(err);
    return null;
  }
}
