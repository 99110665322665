import { ReturnUseCustomNavigate } from "hooks/navigation/useCustomNavigate";
import { ReactNode, useState } from "react";

import { PendingNavigationContext } from "./PendingNavigationContext";

export const PendingNavigationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [pendingNavigation, setPendingNavigation] =
    useState<Parameters<ReturnUseCustomNavigate> | null>(null);
  return (
    <PendingNavigationContext.Provider
      value={{ pendingNavigation, setPendingNavigation }}
    >
      {children}
    </PendingNavigationContext.Provider>
  );
};
