import { Grid, Typography } from "@bosonprotocol/react-kit";
import {
  borders,
  breakpoint,
  Button,
  colors,
  Role,
  RoleId,
} from "@fermionprotocol/react-kit";
import { modelContentStyle } from "components/modal/const";
import { useModal } from "components/modal/useModal";
import { useCallback, useEffect, useState } from "react";
import { css, styled } from "styled-components";

import { roleToComponent, roleToIcon } from "./const";

const RoleGrid = styled(Grid)<{ $isActive: boolean }>`
  min-height: 4.5rem;
  border: 1px solid transparent;
  border-radius: ${borders.defaultBorderRadiusPx}px;
  &:hover {
    cursor: pointer;
    background-color: ${colors.greyLight};
    color: ${colors.black};
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${colors.pinkLightest};
      color: ${colors.pink};
    `}
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  ${breakpoint.s} {
    grid-template-columns: min-content 3fr;
  }

  > * {
    &:first-child {
      box-sizing: content-box;
      border-right: 1px solid ${colors.border};
      padding: 1rem 1.5rem;
    }
  }
`;

const AdvancedRolesWrapper = styled.div`
  width: 100%;
  border: 1px solid ${colors.velvetLight};
  border-radius: ${borders.medium}px;
  padding: 0.5rem;
  margin-top: 0.5rem;
`;

export type SelectRoleToCreateProps = {
  onRoleSelected: (role: Role) => void;
  goBack?: () => void;
  alreadyOwnedRoles?: Role[];
  defaultRole?: Role;
};

export const SelectRoleToCreate = ({
  onRoleSelected,
  goBack,
  alreadyOwnedRoles,
  defaultRole,
}: SelectRoleToCreateProps) => {
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(
    defaultRole,
  );
  const { updateProps, store } = useModal();
  useEffect(() => {
    updateProps<"CREATE_PROFILE">({
      ...store,
      modalProps: {
        ...store.modalProps,
        contentStyle: {
          padding: `0 ${modelContentStyle.padding}`,
        },
        headerComponent: (
          <Grid
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Typography
              tag="h3"
              marginBottom={0}
              marginTop={0}
              fontSize={"1.125rem"}
              color={colors.velvet}
            >
              Select a role you want to set up
            </Typography>
            <Typography color={colors.velvetLight} fontSize={"0.875rem"}>
              Start by selecting one role, you will be able to add additional
              roles later.
            </Typography>
          </Grid>
        ),
        footerComponent: (
          <Grid
            justifyContent={
              goBack && !defaultRole ? "space-between" : "flex-end"
            }
            gap="2rem"
            padding="1.5rem 2rem"
          >
            {goBack && !defaultRole && (
              <Button variant="white" type="button" onClick={() => goBack()}>
                Back
              </Button>
            )}
            <Button
              variant="pink"
              onClick={() => selectedRole && onRoleSelected(selectedRole)}
              disabled={!selectedRole}
            >
              Next
            </Button>
          </Grid>
        ),
      },
      modalMaxWidth: {
        m: "900px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goBack, onRoleSelected, selectedRole]);
  const SelectableRoleItem = useCallback(
    ({ role, icon: Icon, roleDisplay }: (typeof roleToIcon)[number]) => {
      return (
        <RoleGrid
          onClick={() => {
            setSelectedRole(role);
          }}
          justifyContent="flex-start"
          gap="1rem"
          padding="1rem"
          $isActive={selectedRole === role}
        >
          <Icon />
          <Typography
            fontSize="0.875rem"
            fontWeight="500"
            lineHeight="1.3125rem"
            textAlign="left"
          >
            {roleDisplay}
          </Typography>
        </RoleGrid>
      );
    },
    [selectedRole],
  );
  const filterBridgeIfUnavailable = ({ role }: (typeof roleToIcon)[number]) => {
    // you cannot be a bridger and a verifier and a custodian at the same time with the same wallet
    const filterOut =
      alreadyOwnedRoles?.includes(RoleId.VERIFIER) &&
      alreadyOwnedRoles?.includes(RoleId.CUSTODIAN) &&
      role === RoleId.BRIDGER;
    return !filterOut;
  };
  const filterOutOwnedRoles = ({ role }: (typeof roleToIcon)[number]) =>
    !alreadyOwnedRoles?.includes(role);
  const advancedRoles = roleToIcon
    .filter(({ isAdvanced }) => isAdvanced)
    .filter(filterBridgeIfUnavailable)
    .filter(filterOutOwnedRoles);
  return (
    <Grid flexDirection="column" gap="1rem">
      <Wrapper>
        <Grid flexDirection="column" width="auto">
          {roleToIcon
            .filter(({ isAdvanced }) => !isAdvanced)
            .filter(filterBridgeIfUnavailable)
            .filter(filterOutOwnedRoles)
            .map((roleInfo) => {
              return <SelectableRoleItem {...roleInfo} key={roleInfo.role} />;
            })}
          {!!advancedRoles.length && (
            <AdvancedRolesWrapper>
              <Typography
                fontWeight={500}
                fontSize="0.75rem"
                textAlign="center"
                justifyContent="center"
              >
                ADVANCED ROLES
              </Typography>
              <Grid flexDirection="column">
                {advancedRoles.map((roleInfo) => {
                  return (
                    <SelectableRoleItem {...roleInfo} key={roleInfo.role} />
                  );
                })}
              </Grid>
            </AdvancedRolesWrapper>
          )}
        </Grid>
        <Grid flexDirection="column">
          {roleToComponent[selectedRole as keyof typeof roleToComponent]}
        </Grid>
      </Wrapper>
    </Grid>
  );
};
