import { Grid, Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";

import { Span } from "./Span";

type GridRow = {
  label: string;
  capitalizeText?: boolean;
} & (
  | { children: React.ReactNode; text?: never }
  | { children?: never; text: string | undefined | null }
);

export const GridRow: React.FC<GridRow> = ({
  label,
  capitalizeText,
  text,
  children,
}) => {
  return (
    <Grid
      flexDirection="column"
      gap="0.25rem"
      alignItems="start"
      justifyContent="start"
    >
      <Typography
        margin={0}
        padding={0}
        tag="h5"
        fontSize="0.75rem"
        color={colors.velvetLight}
        fontWeight={500}
      >
        {label}
      </Typography>
      {children ?? (
        <Span
          style={{ ...(capitalizeText && { textTransform: "capitalize" }) }}
        >
          {text ?? "-"}
        </Span>
      )}
    </Grid>
  );
};
