import { Grid } from "@bosonprotocol/react-kit";
import { Check, DotsThree } from "phosphor-react";
import { ReactElement, useCallback } from "react";
import { css, styled } from "styled-components";

import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";

const ProgressText = styled.div<{
  $status: Status;
  $forceHover: boolean;
}>`
  min-height: 3.5rem;
  font-size: 0.875rem;
  ${({ $status }) =>
    $status === "current" &&
    css`
      color: ${colors.velvet};
      font-weight: 500;
    `}

  ${({ $status, $forceHover }) =>
    ($status !== "uncompleted" || $forceHover) &&
    css`
      &:hover {
        cursor: pointer;
        color: ${colors.velvetLight};
      }
    `}
`;
type Status = "current" | "completed" | "uncompleted";
const DotWrapper = styled.div<{
  $status: Status;
}>`
  min-height: 3.5rem;
  ${({ $status }) =>
    $status === "current" &&
    css`
      color: ${colors.black};
      font-weight: 500;
    `}
`;
const ProgressDot = styled.div<{
  $status: Status;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  ${({ $status }) =>
    $status === "current"
      ? css`
          background-color: ${colors.pinkLight};
          border: 1px solid ${colors.pink};
        `
      : $status === "completed"
        ? css`
            background-color: ${colors.pinkLight};
          `
        : css`
            background-color: ${colors.shade};
          `}
`;
const Wrapper = styled.div`
  display: flex;
  gap: 0.8125rem;
  color: ${colors.greyDark};
  > * {
    padding: 0.1875rem;
    :last-of-type {
      min-height: initial;
    }
  }
`;
const Progress = styled.div`
  border: 1px solid ${colors.shade};
  border-top-left-radius: ${borders.big}px;
  border-top-right-radius: ${borders.big}px;
  border-bottom-left-radius: ${borders.big}px;
  border-bottom-right-radius: ${borders.big}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export type StepData = {
  text: string;
};

const statusToIcon = {
  completed: <Check color={colors.pink} />,
  current: <DotsThree color={colors.pink} />,
  uncompleted: <></>,
} as const satisfies Record<Status, ReactElement>;

const getStatus = ({
  index,
  active,
  lastCompleted,
}: {
  index: number;
  lastCompleted: number;
} & Pick<MultiStepsProps, "active">): Status => {
  const status =
    index === active
      ? "current"
      : index <= lastCompleted
        ? "completed"
        : "uncompleted";
  return status;
};

export type MultiStepsProps = {
  disableInactiveSteps?: boolean;
  lastCompleted?: number;
  forceHoverOneStepAfterCompleted?: boolean;
  active: number;
  data: Array<StepData>;
  callback?: (cur: number) => void;
  className?: string;
};
export const MultiSteps = ({
  disableInactiveSteps,
  active,
  lastCompleted,
  forceHoverOneStepAfterCompleted,
  data,
  callback,
  className,
}: MultiStepsProps) => {
  const actualLastCompleted =
    lastCompleted === undefined ? active : lastCompleted;
  const handleClickStep = useCallback(
    ({ index, status }: { index: number; status: Status }) => {
      if (status === "uncompleted" && disableInactiveSteps) {
        return;
      }
      callback?.(index);
    },
    [disableInactiveSteps, callback],
  );
  return (
    <Wrapper className={className}>
      <Progress>
        {data.map(({ text }, index) => {
          const status = getStatus({
            index,
            active,
            lastCompleted: actualLastCompleted,
          });
          return (
            <DotWrapper
              key={text}
              onClick={() => handleClickStep({ index, status })}
              $status={status}
            >
              <ProgressDot $status={status}>{statusToIcon[status]}</ProgressDot>
            </DotWrapper>
          );
        })}
      </Progress>
      <Grid flexDirection="column" alignItems="flex-start">
        {data.map(({ text }, index) => {
          const status = getStatus({
            index,
            active,
            lastCompleted: actualLastCompleted,
          });
          return (
            <ProgressText
              key={text}
              $status={status}
              $forceHover={
                !!forceHoverOneStepAfterCompleted &&
                actualLastCompleted + 1 === index
              }
              onClick={() => handleClickStep({ index, status })}
            >
              {text}
            </ProgressText>
          );
        })}
      </Grid>
    </Wrapper>
  );
};
