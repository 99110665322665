const openSeaUrlMap = new Map([
    [
        "testing", // testnets
        new Map([
            [
                "testing-80002-0",
                (tokenId, contractAddress) => `https://testnets.opensea.io/assets/amoy/${contractAddress}/${tokenId}`, // TO BE CONFIRMED
            ],
            [
                "testing-11155111-0",
                (tokenId, contractAddress) => `https://testnets.opensea.io/assets/sepolia/${contractAddress}/${tokenId}`,
            ],
        ]),
    ],
    [
        "staging", // testnets
        new Map([
            [
                "staging-80002-0",
                (tokenId, contractAddress) => `https://testnets.opensea.io/assets/amoy/${contractAddress}/${tokenId}`, // TO BE CONFIRMED
            ],
            [
                "staging-11155111-0",
                (tokenId, contractAddress) => `https://testnets.opensea.io/assets/sepolia/${contractAddress}/${tokenId}`,
            ],
        ]),
    ],
    [
        "production", // Polygon
        new Map([
            [
                "production-137-0",
                (tokenId, contractAddress) => `https://opensea.io/assets/matic/${contractAddress}/${tokenId}`,
            ],
            [
                "production-1-0",
                (tokenId, contractAddress) => `https://opensea.io/assets/ethereum/${contractAddress}/${tokenId}`,
            ],
        ]),
    ],
]);
export const getOpenSeaUrl = ({ configId, envName, tokenId, contractAddress, }) => {
    const urlFn = openSeaUrlMap.get(envName)?.get(configId);
    return urlFn?.(tokenId, contractAddress) || "";
};
