import {
  CountrySelectProps as BosonCountrySelectProps,
  SelectProps as BosonSelectProps,
} from "@bosonprotocol/react-kit";

import { borders } from "../../styles/borders";
import { colors, defaultColors } from "../../styles/colors";

export const selectTheme = {
  control: {
    borderRadius: `${borders.defaultBorderRadiusPx}px`,
    background: defaultColors.inputBackgroundColor,
    border: `1px solid ${colors.greyLight}`,
    focus: {
      border: `1px solid ${colors.pink}`,
    },
    hover: {
      border: `1px solid ${colors.pink}`,
    },
    error: {
      border: `1px solid ${colors.orange}`,
    },
  },
  placeholder: {
    fontSize: "0.8333rem",
    error: {
      color: colors.orange,
    },
  },
  option: {
    focus: {
      color: colors.pink,
    },
    selected: {
      color: colors.black,
    },
  },
} satisfies BosonSelectProps["theme"];

export const countrySelectTheme = {
  ...selectTheme,
  control: {
    ...selectTheme.control,
    height: "2.8125rem", // TODO: remove?
  },
} satisfies BosonCountrySelectProps["theme"];
