import {
  FormField as BosonFormField,
  FormFieldProps,
} from "@bosonprotocol/react-kit";
import styled from "styled-components";

const StyledBosonFormField = styled(BosonFormField)`
  margin-bottom: 2rem;
  :first-child {
    margin: 0;
  }
`;

export const FormField = (props: FormFieldProps) => {
  return <StyledBosonFormField {...props} />;
};
