/*eslint sort-keys: "error"*/
export const colors = {
  black: "#09182C",
  blackLight: "#09182C1C",
  blue: "#0299EE",
  blueLight: "#EAF2FF",
  border: "#5560720f",
  borderHoveredShade: "#8a517300",
  borderPinkLight: "#ff1cc100",
  borderShade: "#8a517300",
  buyWithFermionMobileCardText: "#c7bebe",
  carouselButtonUnactive: "#fdfdfd", // TODO: wrong color name, it's coupled to where it's used
  champagne: "#FAFAFA",
  champagneBorder: "#f1f1f1",
  champagneDark: "#f8f8f8",
  champagneFooter: "#8d8486",
  green: "#5bbe71",
  greenLight: "#DEF2E3",
  greenLightest: "#EFF9F1",
  grey: "#d9cfe5",
  greyDark: "#8b808d",
  greyLight: "#F1F3F9",
  greyShade: "#8b808d08",
  homepageCTABackground: "#372a34",
  homepageCTABorder: "#4f444c",
  hoveredShade: "#8a51731f",
  orange: "#FC6838",
  pink: "#FF1CC1",
  pinkAccent: "#F041B8",
  pinkIcon: "#f8a5de",
  pinkLight: "#ff1cc114",
  pinkLightest: "#ff1cc10a",
  pinkMain: "#600C45",
  purple: "#B131FA",
  purpleDeep: "#131a2a",
  purpleLight: "#F8EBFF",
  red: "#FC6838",
  redLight: "#FFF0EC",
  shade: "#8a51730f",
  velvet: "#1C0D18",
  velvetBackground: "#2a1d26",
  velvetBorder: "rgba(28, 13, 24, 0.08)",
  velvetBorderDark: "#372A33",
  velvetHalf: "#8E868B",
  velvetLight: "rgba(28, 13, 24, 0.75)",
  velvetUltraLight: "rgba(28, 13, 24, 0.50)",
  white: "#ffffff",
  whiteLight: "rgba(255, 255, 255, 0.75)",
  whiteMainPage: "#c6c2c5",
  whiteOff: "#faf9fa",
} as const;

export const defaultColors = {
  inputBackgroundColor: colors.whiteOff,
};
