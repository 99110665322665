export const TimeFrequencyValues = {
    yearly: "yearly",
    monthly: "monthly",
    weekly: "weekly",
    daily: "daily",
};
export const TimeFrecuencyKeys = {
    YEARLY: "YEARLY",
    MONTHLY: "MONTHLY",
    WEEKLY: "WEEKLY",
    DAILY: "DAILY",
};
export const timeFrecuencyOptions = [
    { value: TimeFrequencyValues.yearly, label: TimeFrecuencyKeys.YEARLY },
    { value: TimeFrequencyValues.monthly, label: TimeFrecuencyKeys.MONTHLY },
    { value: TimeFrequencyValues.weekly, label: TimeFrecuencyKeys.WEEKLY },
    { value: TimeFrequencyValues.daily, label: TimeFrecuencyKeys.DAILY },
];
