import { ErrorMessage } from "@bosonprotocol/react-kit";
import { Button } from "@fermionprotocol/react-kit";
import * as Sentry from "@sentry/browser";
import { useEffect, useState } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

// TODO: designs for error boundary
const ErrorFallback: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const location = useLocation();
  const [initLocation] = useState(location.pathname);

  useEffect(() => {
    if (initLocation !== location.pathname) {
      resetErrorBoundary();
    }
  }, [location.pathname, initLocation, resetErrorBoundary]);

  return (
    <ErrorMessage
      title={`An error occurred`}
      message={`Sorry something went wrong. Please try again.`}
      img={<></>}
      cta={
        <Button
          variant="pink"
          onClick={() => {
            resetErrorBoundary();
          }}
        >
          Try again
        </Button>
      }
    />
  );
};

export const CustomErrorBoundary: React.FC<Props> = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error) => {
        Sentry.captureException(error);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
