import { useContext } from "react";

import { ModalContext } from "./ModalContext";
import { MODAL_TYPES } from "./ModalTypes";

export function useModal() {
  const context = useContext(ModalContext);

  return {
    ...context,
    modalTypes: MODAL_TYPES,
  };
}
