import { Grid, Typography } from "@bosonprotocol/react-kit";
import { borders, Button, colors, Subgraph } from "@fermionprotocol/react-kit";
import CheckedCircle from "assets/icons/dashboard/sidebar/checkedCircle.svg?react";
import { BidsProductProps } from "components/hub/bids/BidsTable/BidsTable";
import { BidSubTable } from "components/hub/bids/BidsTable/Components/BidsSubTable";
import { useModal } from "components/modal/useModal";
import {
  TableCategoryWrapperStyled,
  TableThumbnailStyled,
} from "components/table/TableStyles";
import { useEffect, useState } from "react";
import styled from "styled-components";

interface BidsForProductModalProps {
  bid: BidsProductProps;
}

interface ExtendedBidsForProductModalProps extends BidsForProductModalProps {
  bid: BidsProductProps & {
    offer: Subgraph.OfferFieldsFragment;
  };
}

const ItemPreviewContainer = styled.div`
  padding: 1rem;
  border: 1px solid ${colors.velvetBorder};
  border-radius: ${borders.big}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.champagne};
  margin-bottom: 2rem;
`;

export function BidsForProductModal({ bid }: ExtendedBidsForProductModalProps) {
  const { updateProps, store, hideModal } = useModal();
  const [acceptedBid, setAcceptedBid] = useState<number | undefined>(undefined);

  useEffect(() => {
    updateProps<"EDIT_ROLE">({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              fontSize={"1.5rem"}
              fontWeight={500}
              marginBottom="0.5rem"
              marginTop="0.25rem"
            >
              Bids for {bid.name}
            </Typography>
          </Grid>
        ),
        footerComponent: (
          <Grid
            width={"100%"}
            justifyContent="center"
            alignItems="center"
            padding={"1.5rem 2rem"}
          >
            <Button
              variant="pinkLight"
              type="button"
              onClick={() => {
                hideModal();
              }}
            >
              Close
            </Button>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ItemPreviewContainer>
        <Grid alignItems="center" justifyContent="flex-start">
          <TableThumbnailStyled src={bid.img} alt="Thumbnail" />
          <Grid
            flexDirection="column"
            alignItems="flex-start"
            marginLeft={"0.75rem"}
          >
            <Typography fontSize={"1.125rem"} fontWeight={400}>
              {bid.name}
            </Typography>
            <Typography fontSize={"0.875rem"} fontWeight={400}>
              Brand name/Seller name
            </Typography>
          </Grid>
        </Grid>
        <TableCategoryWrapperStyled>
          <Typography
            fontSize={"0.75rem"}
            fontWeight={"600"}
            color={colors.velvet}
          >
            {bid.category}
          </Typography>
        </TableCategoryWrapperStyled>
      </ItemPreviewContainer>
      {acceptedBid === undefined && (
        <>
          <Typography
            tag="h4"
            fontWeight={400}
            fontSize={"1.125rem"}
            color={colors.velvet}
            margin={0}
          >
            Bids
          </Typography>
          <Typography
            fontWeight={400}
            fontSize={"0.875rem"}
            color={colors.velvetLight}
            marginBottom={"2rem"}
          >
            When you accept a bid all the other will be rejected automatically.
          </Typography>
        </>
      )}
      {acceptedBid !== undefined && (
        <Grid
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <CheckedCircle />
          <Typography
            fontSize={"1.5rem"}
            fontWeight={400}
            marginTop={"-1.25rem"}
            marginBottom={"2rem"}
          >
            Bid accepted
          </Typography>
        </Grid>
      )}
      <BidSubTable
        subRows={bid.bids}
        setAcceptedBid={setAcceptedBid}
        acceptedBid={acceptedBid}
        offer={bid.offer}
      />
    </>
  );
}
