import { Grid, Loading, Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";
import { useEffect } from "react";

import { useModal } from "../../../useModal";

interface Props {
  action: string;
}

export function WaitingForConfirmationModal({ action }: Props) {
  const { updateProps, store } = useModal();
  useEffect(() => {
    updateProps<"WAITING_FOR_CONFIRMATION">({
      ...store,
      modalProps: {
        ...store.modalProps,
        action,
      },
      modalSize: "auto",
      modalMaxWidth: {
        xs: "400px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);
  return (
    <Grid flexDirection="column" alignItems="center">
      <Grid padding="0 0 2rem 0" justifyContent="center" alignItems="center">
        <Loading
          style={{
            borderWidth: "0.125rem",
            borderColor: `${colors.pink} ${colors.pink} ${colors.pink} transparent`,
            width: "6rem",
            height: "6rem",
          }}
        />
      </Grid>
      <Typography fontWeight="600" fontSize="1.5rem" lineHeight="150%">
        Waiting For Confirmation
      </Typography>
      <Typography
        fontWeight="600"
        fontSize="1rem"
        lineHeight="150%"
        color={colors.greyDark}
        margin="0.5rem 0 1.5rem 0"
      >
        {action}
      </Typography>
      <Typography
        fontWeight="400"
        fontSize="0.75rem"
        lineHeight="150%"
        color={colors.greyDark}
      >
        Confirm this transaction into your wallet
      </Typography>
    </Grid>
  );
}
