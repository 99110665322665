import { Typography } from "@bosonprotocol/react-kit";
import { HelpItems } from "components/flow/types";

export const selectRoleHelp = [
  {
    title: "How to select which role to use?",
    description: "???", // TODO: change
  },
] satisfies HelpItems;
export const myAssetsBuyerHubHelp = [
  {
    title: "How long does the verification process take?",
    description: "The verification process typically takes a few days.",
  },
  {
    title: "How can I check where the asset is in the process?",
    description:
      "By clicking on your asset, the details of the asset can be viewed, along with a timeline of where the asset is in the process. ",
  },
  {
    title: "How do I checkout my item?",
    description:
      "You can checkout your asset by hovering over the asset card, and selecting ‘Initiate checkout.’ If you are in the list view, you can selection ‘Initiate checkout,’ under the actions sections.",
  },
] satisfies HelpItems;
export const chooseYourVerifierHelp = [
  {
    title: "Who is a verifier?",
    description:
      "A verifier is a trusted entity that provides authentication and condition reports for specific asset categories. They verify that the asset meets the description and provide a certificate of authenticity for a fee.",
  },
  {
    title: "What should I take into consideration when choosing a verifier?",
    description:
      "When choosing a verifier, ensure that they are trusted and reputable to buyers. You will also want to see if the authentication report they provide meets your requirement, and whether they verify the brand or class of your asset. Receiving valuation certificates is often an important consideration, as it is a requirement for insurance purposes or by storage facilities.",
  },
  {
    title: "If I am a seller, can I be my own verifier?",
    description:
      "Yes, you can choose the 'self verify' option when setting up the offer.",
  },
  {
    title: "Can I have different verifiers for different products?",
    description:
      "Yes. A verifier is set at the offer or product level. You can choose the verifier you would like for a product at the beginning of the creation process.",
  },
  {
    title:
      "Is a verifier automatically notified that they have been selected as the verifier for my asset?",
    description:
      "No. Once you have selected a verifier, you must contact them to receive confirmation that they agree to provide authentication services for your asset.",
  },
] satisfies HelpItems;
export const chooseYourCustodianHelp = [
  {
    title: "What does a custodian do?",
    description:
      "A custodian is a trusted entity responsible for providing secure storage facilities for your assets. They safeguard your items in a controlled environment, ensuring their protection and accessibility.",
  },
  {
    title: "What should I consider when choosing a custodian?",
    description:
      "When selecting a custodian, prioritize their trustworthiness, reputation, and security measures. Ensure their facility is located in a suitable area and offers the necessary storage conditions for your assets. Consider factors such as insurance coverage, access policies, and fees to find the best fit for your needs.",
  },
  {
    title: "Can I be my own custodian?",
    description:
      "Yes, if you have the appropriate facilities and security measures in place, you can choose the 'self custody' option when setting up your offer. However, be aware of the potential risks and responsibilities associated with self-custody.",
  },
  {
    title: "Can I have different custodians for different products?",
    description:
      "Yes, you can set a custodian at the offer or product level. This allows you to choose the most suitable custodian based on the specific requirements of each item.",
  },
  {
    title: "Is a custodian automatically notified of my selection?",
    description:
      "No, once you've selected a custodian, you must contact them directly to confirm your choice and discuss the terms of their services. This ensures a clear understanding of your requirements and their capabilities.",
  },
] satisfies HelpItems;
export const productTypeHelp = [
  {
    title: "What is a phygital?",
    description:
      "A phygital item is one that bundles a physical item with an identical digital wearable, an NFT, or other digital or experiential products and services. When a user redeems the rNFT they will receive both the physical and digital or experiential product.",
  },
  {
    title: "Why would I sell a phygital item?",
    description:
      "Phygital items bridge the physical and digital world, allowing your products to hit new levels of utility and reach an ever-growing new audience.",
  },
  {
    title: "What are variants?",
    description:
      "Variants are alternate forms of the same product, for example, color and size. Choosing the 'Different variants' option on the left allows you to leverage all of the same product data for the different variants, instead of manually creating a new product offer for each variant.",
  },
  {
    title: "What is a token gated offer?",
    description:
      "A token gated offer allows you to make the product exclusive by limiting the ability to purchase it only to users holding a specific token.",
  },
] as const;
export const productDigitalHelp = [
  {
    title: "What is a wearable?",
    description:
      "A wearable is an NFT that is a visual representation of the physical item. The wearable can be worn by the owner's avatar in the metaverse.",
  },
  {
    title: "Does my NFT need to be minted before creating the offer?",
    description:
      "No, the NFT does not need to be minted before creating the offer. We recommend providing buyers as much detail as possible to ensure they are aware of what they will receive.",
  },
  {
    title: "What digital file formats can I list?",
    description:
      "While there are no restrictions on the file format you can list, some email providers or softwares that send files impose restrictions.",
  },
  {
    title:
      "How do I know when and where to send the digital or experiential item?",
    description:
      "You can define the criteria and time when you will send the digital or experiential item, along with what information you will need from the buyer to do so. At redemption, the buyer will provide this information that you will receive, similar to receiving the shipping or delivery address for the physical item.",
  },
] as const;
export const productInformationHelp = [
  {
    title: "How do I describe my item?",
    description: (
      <>
        <Typography tag="p" style={{ margin: "0" }}>
          We recommend beginning with a short overview that describes your
          product's unique features. Buyers also often like to hear about the
          story behind the product, how it was made, and the creative
          inspiration behind it.
        </Typography>
        <Typography tag="p" style={{ margin: "0" }}>
          It is also beneficial to include other operational details, such as
          whether duties and taxes are included in the price. We also recommend
          including any relevant details pertaining to consumer protection laws
          in your jurisdiction, for example, the compatibility of digital
          content with hardware and other software, and information about
          copyright protections.
        </Typography>
      </>
    ),
  },
  {
    title: "What if my item isn't part of the categories listed?",
    description: (
      <p style={{ margin: "0" }}>
        If your product category is not listed, you can choose the 'other'
        category field, or send us an email at{" "}
        <a href="mailto:info@bosonapp.io" target="_blank">
          info@bosonapp.io
        </a>{" "}
        to get it included in the category list.
      </p>
    ),
  },
  {
    title: "What are category attributes used for?",
    description:
      "Attributes help buyers understand specific properties of your item. For example, material, colour, size, components, etc.",
  },
] satisfies HelpItems;
export const productVariantsHelp = [...productTypeHelp] satisfies HelpItems;
export const productImagesHelp = [
  {
    title: "How many images do I need?",
    description:
      "While at least one thumbnail image is required, we recommend adding as many images as you can to show your item's most interesting characteristics.",
  },
  {
    title: "Can I add images to my variations?",
    description:
      "Yes, you can add images to your variations so buyers can see all their options.",
  },
  {
    title: "Do you have any tips for the images I include?",
    description:
      "In general, we recommend using natural light, without a flash, and against a clean or simple background. For some products it is beneficial to include a common object for scale, or for the item to be held, worn, or used. Adding images for all your variations help buyers see all their options.",
  },
] satisfies HelpItems;
export const coreTermsOfSaleHelp = [
  {
    title:
      "What should I take into consideration when setting the price of my item?",
    description:
      "Typically, the costs of materials, labour, and other business expenses are factored into the price. If you offer free delivery and/or duties & taxes paid, make sure to include those costs so it doesn't eat into your margins.",
  } as const,
  {
    title: "How do I set the validity period for my offers?",
    description:
      "All offers must specify a start date, or the date from when buyers can commit to your offer. You can choose to either set a specific end date, or default to no specific end date.",
  } as const,
  {
    title:
      "If I set my offers without a specific end date now, can I change it in the future so that buyers will no longer be able to commit to it?",
    description:
      "Yes. You can 'Void' an offer in the Seller Hub so that buyers will no longer be able to commit to the offer.",
  } as const,
  {
    title: "Should I set my offers with or without an expiration date?",
    description:
      "If you have an offer that you want to make available only for a limited time, or that you want to make exclusive for a specific season or event, setting an offer with an expiration date is likely the better choice. For offers that are more readily available or less seasonal, setting them up without an expiration date is a better option.",
  } as const,
  {
    title: "What happens when the offer validity period ends?",
    description:
      "When your offer validity period ends, buyers will no longer be able to commit to the offer.",
  } as const,
  {
    title: "How long should the redemption period be?",
    description: (
      <>
        <Typography tag="p" style={{ margin: "0" }}>
          During the redemption period, buyers can trade, transfer, or gift the
          rNFT. Hence, the longer the redemption period, the more buyers can
          benefit from the transferable nature of these rNFTs. You, as a seller,
          also benefit via royalties from these secondary sales.
        </Typography>
        <Typography tag="p" style={{ margin: "0" }}>
          A buyer can also redeem the rNFT for the physical item anytime during
          this period, so consider how long you feel comfortable storing this
          physical item and factor this into the redemption period.
        </Typography>
      </>
    ),
  } as const,
  {
    title:
      "For offers without an expiration date, what is the redemption start date considered when it has not been specified?",
    description:
      "If a redemption start date has not been specified, the buyer will be able to redeem the rNFT as soon as they have committed to the offer.",
  } as const,
  {
    title:
      "For offers without an expiration date, what is the redemption duration period?",
    description:
      "The redemption duration period is the number of days a buyer has to redeem the rNFT for the physical item. It is calculated from the later of either the date the buyer commits to the offer or from the redemption start date (if it was specified).",
  } as const,
] satisfies HelpItems;
export const tokenGatingHelp = [
  {
    title: "Which token types can I use to create a token gated offer?",
    description:
      "You can create a token gated offer using an ERC20, ERC721, or an ERC1155 token.",
  },
  {
    title: "Which networks does token gating work with?",
    description:
      "Token gating currently works on Polygon but will soon work on other EVM chains.",
  },
  {
    title: "What configuration can you give for each token type?",
    description: (
      <ul>
        <li>ERC20 (fungible tokens)</li>
        <ul>
          <li>
            One can set a minimum threshold that a token holder needs to hold,
            this should be greater than or equal to 1
          </li>
          <li>
            One can set a number of times that a given token holder can unlock
            the gate
          </li>
        </ul>
        <li>ERC721</li>
        <ul>
          <li>
            One can select whether or not they want to target a specific token
            from a collection or any token from a collection
          </li>
          <li>
            One can set a number of times that a given token holder can unlock
            the gate
          </li>
        </ul>
        <li>ERC1155</li>
        <ul>
          <li>One can select a given token from an 1155 collection</li>
          <li>
            One can set a minimum threshold that a token holder needs to hold,
            this should be greater than or equal to 1
          </li>
          <li>
            One can set a number of times that a given token holder can unlock
            the gate
          </li>
        </ul>
      </ul>
    ),
  },
] satisfies HelpItems;
export const termsOfExchangeHelp = [
  {
    title: "Do I have to set a seller deposit?",
    description:
      "No, seller deposits are optional. You can set the seller deposit to '0' if you do not want to set a deposit.",
  },
] satisfies HelpItems;
export const shippingInfoHelp = [
  {
    title: "If I ship internationally, who will pay for the duties and taxes?",
    description:
      "It is up to you, as the seller, to decide who pays for the duties and taxes. We recommend clarifying this to buyers in the product description.",
  },
  {
    title: "How long should the return period be?",
    description:
      "The return period should be a minimum of 15 days to meet our fair exchange guidelines.",
  },
] satisfies HelpItems;
