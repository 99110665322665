import { METADATA_LENGTH_LIMIT } from "@bosonprotocol/react-kit";

export const validationMessage = {
  required: "This field is required",
  isRequired: (field: string) => `Field '${field}' is required`,
  maxLength: `Maximum length is ${METADATA_LENGTH_LIMIT} characters`,
  notUrl: "This is not a URL like: www.example.com",
  min: (min: number) => `It has to be greater than or equal to ${min}`,
  max: (max: number) => `It has to be lower than or equal to ${max}`,
  length: (minLength: number) => `At least ${minLength} are required`,
  invalidType: (expectedType: "number") => `Enter a valid '${expectedType}'`,
} as const;
