import {
  extractUserFriendlyError,
  getHasUserRejectedTx,
  Grid,
  hooks as bosonHooks,
  Loading,
  Provider,
  Typography,
} from "@bosonprotocol/react-kit";
import { eAccountRole } from "@fermionprotocol/core-sdk";
import {
  Button,
  colors,
  DepositFundsButton,
  getNumberWithDecimals,
  getNumberWithoutDecimals,
  hooks,
  Input,
} from "@fermionprotocol/react-kit";
import * as Sentry from "@sentry/browser";
import { useSignerV6 } from "components/listing/hooks/connection";
import { useModal } from "components/modal/useModal";
import { BigNumber } from "ethers";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const depositFundsSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Amount is required")
    .positive("Amount must be positive")
    .typeError("Amount must be a number"),
});

interface WithdrawFundsValues {
  amount: string;
}

interface DepositFundsModalProps {
  token: string;
  tokenSymbol: string | React.ReactNode;
  protocolBalance: string;
  tokenDecimals: string;
  reload: () => void;
}

export function DepositFundsModal({
  protocolBalance,
  tokenSymbol,
  token,
  tokenDecimals,
  reload,
}: DepositFundsModalProps) {
  const [isBeingDeposit, setIsBeingDeposit] = useState<boolean>(false);
  const [isDepositInvalid, setIsDepositInvalid] = useState<boolean>(true);

  const { data: signerV6 } = useSignerV6();
  const { data: exchangeTokenBalance } = bosonHooks.useBalance(undefined, {
    enabled: !!signerV6,
  });
  const { address } = bosonHooks.useAccount();
  const { data: entity } = hooks.useGetWallet({
    enabled: !!address,
    walletAddress: address ?? "",
  });
  const entityThatCanWithdraw = entity?.accountRoles?.find(
    (role) =>
      role.accountRole === eAccountRole.Treasury ||
      role.accountRole === eAccountRole.Assistant ||
      role.accountRole === eAccountRole.Manager, // TODO: remove, right now the subgraph doesnt return that Im also a treasury and assistant so I have to do this
  );
  const accountId = entityThatCanWithdraw?.entityRole.id ?? ""; // TODO: check if this is correct
  const formattedBalance = getNumberWithDecimals(
    exchangeTokenBalance?.toString() ?? "0",
    tokenDecimals,
  );
  const formattedProtocolBalance = getNumberWithDecimals(
    protocolBalance,
    tokenDecimals,
  );
  const { showModal, hideModal, updateProps, store } = useModal();

  const tokenStep = 10 ** -Number(tokenDecimals);
  const step = 0.01;
  useEffect(() => {
    updateProps<"EDIT_ROLE">({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              fontSize={"1.5rem"}
              fontWeight={500}
              marginBottom="0.5rem"
              marginTop="0.25rem"
            >
              {`Deposit ${tokenSymbol}`}
            </Typography>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenSymbol]);
  return (
    <Formik<WithdrawFundsValues>
      initialValues={{
        amount: "",
      }}
      validationSchema={depositFundsSchema}
      onSubmit={() => {
        // do nothing on purpose, it's handled by the button directly
      }}
    >
      {({ setFieldValue, values: { amount: amountToDeposit } }) => {
        const setAmountToDeposit = (value: string) => {
          setFieldValue("amount", value);
        };
        const handleChangeDepositAmount = (
          e: React.ChangeEvent<HTMLInputElement>,
        ) => {
          const valueStr = e.target.value;
          const value = e.target.valueAsNumber || 0;
          setIsDepositInvalid(false);

          try {
            if (value < tokenStep || value > Number.MAX_SAFE_INTEGER) {
              setIsDepositInvalid(true);
            }
          } catch (e) {
            setIsDepositInvalid(true);
          }

          setAmountToDeposit(valueStr);
        };
        return (
          // TODO: Fix formik bug
          // @ts-expect-error Formik bug
          <Form className="depositForm" id="depositForm">
            <Grid flexDirection="column" alignItems="flex-start" gap="1.5rem">
              <Typography
                tag="p"
                margin="0"
                fontSize="0.75rem"
                alignItems="center"
                gap="0.25rem"
              >
                <Typography tag="strong">Protocol Balance:</Typography>
                <Typography tag="span">
                  {formattedProtocolBalance} {tokenSymbol}
                </Typography>
              </Typography>
              <Grid flexDirection="column" alignItems="flex-start" gap="0">
                <Typography
                  fontSize={"0.875rem"}
                  fontWeight="500"
                  color={colors.velvetLight}
                >
                  Enter amount to deposit
                </Typography>
                <Grid alignItems="flex-start" flexDirection="column">
                  <Input
                    name="amount"
                    type="number"
                    step={step}
                    min={0}
                    onChange={handleChangeDepositAmount}
                    disabled={isBeingDeposit}
                  />
                </Grid>
                <Typography
                  color={colors.velvetLight}
                  fontWeight={400}
                  fontSize={"0.75rem"}
                >
                  Balance {formattedBalance} {tokenSymbol}
                </Typography>
              </Grid>
              <Grid
                width={"100%"}
                justifyContent="space-between"
                alignItems="center"
                padding="1.5rem 0 0 0"
              >
                <Button
                  variant="transparent"
                  type="button"
                  onClick={() => {
                    hideModal();
                  }}
                >
                  <Typography fontSize={"0.875rem"} fontWeight={500}>
                    Close
                  </Typography>
                </Button>
                <DepositFundsButton
                  variant="pinkLight"
                  exchangeToken={token}
                  accountId={accountId}
                  amountToDeposit={
                    isDepositInvalid || !Number(amountToDeposit)
                      ? BigNumber.from("0")
                      : BigNumber.from(
                          getNumberWithoutDecimals(
                            amountToDeposit,
                            tokenDecimals,
                          ),
                        )
                  }
                  disabled={isBeingDeposit || isDepositInvalid}
                  onPendingSignature={() => {
                    setIsBeingDeposit(true);
                    showModal({
                      modalType: "WAITING_FOR_CONFIRMATION",
                      modalMaxWidth: { xs: "400px" },
                    });
                  }}
                  onPendingTransaction={(hash, _, actionName) => {
                    switch (actionName) {
                      case "approveExchangeToken":
                        showModal({
                          modalType: "TRANSACTION_SUBMITTED",
                          modalProps: {
                            action: "Approve ERC20 Token",
                            txHash: hash,
                          },
                        });
                        break;
                      case "depositFunds":
                        showModal({
                          modalType: "TRANSACTION_SUBMITTED",
                          modalProps: {
                            action: "Finance deposit",
                            txHash: hash,
                          },
                        });
                        break;
                      default:
                        Sentry.captureException(actionName);
                        break;
                    }
                  }}
                  onSuccess={async () => {
                    // TODO: test if this is necessary
                    // await poll(
                    //   async () => {
                    //     const balance = await refetch();
                    //     return balance;
                    //   },
                    //   (balance) => {
                    //     return dataBalance?.formatted === balance.data?.formatted;
                    //   },
                    //   500
                    // );
                    setAmountToDeposit("0");
                    setIsDepositInvalid(true);
                    reload();
                    setIsBeingDeposit(false);
                    hideModal();
                  }}
                  onError={async (error, { txResponse }) => {
                    console.error("onError", error);
                    const hasUserRejectedTx = getHasUserRejectedTx(error);
                    if (hasUserRejectedTx) {
                      showModal({
                        modalType: "TRANSACTION_FAILED",
                      });
                    } else {
                      Sentry.captureException(error);
                      showModal({
                        modalType: "TRANSACTION_FAILED",
                        modalProps: {
                          errorMessage: "Something went wrong",
                          detailedErrorMessage: await extractUserFriendlyError(
                            error,
                            {
                              txResponse,
                              provider:
                                signerV6?.provider as unknown as Provider,
                            },
                          ),
                        },
                      });
                    }
                    reload();
                    setIsBeingDeposit(false);
                  }}
                >
                  {isBeingDeposit ? (
                    <Loading size={20} />
                  ) : (
                    <Typography
                      tag="p"
                      margin="0"
                      fontSize="0.75rem"
                      fontWeight="600"
                    >
                      Deposit {tokenSymbol}
                    </Typography>
                  )}
                </DepositFundsButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
