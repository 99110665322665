import { ProductCategoryLabels } from "./productCategories";
const defaultProductConditionOptions = [
    {
        label: "New",
        value: "new",
    },
    {
        label: "Used",
        value: "used",
    },
];
const watchProductConditionOptions = [
    {
        label: "Exceptional",
        value: "exceptional",
    },
    {
        label: "Excellent",
        value: "excellent",
    },
    {
        label: "Very good",
        value: "very good",
    },
    {
        label: "Good",
        value: "good",
    },
    {
        label: "Fair",
        value: "fair",
    },
];
export const productConditionsOptions = [
    ...defaultProductConditionOptions,
    ...watchProductConditionOptions,
];
export const getProductCondition = (currentCategory) => {
    if (currentCategory === ProductCategoryLabels.Watches) {
        return watchProductConditionOptions;
    }
    return defaultProductConditionOptions;
};
