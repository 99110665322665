import { Grid, Typography } from "@bosonprotocol/react-kit";
import { Button } from "@fermionprotocol/react-kit";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import { fermionRoutes } from "router/routeNames";

interface Props {
  requiredRole: "manager" | "assistant" | "treasury";
  action: string;
}

export function InvalidRoleModal({ requiredRole, action }: Props) {
  const navigate = useCustomNavigate();
  return (
    <>
      <Grid flexDirection="column" alignItems="flex-start">
        <Typography>
          To {action} you need to be connected as the {requiredRole}. Please,
          connect a wallet with that role or go back to the home page
        </Typography>
      </Grid>
      <Grid flexDirection="row" justifyContent="space-between">
        <Button
          variant="pink"
          onClick={() => {
            navigate({ pathname: fermionRoutes.home });
          }}
        >
          Go back to the home page
        </Button>
      </Grid>
    </>
  );
}
