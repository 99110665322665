import { TransactionReceipt, TransactionResponse } from "@bosonprotocol/common";
import { withQueryClientProvider } from "@bosonprotocol/react-kit";
import { Signer as SignerV6 } from "ethers-v6";

import { useCoreSDKContext, useOpenSeaListing } from "../../../../hooks";
import { CtaButton } from "../common/CtaButton";
import { CtaButtonProps } from "../common/types";

type AdditionalProps<T extends SignerV6 | undefined> = {
  tokenId: string;
  nftContract: string;
  offerId: string;
  signerV6: T;
  price: number;
  duration: number;
  openSeaApiKey: string;
  openseaFeeRecipient: string;
  openseaConduit: string;
  decimals: number;
  exchangeTokenAddress: string;
  onSuccess?: () => void;
};

type SuccessPayload = TransactionReceipt;

export type ICreateListingButton<T extends SignerV6 | undefined> =
  AdditionalProps<T> & Omit<CtaButtonProps<SuccessPayload>, "onSuccess">;

export const CreateListingButton = withQueryClientProvider(
  <T extends SignerV6 | undefined>({
    tokenId,
    nftContract,
    offerId,
    signerV6,
    price,
    duration,
    openSeaApiKey,
    openseaFeeRecipient,
    openseaConduit,
    decimals,
    exchangeTokenAddress,
    variant = "pinkLight",
    ...restProps
  }: ICreateListingButton<T>) => {
    const coreSdk = useCoreSDKContext();
    const isMetaTx = coreSdk.isMetaTxConfigSet;
    const { mutateAsync: createListing } = useOpenSeaListing({
      signerV6,
      tokenId,
      price,
      duration,
      openSeaApiKey,
      openseaFeeRecipient,
      nftContract,
      decimals,
      exchangeTokenAddress,
    });

    const actions = [
      {
        name: "approveExchangeToken" as const, // name is not matching, but needs changes in Boson CC
        writeContractFn: () =>
          coreSdk.erc721SetApprovalForAll({
            contractAddress: nftContract,
            operator: openseaConduit,
            approved: true,
            useMetaTx: isMetaTx,
            offerId,
          }),
        shouldActionRun: async () => {
          const isApprovedForAll = await coreSdk.erc721IsApprovedForAll({
            contractAddress: nftContract,
            operator: openseaConduit,
          });
          return !isApprovedForAll;
        },
      },
      {
        name: "depositFunds" as const, // name is not matching, but needs changes in Boson CC
        writeContractFn: async () => {
          await createListing(() => {});
          return {
            hash: "dummy",
            wait: async () => {
              return {} as TransactionReceipt; // Dummy receipt
            },
          } as TransactionResponse;
        },
      },
    ];

    return (
      <CtaButton
        variant={variant}
        defaultLabel="Create Listing"
        successPayload={(receipt) => receipt}
        actions={actions}
        {...restProps}
      />
    );
  },
);
