import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, hooks as bosonHooks, Typography, } from "@bosonprotocol/react-kit";
import { eCheckoutRequestStatus, eFNFTStatus } from "@fermionprotocol/common";
import { css, styled } from "styled-components";
import { useConversionPrice } from "../../hooks";
import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";
import { formatUSD } from "../../utils/currency/formatUSD";
import { Button } from "../buttons/Button";
import { RequestCheckoutButton } from "../buttons/cta/exchanges/RequestCheckoutButton";
import { FnftLabel } from "../labels/FnftLabel";
import { getStatusToData } from "../labels/getStatusToData";
import { SectionHeader } from "../typography/SectionHeader";
const StyledFnftLabel = styled(FnftLabel) `
  text-transform: uppercase;
  padding: 0.25rem 0.375rem;
`;
const Bottom = styled.div `
  width: 100%;
  flex: 1 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.white};
`;
const CtaWrapper = styled(Grid) `
  transition: all 200ms ease-in-out;
  overflow: hidden;
  > button {
    width: 100%;
    height: 2.125rem;
    margin: 1rem;
    margin-top: 0;
  }

  ${({ $isMobile }) => $isMobile
    ? css `
          max-height: none;
        `
    : css `
          max-height: 0;
        `}
`;
const Wrapper = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid ${colors.velvetBorder};
  border-radius: ${borders.big}px;
  overflow: hidden;
  min-height: 19.125rem;
  > img,
  button > img,
  button > video,
  video {
    background-color: ${colors.whiteOff};
    border-top-left-radius: ${borders.big}px;
    border-top-right-radius: ${borders.big}px;
    height: 13.5rem;
    min-width: 100%;
    object-fit: cover;
    object-position: ${(props) => (props.$isMobile ? "center" : "bottom")};
    transition: scale 200ms ease-in-out;
  }

  ${({ $isMobile, $fontColor }) => !$isMobile &&
    css `
      &:hover {
        border-color: ${$fontColor};
        > img,
        > video {
          scale: 1.2;
        }
        ${CtaWrapper} {
          max-height: 200px;
        }
      }
    `}

  img + *,
  video + * {
    z-index: 1;
    overflow: hidden;
    background-color: ${colors.white};
  }
  ${StyledFnftLabel} {
    z-index: 1;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
`;
const NoWrapTypography = styled(Typography) `
  white-space: nowrap;
`;
const formatWETH = (value) => {
    return value.toFixed(4);
};
const CTAActionDuplicate = ({ children, onClick, show, }) => {
    if (!show)
        return _jsx(_Fragment, { children: children });
    return (_jsx("button", { type: "button", style: { display: "inline-block", width: "100%" }, onClick: onClick, children: children }));
};
export const FnftCard = ({ tokenId, nftContract, offerId, media: { url, type }, title, brand, status, checkoutRequestStatus, price, onCTAClick, onSuccess, onError, onPendingSignature, onPendingTransaction, CTAOnImage, CTAOnTitle, hideCTA = false, }) => {
    const windowBreakpoints = bosonHooks.useBreakpoints();
    const isMobile = windowBreakpoints.isLteM;
    const { data: conversionRate } = useConversionPrice({
        fromTokens: ["ETH"],
        toToken: "USD",
    });
    const { color } = getStatusToData({ status, checkoutRequestStatus });
    return (_jsxs(Wrapper, { "$fontColor": color, "$isMobile": isMobile, children: [_jsx(StyledFnftLabel, { status: status, checkoutRequestStatus: checkoutRequestStatus, fontSize: "0.75rem" }), _jsx(CTAActionDuplicate, { onClick: onCTAClick, show: !hideCTA && !!CTAOnImage && !!onCTAClick, children: type === "image" ? (_jsx("img", { src: url, style: { aspectRatio: "1/1" } })) : (_jsx("video", { src: url })) }), _jsxs(Bottom, { children: [_jsxs(Grid, { flexDirection: "column", padding: "1rem", alignItems: "flex-start", justifyContent: "flex-start", children: [_jsx(SectionHeader, { tag: "h4", margin: 0, fontWeight: 400, fontSize: "0.9437rem", color: colors.velvet, style: {
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 1,
                                }, children: _jsx(CTAActionDuplicate, { onClick: onCTAClick, show: !hideCTA && !!CTAOnTitle && !!onCTAClick, children: title }) }), _jsx(Typography, { color: colors.velvetLight, fontSize: "0.875rem", children: brand }), price !== undefined && (_jsxs(Grid, { flexDirection: "row", width: "fit-content", children: [_jsx(Typography, { color: colors.velvetLight, fontSize: "0.7344rem", fontWeight: 500, children: conversionRate
                                            ? formatUSD(price * conversionRate.ETH.USD)
                                            : "-" }), _jsxs(NoWrapTypography, { fontSize: "0.6294rem", color: colors.velvetUltraLight, marginLeft: "0.3125rem", marginTop: "0.0938rem", children: ["(", formatWETH(price), " WETH)"] })] }))] }), !hideCTA && (_jsx(CtaWrapper, { justifyContent: "center", alignItems: "center", "$isMobile": isMobile, children: status === eFNFTStatus.CheckedIn &&
                            (checkoutRequestStatus === eCheckoutRequestStatus.CheckedIn ||
                                !checkoutRequestStatus) ? (_jsx(RequestCheckoutButton, { variant: "pinkLight", size: "small", tokenId: tokenId, nftContract: nftContract, offerId: offerId, onSuccess: onSuccess, onError: onError, onPendingSignature: () => {
                                onPendingSignature?.();
                            }, onPendingTransaction: (hash, _, actionName) => {
                                onPendingTransaction?.(hash, _, actionName);
                            } })) : (_jsx(Button, { onClick: () => {
                                onCTAClick?.();
                            }, variant: "pinkLight", size: "small", children: "View" })) }))] })] }));
};
