import { BigNumber, utils } from "ethers";

export const formatDecimalsToIntValue = (
  value: number,
  decimals: number,
): BigNumber => {
  return utils.parseUnits(
    typeof value === "number" ? value.toFixed(decimals) : value || "0",
    decimals,
  );
};
