import { SelectProps } from "@bosonprotocol/react-kit";

import { ProductCategoryLabels } from "./productCategories";

const defaultProductConditionOptions: SelectProps["options"] = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Used",
    value: "used",
  },
] as const;

const watchProductConditionOptions: SelectProps["options"] = [
  {
    label: "Exceptional",
    value: "exceptional",
  },
  {
    label: "Excellent",
    value: "excellent",
  },
  {
    label: "Very good",
    value: "very good",
  },
  {
    label: "Good",
    value: "good",
  },
  {
    label: "Fair",
    value: "fair",
  },
] as const;

export const productConditionsOptions = [
  ...defaultProductConditionOptions,
  ...watchProductConditionOptions,
];

export const getProductCondition = (currentCategory?: string) => {
  if (currentCategory === ProductCategoryLabels.Watches) {
    return watchProductConditionOptions;
  }
  return defaultProductConditionOptions;
};
