import { hooks as bosonHooks, Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";
import dayjs from "dayjs";
import styled from "styled-components";

interface Props {
  timesteps: { text: string; date?: string }[];
}

const Li = styled.li`
  position: relative;
  list-style: none;
  padding-bottom: 1rem;
  &::after {
    position: absolute;
    top: 0;
    left: -1rem;
    transform: translate(-50%, 60%);
    content: "";
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 999px;
    background-color: ${colors.velvetHalf};
  }
  &:not(:last-child)::before {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    height: 90%;
    left: -1.08rem;
    background-color: ${colors.velvetHalf};
    width: 2px;
  }
`;

const formatShortDate = (date: string, shortDateFormat: string) => {
  return date
    ? dayjs(new Date(Number(date) * 1000)).format(shortDateFormat)
    : "";
};

export const Timeline = ({ timesteps }: Props) => {
  const { shortDateFormat } = bosonHooks.useConfigContext();
  return (
    <ul>
      {timesteps
        .filter((step): step is Required<typeof step> => !!step.date)
        .sort((a, b) => {
          if (a.date === b.date || !a || !b) {
            return 0;
          }
          return a.date > b.date ? 1 : -1;
        })
        .map((step, index) => (
          <Li key={index}>
            <Typography fontSize="0.875rem" tag="span">
              {step.text}
            </Typography>
            <Typography color={colors.velvetHalf} fontSize="0.75rem" tag="span">
              {step.date ? formatShortDate(step.date, shortDateFormat) : ""}
            </Typography>
          </Li>
        ))}
    </ul>
  );
};
