import { BaseButton, BaseButtonProps } from "@bosonprotocol/react-kit";
import { forwardRef, ReactNode } from "react";

import { buttonThemes } from "./themes";

export type ButtonProps = Omit<BaseButtonProps, "theme"> & {
  variant: keyof typeof buttonThemes;
  children: ReactNode;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, ...rest }, ref) => {
    return <BaseButton {...rest} theme={buttonThemes[variant]} ref={ref} />;
  },
);
