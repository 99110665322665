import { eCheckoutRequestStatus, eFNFTStatus } from "@fermionprotocol/common";

import { BaseLabel } from "./BaseLabel";
import { getStatusToData } from "./getStatusToData";

type FnftLabelProps = {
  status: eFNFTStatus;
  checkoutRequestStatus?: eCheckoutRequestStatus;
  className?: string;
  fontSize?: string;
};

export const FnftLabel = ({
  status,
  checkoutRequestStatus,
  className,
  fontSize = "1rem",
}: FnftLabelProps) => {
  const {
    backgroundColor,
    icon: Icon,
    label,
    color,
  } = getStatusToData({ status, checkoutRequestStatus });
  return (
    <BaseLabel
      backgroundColor={backgroundColor}
      color={color}
      icon={Icon}
      label={label}
      className={className}
      fontSize={fontSize}
    />
  );
};
