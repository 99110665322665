import { borders } from "../../styles/borders";
import { colors, defaultColors } from "../../styles/colors";
export const inputTheme = {
    background: defaultColors.inputBackgroundColor,
    borderColor: colors.border,
    borderRadius: borders.defaultBorderRadiusPx,
    focus: {
        caretColor: colors.pink,
    },
    hover: {
        borderColor: colors.pink,
    },
    error: {
        borderColor: colors.orange,
        hover: {
            borderColor: colors.orange,
        },
        focus: {
            borderColor: colors.orange,
            caretColor: colors.orange,
        },
        placeholder: {
            color: colors.orange,
        },
    },
};
export const checkBoxTheme = {
    ...inputTheme,
    backgroundColor: colors.shade,
    borderColor: colors.shade,
    borderRadius: "4px",
    hover: {
        ...inputTheme.hover,
        backgroundColor: colors.shade,
        borderColor: colors.hoveredShade,
    },
    checked: {
        color: colors.pink,
        borderColor: colors.pinkLight,
        backgroundColor: colors.pinkLight,
    },
};
export const textAreaTheme = inputTheme;
export const clearButtonTheme = {
    fill: "#cccccc",
    stroke: "#cccccc",
    hover: {
        fill: "#999999",
        stroke: "#999999",
    },
};
