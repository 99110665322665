import { EvaluationMethod, TokenType } from "@bosonprotocol/common";
import { BuyerTransferInfo } from "@bosonprotocol/react-kit";

const yesOrNoOptions = [
  {
    value: "false",
    label: "No",
  },
  {
    value: "true",
    label: "Yes",
  },
] as const;
export const OPTIONS_TOKEN_GATED = [...yesOrNoOptions] as const;

export enum TokenTypes {
  "erc20" = "erc20",
  "erc721" = "erc721",
  "erc1155" = "erc1155",
}

export const TokenTypeEnumToString = {
  [TokenType.FungibleToken]: TokenTypes.erc20,
  [TokenType.NonFungibleToken]: TokenTypes.erc721,
  [TokenType.MultiToken]: TokenTypes.erc1155,
} as const;

export const TOKEN_TYPES = [
  {
    value: TokenTypes.erc20,
    label: "ERC20",
  },
  {
    value: TokenTypes.erc721,
    label: "ERC721",
  },
  {
    value: TokenTypes.erc1155,
    label: "ERC1155",
  },
] as const;

export const NFT_TOKEN_TYPES = [
  {
    value: TokenTypes.erc721,
    label: "ERC721",
  },
  {
    value: TokenTypes.erc1155,
    label: "ERC1155",
  },
] as const;

export const BUYER_TRANSFER_INFO_OPTIONS = [
  {
    value: BuyerTransferInfo.email,
    label: "E-mail",
  },
  {
    value: BuyerTransferInfo.walletAddress,
    label: "Wallet address",
  },
] as const;

export const TOKEN_CRITERIA = [
  {
    value: "minbalance",
    label: "Collection balance",
    method: EvaluationMethod.Threshold,
  },
  {
    value: "tokenid",
    label: "Specific token",
    method: EvaluationMethod.TokenRange,
  },
] as const;

// TODO: export const OPTIONS_COUNTRIES = countries;

export const OPTIONS_LENGTH = [
  {
    value: "cm",
    label: "Centimetres",
  },
  {
    value: "m",
    label: "Metres",
  },
  {
    value: "in",
    label: "Inches",
  },
  {
    value: "ft",
    label: "Feet",
  },
] as const;

export const OPTIONS_WEIGHT = [
  {
    value: "g",
    label: "Gram",
  },
  {
    value: "kg",
    label: "Kilogram",
  },
] as const;

export enum ProductTypeTypeValues {
  physical = "physical",
  phygital = "phygital",
}

export enum ProductTypeVariantsValues {
  oneItemType = "oneItemType",
  differentVariants = "differentVariants",
}

export enum TypeKeys {
  Size = "Size",
  Color = "Color",
}

export enum ImageSpecificOrAll {
  all = "all",
  specific = "specific",
}

export const IMAGE_SPECIFIC_OR_ALL_OPTIONS = [
  {
    value: ImageSpecificOrAll.all,
    label: "All",
  },
  {
    value: ImageSpecificOrAll.specific,
    label: "Specific",
  },
] as const;

export const ProductMetadataAttributeKeys = {
  "Token Type": "Token Type",
  "Redeemable At": "Redeemable At",
  "Redeemable Until": "Redeemable Until",
  "Redeemable After X Days": "Redeemable After X Days",
  Seller: "Seller",
  [TypeKeys.Size]: TypeKeys.Size,
  [TypeKeys.Color]: TypeKeys.Color,
} as const;
