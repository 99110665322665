import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@bosonprotocol/react-kit";
import styled from "styled-components";
import { borders } from "../../styles/borders";
const Label = styled.div `
  display: flex;
  background-color: ${(props) => props.$backgroundColor || "transparent"};
  color: ${(props) => props.$color || "black"};
  padding: 0.25rem 0.475rem 0.25rem 0.375rem;
  width: max-content;
  justify-content: center;
  align-items: center;
  border-radius: ${borders.small}px;
  svg {
    margin-right: 0.25rem;
  }
`;
export const BaseLabel = ({ backgroundColor, color, icon: Icon, label, className, fontSize, }) => {
    return (_jsxs(Label, { "$backgroundColor": backgroundColor, "$color": color, className: className, children: [_jsx(Icon, { size: 16, weight: "bold" }), _jsx(Typography, { tag: "span", fontSize: fontSize, fontWeight: 500, children: label })] }));
};
