import styled from "styled-components";
export const MoonPayWrapper = styled.div `
  > * {
    margin: 0 !important;
    border: none !important;
  }
  iframe {
    border: none;
  }
`;
