import { jsx as _jsx } from "react/jsx-runtime";
import { FormField as BosonFormField, } from "@bosonprotocol/react-kit";
import styled from "styled-components";
const StyledBosonFormField = styled(BosonFormField) `
  margin-bottom: 2rem;
  :first-child {
    margin: 0;
  }
`;
export const FormField = (props) => {
    return _jsx(StyledBosonFormField, { ...props });
};
