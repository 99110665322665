import { Grid } from "@bosonprotocol/react-kit";
import { CaretDown, CaretUp } from "phosphor-react";
import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";

import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  border: 1px solid ${colors.hoveredShade};
  border-radius: ${borders.big}px;
`;

export const TableStyled = styled.div`
  width: 100%;
  border-collapse: collapse;
  .th {
    font-weight: 600;
    color: ${colors.greyDark};
    :not([data-sortable]) {
      cursor: default !important;
    }
    [data-sortable] {
      cursor: pointer !important;
    }
    .td {
      &:nth-of-type(1) {
        max-width: 6.25rem;
      }
    }
  }
  .td {
    font-weight: 400;
    color: ${colors.black};
    &:nth-of-type(1) {
      max-width: 2.5rem;
    }
  }
  .th,
  .td {
    font-style: normal;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  .thead {
    width: 100%;
    min-width: max-content;
    .tr {
      display: flex;
      flex: 1 0 auto;
      min-width: 0;
      padding-bottom: 1.4375rem;
      .th {
        border-bottom: 2px solid ${colors.hoveredShade};
        text-align: left;
        padding: 0.5rem;
        padding-left: 0;
        width: 100%;
        &:first-child {
          padding-left: 0.5rem;
        }
      }
    }
  }
  .tbody {
    padding-top: 0.3125rem;
    width: 100%;
    min-width: max-content;
    .row {
      display: flex;
      @media (min-width: 87.8125rem) {
        justify-content: center;
      }
      align-items: center;
      margin: 0.5rem 0;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      border-bottom: 1px solid ${colors.hoveredShade};
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0.9375rem;
        border: none;
      }
      .rowCell {
        width: 100%;
      }
      [role="cell"] {
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
    .tr {
      &:not(:last-child) {
        .td {
          border-bottom: 1px solid ${colors.border};
        }
      }
      .td {
        text-align: left;
        padding: 0.5rem;
        align-items: center;
        display: flex;
        &:last-child {
          display: flex;
          justify-content: flex-end;
          > button {
            display: inline-block;
          }
        }
      }
    }
  }
  [data-testid="price"] {
    transform: scale(0.75);
  }
  .subrows-table {
    width: 80%;
    margin: 0 auto;
    min-width: 40rem;
    border-radius: ${borders.big}px;
    border-collapse: separate;
    padding-bottom: 1.5rem;
    .thead {
      background-color: ${colors.white};
      height: unset;
      .tr {
        border: 1px solid ${colors.hoveredShade};
        border-top-left-radius: ${borders.small}px;
        border-top-right-radius: ${borders.small}px;
        padding: 0;
        background-color: ${colors.white};
        .th {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          border-bottom: 0;
          &:last-child {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-right: 1.5rem;
          }
        }
      }
    }
    .tbody {
      border-left: 1px solid ${colors.hoveredShade};
      border-right: 1px solid ${colors.hoveredShade};
      border-bottom: 1px solid ${colors.hoveredShade};
      border-bottom-left-radius: ${borders.small}px;
      border-bottom-right-radius: ${borders.small}px;
      border-top: 1px solid ${colors.hoveredShade};
      background-color: ${colors.white};
      .row {
        justify-content: flex-start;
        height: 3.75rem;
      }
      .tr {
        display: flex;
        margin: 0;
        &:not(:last-child) {
          .td {
            border: 0;
          }
        }
        .td {
          text-align: center;
          width: 100%;
          max-width: none;
          border: 0;
          padding: 0;
          &:nth-of-type(1) {
            max-width: 100%;
          }
        }
        .td:last-child {
          margin-left: auto;
          border: 0;
        }
      }
    }
  }
`;

const HeaderSorter = styled.span`
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
`;

interface TableProps<T extends Record<string, unknown>> {
  tableProps: ReturnType<typeof useTable<T>>;
  parseColumnType: (
    columnId: string,
    columnText: string,
    row: T,
  ) => React.ReactNode;
}

export const Table = <T extends Record<string, unknown>>({
  tableProps,
  parseColumnType,
}: TableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableProps;

  return (
    <TableContainer style={{ width: "100%", overflow: "auto" }}>
      <TableStyled {...getTableProps()}>
        <div className="thead">
          {headerGroups.map((headerGroup, key) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              key={`table_thead_tr_${key}`}
              className="tr"
            >
              {headerGroup.headers.map((column, i) => (
                <div
                  data-sortable={column.disableSortBy}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={`table_thead_th_${i}`}
                  className="th"
                >
                  <Grid justifyContent="flex-start" alignItems="center">
                    {column.render("Header")}
                    {i > 0 && !column.disableSortBy && column?.isSorted && (
                      <HeaderSorter>
                        {column?.isSortedDesc ? (
                          <CaretDown size={14} />
                        ) : (
                          <CaretUp size={14} />
                        )}
                      </HeaderSorter>
                    )}
                  </Grid>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="tbody">
          {(page || []).map((row, index) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                key={`table_tbody_tr_${row.id}-${index}`}
                className={`row ${row.depth === 1 ? "subRow" : ""}`}
              >
                {row.cells.map((cell) => (
                  <div
                    {...cell.getCellProps()}
                    key={`table_tbody_td_${row.id}-${cell.column.id}`}
                    className="rowCell"
                    onClick={() => {
                      cell.row.toggleRowExpanded();
                    }}
                  >
                    {parseColumnType(cell.column.id, cell.value, row.original)}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </TableStyled>
    </TableContainer>
  );
};
