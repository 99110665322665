import { Grid, Typography } from "@bosonprotocol/react-kit";
import { colors } from "@fermionprotocol/react-kit";
import { TransparentButton } from "components/table/TableStyles";
import { Dispatch, SetStateAction } from "react";

interface FilterButtonsProps {
  buttons: {
    label: string;
    value: string;
  }[];
  activeFilterButton?: string;
  setActiveFilterButton: Dispatch<SetStateAction<string | undefined>>;
}

export function FilterButtons({
  buttons,
  setActiveFilterButton,
  activeFilterButton,
}: FilterButtonsProps) {
  return (
    <Grid alignItems="center" justifyContent="flex-start">
      {buttons.map((button) => {
        return (
          <TransparentButton key={button.label}>
            <Typography
              tag="h4"
              fontWeight="500"
              marginRight="1rem"
              color={
                activeFilterButton === button.value
                  ? colors.purpleDeep
                  : colors.greyDark
              }
              onClick={() => {
                setActiveFilterButton(button.value);
              }}
            >
              {button.label}
            </Typography>
          </TransparentButton>
        );
      })}
    </Grid>
  );
}
