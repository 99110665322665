import {
  Grid,
  hooks as bosonHooks,
  Typography,
} from "@bosonprotocol/react-kit";
import { Role } from "@fermionprotocol/react-kit";
import { FermionConnectWallet } from "components/connection/FermionConnectWallet";
import { useModal } from "components/modal/useModal";
import { useEffect } from "react";

import { CreateProfileSteps } from "./const";
import { CreateProfileFlow } from "./CreateProfileFlow";

export interface CreateProfileModalProps {
  step?: CreateProfileSteps;
  selectedRole?: Role;
}
export const CreateProfileModal = ({
  step,
  selectedRole,
}: CreateProfileModalProps) => {
  const { address } = bosonHooks.useAccount();
  const { updateProps, store } = useModal();
  useEffect(() => {
    if (!address) {
      updateProps<"CREATE_PROFILE">({
        ...store,
        modalProps: {
          ...store.modalProps,
          headerComponent: (
            <Grid flexDirection="column" alignItems="flex-start">
              <Typography tag="h3" fontWeight={500} marginBottom="0.5rem">
                Connect your wallet
              </Typography>
            </Grid>
          ),
          step: step,
          selectedRole: selectedRole,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  if (!address) {
    return (
      <Grid gap="1rem" flexDirection="column">
        <Typography>
          To create a profile you must first connect your wallet
        </Typography>
        <FermionConnectWallet />
      </Grid>
    );
  }
  return <CreateProfileFlow defaultStep={step} defaultRole={selectedRole} />;
};
