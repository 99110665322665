import { Grid, Typography } from "@bosonprotocol/react-kit";
import { borders, Button, colors } from "@fermionprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import { Copy } from "phosphor-react";
import { useEffect } from "react";
import styled from "styled-components";

const List = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ExampleMailContainer = styled.div`
  border: 1px solid ${colors.hoveredShade};
  padding: 1.5rem;
  border-radius: ${borders.big}px;
  max-width: 520px;
`;

const StyledMailHref = styled.a`
  color: ${colors.velvetLight};
  text-decoration: none;
  font-size: 0.875rem;
`;

export function SendToVerifierModal() {
  const { updateProps, store, hideModal } = useModal();
  useEffect(() => {
    updateProps<"EDIT_ROLE">({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              fontSize={"1.5rem"}
              fontWeight={400}
              marginBottom="0.5rem"
              marginTop="0.25rem"
              color={colors.velvet}
            >
              Send to Verifier
            </Typography>
          </Grid>
        ),
        footerComponent: (
          <Grid
            width={"100%"}
            justifyContent="space-between"
            alignItems="center"
            padding={"1.5rem 2rem"}
          >
            <Button
              variant="transparent"
              type="button"
              onClick={() => {
                hideModal();
              }}
            >
              Close
            </Button>
            <Button variant="pinkLight" type="submit">
              Mark as “Sent to Verifier”
            </Button>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        tag="h4"
        fontWeight={400}
        color={colors.velvet}
        fontSize={"1.125rem"}
        marginBottom={"1rem"}
        marginTop={0}
      >
        {/* TODO: Header font here */}
        How it works?
      </Typography>
      <List>
        <ListItem>
          <Typography
            width="2rem"
            textAlign="center"
            margin={0}
            tag="h2"
            color={colors.pink}
          >
            {/* TODO: Header font here and in the lower cases*/}
            01
          </Typography>
          <Typography
            fontSize={"0.875rem"}
            fontWeight={500}
            marginLeft={"1rem"}
            color={colors.velvet}
          >
            Arrange a delivery date with the verifier using the template below
          </Typography>
        </ListItem>
        <ListItem>
          <Typography
            width="2rem"
            textAlign="center"
            margin={0}
            tag="h2"
            color={colors.pink}
          >
            02
          </Typography>
          <Typography
            fontSize={"0.875rem"}
            fontWeight={500}
            marginLeft={"1rem"}
            color={colors.velvet}
          >
            Arrange for a carrier and prepare your asset for shipment
          </Typography>
        </ListItem>
        <ListItem>
          <Typography
            width="2rem"
            textAlign="center"
            margin={0}
            tag="h2"
            color={colors.pink}
          >
            03
          </Typography>
          <Typography
            fontSize={"0.875rem"}
            fontWeight={500}
            marginLeft={"1rem"}
            color={colors.velvet}
          >
            Include all items described in your offer, along with the fNFT ID
          </Typography>
        </ListItem>
        <ListItem>
          <Typography
            width="2rem"
            textAlign="center"
            margin={0}
            tag="h2"
            color={colors.pink}
          >
            04
          </Typography>
          <Typography
            fontSize={"0.875rem"}
            fontWeight={500}
            marginLeft={"1rem"}
            color={colors.velvet}
          >
            Send asset to the shipping address after verifier confirmation
          </Typography>
        </ListItem>
      </List>
      <ExampleMailContainer>
        <Typography
          tag="h4"
          fontWeight={400}
          fontSize={"1.125rem"}
          color={colors.velvet}
          margin={0}
        >
          Request for verification template
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={"14px"}
          color={colors.velvetLight}
        >
          Send an email to the verifier using your business email address and
          copy the details below.
        </Typography>
        <Grid justifyContent="start" alignItems="center" marginTop={"2rem"}>
          <Typography
            fontSize={"0.875rem"}
            fontWeight={500}
            color={colors.velvet}
          >
            Email Address
          </Typography>
          <Button
            variant="transparent"
            style={{
              paddingLeft: "0.3125rem",
            }}
          >
            <Copy size={16} color={colors.pink} />
          </Button>
        </Grid>
        <Typography
          fontSize={"0.875rem"}
          fontWeight={400}
          color={colors.velvetLight}
        >
          <StyledMailHref href="mailto:info@bestverify.com">
            info@bestverify.com
          </StyledMailHref>
        </Typography>
        <Grid justifyContent="start" alignItems="center">
          <Typography
            fontSize={"0.875rem"}
            fontWeight={500}
            color={colors.velvet}
          >
            Email Subject
          </Typography>
          <Button
            variant="transparent"
            style={{
              paddingLeft: "0.3125rem",
            }}
          >
            <Copy size={16} color={colors.pink} />
          </Button>
        </Grid>
        <Typography
          fontSize={"0.875rem"}
          fontWeight={400}
          color={colors.velvetLight}
        >
          Fermion asset verification: pre-booking of delivery date
        </Typography>
        <Grid justifyContent="start" alignItems="center">
          <Typography
            fontSize={"0.875rem"}
            fontWeight={500}
            color={colors.velvet}
          >
            Email Body
          </Typography>
          <Button
            variant="transparent"
            style={{
              paddingLeft: "0.3125rem",
            }}
          >
            <Copy size={16} color={colors.pink} />
          </Button>
        </Grid>
        <Typography
          fontSize={"0.875rem"}
          fontWeight={400}
          color={colors.velvetLight}
        >
          We would like to arrange the delivery of an asset listed on Fermion
          for your authentication services. Please confirm the delivery details,
          including the shipping address and the proposed delivery date.
          <br />
          <br />
          fNFT ID: [dynamic ID]
          <br />
          Seller name: [dynamic]
          <br />
          Proposed date & time of delivery: [Date & time entered by Seller]
          <br />
          Shipping address: [dynamic]
        </Typography>
      </ExampleMailContainer>
    </>
  );
}
