import {
  Action,
  Grid,
  hooks as bosonHooks,
  Typography,
} from "@bosonprotocol/react-kit";
import { eCheckoutRequestStatus, eFNFTStatus } from "@fermionprotocol/common";
import { css, styled } from "styled-components";

import { useConversionPrice } from "../../hooks";
import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";
import { formatUSD } from "../../utils/currency/formatUSD";
import { Button } from "../buttons/Button";
import { RequestCheckoutButton } from "../buttons/cta/exchanges/RequestCheckoutButton";
import { FnftLabel } from "../labels/FnftLabel";
import { getStatusToData } from "../labels/getStatusToData";
import { SectionHeader } from "../typography/SectionHeader";

const StyledFnftLabel = styled(FnftLabel)`
  text-transform: uppercase;
  padding: 0.25rem 0.375rem;
`;

const Bottom = styled.div`
  width: 100%;
  flex: 1 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.white};
`;

const CtaWrapper = styled(Grid)<{ $isMobile: boolean }>`
  transition: all 200ms ease-in-out;
  overflow: hidden;
  > button {
    width: 100%;
    height: 2.125rem;
    margin: 1rem;
    margin-top: 0;
  }

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          max-height: none;
        `
      : css`
          max-height: 0;
        `}
`;

const Wrapper = styled.div<{ $fontColor: string; $isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid ${colors.velvetBorder};
  border-radius: ${borders.big}px;
  overflow: hidden;
  min-height: 19.125rem;
  > img,
  button > img,
  button > video,
  video {
    background-color: ${colors.whiteOff};
    border-top-left-radius: ${borders.big}px;
    border-top-right-radius: ${borders.big}px;
    height: 13.5rem;
    min-width: 100%;
    object-fit: cover;
    object-position: ${(props) => (props.$isMobile ? "center" : "bottom")};
    transition: scale 200ms ease-in-out;
  }

  ${({ $isMobile, $fontColor }) =>
    !$isMobile &&
    css`
      &:hover {
        border-color: ${$fontColor};
        > img,
        > video {
          scale: 1.2;
        }
        ${CtaWrapper} {
          max-height: 200px;
        }
      }
    `}

  img + *,
  video + * {
    z-index: 1;
    overflow: hidden;
    background-color: ${colors.white};
  }
  ${StyledFnftLabel} {
    z-index: 1;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
`;

const NoWrapTypography = styled(Typography)`
  white-space: nowrap;
`;

type FnftInCustodyStatusProps = {
  status: eFNFTStatus.CheckedIn;
  tokenId: string;
  nftContract: string;
  offerId: string;
};

export type FnftCardProps = {
  media: {
    url: string;
    type: "image" | "video";
  };
  title: string;
  brand: string;
  checkoutRequestStatus?: eCheckoutRequestStatus | undefined;
  price?: number;
  hideCTA?: boolean;
  onCTAClick?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  onPendingSignature?: (actionName?: Action["name"]) => void;
  onPendingTransaction?: (
    txHash: string,
    isMetaTx?: boolean,
    actionName?: Action["name"],
  ) => void;
  CTAOnTitle?: boolean;
  CTAOnImage?: boolean;
} & (
  | FnftInCustodyStatusProps
  | {
      status: Exclude<eFNFTStatus, 4>;
      tokenId?: string;
      nftContract?: string;
      offerId?: string;
    }
);

interface CTAButtonWrapperProps {
  show: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

const formatWETH = (value: number) => {
  return value.toFixed(4);
};

const CTAActionDuplicate: React.FC<CTAButtonWrapperProps> = ({
  children,
  onClick,
  show,
}) => {
  if (!show) return <>{children}</>;
  return (
    <button
      type="button"
      style={{ display: "inline-block", width: "100%" }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const FnftCard = ({
  tokenId,
  nftContract,
  offerId,
  media: { url, type },
  title,
  brand,
  status,
  checkoutRequestStatus,
  price,
  onCTAClick,
  onSuccess,
  onError,
  onPendingSignature,
  onPendingTransaction,
  CTAOnImage,
  CTAOnTitle,
  hideCTA = false,
}: FnftCardProps) => {
  const windowBreakpoints = bosonHooks.useBreakpoints();
  const isMobile = windowBreakpoints.isLteM;

  const { data: conversionRate } = useConversionPrice({
    fromTokens: ["ETH"],
    toToken: "USD",
  });
  const { color } = getStatusToData({ status, checkoutRequestStatus });

  return (
    <Wrapper $fontColor={color} $isMobile={isMobile}>
      <StyledFnftLabel
        status={status}
        checkoutRequestStatus={checkoutRequestStatus}
        fontSize="0.75rem"
      />
      <CTAActionDuplicate
        onClick={onCTAClick}
        show={!hideCTA && !!CTAOnImage && !!onCTAClick}
      >
        {type === "image" ? (
          <img src={url} style={{ aspectRatio: "1/1" }} />
        ) : (
          <video src={url} />
        )}
      </CTAActionDuplicate>
      <Bottom>
        <Grid
          flexDirection="column"
          padding="1rem"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <SectionHeader
            tag="h4"
            margin={0}
            fontWeight={400}
            fontSize={"0.9437rem"}
            color={colors.velvet}
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            <CTAActionDuplicate
              onClick={onCTAClick}
              show={!hideCTA && !!CTAOnTitle && !!onCTAClick}
            >
              {title}
            </CTAActionDuplicate>
          </SectionHeader>
          <Typography color={colors.velvetLight} fontSize={"0.875rem"}>
            {brand}
          </Typography>
          {price !== undefined && (
            <Grid flexDirection="row" width={"fit-content"}>
              <Typography
                color={colors.velvetLight}
                fontSize={"0.7344rem"}
                fontWeight={500}
              >
                {conversionRate
                  ? formatUSD(price * conversionRate.ETH.USD)
                  : "-"}
              </Typography>
              <NoWrapTypography
                fontSize={"0.6294rem"}
                color={colors.velvetUltraLight}
                marginLeft={"0.3125rem"}
                marginTop={"0.0938rem"}
              >
                ({formatWETH(price)} WETH)
              </NoWrapTypography>
            </Grid>
          )}
        </Grid>
        {!hideCTA && (
          <CtaWrapper
            justifyContent="center"
            alignItems="center"
            $isMobile={isMobile}
          >
            {status === eFNFTStatus.CheckedIn &&
            (checkoutRequestStatus === eCheckoutRequestStatus.CheckedIn ||
              !checkoutRequestStatus) ? (
              <RequestCheckoutButton
                variant="pinkLight"
                size="small"
                tokenId={tokenId}
                nftContract={nftContract}
                offerId={offerId}
                onSuccess={onSuccess}
                onError={onError}
                onPendingSignature={() => {
                  onPendingSignature?.();
                }}
                onPendingTransaction={(hash, _, actionName) => {
                  onPendingTransaction?.(hash, _, actionName);
                }}
              />
            ) : (
              <Button
                onClick={() => {
                  onCTAClick?.();
                }}
                variant="pinkLight"
                size="small"
              >
                View
              </Button>
            )}
          </CtaWrapper>
        )}
      </Bottom>
    </Wrapper>
  );
};
