import { jsx as _jsx } from "react/jsx-runtime";
import { withQueryClientProvider } from "@bosonprotocol/react-kit";
import { useCoreSDKContext } from "../../../../hooks";
import { CtaButton } from "../common/CtaButton";
export const RequestCheckoutButton = withQueryClientProvider(({ tokenId, nftContract, offerId, variant = "pinkLight", ...restProps }) => {
    const coreSdk = useCoreSDKContext();
    const isMetaTx = coreSdk.isMetaTxConfigSet;
    const actions = [
        {
            name: "approveExchangeToken", // name is not matching, but needs changes in Boson CC
            writeContractFn: () => coreSdk.erc721SetApprovalForAll({
                contractAddress: nftContract,
                approved: true,
                useMetaTx: isMetaTx,
                offerId,
            }),
            shouldActionRun: async () => {
                const isApprovedForAll = await coreSdk.erc721IsApprovedForAll({
                    contractAddress: nftContract,
                });
                return !isApprovedForAll;
            },
        },
        {
            name: "depositFunds", // name is not matching, but needs changes in Boson CC
            writeContractFn: () => coreSdk.requestCheckOut(tokenId, { useMetaTx: isMetaTx }),
        },
    ];
    return (_jsx(CtaButton, { variant: variant, defaultLabel: "Request checkout", successPayload: (receipt) => receipt, actions: actions, ...restProps }));
});
