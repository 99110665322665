import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@bosonprotocol/react-kit";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useCallback } from "react";
import { Button } from "../../buttons/Button";
import { Selector } from "./DatePicker.style";
import { changeMonth } from "./utils";
export function SelectMonth({ month, setMonth }) {
    const handleMonthChange = useCallback((isNext) => setMonth(changeMonth(month, isNext)), [setMonth, month]);
    return (_jsxs(Selector, { children: [_jsx(Button, { variant: "pinkLight", onClick: () => handleMonthChange(false), children: _jsx(CaretLeft, { size: 18 }) }), _jsx(Typography, { tag: "p", children: month.format("MMMM YYYY") }), _jsx(Button, { variant: "pinkLight", onClick: () => handleMonthChange(true), children: _jsx(CaretRight, { size: 18 }) })] }));
}
