import { Grid, Typography } from "@bosonprotocol/react-kit";
import { Button } from "@fermionprotocol/react-kit";

export type CreateProductDraftModalProps = {
  chooseNew: () => void;
  chooseDraft: () => void;
};
export const CreateProductDraftModal = ({
  chooseNew,
  chooseDraft,
}: CreateProductDraftModalProps) => {
  return (
    <>
      <Grid flexDirection="column" alignItems="flex-start">
        <Typography tag="h5">We saved your unfinished product</Typography>
        <Typography tag="p">
          Do you wish to continue editing this product or start fresh?
        </Typography>
      </Grid>
      <Grid flexDirection="row" justifyContent="space-between" flexWrap="wrap">
        <Button
          variant="pink"
          onClick={chooseDraft}
          style={{ whiteSpace: "pre-line" }}
        >
          Edit draft
        </Button>
        <Button
          variant="grey"
          onClick={chooseNew}
          style={{ whiteSpace: "pre-line" }}
        >
          Start Fresh
        </Button>
      </Grid>
    </>
  );
};
