import { MultiValue } from "react-select";

export const openSeaTypeOfSaleOptions = [
  { value: "highest", label: "Sell to highest bidder" },
] as const;
export const tokensValues = {
  weth: "weth",
  usdc: "usdc",
} as const;
export const openSeaSupportedCurrencies = [
  { value: tokensValues.weth, label: "WETH" }, // TODO: remove hardcoded values
  { value: tokensValues.usdc, label: "USDC" },
];

export const getCurrencyFlagsFromOptions = (
  selectedOptions: MultiValue<{ value: string }>,
) => {
  return selectedOptions.reduce(
    (acc, option) => {
      if (option.value === tokensValues.weth) {
        acc.isWeth = true;
      }
      if (option.value === tokensValues.usdc) {
        acc.isUsdc = true;
      }
      return acc;
    },
    { isWeth: false, isUsdc: false },
  );
};
