import { stringConstants } from "@fermionprotocol/common";
export const ProductCategoryValues = stringConstants.ProductCategory;
export var ProductCategoryLabels;
(function (ProductCategoryLabels) {
    ProductCategoryLabels["Wine"] = "Wine";
    ProductCategoryLabels["Watches"] = "Watches";
    ProductCategoryLabels["Spirits"] = "Spirits";
    ProductCategoryLabels["Art"] = "Art";
    ProductCategoryLabels["Automobiles"] = "Automobiles";
    ProductCategoryLabels["Commodities"] = "Commodities";
    ProductCategoryLabels["RealEstates"] = "Real Estates";
    ProductCategoryLabels["Other"] = "Other";
})(ProductCategoryLabels || (ProductCategoryLabels = {}));
const productCategoryLabels = Object.values(ProductCategoryLabels);
export const productCategoriesOptions = Object.values(ProductCategoryValues).map((category, index) => {
    const label = productCategoryLabels[index];
    if (!label) {
        throw new Error(`no product label for category ${category}`);
    }
    return {
        label,
        value: category,
    };
});
export const categoryToAttributes = {
    wine: [
        "Wine typology",
        "Age/year",
        "ABV",
        "Size",
        "Country",
        "Region",
        "Varietal/Blend (Grape)",
    ],
    watches: [
        "Year",
        "Make",
        "Model",
        "Ref. number",
        "Case size",
        "Case material",
        "Strap",
        "Movement",
    ],
};
