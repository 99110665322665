import { Grid, Typography } from "@bosonprotocol/react-kit";
import { Button, colors } from "@fermionprotocol/react-kit";
import buyerSellerImg from "assets/buyerSeller.svg";
import { modelContentStyle } from "components/modal/const";
import { ModalTitleHeader } from "components/modal/header/ModalTitleHeader";
import { useModal } from "components/modal/useModal";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import { useEffect } from "react";
import { fermionRoutes } from "router/routeNames";
import styled from "styled-components";

const CreateAccountStyledButton = styled(Button)`
  min-width: 11.6875rem;
  font-size: 0.875rem;
  font-weight: 500;
`;
export type InfoCreateAccountProps = {
  onCreateAccountClick: () => void;
};
export const InfoCreateAccount = ({
  onCreateAccountClick,
}: InfoCreateAccountProps) => {
  const { updateProps, store, hideModal } = useModal();
  const navigate = useCustomNavigate();
  useEffect(() => {
    updateProps({
      ...store,
      modalProps: {
        ...store.modalProps,
        contentStyle: {
          padding: `0 ${modelContentStyle.padding} 0 ${modelContentStyle.padding}`,
        },
        headerComponent: (
          <ModalTitleHeader>Let's get started.</ModalTitleHeader>
        ),
        footerComponent: undefined,
      },
      modalMaxWidth: {
        m: "900px",
      },
      modalMinWidth: {
        m: "900px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid flexDirection="column" justifyContent="center" alignItems="center">
      <img
        src={buyerSellerImg}
        style={{ margin: "0 2.5rem 0 2.5rem" }}
        width="350"
        height="325"
      />
      <Grid
        justifyContent="center"
        flexDirection="column"
        style={{ width: "32rem" }}
      >
        <Typography fontSize="1.5rem" tag="h3" color={colors.velvet} margin={0}>
          Which of these best describes you?
        </Typography>
        <Typography
          fontWeight="400"
          fontSize="0.875rem"
          color={colors.velvetLight}
          textAlign="center"
        >
          We will help you get set up based on how you will use Fermion
        </Typography>
      </Grid>
      <Grid
        justifyContent="center"
        marginTop="1.5rem"
        marginBottom="2.5rem"
        gap="1.5rem"
      >
        <CreateAccountStyledButton
          variant="blue"
          type="button"
          onClick={() => {
            navigate({
              pathname: fermionRoutes.buyer,
            });
            hideModal();
          }}
        >
          Buyer
        </CreateAccountStyledButton>
        <CreateAccountStyledButton
          variant="pink"
          type="button"
          onClick={onCreateAccountClick}
        >
          All other roles
        </CreateAccountStyledButton>
      </Grid>
    </Grid>
  );
};
