import { UseQueryResult } from "react-query";

type Falsy = false | 0 | "" | null | undefined;

export const isTruthy = <T>(x: T | Falsy): x is T => !!x;

// This allows us to create a string type that is compatible with all strings and can be used in a union with other hardcoded strings.
// eslint-disable-next-line @typescript-eslint/ban-types
export type AnyString = string & {};

// It is types package, any here allows us to create generic that will properly infer types, this any is not real any it will be infered
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCoreSDKContext } from "../hooks/core-sdk/useCoreSDKContext";

type CoreSdkResult = ReturnType<typeof useCoreSDKContext>;

type AsyncCoreSdkMethods = {
  [key in keyof CoreSdkResult]: CoreSdkResult[key] extends (
    ...args: any[]
  ) => Promise<any>
    ? key
    : never;
}[keyof CoreSdkResult];

export type CoreSdkMethodResponse<CoreSdkMethod extends AsyncCoreSdkMethods> =
  Awaited<
    ReturnType<Awaited<ReturnType<typeof useCoreSDKContext>[CoreSdkMethod]>>
  >;

type InferSelectData<T extends (...args: any[]) => any> = Parameters<
  Exclude<Exclude<Parameters<T>[0]["options"], undefined>["select"], undefined>
>[0];

export type InferQueryResult<T extends (...args: any[]) => UseQueryResult> =
  T extends (...args: any[]) => UseQueryResult<infer U>
    ? U extends unknown
      ? InferSelectData<T>
      : U
    : never;
