import { ModalContextType } from "./types";

export const initalState: ModalContextType = {
  showModal: () => {},
  hideModal: () => {},
  updateProps: () => {},
  store: {
    modalType: null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modalProps: {} as any,
    modalSize: "l",
    modalMaxWidth: null,
    modalMinWidth: null,
  } as const,
};
