import { PendingNavigationContext } from "components/navigation/pendingNavigation/PendingNavigationContext";
import { useContext } from "react";

export const usePendingNavigation = () => {
  const context = useContext(PendingNavigationContext);
  if (!context) {
    throw new Error(
      "usePendingNavigation has to be used within PendingNavigationProvider",
    );
  }
  return context;
};
