import { TransactionReceipt } from "@bosonprotocol/common";
import { withQueryClientProvider } from "@bosonprotocol/react-kit";
import { BigNumberish } from "ethers";

import { useCoreSDKContext } from "../../../../hooks";
import { CtaButton } from "../common/CtaButton";
import { CtaButtonProps } from "../common/types";

type AdditionalProps = {
  accountId: string;
  tokensToWithdraw: {
    tokenAddress: string;
    amount: BigNumberish;
  }[];
  treasuryAddress: string;
};

type SuccessPayload = TransactionReceipt;

export type IWithdrawFundsButton = AdditionalProps &
  CtaButtonProps<SuccessPayload>;

export const WithdrawFundsButton = withQueryClientProvider(
  ({
    accountId,
    variant = "pinkLight",
    tokensToWithdraw,
    treasuryAddress,
    ...restProps
  }: IWithdrawFundsButton) => {
    const coreSdk = useCoreSDKContext();
    const isMetaTx = coreSdk.isMetaTxConfigSet;
    const actions = [
      // Withdraw funds
      {
        writeContractFn: () => {
          return coreSdk.withdrawFunds(
            accountId,
            treasuryAddress,
            tokensToWithdraw,
            { useMetaTx: isMetaTx },
          );
        },
      },
    ];

    return (
      <CtaButton
        variant={variant}
        defaultLabel="Withdraw"
        successPayload={(receipt) => receipt}
        actions={actions}
        {...restProps}
      />
    );
  },
);
