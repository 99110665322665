import { Path } from "@remix-run/router";
import { Link } from "react-router-dom";
import { fermionProtocolRoutes, fermionRoutes } from "router/routeNames";

export type RouteValue =
  | (typeof fermionRoutes)[keyof typeof fermionRoutes]
  | (typeof fermionProtocolRoutes)[keyof typeof fermionProtocolRoutes];

type CustomLinkProps = Omit<Parameters<typeof Link>[0], "to"> & {
  to:
    | RouteValue
    | Partial<Omit<Path, "pathname"> & Partial<{ pathname: RouteValue }>>;
};
export const CustomLink = (props: CustomLinkProps) => {
  return <Link {...props} />;
};
