import { BaseInput, BaseInputProps } from "@bosonprotocol/react-kit";
import { forwardRef } from "react";

import { clearButtonTheme, inputTheme } from "./themes";

export type InputProps = Omit<BaseInputProps, "theme" | "children">;

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <BaseInput
      {...props}
      ref={ref}
      theme={inputTheme}
      clearButtonTheme={clearButtonTheme}
    />
  );
});
