import { zIndex } from "@bosonprotocol/react-kit";
import { createGlobalStyle } from "styled-components";

import { FONT_CONSTANTS } from "../constants/fontsConstants";
import { breakpoint } from "./breakpoint";
import { colors } from "./colors";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    /* zIndex for wallet connect legacy view */
    --wcm-z-index: ${zIndex.Modal + 100};
  }
  :root {
    font-size: 0.75rem;
    ${breakpoint.xs} {
      font-size: 0.75rem;
    }
    ${breakpoint.s} {
      font-size: 0.875rem;
    }
    ${breakpoint.m} {
      font-size: 0.875rem;
    }
    ${breakpoint.l} {
      font-size: 1rem;
    }
    ${breakpoint.xl} {
      font-size: 1rem;
    }
  }

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragment};
  src: url('/fonts/PPFragment-GlareRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareBlack};
  src: url('/fonts/PPFragment-GlareBlack.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareBlackItalic};
  src: url('/fonts/PPFragment-GlareBlackItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareBold};
  src: url('/fonts/PPFragment-GlareBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareBoldItalic};
  src: url('/fonts/PPFragment-GlareBoldItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareExtraBold};
  src: url('/fonts/PPFragment-GlareExtraBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareExtraBoldItalic};
  src: url('/fonts/PPFragment-GlareExtraBoldItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareExtraLight};
  src: url('/fonts/PPFragment-GlareExtraLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareExtraLightItalic};
  src: url('/fonts/PPFragment-GlareExtraLightItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareLight};
  src: url('/fonts/PPFragment-GlareLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareLightItalic};
  src: url('/fonts/PPFragment-GlareLightItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareMedium};
  src: url('/fonts/PPFragment-GlareMedium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareMediumItalic};
  src: url('/fonts/PPFragment-GlareMediumItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareRegular};
  src: url('/fonts/PPFragment-GlareRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareRegularItalic};
  src: url('/fonts/PPFragment-GlareRegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareSemiBold};
  src: url('/fonts/PPFragment-GlareSemiBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareSemiBoldItalic};
  src: url('/fonts/PPFragment-GlareSemiBoldItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareThin};
  src: url('/fonts/PPFragment-GlareThin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ${FONT_CONSTANTS.PPFragmentGlareThinItalic};
  src: url('/fonts/PPFragment-GlareThinItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

  button {
    all: unset;
  }

  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    margin: 0;
    padding: 0;

    color: ${colors.black};

    font-family: "Inter", sans-serif;
    font-style: normal;

    max-height: 100vh;
  }

  a,
  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-style: inherit;
    text-decoration: none;
    &:focus,
    &:hover {
      outline: none;
    }
    cursor: pointer;
  }

  select {
    -webkit-appearance: none;
  }

  input {
    user-select: text;
  }

  * > small {
    font-size: 65%;
    margin: 0 0.5rem;
    opacity: 0.75;
    white-space: pre;
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 140%;
  }
  h2 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 140%;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 140%;
  }
  h4, h5, h6 {
    font-size: 1.125rem;
    line-height: 150%;
  }
  h1, h2, h3, h4 {
    font-family: ${FONT_CONSTANTS.PPFragment}, "Inter", sans-serif;
  }
  a, p, span, div {
    font-size: 1rem;
    line-height: 1.5;
  }
  img, svg {
    user-select: none;
  }
`;
