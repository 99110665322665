import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
import { useMemo } from "react";
import { CalendarCell, CalendarDay, CalendarHeader, CalendarRow, } from "./DatePicker.style";
import { getCalendarRow } from "./utils";
export function Calendar({ date, secondDate, month, period, onChange, minDate, maxDate, }) {
    const firstDay = date ? dayjs(date) : null;
    const secondDay = secondDate ? dayjs(secondDate) : null;
    const handleSelectDate = (value) => {
        const isDateWithinMinMax = (minDate
            ? value?.isSame(minDate, "day") || value?.isAfter(minDate, "day")
            : true) &&
            (maxDate
                ? value?.isSame(maxDate, "day") || value?.isBefore(maxDate, "day")
                : true);
        if (!isDateWithinMinMax) {
            return onChange(null);
        }
        if (period && !isDateWithinMinMax) {
            return onChange(null);
        }
        return onChange(value);
    };
    const rows = useMemo(() => getCalendarRow(month), [month]);
    return (_jsxs(_Fragment, { children: [_jsx(CalendarHeader, { children: rows
                    .slice(0, 7)
                    .map(({ value }, headerIndex) => (_jsx(CalendarCell, { children: value.format("dd") }, `calendar_header_cell_${headerIndex}`))) }), _jsx(CalendarRow, { children: rows.map(({ text, value, current }, i) => {
                    const isBeforeMinDate = minDate
                        ? value?.isBefore(minDate, "day")
                        : false;
                    const isAfterMaxDate = maxDate
                        ? value?.isAfter(maxDate, "day")
                        : false;
                    const disabled = isBeforeMinDate || isAfterMaxDate;
                    return (_jsx(CalendarDay, { active: secondDate
                            ? value.isSame(firstDay, "day") ||
                                value.isSame(secondDay, "day")
                            : value.isSame(firstDay, "day"), between: secondDate ? value.isBetween(firstDay, secondDay, "day") : false, disabled: disabled, current: current, onClick: () => handleSelectDate(value), children: _jsx("span", { children: text }) }, `calendar_row_day${text}-${i}`));
                }) })] }));
}
