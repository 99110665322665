import { useMutation } from "react-query";
import { useCoreSDKContext } from "../useCoreSDKContext";
export const useUpdateEntity = () => {
    const coreSDK = useCoreSDKContext();
    return useMutation((...args) => {
        return coreSDK.updateEntity(args[0], {
            ...args[1],
            useMetaTx: coreSDK.isMetaTxConfigSet,
        });
    });
};
