import { ProfileContactLinkTags, ProfileImageTags, ProfileLocationTags, ProfileSocialLinkTags, } from "../../constants/entityMetadataTags";
import { openSeaSupportedCurrencies } from "../../constants/opensea";
import { productCategoriesOptions } from "../../constants/productCategories";
import { selfVerifierOrThirdPartyOptions, SelfVerifierOrThirdPartyValues, } from "../../constants/selfVerifierOrThirtParty";
import { shippingMethodsOptions, ShippingMethodsValues, } from "../../constants/shippingMethods";
import { storageFeesPeriodOptions } from "../../constants/storageFeesPeriod";
import { emptyInputNumber } from "../../constants/validation";
import { isTruthy } from "../../types/helpers";
import { resolveUrlFromIPFS } from "../ipfs/ipfs";
export function buildProfileFromMetadata(metadata, roles, config) {
    const regularProfileImage = metadata?.images?.find((img) => img.tag === ProfileImageTags.PROFILE);
    const regularCoverPicture = metadata?.images?.find((img) => img.tag === ProfileImageTags.COVER);
    const coverPicture = regularCoverPicture;
    const profileImage = regularProfileImage;
    const email = getMetadataEmail(metadata) || "";
    const verifier = getVerifierFromMetadata(metadata?.verifier);
    const custodian = getCustodianFromMetadata(metadata?.custodian);
    const bridgerVerifier = getVerifierFromMetadata(metadata?.bridger?.verifier);
    const bridgerCustodian = getCustodianFromMetadata(metadata?.bridger?.custodian);
    const profileDataFromMetadata = {
        roles,
        name: metadata?.name ?? "",
        description: metadata?.description ?? "",
        email,
        businessPhoneNumber: metadata?.businessPhoneNumber,
        legalTradingName: metadata?.legalTradingName || "",
        websiteForFermionIdentity: metadata?.fermionIdentityWebsite ?? "",
        coverPicture: coverPicture
            ? [
                {
                    ...coverPicture,
                    src: resolveUrlFromIPFS(coverPicture.url, config.ipfsGateway),
                },
            ]
            : [],
        logo: profileImage
            ? [
                {
                    ...profileImage,
                    src: resolveUrlFromIPFS(profileImage.url, config.ipfsGateway),
                },
            ]
            : [],
        businessLocation: {
            streetName: metadata?.businessLocation?.streetName ?? "",
            number: metadata?.businessLocation?.number ?? "",
            city: metadata?.businessLocation?.city ?? "",
            state: metadata?.businessLocation?.state ?? "",
            country: metadata?.businessLocation?.country ?? "",
            zip: metadata?.businessLocation?.zip ?? "",
        },
        website: metadata?.socialLinks?.find((socialLink) => socialLink.tag === ProfileSocialLinkTags.WEBSITE)?.url ?? "",
        verifier,
        custodian,
        bridger: {
            bridgingRoles: [], // TODO: change
            verifier: bridgerVerifier,
            custodian: bridgerCustodian,
        },
    };
    return profileDataFromMetadata;
}
export function getMetadataEmail(metadata) {
    return (metadata?.contactLinks?.find((cl) => cl.tag === ProfileContactLinkTags.EMAIL)?.url ?? "");
}
function getProductCategoryFromMetadata(category) {
    return category
        ? (productCategoriesOptions.find((productCategory) => productCategory.value === category) ?? null)
        : null;
}
function getSelfVerifierOrThirdPartyFromMetadata(isSelfVerifier) {
    return isSelfVerifier
        ? (selfVerifierOrThirdPartyOptions.find((option) => option.value === SelfVerifierOrThirdPartyValues["true"]) ?? null)
        : (selfVerifierOrThirdPartyOptions.find((option) => option.value === SelfVerifierOrThirdPartyValues["false"]) ?? null);
}
function getVerifierFromMetadata(verifier) {
    let usdcSelected = false;
    let wethSelected = false;
    const feePriceBands = verifier?.feePriceBands?.map((feePriceBand) => {
        const usdc = feePriceBand.tokens?.find((t) => t.address === "0x41e94eb019c0762f9bfcf9fb1e58725bfb0e7582")?.value || emptyInputNumber;
        usdcSelected = usdcSelected || usdc !== emptyInputNumber;
        const weth = feePriceBand.tokens?.find((t) => t.address === "0x52eF3d68BaB452a294342DC3e5f464d7f610f72E")?.value || emptyInputNumber;
        wethSelected = wethSelected || weth !== emptyInputNumber;
        return {
            max: Number(feePriceBand.max.value),
            min: Number(feePriceBand.min.value),
            usdc,
            weth,
        };
    });
    const openSeaSupportedCurrency = (currency) => openSeaSupportedCurrencies.find((c) => c.value === currency);
    const feePriceBandsTokens = [
        usdcSelected ? openSeaSupportedCurrency("usdc") : undefined,
        wethSelected ? openSeaSupportedCurrency("weth") : undefined,
    ].filter(isTruthy);
    return {
        name: verifier?.name,
        category: getProductCategoryFromMetadata(verifier?.categories?.[0]),
        authenticationReport: verifier?.authenticationReport,
        valuationCertificate: verifier?.valuationCertificate,
        feePriceBands,
        feePriceBandsTokens,
        selfOrThirdParty: getSelfVerifierOrThirdPartyFromMetadata(verifier?.isSelfVerifier),
    };
}
function getCustodianFromMetadata(custodian) {
    const location = custodian?.locations?.find((location) => location.tag === ProfileLocationTags.CUSTODY);
    const usdc = custodian?.storageFees?.find((sf) => sf.address === "0x41e94eb019c0762f9bfcf9fb1e58725bfb0e7582")?.value || emptyInputNumber;
    const weth = custodian?.storageFees?.find((sf) => sf.address === "0x52eF3d68BaB452a294342DC3e5f464d7f610f72E")?.value || emptyInputNumber;
    const openSeaSupportedCurrency = (currency) => openSeaSupportedCurrencies.find((c) => c.value === currency);
    const storageFeesTokens = [
        usdc !== emptyInputNumber ? openSeaSupportedCurrency("usdc") : undefined,
        weth !== emptyInputNumber ? openSeaSupportedCurrency("weth") : undefined,
    ].filter(isTruthy);
    return {
        name: custodian?.name,
        category: getProductCategoryFromMetadata(custodian?.categories?.[0]),
        locationName: location?.name ?? "",
        location: getCustodianLocation(location),
        insuredValue: custodian?.insuredValue?.value || emptyInputNumber,
        storageFees: [{ usdc, weth }],
        storageFeesTokens,
        storageFeesPeriod: getStorageFeePeriodFromMetadata(custodian?.storageFeesPeriod) ||
            storageFeesPeriodOptions[0],
        shippingMethod: getCustodianShippingMethods(custodian?.shippingMethods),
    };
}
function getStorageFeePeriodFromMetadata(storageFeePeriod) {
    return storageFeePeriod
        ? (storageFeesPeriodOptions.find((storageFeePeriodOption) => storageFeePeriodOption.value === storageFeePeriod) ?? null)
        : null;
}
function getShippingMethodFromMetadata(shippingMethod) {
    return shippingMethod
        ? (shippingMethodsOptions.find((storageFeePeriodOption) => storageFeePeriodOption.value === shippingMethod) ?? null)
        : null;
}
function getCustodianLocation(location) {
    return {
        city: location?.city ?? "",
        country: location?.country ?? "",
        zip: location?.zip ?? "",
        state: location?.state ?? "",
        streetName: location?.streetName ?? "",
        number: location?.number ?? "",
    };
}
function getCustodianShippingMethods(shippingMethodsFromMetadata) {
    const shippingMethods = shippingMethodsFromMetadata?.map((shippingMethod) => getShippingMethodFromMetadata(shippingMethod));
    const { isDelivery, isPickup } = shippingMethods?.reduce((acum, current) => {
        if (current?.value === ShippingMethodsValues.delivery) {
            acum.isDelivery = true;
        }
        if (current?.value === ShippingMethodsValues.pickup) {
            acum.isPickup = true;
        }
        return acum;
    }, { isDelivery: false, isPickup: false }) || { isDelivery: false, isPickup: false };
    return shippingMethodsOptions.find((shippingMethodOption) => {
        if (isDelivery && isPickup) {
            return (shippingMethodOption.value === ShippingMethodsValues.delivery_and_pickup);
        }
        else if (isDelivery) {
            return shippingMethodOption.value === ShippingMethodsValues.delivery;
        }
        else if (isPickup) {
            return shippingMethodOption.value === ShippingMethodsValues.pickup;
        }
        return false;
    });
}
