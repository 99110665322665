import {
  Grid,
  hooks as bosonHooks,
  Typography,
} from "@bosonprotocol/react-kit";
import { AnyString, MoonPaySellWidget } from "@fermionprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import { useEffect } from "react";

export type OfframpFundsModalProps = {
  tokenOrNativeSymbol: "eth" | AnyString;
};
export const OfframpFundsModal = ({
  tokenOrNativeSymbol,
}: OfframpFundsModalProps) => {
  const { address } = bosonHooks.useAccount();
  const { updateProps, store } = useModal();
  useEffect(() => {
    updateProps<"OFFRAMP_FUNDS">({
      ...store,
      modalProps: {
        tokenOrNativeSymbol,
        contentStyle: {
          padding: "0px",
        },
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography
              fontSize={"1.5rem"}
              fontWeight={500}
              marginBottom="0.5rem"
              marginTop="0.25rem"
            >
              Offramp funds
            </Typography>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenOrNativeSymbol]);
  return (
    <MoonPaySellWidget
      defaultBaseCurrencyCode={tokenOrNativeSymbol}
      walletAddress={address}
    />
  );
};
