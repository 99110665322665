import * as Yup from "yup";

import { getStringValidation } from "./string";
export const getLocationSchema = ({ required }: { required: boolean }) =>
  Yup.object({
    streetName: getStringValidation({ required }),
    number: getStringValidation({ required }),
    city: getStringValidation({ required }),
    state: getStringValidation({ required }),
    zip: getStringValidation({ required }),
    country: getStringValidation({ required }),
  });
