import { stringConstants, } from "@fermionprotocol/common";
import { eEntityRole } from "@fermionprotocol/core-sdk";
export const RoleId = stringConstants.RoleId;
export const allRoles = Object.values(RoleId);
export const roleToEntityRole = {
    [RoleId.SELLER]: eEntityRole.Seller,
    [RoleId.FACILITATOR]: eEntityRole.Seller,
    [RoleId.BRIDGER]: eEntityRole.Seller,
    [RoleId.VERIFIER]: eEntityRole.Verifier,
    [RoleId.CUSTODIAN]: eEntityRole.Custodian,
};
export const entityRoleToRoles = {
    [eEntityRole.Seller]: [RoleId.SELLER, RoleId.FACILITATOR, RoleId.BRIDGER],
    [eEntityRole.Verifier]: RoleId.VERIFIER,
    [eEntityRole.Custodian]: RoleId.CUSTODIAN,
};
