import { useQuery } from "react-query";
import { handleUseQueryError } from "../../../utils/errors/handleUseQueryError";
import { useCoreSDKContext } from "../useCoreSDKContext";
export const useGetBaseEntities = ({ enabled, filter, }) => {
    const coreSDK = useCoreSDKContext();
    return handleUseQueryError(useQuery(["get-base-entities", coreSDK.uuid, filter], () => {
        return coreSDK.getBaseEntities(filter);
    }, {
        enabled: enabled,
    }));
};
