import { IpfsProvider, Web3Provider } from "@bosonprotocol/react-kit";
import {
  CoreSDKProvider,
  FermionConfigProvider,
} from "@fermionprotocol/react-kit";
import { MoonPayProvider } from "@moonpay/moonpay-react";
import { ModalProvider } from "components/modal/ModalProvider";
import { PendingNavigationProvider } from "components/navigation/pendingNavigation/PendingNavigationProvider";
import { ReactNode } from "react";

import { CONFIG, getMetaTxApiIds, getMetaTxApiKey } from "../config";

type ProvidersProps = {
  children: ReactNode;
};
export const Providers = ({ children }: ProvidersProps) => {
  return (
    <Web3Provider
      configProps={{
        configId: CONFIG.config.configId,
        dateFormat: "YYYY/MM/DD",
        defaultCurrencySymbol: "USD",
        defaultCurrencyTicker: "$",
        envName: CONFIG.envName,
        infuraKey: CONFIG.infuraKey,
        shortDateFormat: "MMM DD, YYYY",
        walletConnectProjectId: CONFIG.walletConnectProjectId,
        withWeb3React: true,
        externalConnectedAccount: undefined,
        externalConnectedChainId: undefined,
        externalConnectedSigner: undefined,
        metaTx: undefined,
        usePendingTransactions: undefined,
        withExternalConnectionProps: false,
        withCustomReduxContext: false,
        withMagicLink: false,
      }}
    >
      <IpfsProvider
        ipfsProjectId={CONFIG.infuraProjectId}
        ipfsProjectSecret={CONFIG.infuraProjectSecret}
        ipfsGateway={CONFIG.ipfsGateway}
        ipfsImageGateway={CONFIG.ipfsImageGateway}
      >
        <FermionConfigProvider
          configProps={{
            metaTx: {
              apiKey: getMetaTxApiKey(CONFIG.config),
              apiIds: getMetaTxApiIds(CONFIG.config),
            },
          }}
        >
          <CoreSDKProvider>
            <MoonPayProvider apiKey={CONFIG.moonPayApiKey} debug>
              <PendingNavigationProvider>
                <ModalProvider>{children}</ModalProvider>
              </PendingNavigationProvider>
            </MoonPayProvider>
          </CoreSDKProvider>
        </FermionConfigProvider>
      </IpfsProvider>
    </Web3Provider>
  );
};
