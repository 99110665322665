import {
  EnvironmentType,
  getEnvConfigs,
  ProtocolConfig,
} from "@fermionprotocol/core-sdk";
import { Token } from "@fermionprotocol/react-kit";
import * as Sentry from "@sentry/browser";

const envName = import.meta.env.REACT_APP_ENV_NAME as EnvironmentType;
if (!envName) {
  throw new Error("VITE_ENV_NAME is not defined");
}
const envConfigsFilteredByEnv: ProtocolConfig[] = getEnvConfigs(envName);
const defaultEnvConfig = envConfigsFilteredByEnv[0] as ProtocolConfig;

function stringToBoolean(value: unknown | undefined, defaultValue: boolean) {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  if (typeof value === "string") {
    if (defaultValue) {
      // return true except if value is "0" or "false"
      return !["0", "false"].includes(value);
    } else {
      // return false except if value is "1" or "true"
      return ["1", "true"].includes(value);
    }
  }

  return Boolean(value);
}

export function getMetaTxApiIds(envConfig: ProtocolConfig) {
  const protocolAddress: string = envConfig.contracts.protocolDiamond;
  const defaultTokens: Token[] = envConfig.defaultTokens || [];
  const apiIds: Record<string, Record<string, string>> = {};
  try {
    const apiIdsInputPerConfigId = JSON.parse(
      import.meta.env.REACT_APP_META_TX_API_IDS_MAP || "{}",
    );
    const method = "executeMetaTransaction"; // At the moment, both protocol and tokens have the same method
    const tokens = defaultTokens;
    const apiIdsInput = apiIdsInputPerConfigId[envConfig.configId];
    if (!apiIdsInput) {
      return;
    }
    Object.keys(apiIdsInput).forEach((key) => {
      if (key.toLowerCase() === "protocol") {
        apiIds[protocolAddress.toLowerCase()] = {};
        (apiIds[protocolAddress.toLowerCase()] as Record<string, string>)[
          method
        ] = apiIdsInput[key];
      } else {
        const token = tokens.find(
          (t: Token) => t.symbol.toLowerCase() === key.toLowerCase(),
        );
        if (token) {
          apiIds[token.address.toLowerCase()] = {};
          (apiIds[token.address.toLowerCase()] as Record<string, string>)[
            method
          ] = apiIdsInput[key];
        } else {
          console.error(`Unable to resolve token with symbol ${key}`);
        }
      }
    });
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }
  return apiIds;
}

export function getMetaTxApiKey(envConfig: ProtocolConfig) {
  try {
    const apiKeysPerConfigId = JSON.parse(
      import.meta.env.REACT_APP_META_TX_API_KEY_MAP || "{}",
    );
    return apiKeysPerConfigId[envConfig.configId];
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    // Do not propagate the error
  }
  return "";
}

export const CONFIG = {
  envName,
  config: defaultEnvConfig,
  infuraKey: import.meta.env.REACT_APP_INFURA_KEY || "",
  ipfsGateway: import.meta.env.REACT_APP_IPFS_GATEWAY || "https://ipfs.io/ipfs",
  ipfsImageGateway:
    import.meta.env.REACT_APP_IPFS_IMAGE_GATEWAY || "https://ipfs.io/ipfs",
  infuraProjectId: import.meta.env.REACT_APP_INFURA_IPFS_PROJECT_ID || "",
  infuraProjectSecret:
    import.meta.env.REACT_APP_INFURA_IPFS_PROJECT_SECRET || "",
  walletConnectProjectId:
    import.meta.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "",
  openSeaApiKey: import.meta.env.REACT_APP_OPENSEA_API_KEY || "",
  openSeaFeeRecipient: import.meta.env.REACT_APP_OPENSEA_FEE_RECIPIENT || "",
  openSeaConduit: import.meta.env.REACT_APP_OPENSEA_CONDUIT || "",
  mockConversionRates: stringToBoolean(
    import.meta.env.REACT_APP_MOCK_CONVERSION_RATES,
    false,
  ),
  moonPayApiKey: import.meta.env.REACT_APP_MOONPAY_API_KEY || "",
  infoEmail: "info@fermionapp.io",
  signUpLink: "https://form.typeform.com/to/WbAGPB8w",
};
