import { useAtom } from "jotai";
import _ from "lodash";
import { useQuery } from "react-query";
import * as Yup from "yup";
import { handleUseQueryError } from "../../utils/errors/handleUseQueryError";
import { isObject } from "../../utils/type-guards/isObject";
import { readWriteConversionAtom } from "./conversionCache";
const cryptoCompareApiUrl = "https://min-api.cryptocompare.com";
const responseSchema = (fromSymbols, toSymbol) => Yup.object().test({
    name: "cc-response",
    test: (data) => {
        if (!isObject(data)) {
            return false;
        }
        for (const symbol of fromSymbols) {
            if (!(symbol in data)) {
                return false;
            }
            const conversion = data[symbol];
            if (!isObject(conversion) ||
                !(toSymbol in conversion) ||
                typeof conversion[toSymbol] !== "number") {
                return false;
            }
        }
        return true;
    },
});
export const useConversionPrice = ({ fromTokens, toToken, options, }) => {
    const [cachedConversionRate, setConversionRate] = useAtom(readWriteConversionAtom);
    return handleUseQueryError(useQuery(["conversionPrice", `to-${toToken}`, ...fromTokens.sort()], async () => {
        const cachedData = {};
        let isCached = true;
        for (const fromToken of fromTokens) {
            const fromTokenData = cachedConversionRate[fromToken];
            if (!fromTokenData) {
                isCached = false;
                break;
            }
            const conversionValue = fromTokenData[toToken];
            if (!conversionValue) {
                isCached = false;
                break;
            }
            _.set(cachedData, `${fromToken}.${toToken}`, conversionValue);
        }
        if (isCached)
            return cachedData;
        const response = await fetch(`${cryptoCompareApiUrl}/data/pricemulti?fsyms=${fromTokens.join(",")}&tsyms=${toToken}`);
        if (!response.ok) {
            throw new Error("Failed to fetch conversion price");
        }
        const data = await response.json();
        const parsedData = (await responseSchema(fromTokens, toToken).validate(data));
        setConversionRate(parsedData);
        return parsedData;
    }, options));
};
