import { TimeFrecuencyKeys, TimeFrequencyValues } from "./timeFrecuency";

export const StorageFeesPeriodValues = {
  yearly: TimeFrequencyValues.yearly,
  monthly: TimeFrequencyValues.monthly,
  weekly: TimeFrequencyValues.weekly,
} as const;

export const StorageFeesPeriodKeys = {
  YEARLY: TimeFrecuencyKeys.YEARLY,
  MONTHLY: TimeFrecuencyKeys.MONTHLY,
  WEEKLY: TimeFrecuencyKeys.WEEKLY,
} as const;

export const storageFeesPeriodOptions = [
  {
    value: StorageFeesPeriodValues.yearly,
    label: StorageFeesPeriodKeys.YEARLY,
  },
  {
    value: StorageFeesPeriodValues.monthly,
    label: StorageFeesPeriodKeys.MONTHLY,
  },
  {
    value: StorageFeesPeriodValues.weekly,
    label: StorageFeesPeriodKeys.WEEKLY,
  },
] as const;
