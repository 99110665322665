import { Grid, GridContainer, isTruthy } from "@bosonprotocol/react-kit";
import {
  BridgingRoleValues,
  FormField,
  Profile,
  Role,
  RoleId,
} from "@fermionprotocol/react-kit";
import { Fragment, ReactElement, ReactNode, useCallback } from "react";
import styled from "styled-components";

const SpecificRoleTitle = styled.h4``;

type ProfileFormFieldsTemplateProps = {
  children?: ReactNode;
  Logo: ReactElement;
  CoverPicture: ReactElement;
  BridgerWhichRoleAreYouBridging: ReactElement | undefined;
  BridgerCustodianName: ReactElement | undefined;
  BridgerCustodianCategory: ReactElement | undefined;
  BridgerCustodianLocationName: ReactElement | undefined;
  BridgerCustodianLocationAddress: ReactElement | undefined;
  BridgerCustodianInsuredValue: ReactElement | undefined;
  BridgerCustodianStorageFee: ReactElement | undefined;
  BridgerCustodianShippingMethod: ReactElement | undefined;
  BridgerVerifierName: ReactElement | undefined;
  BridgerVerifierCategory: ReactElement | undefined;
  BridgerVerifierAuthenticationReport: ReactElement | undefined;
  BridgerVerifierValuationCertificate: ReactElement | undefined;
  BridgerVerifierFeePriceBands: ReactElement | undefined;
  BrigerVerifierSelfOrThirdParty: ReactElement | undefined;
  bridgingRoles: Profile["bridger"]["bridgingRoles"] | null | undefined;
  Name: ReactElement;
  Description: ReactElement;
  Email: ReactElement;
  BusinessPhoneNumber: ReactElement;
  Website: ReactElement;
  LegalTradingName: ReactElement;
  BusinessRegistrationAddress: ReactElement;
  CustodianCategory: ReactElement | undefined;
  VerifierCategory: ReactElement | undefined;
  VerifierAuthenticationReport: ReactElement | undefined;
  VerifierValuationCertificate: ReactElement | undefined;
  VerifierFeePriceBands: ReactElement | undefined;
  VerifierSelfOrThirdParty: ReactElement | undefined;
  CustodianLocationName: ReactElement | undefined;
  CustodianLocationAddress: ReactElement | undefined;
  CustodianInsuredValue: ReactElement | undefined;
  CustodianStorageFee: ReactElement | undefined;
  CustodianShippingMethod: ReactElement | undefined;
  WebsiteForFermion: ReactElement;
  withSubtitles: boolean;
  withRequired: boolean;
  roles: Role[] | undefined | null;
};
export function ProfileFormFieldsTemplate({
  children,
  Logo,
  CoverPicture,
  BridgerWhichRoleAreYouBridging,
  BridgerCustodianName,
  BridgerCustodianCategory,
  BridgerCustodianLocationName,
  BridgerCustodianLocationAddress,
  BridgerCustodianInsuredValue,
  BridgerCustodianStorageFee,
  BridgerCustodianShippingMethod,
  BridgerVerifierName,
  BridgerVerifierCategory,
  BridgerVerifierAuthenticationReport,
  BridgerVerifierValuationCertificate,
  BridgerVerifierFeePriceBands,
  BrigerVerifierSelfOrThirdParty,
  bridgingRoles,
  Name,
  Description,
  Email,
  BusinessPhoneNumber,
  Website,
  LegalTradingName,
  BusinessRegistrationAddress,
  CustodianCategory,
  VerifierCategory,
  VerifierAuthenticationReport,
  VerifierValuationCertificate,
  VerifierFeePriceBands,
  VerifierSelfOrThirdParty,
  CustodianLocationName,
  CustodianLocationAddress,
  CustodianInsuredValue,
  CustodianStorageFee,
  CustodianShippingMethod,
  WebsiteForFermion,
  withSubtitles,
  withRequired,
  roles,
}: ProfileFormFieldsTemplateProps) {
  const VerifierSpecificInfo = useCallback(
    ({
      Category,
      AuthenticationReport,
      ValuationCertificate,
      FeePriceBands,
      SelfOrThirdParty,
    }: {
      Category: ReactElement;
      AuthenticationReport: ReactElement;
      ValuationCertificate: ReactElement;
      FeePriceBands: ReactElement;
      SelfOrThirdParty: ReactElement;
    }) => {
      return (
        <>
          <FormField
            title="Asset category"
            required={withRequired}
            subTitle="Select the asset category for which you provide verification."
          >
            {Category}
          </FormField>

          <FormField
            title="Authentication services"
            subTitle="Describe your authentication services, brands or asset classes you assess, and the report(s) that will accompany your assessment."
            required={withRequired}
          >
            {AuthenticationReport}
          </FormField>

          <FormField
            title="Valuation certificate"
            subTitle="Specify whether you will provide a valuation certificate with the asset assessment and the details it will include."
            required={withRequired}
          >
            {ValuationCertificate}
          </FormField>

          <FormField
            title="Verification fees"
            required={withRequired}
            subTitle="Choose the token(s) you accept and the fee per price tier."
          >
            {FeePriceBands}
          </FormField>

          <FormField
            title="Are you a third party verifier or a self-verifier?"
            subTitle="Third party verifiers provide authentication services to sellers and their profile will be available for selection as the verifier during product creation. Self-verifiers provide authentication solely on the assets they list and sell; their profile will be hidden from other sellers for selection."
          >
            {SelfOrThirdParty}
          </FormField>
        </>
      );
    },
    [withRequired],
  );
  const CustodianSpecificInfo = useCallback(
    ({
      Category,
      LocationName,
      LocationAddress,
      InsuredValue,
      StorageFee,
      ShippingMethod,
    }: {
      Category: ReactElement;
      LocationName: ReactElement;
      LocationAddress: ReactElement;
      InsuredValue: ReactElement;
      StorageFee: ReactElement;
      ShippingMethod: ReactElement;
    }) => {
      return (
        <>
          <FormField
            title="Asset category"
            required={withRequired}
            subTitle="Select the asset category for which you provide custody services."
          >
            {Category}
          </FormField>

          <FormField
            title="Location name"
            required={withRequired}
            subTitle="We’ll display this location name to users on your profile, as well as on the asset listing."
          >
            {LocationName}
          </FormField>

          <FormField
            title="Location address"
            required={withRequired}
            subTitle="We’ll display this address to users on your profile, as well as on the asset listing."
          >
            {LocationAddress}
          </FormField>

          <FormField
            title="Insured value"
            required={withRequired}
            subTitle="Provide the total liability amount, in the location above, assumed by you for any loss of property."
          >
            {InsuredValue}
          </FormField>

          <FormField
            title="Storage fee"
            required={withRequired}
            subTitle="Choose the token(s) you accept, your storage fee, and the fee period."
          >
            {StorageFee}
          </FormField>

          <FormField
            title="Shipping method supported"
            required={withRequired}
            subTitle="Let users know whether you can arrange delivery of the asset or if you only support in person pick-up."
          >
            {ShippingMethod}
          </FormField>
        </>
      );
    },
    [withRequired],
  );
  return (
    <>
      <GridContainer
        itemsPerRow={{
          xs: 1,
          s: 2,
          m: 2,
          l: 2,
          xl: 2,
        }}
      >
        {/* TODO: Add limits */}
        <FormField
          title="Logo / Profile picture"
          subTitle="Upload a profile image with a max. width and height of 800px and a max. size of 300kb."
          required={withRequired}
        >
          {Logo}
        </FormField>
        {/* TODO: Add limits */}

        <FormField
          title="Cover picture"
          subTitle="Upload a profile image with a max. width and height of 800px and a max. size of 300kb."
          required={withRequired}

          // titleIcon={ // TODO: do we want this?
          //   <ProfilePreview
          //     address={address}
          //     profileImage={profileImage}
          //     metadataCoverImage={coverPicture}
          //     defaultIsObjectFitContain={coverPicture?.fit === "contain"}
          //     defaultPosition={defaultPosition}
          //     draggable
          //   />
          // }
        >
          {CoverPicture}
        </FormField>
      </GridContainer>
      <FormField
        title="Your business, brand, or company name"
        required={withRequired}
      >
        {Name}
      </FormField>
      <FormField
        title="Description"
        subTitle="Appears on your profile to users, giving them more information about your business."
        required={withRequired}
      >
        {Description}
      </FormField>
      <FormField title="Contact email" required={withRequired}>
        {Email}
      </FormField>
      <FormField title="Business phone number" required={withRequired}>
        {BusinessPhoneNumber}
      </FormField>
      <FormField
        title="Website / Social media link"
        subTitle={
          withSubtitles
            ? "Add your most frequently used online channel here. For social media, use the entire URL."
            : ""
        }
        required={withRequired}
      >
        {Website}
      </FormField>
      <FormField
        title="Legal trading name"
        subTitle={
          withSubtitles
            ? "Input your legal trading name under which you will be selling items. This information is used for the contractual agreement underlying your exchanges."
            : ""
        }
      >
        {LegalTradingName}
      </FormField>
      <FormField
        title="Business registration address"
        subTitle="We’ll display this address on your profile to users, and also in the contractual agreements underlying your exchange."
        required={withRequired}
      >
        {BusinessRegistrationAddress}
      </FormField>
      <FormField
        title="Website for Fermion Identity"
        subTitle="To ensure that users can verify that your account has not been impersonated, publish your Fermion identity on a website following the guidelines here." // TODO: add link
        required={withRequired}
      >
        {WebsiteForFermion}
      </FormField>
      {roles?.includes(RoleId.BRIDGER) && (
        <Grid flexDirection="column" alignItems="flex-start">
          <SpecificRoleTitle>Bridger specific info</SpecificRoleTitle>

          {BridgerWhichRoleAreYouBridging && (
            <FormField
              title="Which role/s are you bridging?"
              subTitle="Select the role(s) for which you will be acting as an authorized agent."
              required={withRequired}
            >
              {BridgerWhichRoleAreYouBridging}
            </FormField>
          )}
          {bridgingRoles?.filter(isTruthy).map(({ value }) => {
            return value === BridgingRoleValues.custodian ? (
              <Fragment key={value}>
                <SpecificRoleTitle>
                  Underlying custodian specific info
                </SpecificRoleTitle>
                <FormField title="Custodian name" required={withRequired}>
                  {BridgerCustodianName}
                </FormField>
                <CustodianSpecificInfo
                  Category={<>{BridgerCustodianCategory}</>}
                  LocationName={<>{BridgerCustodianLocationName}</>}
                  LocationAddress={<>{BridgerCustodianLocationAddress}</>}
                  InsuredValue={<>{BridgerCustodianInsuredValue}</>}
                  StorageFee={<>{BridgerCustodianStorageFee}</>}
                  ShippingMethod={<>{BridgerCustodianShippingMethod}</>}
                />
              </Fragment>
            ) : value === BridgingRoleValues.verifier ? (
              <Fragment key={value}>
                <SpecificRoleTitle>
                  Underlying verifier specific info
                </SpecificRoleTitle>
                <FormField title="Verifier name" required={withRequired}>
                  {BridgerVerifierName}
                </FormField>
                <VerifierSpecificInfo
                  Category={<>{BridgerVerifierCategory}</>}
                  AuthenticationReport={
                    <>{BridgerVerifierAuthenticationReport}</>
                  }
                  ValuationCertificate={
                    <>{BridgerVerifierValuationCertificate}</>
                  }
                  FeePriceBands={<>{BridgerVerifierFeePriceBands}</>}
                  SelfOrThirdParty={<>{BrigerVerifierSelfOrThirdParty}</>}
                />
              </Fragment>
            ) : null;
          })}
        </Grid>
      )}
      {roles?.includes(RoleId.VERIFIER) && (
        <Grid flexDirection="column" alignItems="flex-start">
          <SpecificRoleTitle>Verifier specific info</SpecificRoleTitle>
          <VerifierSpecificInfo
            Category={<>{VerifierCategory}</>}
            AuthenticationReport={<>{VerifierAuthenticationReport}</>}
            ValuationCertificate={<>{VerifierValuationCertificate}</>}
            FeePriceBands={<>{VerifierFeePriceBands}</>}
            SelfOrThirdParty={<>{VerifierSelfOrThirdParty}</>}
          />
        </Grid>
      )}
      {roles?.includes(RoleId.CUSTODIAN) && (
        <Grid flexDirection="column" alignItems="flex-start">
          <SpecificRoleTitle>Custodian specific info</SpecificRoleTitle>
          <CustodianSpecificInfo
            Category={<>{CustodianCategory}</>}
            LocationName={<>{CustodianLocationName}</>}
            LocationAddress={<>{CustodianLocationAddress}</>}
            InsuredValue={<>{CustodianInsuredValue}</>}
            StorageFee={<>{CustodianStorageFee}</>}
            ShippingMethod={<>{CustodianShippingMethod}</>}
          />
        </Grid>
      )}

      {children}
    </>
  );
}
