import { Grid, GridContainer, Typography } from "@bosonprotocol/react-kit";
import { Button, CardCTA, colors, hooks } from "@fermionprotocol/react-kit";
import { ModalTitleHeader } from "components/modal/header/ModalTitleHeader";
import { useModal } from "components/modal/useModal";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import { CheckCircle, Storefront, Users } from "phosphor-react";
import React, { useEffect } from "react";
import { fermionRoutes } from "router/routeNames";
type ProfileSuccessProps = {
  setupAnotherRole: () => void;
};

export const ProfileSuccess: React.FC<ProfileSuccessProps> = () => {
  const { updateProps, store, hideModal, showModal } = useModal();
  const navigate = useCustomNavigate();
  const { refetch } = hooks.useGetProfile();
  useEffect(() => {
    updateProps({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: <ModalTitleHeader>Congratulations!</ModalTitleHeader>,
      },
      modalMaxWidth: {
        m: "1100px",
      },
      modalMinWidth: {
        m: "900px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Grid flexDirection="column" alignItems="center" justifyContent="center">
        <CheckCircle color={colors.green} size="6.5625rem" />
        <Typography tag="h2" marginTop="0.75rem">
          Your profile is now successfully created!
        </Typography>
        <GridContainer
          itemsPerRow={{
            xs: 1,
            s: 2,
            m: 3,
            l: 3,
            xl: 3,
          }}
        >
          <CardCTA
            icon={<Users color={colors.pink} size={32} />}
            title="Setup another role"
            text="Create an additional role using the same wallet or credentials."
            cta={
              <Button
                variant="grey"
                onClick={() => {
                  showModal({ modalType: "EDIT_PROFILE" });
                }}
              >
                Add a new role
              </Button>
            }
            style={{ minWidth: "12.5rem", maxWidth: "20.625rem" }}
          />
          <CardCTA
            icon={<Storefront color={colors.pink} size={32} />}
            title="List your first asset"
            text="Create and list a new asset to begin fractional tokenization and trading."
            cta={
              <Button
                variant="grey"
                onClick={() => {
                  hideModal();
                  navigate({ pathname: fermionRoutes.createProduct });
                }}
              >
                Create new assets
              </Button>
            }
            style={{ minWidth: "12.5rem", maxWidth: "20.625rem" }}
          />
          <CardCTA
            icon={<Users color={colors.pink} size={32} />}
            title="Assign a Facilitator or Assistant"
            text="Designate an agent or assign team members to manage your account."
            cta={
              <Button
                variant="grey"
                onClick={() => {
                  showModal({ modalType: "EDIT_ROLE" });
                }}
              >
                Manage and assign roles
              </Button>
            }
            style={{ minWidth: "12.5rem", maxWidth: "20.625rem" }}
          />
        </GridContainer>
      </Grid>
    </div>
  );
};
