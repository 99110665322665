import { Grid } from "@bosonprotocol/react-kit";
import {
  Button,
  emptyInputNumber,
  getCurrencyFlagsFromOptions,
  hooks,
  Input,
  openSeaSupportedCurrencies,
  Profile,
  Select,
  tokensValues,
} from "@fermionprotocol/react-kit";
import { FieldArray } from "formik";
import { Plus, Trash } from "phosphor-react";
import { useRef } from "react";

type VerifierFeePriceBandsProps = {
  prefix: string;
  isBridger: boolean;
};
export const VerifierFeePriceBands = ({
  prefix: verifierPrefix,
  isBridger,
}: VerifierFeePriceBandsProps) => {
  const { setFieldValue, values } = hooks.useForm<Profile>();
  const { feePriceBands, feePriceBandsTokens } = isBridger
    ? values.bridger.verifier
    : values.verifier;
  const { isUsdc, isWeth } = getCurrencyFlagsFromOptions(
    feePriceBandsTokens as Parameters<typeof getCurrencyFlagsFromOptions>[0],
  );
  const heightRef = useRef<HTMLInputElement>(null);
  return (
    <Grid flexDirection="column" alignItems="flex-start" gap="1rem">
      <Grid flexDirection="column" alignItems="flex-start">
        <Select
          name={`${verifierPrefix}verifier.feePriceBandsTokens`}
          options={openSeaSupportedCurrencies}
          isMulti
          onChange={(selectedOptions) => {
            const { isWeth, isUsdc } =
              getCurrencyFlagsFromOptions(selectedOptions);
            const filteredPriceBands = feePriceBands?.filter(
              ({ min, max, weth, usdc }) =>
                min !== 0 ||
                max !== 0 ||
                (isWeth && weth !== 0) ||
                (isUsdc && usdc !== 0),
            );
            if (filteredPriceBands?.length && (isUsdc || isWeth)) {
              setFieldValue(
                `${verifierPrefix}verifier.feePriceBands`,
                filteredPriceBands.map(({ min, max, usdc, weth }) => {
                  return {
                    min,
                    max,
                    usdc: isUsdc ? usdc : emptyInputNumber,
                    weth: isWeth ? weth : emptyInputNumber,
                  };
                }),
              );
            } else if (isUsdc || isWeth) {
              setFieldValue(`${verifierPrefix}verifier.feePriceBands`, [
                {
                  min: emptyInputNumber,
                  max: emptyInputNumber,
                  usdc: emptyInputNumber,
                  weth: emptyInputNumber,
                },
              ] satisfies typeof feePriceBands);
            } else {
              setFieldValue(
                `${verifierPrefix}verifier.feePriceBands`,
                [] satisfies typeof feePriceBands,
              );
            }
          }}
        />
      </Grid>
      <FieldArray
        name={`${verifierPrefix}verifier.feePriceBands`}
        render={(arrayHelpers) => (
          <>
            {feePriceBands?.map((_, index, array) => {
              const getFeePriceBandPrefix = (i: number) =>
                `${verifierPrefix}verifier.feePriceBands[${i}]`;
              const prefix = getFeePriceBandPrefix(index);
              const UsdcComponent = (
                <>
                  {isUsdc && (
                    <Grid flexDirection="column" alignItems="flex-start">
                      <Input
                        name={`${prefix}.usdc`}
                        placeholder="USDC"
                        type="number"
                      />
                    </Grid>
                  )}
                </>
              );
              const WethComponent = (
                <>
                  {isWeth && (
                    <Grid flexDirection="column" alignItems="flex-start">
                      <Input
                        name={`${prefix}.weth`}
                        placeholder="WETH"
                        type="number"
                      />
                    </Grid>
                  )}
                </>
              );
              return (
                <Grid key={`${index}`} gap="1rem" alignItems="flex-start">
                  <Grid flexDirection="column" alignItems="flex-start">
                    <Input
                      ref={index === 0 ? heightRef : undefined}
                      type="number"
                      name={`${prefix}.min`}
                      placeholder="min $"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (index !== 0) {
                          setFieldValue(
                            `${getFeePriceBandPrefix(index - 1)}.max`,
                            value,
                          );
                        }
                        setFieldValue(`${prefix}.min`, value);
                      }}
                    />
                  </Grid>
                  <Grid flexDirection="column" alignItems="flex-start">
                    <Input
                      type="number"
                      name={`${prefix}.max`}
                      placeholder="max $"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (index !== array.length - 1) {
                          setFieldValue(
                            `${getFeePriceBandPrefix(index + 1)}.min`,
                            value,
                          );
                        }
                        setFieldValue(`${prefix}.max`, value);
                      }}
                    />
                  </Grid>
                  {feePriceBandsTokens?.[0]?.value === tokensValues.usdc ? (
                    <>
                      {UsdcComponent}
                      {WethComponent}
                    </>
                  ) : (
                    <>
                      {WethComponent}
                      {UsdcComponent}
                    </>
                  )}

                  <Grid
                    alignItems="center"
                    width="auto"
                    height={
                      heightRef.current
                        ? window.getComputedStyle(heightRef.current).height
                        : undefined
                    }
                  >
                    <Trash
                      size={18}
                      style={{ cursor: "pointer", minWidth: "1.125rem" }}
                      onClick={() => {
                        arrayHelpers.remove(index);
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
            {!!feePriceBandsTokens?.length && (
              <Button
                variant="white"
                onClick={() => {
                  arrayHelpers.push({
                    min: feePriceBands?.at(-1)?.max ?? emptyInputNumber,
                    max: emptyInputNumber,
                    usdc: emptyInputNumber,
                    weth: emptyInputNumber,
                  } satisfies NonNullable<typeof feePriceBands>[number]);
                }}
              >
                Add price band <Plus />
              </Button>
            )}
          </>
        )}
      />
    </Grid>
  );
};
