import { Grid, Typography } from "@bosonprotocol/react-kit";
import { Button } from "@fermionprotocol/react-kit";
import { useModal } from "components/modal/useModal";
import { useCustomNavigate } from "hooks/navigation/useCustomNavigate";
import React, { useEffect } from "react";
import styled from "styled-components";

interface NavItem {
  label: string;
  route: string;
  external?: boolean;
}

interface BuyerHubMobileMenuModalProps {
  navItems: NavItem[];
}

const StyledButton = styled(Button)`
  min-width: 100%;
  margin-bottom: 1rem;
  justify-content: flex-start;
  &:last-child {
    margin-bottom: 0;
  }
`;

export function BuyerHubMobileMenuModal({
  navItems,
}: BuyerHubMobileMenuModalProps): React.ReactElement {
  const { updateProps, store, hideModal } = useModal();
  const navigate = useCustomNavigate();

  useEffect(() => {
    updateProps({
      ...store,
      modalProps: {
        ...store.modalProps,
        headerComponent: (
          <Grid flexDirection="column" alignItems="flex-start">
            <Typography fontSize="1.5rem" fontWeight={500} tag="h3" margin={0}>
              Fermion Menu
            </Typography>
          </Grid>
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigation = (route: string, external?: boolean) => {
    if (external) {
      window.open(route, "_blank", "noopener,noreferrer");
    } else {
      navigate({ pathname: route });
      hideModal();
    }
  };

  return (
    <Grid
      flexDirection="column"
      alignItems="center"
      width="calc(100vw - 3.925rem)"
    >
      {navItems.map((item, index) => (
        <StyledButton
          variant="pinkTransparent"
          key={index}
          onClick={() => handleNavigation(item.route, item.external)}
        >
          <Typography fontSize="0.875rem">{item.label}</Typography>
        </StyledButton>
      ))}
    </Grid>
  );
}
