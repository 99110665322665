import {
  Grid,
  hooks as bosonHooks,
  Typography,
} from "@bosonprotocol/react-kit";
import { Button, colors } from "@fermionprotocol/react-kit";
import { ArrowCircleUp } from "phosphor-react";
import { useEffect } from "react";
import styled from "styled-components";

import { useModal } from "../../../useModal";

const StyledArrowCircleUp = styled(ArrowCircleUp)`
  * {
    stroke-width: 2px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

interface Props {
  action: string;
  txHash: string;
}
export function TransactionSubmittedModal({ action, txHash }: Props) {
  const { config } = bosonHooks.useConfigContext();
  const { updateProps, store, hideModal } = useModal();
  useEffect(() => {
    updateProps<"TRANSACTION_SUBMITTED">({
      ...store,
      modalProps: {
        ...store.modalProps,
        action,
        txHash,
      },
      modalSize: "auto",
      modalMaxWidth: {
        xs: "550px",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, txHash]);
  return (
    <Grid
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
    >
      <StyledArrowCircleUp size={128} color={colors.pink} />

      <Typography fontWeight="600" fontSize="1.5rem" lineHeight="150%">
        {action} transaction submitted
      </Typography>
      <a href={config.getTxExplorerUrl?.(txHash)} target="_blank">
        <Typography
          fontWeight="600"
          fontSize="1rem"
          lineHeight="150%"
          margin="0.5rem 0 1.5rem 0"
          color={colors.pink}
        >
          View on Explorer
        </Typography>
      </a>

      <StyledButton onClick={hideModal} variant="pinkLight">
        Close
      </StyledButton>
    </Grid>
  );
}
