import { jsx as _jsx } from "react/jsx-runtime";
import { CardCTA as BosonCardCTA, } from "@bosonprotocol/react-kit";
import { colors } from "../../styles/colors";
import { cardCtaTheme } from "./themes";
export const CardCTA = (props) => {
    return (_jsx(BosonCardCTA, { ...props, theme: cardCtaTheme, titleProps: { fontWeight: "500", fontSize: "1rem" }, textProps: {
            fontWeight: "400",
            fontSize: "0.875rem",
            color: colors.greyDark,
        } }));
};
