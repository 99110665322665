import { GridFour, ListBullets } from "phosphor-react";
import { styled } from "styled-components";

import { borders } from "../../styles/borders";
import { colors } from "../../styles/colors";

const Button = styled.button<{ $isSelected: boolean }>`
  line-height: 0;
  padding: 0.25rem 0.375rem;
  background-color: ${({ $isSelected }) =>
    $isSelected ? colors.white : "transparent"};
  cursor: pointer;
  border-radius: ${borders.small}px;
  border: ${({ $isSelected }) =>
    $isSelected ? `1px solid ${colors.velvetBorder}` : "1px solid transparent"};
`;

const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  gap: 0.25rem;
  padding: 0.38rem;
  background-color: ${colors.champagne};
  border: 1px solid ${colors.velvetBorder};
  border-radius: ${borders.medium}px;
`;

export type TableViewSelectorProps = {
  isGridSelected: boolean;
  onGridSelected: (isGridSelected: boolean) => void;
};
export const TableViewSelector = ({
  isGridSelected,
  onGridSelected,
}: TableViewSelectorProps) => {
  return (
    <Wrapper>
      <Button $isSelected={isGridSelected} onClick={() => onGridSelected(true)}>
        <GridFour size={16} />
      </Button>
      <Button
        $isSelected={!isGridSelected}
        onClick={() => onGridSelected(false)}
      >
        <ListBullets size={16} />
      </Button>
    </Wrapper>
  );
};
