import { colors } from "@fermionprotocol/react-kit";
import CheckmarkFull from "assets/icons/dashboard/sidebar/circleFull.svg?react";
import ProfileIcon from "assets/icons/dashboard/sidebar/profile.svg?react";
import { CaretDown, Circle } from "phosphor-react";
import { AgentHubSubRoutes } from "router/routeNames";

import BidsIcon from "../../assets/icons/dashboard/sidebar/bids.svg?react";
import CustodyIcon from "../../assets/icons/dashboard/sidebar/custody.svg?react";
import DashboardIcon from "../../assets/icons/dashboard/sidebar/dashboard.svg?react";
import DollarIcon from "../../assets/icons/dashboard/sidebar/dollar.svg?react";
import FinancesIcon from "../../assets/icons/dashboard/sidebar/finances.svg?react";
import ProductsIcon from "../../assets/icons/dashboard/sidebar/products.svg?react";
import VerificationsIcon from "../../assets/icons/dashboard/sidebar/verifications.svg?react";
import { FlexContainer } from "./sidebar/FlexContainer";
import { HubType } from "./types";

export const agentHubPageLabels = {
  dashboard: "Dashboard",
  products: { label: "Products", own: "Own", facilitated: "Facilitated" },
  bids: "Bids",
  exchanges: "Exchanges",
  verifications: "Verifications",
  custody: "Custody",
  finances: "Finances",
  profile: "Profile & Roles",
} as const;

export const agentHubPageTypes: Record<
  keyof typeof agentHubPageLabels,
  HubType
> = {
  dashboard: {
    url: AgentHubSubRoutes.Dashboard,
    label: agentHubPageLabels.dashboard,
    icon: <DashboardIcon />,
  },
  products: {
    urlPrefix: AgentHubSubRoutes.Products,
    url: AgentHubSubRoutes.ProductsOwn,
    label: (
      <FlexContainer>
        <span>
          {agentHubPageLabels.products.label}
          <CaretDown color={colors.purpleDeep} size="17" />
        </span>
      </FlexContainer>
    ),
    icon: <ProductsIcon />,
    subLinks: [
      {
        icon: <CheckmarkFull />,
        label: agentHubPageLabels.products.own,
        url: AgentHubSubRoutes.ProductsOwn,
      },
      {
        icon: <Circle />,
        label: agentHubPageLabels.products.facilitated,
        url: AgentHubSubRoutes.ProductsFacilitated,
      },
    ],
  },
  bids: {
    url: AgentHubSubRoutes.Bids,
    label: agentHubPageLabels.bids,
    icon: <BidsIcon />,
  },
  exchanges: {
    url: AgentHubSubRoutes.Exchanges,
    label: agentHubPageLabels.exchanges,
    icon: <FinancesIcon />,
  },
  verifications: {
    url: AgentHubSubRoutes.Verifications,
    label: agentHubPageLabels.verifications,
    icon: <VerificationsIcon />,
  },
  custody: {
    url: AgentHubSubRoutes.Custody,
    label: agentHubPageLabels.custody,
    icon: <CustodyIcon />,
  },
  finances: {
    url: AgentHubSubRoutes.Finances,
    label: agentHubPageLabels.finances,
    icon: <DollarIcon />,
  },
  profile: {
    url: AgentHubSubRoutes.Profile,
    label: agentHubPageLabels.profile,
    icon: <ProfileIcon />,
  },
} as const;
